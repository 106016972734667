import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import dashboard from '../../../utils/api/dashboard';

function Installations() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 }); // State to handle size of the area-chart
  const [selectedYear, setSelectedYear] = useState();

  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
  };

  const [barChartData, setBarChartData] = useState([]);

  // Function to get subscribers data for the year
  const getInstallationData = () => {
    dashboard.getInstallations({ year: selectedYear || 2024 })
      .then((res) => {
        setBarChartData(res?.data);
      })
      .catch((err) => {
        dispatch(setErrorNotification(err?.response?.data));
      });
  };

  useEffect(() => {
    getInstallationData();
  }, [selectedYear]);

  // Update the width and height of the area chart container
  useEffect(() => {
    const updateDimensions = () => {
      const containerWidth = document.getElementById('barChart-container').clientWidth;
      const containerHeight = document.getElementById('barChart-container').clientHeight;
      setDimensions({ width: containerWidth, height: containerHeight });
    };

    updateDimensions();
    window.addEventListener('resize', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  return (
    <div className="barchart-wrap">
      <div className="barchart-header">
        <div className="barchart-left">
          <h2>{t('totalInstallations')}</h2>
        </div>
        <div className="barchart-right">
          <select className="year-dropdown" value={selectedYear} onChange={handleYearChange}>
            <option value="2023">2023</option>
            <option selected value="2024">2024</option>
          </select>
        </div>
      </div>
      <div id="barChart-container" className="barchart-table">
        <BarChart
          width={dimensions.width}
          height={dimensions.height}
          data={barChartData}
          margin={{
            top: 40,
            left: 0,
            bottom: 5,
            right: 50,
          }}
          xAxis={[{ scaleType: 'band' }]}
        >
          <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" strokeWidth={0.5} />
          <XAxis
            dataKey="month"
            tick={{ fontSize: 12 }}
            axisLine={false}
            tickLine={false}
          />
          <YAxis
            tick={{ fontSize: 12 }}
            axisLine={false}
            tickLine={false}
          />
          <Tooltip />
          <Bar dataKey="count" fill="#1c76bb" radius={[50, 50, 50, 50]} barSize={15} />
        </BarChart>
      </div>
    </div>
  );
}

export default Installations;
