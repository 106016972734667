/* eslint-disable */
import {
    get,
    patch,
} from './base/index';

export default {
    getFeatureControllerList: () => get(`${process.env.REACT_APP_API_URL}admin/v1/licenseFeature`),
    updateFeatureControllerList: (data) => patch(`${process.env.REACT_APP_API_URL}admin/v1/licenseFeature`, { ...data }),
};
