/* eslint-disable no-param-reassign */
/* eslint no-underscore-dangle: 0 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import users from '../../utils/api/users';
import { setErrorNotification } from './NotificationSlice';

export const fetchUsersList = createAsyncThunk(
  'users/fetchUsers',
  async (data, { dispatch }) => {
    const response = await users.getUsers(data)
      .then((response1) => (
        {
          ...response1.data,
          results: response1.data.results.map((e) => ({
            ...e,
            id: e?._id,
            role_name: e?.role?.name,
            location: e?.stores?.map((store) => store.name),
          })),
          code: 200,
        }
      ))
      .catch((error) => dispatch(setErrorNotification(error?.response?.data)));
    return response;
  },
);

const initialState = {
  users: [],
  pageCount: 0,
  totalPageCount: 1,
  singleUsers: {},
  status: '',
  totalDataCount: 0,
  filter: {},
  tableFilterData: {},
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    singleUsers: (state, action) => {
      state.singleUsers = state.users.find((val) => val.id === action.payload);
    },
    singleUsersAdd: (state, action) => {
      state.singleUsers = action.payload;
    },
    addUserDetails: (state, action) => {
      state.users.unshift({
        ...action.payload,
        id: action?.payload?._id,
        role_name: action?.payload?.role?.name,
        location: action?.payload?.stores?.map((store) => store.name),
      });
    },
    updateUserDetails: (state, action) => {
      state.users = state.users.map((userDetails) => {
        let output = userDetails;
        if (userDetails.id === action?.payload?._id) {
          output = {
            ...action.payload,
            id: action?.payload?._id,
            role_name: action?.payload?.role?.name,
            location: action?.payload?.stores?.map((store) => store.name),
          };
        }
        return output;
      });
    },
    deleteUserDetails: (state, action) => {
      state.users = state.users.filter((userDetails) => userDetails.id !== action?.payload);
    },
    setTableFilterData: (state, action) => {
      state.tableFilterData = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    resetUserState: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchUsersList.pending, (state) => {
      state.status = 'loading';
    }).addCase(fetchUsersList.fulfilled, (state, action) => {
      state.status = '';
      // Add user to the state array
      if (action?.payload?.code === 200) {
        state.pageCount = action.payload.page;
        state.totalPageCount = action?.payload?.totalPages > 0 ? action?.payload?.totalPages : 1;
        const data = action?.payload?.results;
        if (action?.payload?.page === 1) {
          state.users = data;
        } else {
          const finalData = [...state.users];
          // Add fetchedData to finalData, but only if the id is not already present
          data.forEach((obj) => {
            if (!finalData.some((item) => item._id === obj._id)) {
              finalData.push(obj);
            }
          });
          state.users = finalData;
        }
      }
    });
  },
});

export const {
  singleUsers,
  singleUsersAdd,
  setTableFilterData,
  setFilter,
  addUserDetails,
  updateUserDetails,
  deleteUserDetails,
  resetUserState,
} = usersSlice.actions;

export default usersSlice.reducer;
