import { download, generateCsv, mkConfig } from 'export-to-csv';

function exportCSV(header, data) {
  const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: false,
    columnHeaders: header,
  });

  const csv = generateCsv(csvConfig)(data);
  download(csvConfig)(csv);
}

export default exportCSV;
