/* eslint-disable no-param-reassign */
/* eslint no-underscore-dangle: 0 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import plansApi from '../../utils/api/plans';
import { setErrorNotification } from './NotificationSlice';

export const fetchPlans = createAsyncThunk(
  'plans/fetchPlans',
  async (data, { dispatch }) => {
    const response = await plansApi.getPlans(data)
      .then((res) => {
        const tableData = res?.data?.results?.map((item) => ({
          ...item,
          id: item?._id,
          storage: `${item?.storage_limit?.storage || 0} ${item?.storage_limit?.unit === 'gb' ? 'GB' : 'MB'}`,
        }));
        return { ...res?.data, results: tableData, code: 200 };
      })
      .catch((err) => dispatch(setErrorNotification(err?.response?.data)));
    return response;
  },
);

export const fetchPlansWithoutTrail = createAsyncThunk(
  'plans/fetchPlansWithoutTrail',
  async (data, { dispatch }) => {
    const response = await plansApi.getPlansWithoutTrail(data)
      .then((res) => {
        const results = res?.data?.map((item) => ({
          ...item,
          id: item?._id,
          name: item?.name,
        }));
        console.log(res);
        return { results, code: 200 };
      })
      .catch((err) => dispatch(setErrorNotification(err?.response?.data)));
    return response;
  },
);

const initialState = {
  plans: [],
  plansWithoutTrail: [],
  pageCount: 0,
  totalPageCount: 1,
  totalDataCount: 0,
  status: '',
  filter: {},
  tableFilterData: {},
};

export const planSlice = createSlice({
  name: 'plan',
  initialState,
  reducers: {
    setTableFilterData: (state, action) => {
      state.tableFilterData = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    addPlanDetails: (state, action) => {
      state.plans.unshift({
        ...action.payload,
        storage: `${action?.payload?.storage_limit?.storage || 0}
        ${action?.payload?.storage_limit?.unit === 'gb' ? 'GB' : 'MB'}`,
        id: action?.payload?._id,
      });
    },
    resetPlanState: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlans.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPlans.fulfilled, (state, action) => {
        state.status = 'success';
        if (action?.payload?.code === 200) {
          const data = action?.payload?.results;
          state.pageCount = (action.payload.page + 1);
          state.totalPageCount = action?.payload?.totalPageCount === 0 ? 1 : action?.payload?.totalPageCount;
          if (action?.payload?.page === 1) {
            state.plans = data;
          } else {
            const finalData = [...state.plans];
            // Add fetchedData to finalData, but only if the id is not already present
            data.forEach((obj) => {
              if (!finalData.some((item) => item._id === obj._id)) {
                finalData.push(obj);
              }
            });
            state.plans = finalData;
          }
        }
      });

    builder
      .addCase(fetchPlansWithoutTrail.fulfilled, (state, action) => {
        if (action?.payload?.code === 200) {
          state.plansWithoutTrail = action.payload?.results;
        }
      });
  },
});

export const {
  setTableFilterData,
  setFilter,
  resetPlanState,
  addPlanDetails,
} = planSlice.actions;

export default planSlice.reducer;
