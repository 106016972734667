import {
  get,
  post,
  patch,
} from './base/index';

export default {
  getSubscription: (data = {}) => {
    const params = { ...data, limit: 20 };
    return get(`${process.env.REACT_APP_API_URL}admin/v1/subscription`, { params });
  },
  getSingleSubscription: (data) => get(`${process.env.REACT_APP_API_URL}admin/v1/subscription/${data}`, {}),
  addSubscription: (data = {}) => {
    const body = { ...data };
    return post(`${process.env.REACT_APP_API_URL}admin/v1/subscription/`, body);
  },
  updateSubscription: (data = {}) => {
    const body = {
      ...data,
      id: undefined,
    };
    return patch(`${process.env.REACT_APP_API_URL}admin/v1/subscription/${data.id}`, body);
  },
  approveSubscription: (data = {}) => {
    const body = {
      ...data,
      id: undefined,
    };
    return patch(`${process.env.REACT_APP_API_URL}admin/v1/subscription/approve/${data.id}`, body);
  },
  calculatePrice: (data = {}) => {
    const body = { ...data };
    return post(`${process.env.REACT_APP_API_URL}admin/v1/subscription/calculatePrice`, { ...body });
  },
  editSubscription: (data = {}) => {
    const body = {
      ...data,
      id: undefined,
    };
    return patch(`${process.env.REACT_APP_API_URL}admin/v1/subscription/edit/${data.id}`, body);
  },
  editSubscriptionPlan: (data = {}) => {
    const body = {
      plan: data.plan,
      create_payment_link: data.create_payment_link,
    };
    return patch(`${process.env.REACT_APP_API_URL}admin/v1/subscription/edit-plan/${data.id}`, body);
  },
  editSubscriptionDevice: (data = {}) => {
    const body = {
      device: Number(data.deviceCount),
      storage: Number(data.addOnStorage),
      plan: data.plan,
      create_payment_link: data.create_payment_link,
    };
    return patch(`${process.env.REACT_APP_API_URL}admin/v1/subscription/edit-device/${data.id}`, body);
  },
  editSubscriptionAddOn: (data = {}) => {
    const body = {
      storage: Number(data.addOnStorage),
      device: Number(data.deviceCount),
      plan: data.plan,
      create_payment_link: data.create_payment_link,
    };
    return patch(`${process.env.REACT_APP_API_URL}admin/v1/subscription/edit-addOn/${data.id}`, body);
  },
  deleteSubscription: (data = {}) => {
    const params = data;
    return patch(
      `${process.env.REACT_APP_API_URL}admin/v1/subscription/cancel/${params}`,
      { status: 'cancelled' },
    );
  },
  deletePendingSubscription: (data = {}) => {
    const params = data;
    return patch(
      `${process.env.REACT_APP_API_URL}admin/v1/subscription/cancel-pending-subscription/${params}`,
      { status: 'cancelled' },
    );
  },
  addExtendSubscription: (data = {}) => {
    const body = {
      no_of_days: data.on_of_days,
    };
    if (data.reason) {
      body.reason = data.reason;
    }
    console.log(body);
    return patch(`${process.env.REACT_APP_API_URL}admin/v1/subscription/extend/${data.id}`, body);
  },
  exportSubscriptionData: (data = {}) => {
    const body = { ...data };
    return post(`${process.env.REACT_APP_API_URL}admin/v1/subscription/export`, { ...body });
  },
};
