import {
  get,
} from './base/index';

export default {
  getContacts: (data = {}) => {
    const params = { ...data, limit: 10 };
    return get(`${process.env.REACT_APP_API_URL}admin/v1/contact`, { params });
  },
  exportContactData: (data = {}) => {
    const params = { ...data };
    return get(`${process.env.REACT_APP_API_URL}admin/v1/contact/export`, { params });
  },
};
