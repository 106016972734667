import {
  get,
  patch,
  post,
} from './base/index';

export default {
  getPlayerAppVersions: (data = {}) => {
    const params = { ...data };
    return get(`${process.env.REACT_APP_API_URL}admin/v1/playerApp`, { params });
  },
  addPlayerVersion: (data = {}) => post(`${process.env.REACT_APP_API_URL}admin/v1/playerApp`, data),
  updatePlayerVersion: (data, id) => patch(`${process.env.REACT_APP_API_URL}admin/v1/playerApp/${id}`, data),
};
