/* eslint no-underscore-dangle: 0 */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, createTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import deepEqual from 'deep-equal';

import { fetchPlans } from '../../redux/slices/PlanSlice';
import {
  fetchFeatureController,
  fetchFeatureSubFeature,
} from '../../redux/slices/FeatureControllerSlice';

import TopContent from '../../Shared/Components/TopContent';
import Button from '../../Shared/Components/Button';
import ModalBox from '../../Shared/Components/ModalBox';
import Success from '../../Shared/Components/Success';
import featureApi from '../../utils/api/feature';
import { setErrorNotification } from '../../redux/slices/NotificationSlice';
import ConfirmationPopup from '../../Shared/Components/ConfirmationPopup';
import { checkPermissionAllow } from '../../utils/helpers';

function FeatureController() {
  // Custom CSS for Checkbox
  const { t } = useTranslation();
  const theme = createTheme({
    components: {
      MuiCheckbox: {
        styleOverrides: {
          root: {
            padding: '0px',
            color: '#CACACA',
          },
        },
      },
    },
  });

  const dispatch = useDispatch();
  const { plans } = useSelector((state) => state.plans);
  const {
    featureList,
    featureController,
  } = useSelector((state) => state.feature);

  const [featureControllerList, setFeatureControllerList] = useState([]);
  // State to button enable & disable
  const [buttonState, setButtonState] = useState('disable');
  // State to handle update notification & button loading
  const [updateNotification, setUpdateNotification] = useState('close');
  const [modalView, setModalView] = useState({});

  // Update feature data based the input value
  const handleChange = (event, singleFeature, type, dropdown) => {
    setFeatureControllerList((prevState) => prevState.map((item) => {
      // Update the value based on plan, feature and sub_feature
      if (
        item.plan === singleFeature.plan
        && item.sub_feature === singleFeature.sub_feature
        && item.feature === singleFeature.feature
      ) {
        let finalValue = { ...item };
        if (type === 'input') {
          if (dropdown) {
            finalValue = {
              ...item,
              [dropdown]: event.target.value,
            };
          } else {
            finalValue = {
              ...item,
              value: event.target.value,
            };
          }
        } else if (type === 'boolean') {
          finalValue = {
            ...item,
            value: !item.value,
          };
        }
        return finalValue;
      }
      return item; // Return the item unchanged if it doesn't match the condition
    }));
  };

  // Reset the entire data set
  const handleReset = () => {
    setUpdateNotification('close');
    setFeatureControllerList((prevState) => prevState.map((item) => {
      let finalValue = item.value;
      if (item.field_type === 'input') {
        finalValue = 0;
      } else if (item.field_type === 'boolean') {
        finalValue = false;
      }
      return {
        ...item, // Copy all existing properties of the item
        value: finalValue, // Update the value property
      };
    }));
  };

  // Function to handle update controller API
  const handleSubmit = (type) => {
    const updateFeatureData = [];

    // Filter the updated values and append to the new_array
    featureControllerList.forEach((item, index) => {
      if (
        item.value !== featureController[index].value
        || item.storage_unit !== featureController[index].storage_unit
        || item.storage_type !== featureController[index].storage_type
      ) {
        updateFeatureData.push({
          feature: item.feature,
          sub_feature: item.sub_feature,
          plan: item.plan,
          feature_key: item.feature_key,
          value: item.value,
          storage_unit: item.storage_unit || undefined,
          storage_type: item.storage_type || undefined,
        });
      }
    });

    // Update subFeature list
    setUpdateNotification('loading');
    featureApi.updateFeatureControllerList({ updateFeatureData, old_user_enable_access: type === 'Yes' })
      .then(() => {
        setModalView({ content: <Success message={t('featureControllerUpdated')} /> });
        setUpdateNotification('open');
        setTimeout(() => {
          dispatch(fetchFeatureController());
          setUpdateNotification('close');
        }, 3000);
      })
      .catch((err) => {
        // Show error in snackbar
        dispatch(setErrorNotification(err?.response?.data));
        setUpdateNotification('close');
      });
  };

  // Function to handle update confirmation
  const updateConfirmation = () => {
    setUpdateNotification('open');
    setModalView({
      content: <ConfirmationPopup
        title={t('updateConfirmation')}
        description={t('changesForSubscribersConfirmation')}
        onCloseFunction={() => handleSubmit('No')}
        closeButtonLabel={t('no')}
        onConfirmFunction={() => handleSubmit('Yes')}
        confirmButtonLabel={t('yes')}
        confirmButtonFontColor="#FFF"
        confirmButtonColor="#1C75BC"
        confirmButtonHoverColor="#1C75BC"
      />,
    });
  };

  // Function to handle reset confirmation
  const resetConfirmation = () => {
    setUpdateNotification('open');
    setModalView({
      content: <ConfirmationPopup
        title={t('confirmResetFeatureController')}
        description={t('resetConfirmationMessage')}
        onCloseFunction={() => setUpdateNotification('close')}
        closeButtonLabel={t('back')}
        onConfirmFunction={() => handleReset('Yes')}
        confirmButtonLabel={t('confirm')}
        confirmButtonFontColor="#FFF"
        confirmButtonColor="#E8212E"
        confirmButtonHoverColor="#E8212E"
      />,
    });
  };

  // FeatureController will be updated after fetchFeatureController() dispatch
  useEffect(() => {
    setFeatureControllerList(featureController);
  }, [featureController]);

  // useEffect to handle button disable state
  useEffect(() => {
    if (!deepEqual(featureControllerList, featureController)) {
      setButtonState('enable');
    } else {
      setButtonState('disable');
    }
  }, [featureControllerList]);

  // If plan array is empty, dispatch plans Slice
  useEffect(() => {
    dispatch(fetchPlans({ page: 1 }));
    dispatch(fetchFeatureSubFeature());
    dispatch(fetchFeatureController());
  }, []);

  return (
    <div className="main-container">
      <ModalBox
        status={updateNotification === 'open'}
        closeModal={setUpdateNotification}
        modalView={modalView}
        notification
      />
      <TopContent
        label={t('featureController')}
      />
      <div className="content-area">
        <div className="controller-wrap">
          <table>
            <thead className="sticky-header">
              <tr>
                <th label="empty-header" />
                {
                  plans.map((plan) => (
                    <th>{plan?.name}</th>
                  ))
                }
              </tr>
            </thead>
            <tbody>
              {/* Map main feature */}
              {featureList?.map((feature) => (
                <>
                  <tr>
                    <td className="feature-header">{t(feature?.name)}</td>
                    <td label="empty" />
                  </tr>
                  {/* Map main sub feature */}
                  {feature?.sub_features?.map((subFeature) => (
                    <tr>
                      <td className="feature-data">{subFeature?.name}</td>
                      {/* Map plans and filter each field based featureId, subFeatureId and PlanId */}
                      {plans?.map((plan) => {
                        let inputField;
                        const filterInputType = featureControllerList?.filter((item) => plan.id === item.plan
                          && subFeature._id === item.sub_feature
                          && feature._id === item.feature);
                        const singleFeature = filterInputType[0];
                        if (singleFeature?.field_type === 'boolean') {
                          inputField = (
                            <td className="feature-data">
                              <Checkbox
                                theme={theme}
                                aria-label="Checkbox"
                                checked={singleFeature.value}
                                onChange={(e) => handleChange(e, singleFeature, 'boolean')}
                              />
                            </td>
                          );
                        } else if (singleFeature?.field_type === 'input') {
                          inputField = (
                            <td label="input" className="feature-data">
                              <input
                                type="number"
                                value={singleFeature.value}
                                onChange={(e) => handleChange(e, singleFeature, 'input')}
                              />
                              {singleFeature?.dropdown
                                && (
                                  <div className="feature-data-dropdown">
                                    <select
                                      value={singleFeature?.storage_unit}
                                      onChange={(e) => handleChange(e, singleFeature, 'input', 'storage_unit')}
                                    >
                                      <option value="mb">{t('mb')}</option>
                                      <option value="gb">{t('gb')}</option>
                                    </select>
                                    <select
                                      value={singleFeature?.storage_type}
                                      onChange={(e) => handleChange(e, singleFeature, 'input', 'storage_type')}
                                    >
                                      <option value="fixed">{t('fixed')}</option>
                                      <option value="perDevice">{t('perDevice')}</option>
                                    </select>
                                  </div>
                                )}
                            </td>
                          );
                        } else {
                          inputField = <td className="feature-data" label="empty-data" />;
                        }
                        return inputField;
                      })}
                    </tr>
                  ))}
                </>
              ))}
            </tbody>
          </table>
          <div className="controller-buttons">
            <Button
              label={t('reset')}
              classes="default-button"
              click={resetConfirmation}
            />
            {
              checkPermissionAllow('editFeatureController') && (
                <Button
                  label={updateNotification === 'loading' ? t('updating') : t('update')}
                  loading={updateNotification === 'loading'}
                  classes={
                    updateNotification === 'loading'
                      ? 'success-button loading-btn'
                      : 'success-button'
                  }
                  click={updateConfirmation}
                  disabled={buttonState === 'disable'}
                />
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeatureController;
