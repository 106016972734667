import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import TopContent from '../../Shared/Components/TopContent';
import Pending from './Components/PendingTable';
import ProcessedTable from './Components/ProcessedTable';
import FulfillTable from './Components/FulfillTable';
import { checkPermissionAllow } from '../../utils/helpers';

function DeviceOrder() {
  const { t } = useTranslation();
  const params = useParams();
  const menus = [
    {
      key: 'processed',
      enable: checkPermissionAllow('processedGetDeviceOrder'),
    },
    {
      key: 'pending',
      enable: checkPermissionAllow('pendingGetDeviceOrder'),
    },
    {
      key: 'fulfillment',
      enable: checkPermissionAllow('fulfillmentGetDeviceOrder'),
    },
  ];
  const navigate = useNavigate();
  const addOrder = () => {
    navigate('../add');
  };
  return (
    <div className="main-container">
      <TopContent
        label={t('deviceOrder')}
        buttonClass="success-button"
        button={checkPermissionAllow('addDeviceOrder')}
        buttonLabel={t('newOrder')}
        click={addOrder}
      />
      <div className="content-area">
        <div className="my-content">
          <div className="my-content-area">
            <div className="subscription-menu">
              {
                menus?.map((menu) => menu.enable && (
                  <div
                    role="presentation"
                    className={params?.page === menu.key ? 'each-menu menu-active' : 'each-menu'}
                    onClick={() => navigate(`../${menu.key}`)}
                  >
                    {t(menu.key)}
                  </div>
                ))
              }
            </div>
            {
              params?.page === 'processed' && checkPermissionAllow('processedGetDeviceOrder') && <ProcessedTable />
            }
            {
              params?.page === 'pending' && checkPermissionAllow('pendingGetDeviceOrder') && <Pending />
            }
            {
              params?.page === 'fulfillment' && checkPermissionAllow('fulfillmentGetDeviceOrder') && <FulfillTable />
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeviceOrder;
