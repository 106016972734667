import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import loginImage from '../../assets/images/adminPortalLogo.jpg';
import passwordIcon from '../../assets/icons/password1.png';

import { Input1 } from '../../Shared/Components/Form/Input';
import Button from '../../Shared/Components/Button';

import auth from '../../utils/api/auth';

function ResetPassword() {
  const { search } = useLocation();
  const token = useMemo(() => new URLSearchParams(search).get('token'), [search]);
  const [value, setValue] = useState({});
  const [errorMessage, setError] = useState('');
  const [buttonStatus, setButtonStatus] = useState('disabled');
  const navigate = useNavigate();

  const changeHandle = (name, changeValue) => {
    setValue({ ...value, [name]: changeValue });
  };

  const submit = () => {
    if (value.password === value.confirm_password) {
      setButtonStatus('loading');
      auth.resetPassword({ password: value.password }, token)
        .then(() => {
          navigate('/login');
          setButtonStatus('enabled');
        })
        .catch((error) => {
          setButtonStatus('enabled');
          if (error?.response?.data?.code === 401) {
            setError(error?.response?.data?.message);
          }
          if (error?.response?.data?.code === 400) {
            setError(error?.response?.data?.message);
          }
        });
    } else {
      setError('Passwords do not match');
    }
  };

  useEffect(() => {
    if (value?.password && value?.confirm_password) {
      setButtonStatus('enabled');
    }
  }, [value]);

  return (
    <div className="login-wrap">
      <div className="login-banner">
        <img src={loginImage} className="login-image" alt="" />
      </div>
      <div className="login-form-wrap">
        <div className="login-form-container">
          <div className="login-form-header">
            <h1>Reset Password</h1>
            <p>{errorMessage}</p>
            <div className="login-form">
              <Input1
                icon={passwordIcon}
                label="New Password"
                type="password"
                name="password"
                change={changeHandle}
              />
              <Input1
                icon={passwordIcon}
                label="Confirm Password"
                type="password"
                name="confirm_password"
                change={changeHandle}
              />
              <Button
                label="Reset Password"
                click={submit}
                classes="success-button"
                disabled={buttonStatus === 'disabled'
                  || buttonStatus === 'loading'}
                loading={buttonStatus === 'loading'}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
