import {
  get,
  post,
  patch,
  deletes,
} from './base/index';

export default {
  getDeviceCatalogues: (data = {}) => {
    const params = { ...data };
    return get(`${process.env.REACT_APP_API_URL}admin/v1/deviceCatalog`, { params });
  },
  getDeviceCatalogueAll: (data = {}) => {
    const params = { ...data };
    return get(`${process.env.REACT_APP_API_URL}admin/v1/deviceCatalog/getAllDeviceCatalogList`, { params });
  },
  getSingleDeviceCatalogue: (data) => get(`${process.env.REACT_APP_API_URL}admin/v1/deviceCatalog/${data}`, {}),
  addDeviceCatalogue: (data = {}) => post(`${process.env.REACT_APP_API_URL}admin/v1/deviceCatalog`, data, true),
  updateDeviceCatalogue:
    (data = {}, id = '') => patch(`${process.env.REACT_APP_API_URL}admin/v1/deviceCatalog/${id}`, data, true),
  deleteDeviceCatalogue: (data) => deletes(`${process.env.REACT_APP_API_URL}admin/v1/deviceCatalog/${data}`, {}),
};
