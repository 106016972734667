import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Button from '../../../Shared/Components/Button';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import { removePendingById } from '../../../redux/slices/LicenseTransactionSummarySlice';
import licenses from '../../../utils/api/licenses';

function OrderSummary({ closeModal, transaction }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [successNotification, setSuccessNotification] = useState('');
  console.log(successNotification);
  const navigateBack = () => {
    closeModal();
  };
  const submit = () => {
    setSuccessNotification('open');
    const verifyData = {
      id: transaction.id,
      status: 'active',
    };
    licenses.verifyPayment(verifyData)
      .then(() => {
        setTimeout(() => {
          setSuccessNotification('');
          dispatch(removePendingById(transaction.id));
          closeModal();
        }, 3000);
      })
      .catch((err) => {
        dispatch(setErrorNotification(err?.response?.data));
      });
  };

  return (
    <div className="license-summary-wrap-content">
      <div className="license-summary-containers">
        <div className="license-summary">
          <div className="license-content-summary">
            <div className="single-row-content">
              <p>{t('planName')}:</p>
              <p>{transaction?.plan_name}</p>
            </div>
            <div className="single-row-content">
              <p>{t('account')}:</p>
              <p>{transaction?.company_name}</p>
            </div>
          </div>
          <div className="license-content-summary">
            <div className="single-row-content">
              <p>{t('totalAmount')}:</p>
              <p>{transaction?.transaction_amount}</p>
            </div>
            <div className="single-row-content">
              <p>{t('licenseType')}:</p>
              <p>{transaction?.license_type}</p>
            </div>
          </div>
          <h3>{t('transactionDetails')}:</h3>
          <div className="license-content-summary">
            <p>{t('transactionID')}:</p>
            <p>{transaction?.transaction_id}</p>
            <p>{t('transactionDate')}:</p>
            <p>{transaction?.transaction_date}</p>
            <p>{t('transactionMode')}:</p>
            <p>{transaction?.transaction_type}</p>
            <p>{t('transactionAmount')}:</p>
            <p>{transaction?.price_to_display}</p>
          </div>
        </div>
        {successNotification === 'open' && (
          <div className="success-notification">
            <p>{t('paymentVerifiedSuccess')}</p>
          </div>
        )}
        <div className="form-button-group" style={{ padding: '10px 0px' }}>
          <div className="form-button">
            <Button
              label={t('back')}
              click={navigateBack}
              classes="default-button"
            />
          </div>
          <div className="form-button">
            <Button
              label={t('verifyPayment')}
              click={submit}
              classes="success-button"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

OrderSummary.propTypes = {
  closeModal: PropTypes.func.isRequired,
  transaction: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      transaction_id: PropTypes.string.isRequired,
      transaction_type: PropTypes.string.isRequired,
      transaction_date: PropTypes.string.isRequired,
      transaction_amount: PropTypes.number.isRequired,
      is_verified: PropTypes.string.isRequired,
      company_name: PropTypes.string.isRequired,
      license_type: PropTypes.string.isRequired,
      plan_name: PropTypes.string.isRequired,
      price_to_display: PropTypes.string.isRequired,
      verifyPayment: PropTypes.func.isRequired, // Add this line
    }),
  ).isRequired,
};

export default OrderSummary;
