/* eslint no-underscore-dangle: 0 */

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import TopContent from '../../../Shared/Components/TopContent';
import TableForm from '../../../Shared/Components/TableForm';
import updateSummaryHeader from '../../../assets/json/payment-summary-header.json';
import { Input, InputDate, InputInnerSymbol } from '../../../Shared/Components/Form/Input';
import subscription from '../../../utils/api/subscription';
import Button from '../../../Shared/Components/Button';
import ModalBox from '../../../Shared/Components/ModalBox';
import Success from '../../../Shared/Components/Success';
import {
  changeSuccessStatus,
  fetchPendingSubscription,
  updateTransaction,
} from '../../../redux/slices/PendingSubscriptionSlice';
import SelectBox from '../../../Shared/Components/Form/SelectBox';
import { currencySymbol, currencySymbolCode } from '../../../utils/helpers';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';

function UpdatePayment() {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Data of the pending subscriptions
  const {
    pendingSubscription,
    successNotification,
  } = useSelector((state) => state.subscription);

  // Available payment options
  const paymentOption = [
    {
      label: 'Net banking',
    },
    {
      label: 'UPI',
    },
    {
      label: 'Other',
    },
  ];

  // To find the selected payment based on ID the in params
  const [updateSummary, setUpdateSummary] = useState(
    [pendingSubscription?.find((each) => each?.id === params?.id) || []],
  );

  // Set the payment amount in submit value
  const [payment, setPayment] = useState({
    transaction_amount: updateSummary?.[0]?.total_price,
  });

  // To handle date in Mui date picker
  const [selectedDate, setSelectedDate] = useState({});

  // Button status
  const [disable, setDisable] = useState(true);
  const [buttonStatus, setButtonStatus] = useState('');

  // To handle change event from input and select tag
  const handleChange = (name, value) => {
    if (name === 'transaction_date') {
      setSelectedDate({ ...selectedDate, [name]: value });
      setPayment({ ...payment, [name]: dayjs(value).format('YYYY-MM-DD') });
    } else if (name === 'start_date') {
      const tempDate = new Date(dayjs(value).format('YYYY-MM-DD'));
      const endDate = new Date(tempDate.setMonth(tempDate.getMonth() + Number(updateSummary?.[0]?.tenure)));
      setSelectedDate({
        ...selectedDate,
        [name]: value,
        end_date: dayjs(endDate),
      });
      setPayment({
        ...payment,
        [name]: dayjs(value).format('YYYY-MM-DD'),
        end_date: dayjs(endDate).format('YYYY-MM-DD'),
      });
    } else if (name === 'transaction_amount') {
      setPayment({ ...payment, [name]: Number(value) });
    } else if (name === 'transaction_type' && value) {
      setPayment({ ...payment, [name]: value?.label });
    } else {
      setPayment({ ...payment, [name]: value });
    }
  };

  // Set the subscription view in pending state
  const clickFunction = () => {
    navigate('../', { state: { selected: 'Pending' } });
  };

  // Update the payment
  const submit = () => {
    setButtonStatus('');
    const data = {
      ...payment,
      id: updateSummary?.[0]?._id,
    };
    setButtonStatus('loading');
    dispatch(updateTransaction(data));
  };

  // Show success notification and redirect to subscription pending
  useEffect(() => {
    if (successNotification) {
      setButtonStatus('success');
      setTimeout(() => {
        setButtonStatus('');
        dispatch(changeSuccessStatus(false));
        navigate('../', { state: { selected: 'Pending' } });
      }, 3000);
    } else {
      setButtonStatus('');
      dispatch(changeSuccessStatus(null));
    }
  }, [successNotification]);

  // Change the status of the button based on input
  useEffect(() => {
    if (payment?.transaction_id
      && payment?.transaction_type
      && payment?.transaction_date
      && payment?.transaction_amount
    ) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [payment]);

  useEffect(() => {
    if (!updateSummary[0]?.id) {
      subscription.getSingleSubscription(params?.id)
        .then((res) => {
          dispatch(fetchPendingSubscription(1));
          const item = res?.data;
          setUpdateSummary([
            {
              ...item,
              id: item?._id,
              product: item?.new_plan_id?.product,
              plan_type: item?.new_plan_id?.plan_type,
              plan_name: item?.new_plan_id?.name,
              plan_storage: `${item?.new_plan_id?.storage_limit?.storage} ${item?.new_plan_id?.storage_limit?.unit}`,
              company_id: item?.company?._id,
              company_name: item?.company?.name,
              device_limit: item?.device?.count,
              total_price_toShow: currencySymbol(item?.currency, item?.total_price),
              order_id: item?.subscription?.order_id,
              start_date: item?.start_date ? new Date(item?.start_date).toISOString().split('T')[0] : '-',
              end_date: item?.end_date ? new Date(item?.end_date).toISOString().split('T')[0] : '-',
            },
          ]);
          setPayment({
            transaction_amount: item?.total_price,
          });
        })
        .catch((err) => dispatch(setErrorNotification(err?.response?.data)));
    }
  }, [params]);

  return (
    <div className="main-container">
      <ModalBox
        status={buttonStatus === 'success'}
        closeModal={() => setButtonStatus('')}
        modalView={{ content: <Success message="Payment Updated Successfully!!" /> }}
        notification
      />
      <TopContent
        label={[t('subscriptions'), t('foodNetwork')]}
      />
      <div className="content-area">
        <div className="summary-wrap">
          <div className="summary-container">
            <div className="summary-header">
              <h3>
                {t('productSummary')}
                <span>
                  ({updateSummary?.[0]?.start_date} to {updateSummary?.[0]?.end_date})
                </span>
              </h3>
            </div>
            <TableForm
              header={updateSummaryHeader}
              value={updateSummary}
              checkBox={false}
            />
          </div>
          <div className="payment-info">
            <h2>{t('paymentInformation')}</h2>
            <div className="single-row">
              <Input
                type="text"
                label={t('transactionID')}
                required
                name="transaction_id"
                value={payment?.transaction_id}
                change={handleChange}
                placeholder={t('enterTransactionID')}
              />
              <InputDate
                label={t('transactionDate')}
                name="transaction_date"
                required
                value={selectedDate?.transaction_date}
                change={handleChange}
                disableFuture
              />
            </div>
            <div className="single-row">
              <SelectBox
                label={t('transactionMode')}
                name="transaction_type"
                required
                value={payment.transaction_type}
                onchange={handleChange}
                lists={paymentOption}
                id="select-account"
                placeholder={t('selectCompanyName')}
              />
              <InputInnerSymbol
                type="number"
                label={t('transactionAmount')}
                required
                value={updateSummary?.[0]?.total_price}
                placeholder="0"
                symbol={currencySymbolCode(updateSummary?.[0]?.currency)}
                min={0}
                readonly
              />
            </div>
            <div className="form-button-group">
              <div className="form-button">
                <Button label={t('back')} click={clickFunction} classes="default-button" />
              </div>
              <div className="form-button">
                <Button
                  label={buttonStatus === 'loading' ? t('creating') : t('create')}
                  click={submit}
                  classes="success-button"
                  disabled={disable}
                  loading={buttonStatus === 'loading'}
                />
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdatePayment;
