import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';
import { Drawer, IconButton } from '@mui/material';
import { HamburgerIcon, CloseIcon } from './svgIcon'; // Assuming svgIcon contains these icons

import profileImage from '../../assets/images/download.jpg';
import language from '../../assets/icons/language.png';
import dropdownArrow from '../../assets/icons/arrow-down.png';

import DropDown from './DropDown';
import DropDownMenu from './Form/DropDownMenu';

import auth from '../../utils/api/auth';
import { setErrorNotification } from '../../redux/slices/NotificationSlice';
import SideBar from './SideBar'; // Import SideBar component
import logoImage from '../../assets/images/adminLogo.png';
import { resetAllSlices } from '../../redux/slices/ReduxStateResetSlice';
import { removeCookieStorageItem } from '../../utils/helpers';

function Header(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [user, setUser] = useState({});
  const [drawerOpen] = useState(false);
  const currentLanguageCode = cookies.get('i18next') || 'en';

  const logout = () => {
    const data = cookies.get('secure-auth-refresh');
    if (data) {
      auth.logout()
        .then((response) => {
          if (response.status === 204) {
            localStorage.removeItem('data');
            removeCookieStorageItem('secure-auth-access');
            removeCookieStorageItem('secure-auth-refresh');
            navigate('/login');
            dispatch(resetAllSlices());
          }
        })
        .catch((error) => {
          dispatch(setErrorNotification(error?.response?.data));
        });
    } else {
      navigate('/login');
      dispatch(resetAllSlices());
    }
  };

  const actions = [
    {
      name: t('logout'),
      url: '',
      function: logout,
      key: 'logout',
      enable: true,
    },
  ];

  const handleLanguage = (code) => {
    i18next.changeLanguage(code);
    window.location.reload();
  };

  const menuList = [
    {
      code: 'en',
      name: 'English',
      function: handleLanguage,
      disabled: currentLanguageCode === 'en',
    },
    {
      code: 'ar',
      name: 'عربي (Arabic)',
      function: handleLanguage,
      disabled: currentLanguageCode === 'ar',
    },
  ];

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem('data'));
    if (!data) {
      navigate('/login');
      dispatch(resetAllSlices());
    } else {
      setUser(data.user);
    }
  }, [props]);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    props?.setDrawerOpen(open);
  };

  return (
    <div className="header">
      <div className="hamburger-icon">
        <IconButton onClick={toggleDrawer(true)}>
          <HamburgerIcon />
        </IconButton>
        <div className="header-logo">
          <img src={logoImage} alt="logo" />
        </div>
      </div>
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        className="sidebar-drawer"
      >
        <div className="drawer-content">
          <div className="drawer-header">
            <IconButton onClick={toggleDrawer(false)}>
              <CloseIcon color="#000" />
            </IconButton>
          </div>
          <div className="drawer-menu">
            <SideBar />
          </div>
        </div>
      </Drawer>
      <div className="datetime">
        {/* <div>GMT{timeZone}</div>
        <div>{time}</div> */}
      </div>
      <div className="right-navbar">
        <div className="language-wrap">
          <p>{currentLanguageCode === 'en' ? 'English' : 'عربي '}</p>
          <DropDownMenu
            dropdown="dropdown"
            icon={language}
            action={menuList}
            height="24px"
            tooltip={t('language')}
          />
        </div>
        {/* <div className="notification-icon">
          <img src={notification} alt="notification" />
        </div> */}
        <div className="profile-button">
          <div className="avatar-image">
            <img src={profileImage} alt="avatarimage" />
          </div>
          <div className="user-name">
            <label className="name" htmlFor="Super Admin">{user?.name}</label>
            <span className="role"> {user?.user_name}</span>
          </div>
          <div className="dropdown-arrow-icon">
            <DropDown action={actions} value={{}} icon={dropdownArrow} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
Header.propTypes = {
  setDrawerOpen: PropTypes.func.isRequired,
};
