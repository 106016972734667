/* eslint no-underscore-dangle: 0 */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import more from '../../../assets/icons/more.png';

import Table from '../../Table';
import { setSelectedOrders } from '../../../redux/slices/TransactionPendingSlice';
import {
  fetchDeviceRentalPending,
  removeDeviceRentalPendingById,
  setFilter,
  setTableFilterData,
} from '../../../redux/slices/RentalPendingSlice';
import DropDown from '../../../Shared/Components/DropDown';
import transaction from '../../../utils/api/transaction';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import ModalBox from '../../../Shared/Components/ModalBox';
import Success from '../../../Shared/Components/Success';
import { checkPermissionAllow } from '../../../utils/helpers';
import height from '../../../utils/size-variables';

function RentalPending() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    deviceRentalPending,
    status,
    pageCount,
    totalPageCount,
    totalDataCount,
    filter,
    tableFilterData,
  } = useSelector((state) => state.rentalPending);

  const statusList = [
    { value: 'pending', label: 'Pending' },
    { value: 'payment_updated', label: 'Payment Updated' },
  ];

  const [successNotification, setSuccessNotification] = useState('');

  // Table state variables
  const [rowSelection, setRowSelection] = useState({});
  const [columnVisibility, setColumnVisibility] = useState({});
  const [isFirstRender, setIsFirstRender] = useState(true);

  const updateSinglePayment = (id) => {
    const selectedOrders = deviceRentalPending.find((data) => data.id === id);
    dispatch(setSelectedOrders({
      selectedOrders: [selectedOrders],
      updatePaymentType: 'device-rental',
    }));
    navigate('../../payment/summary');
  };

  const verifyPayment = (singleOrder) => {
    setSuccessNotification('open');
    transaction.verifyDeviceRentalPayment({ id: singleOrder.id })
      .then(() => {
        setTimeout(() => {
          setSuccessNotification('');
          dispatch(removeDeviceRentalPendingById(singleOrder.id));
        }, 3000);
      })
      .catch((err) => {
        dispatch(setErrorNotification(err?.response?.data));
      });
  };

  const actions = [
    {
      name: t('updatePayment'),
      url: '',
      function: updateSinglePayment,
      key: 'update',
      updatePayment: true,
      enable: checkPermissionAllow('updatePaymentTransaction'),
    },
    {
      name: t('verifyPayment'),
      function: verifyPayment,
      key: 'update',
      approve: true,
      enable: checkPermissionAllow('verifyPaymentTransaction'),
    },
  ];

  const columns = [
    {
      header: t('account'),
      accessorKey: 'company_name',
    },
    {
      header: t('rentalOrderID'),
      accessorKey: 'order_id',
    },
    {
      header: t('price'),
      accessorKey: 'price_to_show',
    },
    {
      header: t('paymentStatus'),
      accessorKey: 'status',
      filterVariant: 'select',
      filterSelectOptions: statusList,
      Cell: ({ row }) => {
        const originalStatus = row.original.status;
        let paymentStatus = '';

        if (originalStatus === 'pending') {
          paymentStatus = <span className="payment-pending">{t('pending')} </span>;
        } else if (originalStatus === 'payment_updated') {
          paymentStatus = <span className="payment-updated">{t('updated')} </span>;
        }

        return paymentStatus;
      },
    },
    {
      header: '',
      accessorKey: 'action',
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      enableHiding: true,
      filterVariant: 'none',
      size: 50,
      Cell: ({ row }) => (
        <DropDown
          action={actions}
          icon={more}
          value={row?.original}
          dropdown="tableForm"
          height="15px"
          tooltip=""
        />
      ),
    },
  ];

  const getDeviceRentalPendingData = (page, filters) => {
    dispatch(fetchDeviceRentalPending({ page, ...filters }));
  };

  // Function to fetch next page data, scroll inside table
  const handleScroll = () => {
    getDeviceRentalPendingData(pageCount + 1, filter);
  };

  const onFilterOrSortingChange = (filteredValues, reduxColumnFiltersSorting) => {
    dispatch(setTableFilterData(reduxColumnFiltersSorting));
    dispatch(setFilter(filteredValues));
    getDeviceRentalPendingData(1, filteredValues);
  };
  useEffect(() => {
    setIsFirstRender(false);
    getDeviceRentalPendingData(1, {});
  }, []);

  return (
    <div>
      <ModalBox
        status={successNotification === 'open'}
        closeModal={() => setSuccessNotification('close')}
        modalView={{ content: <Success message={t('paymentVerifiedSuccess')} /> }}
        notification
      />
      <div className="table-container">
        <Table
          header={columns}
          value={deviceRentalPending}
          status={status}
          totalPageCount={totalPageCount}
          pageCount={pageCount}
          scrollData={handleScroll}
          totalDataCount={totalDataCount}
          reduxColumnFiltersSorting={tableFilterData}
          onFilterOrSortingChange={onFilterOrSortingChange}
          isFirstRender={isFirstRender}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          columnVisibility={columnVisibility}
          setColumnVisibility={setColumnVisibility}
          enable={tableFilterData?.columnFilters?.length > 0}
          height={height.tableHeight}
        />
      </div>
    </div>
  );
}

RentalPending.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      name: PropTypes.string.isRequired,
      permissions: PropTypes.string.isRequired,
    }).isRequired,
  }),
};

RentalPending.defaultProps = {
  row: null,
};

export default RentalPending;
