import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import SelectBox from '../../../Shared/Components/Form/SelectBox';
import { Input, InputInnerSymbol } from '../../../Shared/Components/Form/Input';
import Button from '../../../Shared/Components/Button';
import { fetchDeviceCatalogueListAll } from '../../../redux/slices/DeviceCatalogueSlice';

function RentalDeviceAdd(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    currency,
    currencyCode,
    back,
    rentalSubmit,
    oldValue,
  } = props;
  const [rentalDevice, setRentalDevice] = useState([...oldValue]);
  const [disabled, setDisabled] = useState(true);
  const { deviceCatalogueAllList } = useSelector((state) => state.deviceCatalogue);

  const inputHandleChange = (name, changeValue, index) => {
    setRentalDevice((prevState) => prevState.map((device, i) => {
      let values = device;
      if (i === index) {
        if (name === 'device_catalog_id') {
          const tenureList = changeValue?.price_per_device?.find(
            (currencies) => currencies.currency === currencyCode,
          );
          const price = tenureList?.price_in_months.find((month) => month.month === device.tenure);
          values = {
            ...device,
            [name]: changeValue?.id,
            [`${name}_label`]: changeValue?.label,
            tenureList: tenureList?.price_in_months?.map((val) => (
              {
                ...val,
                label: `${val.month} months`,
                id: val.month,
              }
            )),
            unitPrice: price?.price || 0,
            price: price ? price.price * device.quantity : 0,
          };
        }
        if (name === 'tenure') {
          values = {
            ...device,
            [name]: changeValue?.id,
            [`${name}_label`]: changeValue?.label,
            unitPrice: changeValue?.price,
            price: changeValue ? changeValue.price * device.quantity : 0,
          };
        }
        if (name === 'quantity') {
          values = {
            ...device,
            [name]: changeValue,
            price: changeValue * device.unitPrice,
          };
        }
      }
      return values;
    }));
  };

  const addOne = () => {
    setRentalDevice(
      (prevState) => [
        ...prevState,
        {
          device_catalog_id: '',
          tenure: '',
          quantity: 1,
          price: 0,
          unitPrice: 0,
        },
      ],
    );
  };

  const removeOne = (val) => {
    setRentalDevice((prevState) => prevState.filter((device, i) => val !== i));
  };

  const backButton = () => {
    back('');
  };

  const rentalSubmitted = () => {
    back('');
    rentalSubmit(rentalDevice.filter((device) => device.device_catalog_id_label !== undefined));
  };

  useEffect(() => {
    dispatch(fetchDeviceCatalogueListAll());
  }, []);

  useEffect(() => {
    let isDisabled = 0;
    rentalDevice.map((device) => {
      if (
        device?.device_catalog_id_label
        && device?.tenure_label
        && device?.quantity > 0
        && device?.price > 0
      ) {
        isDisabled += 1;
      }
      return device;
    });
    setDisabled(isDisabled !== rentalDevice.length);
  }, [rentalDevice]);

  return (
    <div className="rental-devices-form">
      {
        rentalDevice.map((device, index) => (
          <div className="rental-device-row">
            <SelectBox
              label={t('deviceName')}
              name="device_catalog_id"
              required
              value={device.device_catalog_id_label}
              onchange={inputHandleChange}
              lists={deviceCatalogueAllList}
              index={index}
              id="select-term"
              placeholder={t('selectDeviceName')}
            />
            <SelectBox
              label={t('rentalTenure')}
              name="tenure"
              required
              value={device.tenure_label}
              onchange={inputHandleChange}
              index={index}
              lists={device.tenureList}
              id="select-term"
              placeholder={t('selectRentalTenure')}
            />
            <Input
              icon={' '}
              label={t('quantity')}
              type="number"
              name="quantity"
              required
              index={index}
              value={device.quantity}
              change={inputHandleChange}
              min={1}
            />
            <InputInnerSymbol
              type="number"
              label={t('price')}
              name="price"
              value={device.price}
              index={index}
              readonly
              change={inputHandleChange}
              placeholder={0}
              symbol={currency}
            />
            {
              index === 0 ? (
                <div onClick={addOne} role="presentation" className="add-button">{t('add')}</div>
              ) : (
                <div onClick={() => removeOne(index)} role="presentation" className="delete-button">{t('remove')}</div>
              )
            }

          </div>
        ))
      }
      <div className="form-button-group">
        <div className="form-button">
          <Button
            label={t('back')}
            click={backButton}
            classes="default-button"
          />
        </div>
        <div className="form-button">
          <Button
            label={props?.deviceRentalEdit ? t('update') : t('create')}
            click={rentalSubmitted}
            classes="success-button"
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
}
RentalDeviceAdd.propTypes = {
  currency: PropTypes.string.isRequired,
  currencyCode: PropTypes.string.isRequired,
  back: PropTypes.func.isRequired,
  rentalSubmit: PropTypes.func.isRequired,
  oldValue: PropTypes.arrayOf().isRequired,
  deviceRentalEdit: PropTypes.bool,
};

RentalDeviceAdd.defaultProps = {
  deviceRentalEdit: false,
};

export default RentalDeviceAdd;
