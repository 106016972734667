import {
  get,
  post,
  patch,
  deletes,
} from './base/index';

export default {
  getUsers: (data = {}) => {
    const params = { ...data };
    return get(`${process.env.REACT_APP_API_URL}admin/v1/users`, { params });
  },
  getSingleUser: (data = 0) => get(`${process.env.REACT_APP_API_URL}admin/v1/users/${data}`, {}),
  addUser: (data = {}) => {
    const body = { ...data };
    return post(`${process.env.REACT_APP_API_URL}admin/v1/users`, body, true);
  },
  updateUser: (data = {}) => {
    const params = {
      email: data.email,
      name: data.name,
      role: data.role,
      user_name: data.user_name,
      profile_image: data.profile_image,
    };
    console.log(params);
    return patch(`${process.env.REACT_APP_API_URL}admin/v1/users/${data.id}`, params, true);
  },
  deleteUser: (data = 0) => deletes(`${process.env.REACT_APP_API_URL}admin/v1/users/${data}`, {}),
};
