import {
  get,
  patch,
} from './base/index';

export default {
  getSaasPending: (data = {}) => {
    const params = { ...data };
    return get(`${process.env.REACT_APP_API_URL}admin/v1/transaction/saas-pending-transaction`, { params });
  },
  getDeviceRentalPending: (data = {}) => {
    const params = { ...data, type: 'pending' };
    return get(`${process.env.REACT_APP_API_URL}admin/v1/transaction/device-rental-pending-transaction`, { params });
  },
  getSaasDeviceRentalPending: (data = {}) => {
    const params = { ...data };
    return get(`${process.env.REACT_APP_API_URL}admin/v1/transaction/saas-device-pending-transaction`, { params });
  },
  getTransactionSummary: (data = {}) => {
    const params = { ...data };
    return get(`${process.env.REACT_APP_API_URL}admin/v1/transaction/transaction-summary`, { params });
  },
  updateSaasPayment: (data = {}) => {
    const body = { ...data };
    return patch(`${process.env.REACT_APP_API_URL}admin/v1/transaction/saas-payment-update`, { ...body });
  },
  verifyPayment: (data = {}) => {
    const body = {
      ...data,
      id: undefined,
    };
    return patch(`${process.env.REACT_APP_API_URL}admin/v1/transaction/saas-payment-verify/${data.id}`, body);
  },
  updateDeviceRentalPayment: (data = {}) => {
    const body = {
      ...data,
      order_id: undefined,
    };
    return patch(
      `${process.env.REACT_APP_API_URL}admin/v1/transaction/device-rental-payment-update/${data.order_id}`,
      { ...body },
    );
  },
  verifyDeviceRentalPayment: (data = {}) => {
    const params = { ...data };
    return patch(`${process.env.REACT_APP_API_URL}admin/v1/transaction/device-rental-verify/${params.id}`);
  },
  updateSaasDeviceRentalPayment: (data = {}) => {
    const body = {
      ...data,
      id: undefined,
    };
    return patch(
      `${process.env.REACT_APP_API_URL}admin/v1/transaction/saas-device-rental-payment-update/${data.id}`,
      { ...body },
    );
  },
  verifySaasDeviceRentalPayment: (data = {}) => {
    const params = {
      ...data,
      id: undefined,
    };
    return patch(
      `${process.env.REACT_APP_API_URL}admin/v1/transaction/saas-device-rental-payment-verify/${data.id}`,
      params,
    );
  },
};
