import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import dashboard from '../../../utils/api/dashboard';

function Count() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [cardDetails, setCardDetails] = useState([
    {
      name: 'CMS Account',
      count: 0,
      percentage: 0,
    },
  ]);

  const fetchCardDetails = () => {
    dashboard.getCardDetails()
      .then((res) => setCardDetails(res?.data))
      .catch((err) => dispatch(setErrorNotification(err?.response?.data)));
  };

  useEffect(() => {
    fetchCardDetails();
  }, []);

  return (
    <div className="count-wrap">
      {cardDetails?.map((item) => (
        <div className="each-count" key={item.title}>
          <div className="count-top">
            <div className="count-header">
              <p>{item?.name}</p>
              <h1>{item?.count}</h1>
            </div>
          </div>
          <div className="count-bottom">
            <span
              style={{ '--percentage-color': item?.percentage < 0 ? '#E8212E' : '#0fe73ed0' }}
            >
              {item.percentage === null
                ? (
                  <span className="infinity-symbol">
                    &#8734;
                  </span>
                ) : (
                  <span>
                    {item.percentage > 0 ? '+' : ''}{item?.percentage?.toFixed(2) || 0}%
                  </span>
                )}
            </span>
            <span>
              {t('sinceLastMonth')}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
}

Count.propTypes = {
  count: PropTypes.shape({
    store: PropTypes.number,
    device: PropTypes.number,
    contents: PropTypes.number,
    schedule: PropTypes.number,
  }).isRequired,
};

export default Count;
