import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';

import more from '../../assets/icons/more.png';

function DropDown(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const statusCheck = (
    id,
    status,
  ) => (props?.statusCheck ? props?.statusCheck(id, status) : false);
  return (
    <div>
      <div
        role="presentation"
        className="action-button"
        id={`basic-button${props?.value?.id}`}
        onClick={handleClick}
      >
        <img src={props?.icon ? props?.icon : more} alt="more" />
      </div>
      <Menu
        id={`basic-menu${props?.value?.id}`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className="manus"
      >
        {
          props?.action.map((key) => {
            let value;
            if (key.url) {
              value = (
                <MenuItem key={key.name}>
                  <Link to={`${key?.url}/${props?.value?.id}`}>{key?.name}</Link>
                </MenuItem>
              );
            } else if (key.approve) {
              value = (
                <MenuItem
                  onClick={() => key.function(props?.value)}
                  key={key.name}
                  disabled={props?.value?.status === 'pending'}
                >
                  {key.name}
                </MenuItem>
              );
            } else if (key.updatePayment) {
              value = (
                <MenuItem
                  onClick={() => key.function(props?.value?.id)}
                  key={key.name}
                  disabled={props?.value?.status !== 'pending'}
                >
                  {key.name}
                </MenuItem>
              );
            } else {
              value = (
                <MenuItem
                  onClick={() => key.function(key?.sendFullDetails ? props?.value : props?.value?.id)}
                  key={key.name}
                >
                  {key.name}
                </MenuItem>
              );
            }
            if (key.available) {
              value = (
                <MenuItem
                  key={key.name}
                >
                  {key.name}
                </MenuItem>
              );
            }
            return !key.enable || statusCheck(props?.value?.plan_name, key.key) ? '' : value;
          })
        }
      </Menu>
    </div>
  );
}

DropDown.propTypes = {
  value: PropTypes.objectOf().isRequired,
  action: PropTypes.arrayOf().isRequired,
  statusCheck: PropTypes.func,
  icon: PropTypes.string.isRequired,
};

DropDown.defaultProps = {
  statusCheck: () => false,
};

export default DropDown;
