/* eslint-disable no-param-reassign */
/* eslint no-underscore-dangle: 0 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import subscription from '../../utils/api/subscription';
import { currencySymbol } from '../../utils/helpers';
import { setErrorNotification } from './NotificationSlice';

export const fetchExpiredSubscription = createAsyncThunk(
  'subscription/fetchExpiredSubscription',
  async (data, { dispatch }) => {
    const response = await subscription.getSubscription({ ...data, status: 'expired' })
      .then((res) => {
        const tableData = res?.data?.results?.map((item) => ({
          ...item,
          id: item?._id,
          product: item?.plan?.product,
          plan_type: item?.plan?.plan_type,
          plan_name: item?.plan?.name,
          plan_storage: `${item?.plan?.storage_limit?.storage} ${item?.plan?.storage_limit?.unit}`,
          company_id: item?.company?._id,
          company_name: item?.company?.name,
          device_limit: item?.device?.count,
          total_price_toShow: currencySymbol(item?.currency, item?.total_price),
          order_id: item?.subscription?.order_id,
          start_date: item?.start_date ? new Date(item?.start_date).toISOString().split('T')[0] : '-',
          end_date: item?.end_date ? new Date(item?.end_date).toISOString().split('T')[0] : '-',
          device_rental: item?.device_rental?.map((device) => ({
            ...device,
            total_price_toShow: currencySymbol(device?.currency, device?.price),
          })),
        }));
        return { ...res?.data, results: tableData, code: 200 };
      })
      .catch((err) => dispatch(setErrorNotification(err?.response?.data)));
    return response;
  },
);

const initialState = {
  expiredSubscription: [],
  successNotification: null,
  pageCountExpired: 0,
  totalPageCountExpired: 1,
  filterExpired: {},
  tableFilterDataExpired: {},
  totalDataCountExpired: 0,
  status: 'success',
};

export const expiredSubscriptionSlice = createSlice({
  name: 'expiredSubscription',
  initialState,
  reducers: {
    changeSuccessStatus: (state, action) => {
      state.successNotification = action.payload;
    },
    setTableFilterData: (state, action) => {
      state.tableFilterDataExpired = action.payload;
    },
    setFilter: (state, action) => {
      state.filterExpired = action.payload;
    },
    resetSubscriptionState: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchExpiredSubscription.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchExpiredSubscription.fulfilled, (state, action) => {
        state.status = 'success';
        if (action?.payload?.code === 200) {
          const data = action?.payload?.results;
          state.pageCountExpired = action.payload.page;
          state.totalPageCountExpired = action?.payload?.totalPages === 0
            ? 1
            : action?.payload?.totalPages;
          if (action?.payload?.page === 1) {
            state.expiredSubscription = data;
          } else {
            state.expiredSubscription.push(...data);
          }
        }
      });
  },
});

export const {
  changeSuccessStatus,
  setTableFilterData,
  setFilter,
  resetSubscriptionState,
} = expiredSubscriptionSlice.actions;

export default expiredSubscriptionSlice.reducer;
