import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

function LicenseView({ data }) {
  const { t } = useTranslation();

  const { licenses } = useSelector((state) => state.licenses);

  const singleLicensesData = licenses.find((val) => val.id === data);

  // Define the originalStatus and paymentStatus
  const originalStatus = singleLicensesData.initial_payment_status;
  let paymentStatus;

  if (originalStatus === 'pending') {
    paymentStatus = <span className="payment-pending">{t('inactive')}</span>;
  } else if (originalStatus === 'Active') {
    paymentStatus = <span className="payment-updated">{t('active')}</span>;
  }

  return (
    <div className="license-view">
      <div className="view-header">
        <h2>
          {t('licenseId')} - <span>{singleLicensesData.license_id}</span> {paymentStatus}
        </h2>
        <p>{t('billedOn')} {singleLicensesData?.created_at}</p>
      </div>
      <div className="view-content">
        <div className="each-content">
          <h3>{t('account')}</h3>
          <p>{singleLicensesData?.company_name}</p>
          <h3>{t('licenseKey')}</h3>
          <p>{singleLicensesData?.company_name}</p>
          <h3>{t('licenseType')}</h3>
          <p>{singleLicensesData?.license_type}</p>
          <h3>{t('planName')}</h3>
          <p>{singleLicensesData?.license_plan_name}</p>
          <h3>{t('tenure')}</h3>
          <p>{singleLicensesData?.tenure}</p>
          <h3>{t('totalDisplay')}</h3>
          <p>{singleLicensesData?.device_count}</p>
          <h3>{t('activatedDate')}</h3>
          <p>{singleLicensesData?.active_date}</p>
          <h3>{t('validity')}</h3>
          <p>{singleLicensesData?.active_date} - {singleLicensesData?.active_date}</p>
        </div>
      </div>
    </div>
  );
}
LicenseView.propTypes = {
  data: PropTypes.objectOf().isRequired,
};

export default LicenseView;
