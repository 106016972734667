/* eslint-disable no-param-reassign */
/* eslint no-underscore-dangle: 0 */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { setErrorNotification } from './NotificationSlice';
import transaction from '../../utils/api/transaction';
import { currencySymbol } from '../../utils/helpers';

const formatRentalDataToDisplay = (rentalData) => {
  const formattedData = rentalData.map((item) => ({
    ...item,
    id: item?._id,
    order_id: item?._id,
    actual_price: item?.price,
    total_price: item?.price,
    price_to_show: `${currencySymbol(item?.company?.currency || 'USD')} ${item?.price}`,
    company_name: item?.companyName,
  }));
  return formattedData; // Return the formatted data
};

export const fetchDeviceRentalPending = createAsyncThunk(
  'deviceRentalPending/fetchDeviceRentalPending',
  async (data, { dispatch }) => {
    const response = await transaction.getDeviceRentalPending(data)
      .then((res) => ({
        ...res.data,
        results: formatRentalDataToDisplay(res.data.result),
        code: 200,
      }))
      .catch((error) => dispatch(setErrorNotification(error?.response?.data)));
    return response;
  },
);

const initialState = {
  deviceRentalPending: [],
  pageCount: 0,
  totalPageCount: 1,
  totalDataCount: 0,
  filter: {},
  tableFilterData: {},
};

export const transactionPendingSlice = createSlice({
  name: 'deviceRentalPending',
  initialState,
  reducers: {
    setTableFilterData: (state, action) => {
      state.tableFilterData = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    removeDeviceRentalPendingById: (state, action) => {
      const pendingList = state.deviceRentalPending;

      // Find the index to delete by ID
      const index = pendingList.findIndex((pending) => pending.id === action.payload);
      // If the index found, remove that index from list of deviceRentalPending
      if (index !== -1) {
        pendingList.splice(index, 1);
      }
      state.deviceRentalPending = pendingList; // Update the deviceRentalPending state after deleted
    },
    resetRentalPendingState: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDeviceRentalPending.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDeviceRentalPending.fulfilled, (state, action) => {
        if (action?.payload?.code === 200) {
          state.pageCount = action.payload.page + 1;
          state.totalPageCount = action.payload.totalPages > 0
            ? action.payload.totalPages
            : 1;
          const fetchedData = action.payload.results;
          if (action.payload.page === 1) {
            state.deviceRentalPending = fetchedData;
          } else {
            state.deviceRentalPending.push(...fetchedData);
          }
        }
        state.status = 'succeed';
      });
  },
});

export const {
  setTableFilterData,
  setFilter,
  resetRentalPendingState,
  removeDeviceRentalPendingById,
} = transactionPendingSlice.actions;

export default transactionPendingSlice.reducer;
