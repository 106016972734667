import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  UploadIcon,
  DeleteIcon,
} from '../../../Shared/Components/svgIcon';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';

function ImageUpload({ deviceValue, setDeviceValue }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [imagePreview, setImagePreview] = useState();
  const selectedImages = [
    deviceValue?.device_images[0] || '',
    deviceValue?.device_images[1] || '',
    deviceValue?.device_images[2] || '',
    deviceValue?.device_images[3] || '',
  ];
  const onChangePicture = (e) => {
    const selectedFiles = Array.from(e.target.files);

    const maxFileSizeInMB = 1;
    const maxFileSizeInBytes = maxFileSizeInMB * 1024 * 1024; // Convert MB to bytes
    if (selectedFiles?.some((file) => file.size > maxFileSizeInBytes)) {
      dispatch(setErrorNotification({ message: `File size should be less than ${maxFileSizeInMB} MB` }));
    } else {
      selectedFiles.forEach((item) => {
        setDeviceValue((prevValue) => {
          if (prevValue.device_images && prevValue.device_images.length < 4) {
            return {
              ...prevValue,
              device_images: [...prevValue.device_images, URL.createObjectURL(item)],
              newImages: [...prevValue.newImages, item],
            };
          }
          return prevValue; // If already reached the limit, return the unchanged state
        });
      });
    }
  };

  const changePreview = (deviceImages) => {
    setImagePreview(deviceImages);
  };

  const deleteSelected = (indexToRemove) => {
    const localRemoveImage = [...deviceValue.newImages];
    const clonedDeviceImage = [...deviceValue.device_images];
    clonedDeviceImage[indexToRemove] = {
      ...deviceValue.device_images[indexToRemove],
    };
    clonedDeviceImage.splice(indexToRemove, 1);
    localRemoveImage.splice(indexToRemove, 1);
    let removedImage = [];
    if (deviceValue?.removedImage) {
      removedImage = deviceValue?.removedImage;
    }

    setDeviceValue({
      ...deviceValue,
      device_images: clonedDeviceImage,
      newImages: localRemoveImage,

      removedImage: [
        deviceValue.device_images[indexToRemove],
        ...removedImage,
      ],
    });
    setImagePreview('');
  };

  useEffect(() => {
    if (deviceValue?.device_images?.length === 4) {
      setImagePreview(
        deviceValue?.device_images[0].signedUrl || deviceValue?.device_images[0],
      );
    }
  }, [deviceValue?.device_images]);
  useEffect(() => {
    setImagePreview(deviceValue?.device_images[0]?.signedUrl || '');
  }, [deviceValue?.device_images[0]?.signedUrl]);

  return (
    <div className="upload-container">
      <div className="image-slider-wrap">
        {imagePreview !== ''
          ? (
            <div className="slider">
              <img src={imagePreview} alt="slider" className="slider-image" />
            </div>
          )
          : (
            <div className="photo-upload">
              <label htmlFor="file" className="upload-click">
                <UploadIcon color="#1F1F1F" width="40px" />
                <p><span>{t('browseToUploadImages')}</span></p>
              </label>
              <input
                type="file"
                accept="image/jpeg, image/png, image/webp, image/bmp, image/tiff, image/x-icon"
                id="file"
                multiple
                onChange={onChangePicture}
                style={{ display: 'none' }}
              />
            </div>
          )}
      </div>
      <div className="selected-images">
        {selectedImages.map((image, index) => (
          image !== '' && !image.deleted ? (
            <div className="image-container">
              <div className="deleteIcon" role="presentation" onClick={() => deleteSelected(index)}>
                <DeleteIcon width="15px" color="#E8212E" />
              </div>
              <div role="presentation" onClick={() => changePreview(image.signedUrl || image)}>
                <img src={image.signedUrl || image} alt="box" className="each-image" />
              </div>
            </div>
          )
            : (
              <div className="image-container" role="presentation" onClick={() => changePreview('')}>
                <p>{t('upload')}</p>
              </div>
            )
        ))}
      </div>
    </div>
  );
}
ImageUpload.propTypes = {
  deviceValue: PropTypes.shape({
    newImages: PropTypes.arrayOf(PropTypes.string),
    device_images: PropTypes.arrayOf(
      PropTypes.shape({
        signedUrl: PropTypes.string.isRequired,
      }),
    ),
    removedImage: PropTypes.arrayOf(
      PropTypes.shape({
        signedUrl: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
  setDeviceValue: PropTypes.func.isRequired,
};
export default ImageUpload;
