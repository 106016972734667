import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchContactList,
  setTableFilterData,
  setFilter,
} from '../../redux/slices/ContactSlice';
import DataTable from '../Table';
import TopContent from '../../Shared/Components/TopContent';
import contactApi from '../../utils/api/contacts';
import exportCSV from '../Global/exportCSV';
import { setErrorNotification } from '../../redux/slices/NotificationSlice';
import height from '../../utils/size-variables';

function Contacts() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    pageCount,
    totalPageCount,
    contacts,
    status,
    filter,
    tableFilterData,
    totalDataCount,
  } = useSelector((state) => state.contacts);

  const [rowSelection, setRowSelection] = useState({});
  const [columnVisibility, setColumnVisibility] = useState({});
  const [isFirstRender, setIsFirstRender] = useState(true);

  const getContacts = (counts, search) => {
    if (status === 'success') {
      dispatch(fetchContactList({ page: counts, ...search }));
    }
  };

  const handleScroll = () => {
    getContacts(pageCount + 1, filter);
  };

  const exportData = () => {
    const columnHeaders = [
      'name',
      'email',
      'phone_number',
      'message',
    ];

    // Filter the hidden column
    const filteredHeaders = columnHeaders.filter((header) => {
      if (header === 'plan_name') {
        return columnVisibility.name !== false;
      }
      return columnVisibility[header] !== false;
    });

    // API call for export CSV
    contactApi.exportContactData({ fields: filteredHeaders, ...filter })
      .then((res) => {
        // Function to export as CSV file
        exportCSV(Object.keys(res?.data?.[0]), res?.data);
      })
      .catch((err) => {
        dispatch(setErrorNotification(err?.response?.data));
      });
  };

  const columns = [
    {
      header: t('sNo'),
      size: 60,
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      Cell: ({ row }) => row.index + 1,
    },
    {
      header: t('name'),
      accessorKey: 'name',
    },
    {
      header: t('email'),
      accessorKey: 'email',
    },
    {
      header: t('phoneNumber'),
      accessorKey: 'phone_number',
    },
    {
      header: t('enquiryDate'),
      accessorKey: 'enquiryDate',
      enableColumnFilter: false,
    },
  ];

  const onFilterOrSortingChange = (filteredValues, reduxColumnFiltersSorting) => {
    dispatch(setTableFilterData(reduxColumnFiltersSorting));
    dispatch(setFilter(filteredValues));
    getContacts(1, filteredValues);
  };
  useEffect(() => {
    setIsFirstRender(false);
    if (pageCount === 0 && Object.keys(tableFilterData).length === 0) {
      getContacts(1, {});
    }
  }, []);

  return (
    <div className="main-container">
      <TopContent
        label={t('contact')}
        buttonClass="success-button"
        button={false}
        buttonLabel="New Order"
        click={() => { }}
      />
      <div className="content-area">
        <div className="my-content">
          <div className="my-content-area">
            <div className="content-area">
              <div className="my-content">
                <div className="my-content-area">
                  <DataTable
                    header={columns}
                    value={contacts}
                    status={status === 'loading'}
                    totalPageCount={totalPageCount}
                    pageCount={pageCount}
                    scrollData={handleScroll}
                    reduxColumnFiltersSorting={tableFilterData}
                    onFilterOrSortingChange={onFilterOrSortingChange}
                    isFirstRender={isFirstRender}
                    totalDataCount={totalDataCount}
                    rowSelection={rowSelection}
                    setRowSelection={setRowSelection}
                    columnVisibility={columnVisibility}
                    setColumnVisibility={setColumnVisibility}
                    enable={
                      Object.keys(filter).filter(
                        (val) => val !== 'sortField' && val !== 'sortBy' && val !== 'type',
                      ).length > 0
                    }
                    height={height.onlyTableHeight}
                    exportButton
                    exportData={exportData}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
Contacts.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      deviceCatalogList: PropTypes.arrayOf.isRequired,
      status: PropTypes.string.isRequired,
    }).isRequired,
  }),
};

Contacts.defaultProps = {
  row: null,
};
export default Contacts;
