/* eslint-disable no-param-reassign */
/* eslint no-underscore-dangle: 0 */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { setErrorNotification } from './NotificationSlice';
import transaction from '../../utils/api/transaction';
import { changeDateFormat, currencySymbol } from '../../utils/helpers';

const formatSaasRentalDataToDisplay = (rentalData) => {
  const formattedData = rentalData.map((item) => ({
    ...item,
    id: item?._id,
    product: item?.plan?.product,
    order_id: item?.subscription.order_id,
    company_name: item?.company?.name,
    actual_price: item?.total_price,
    tenure: `${item?.subscription?.tenure} months`,
    subscription_total_price: `${currencySymbol(item?.company?.currency || 'USD')} ${item?.subscription?.total_price}`,
    price_to_display: `${currencySymbol(item?.company?.currency || 'USD')} ${item?.total_price}`,
    valid_from: changeDateFormat(item?.subscription?.start_date),
  }));
  return formattedData; // Return the formatted data
};

export const fetchSaasDeviceRentalPending = createAsyncThunk(
  'transactionPending/fetchSaasDeviceRentalPending',
  async (data, { dispatch }) => {
    const response = await transaction.getSaasDeviceRentalPending(data)
      .then((res) => ({
        ...res.data,
        results: formatSaasRentalDataToDisplay(res.data.results),
        code: 200,
      }))
      .catch((error) => dispatch(setErrorNotification(error?.response?.data)));
    return response;
  },
);

const initialState = {
  saasDeviceRentalPending: [],
  pageCount: 0,
  totalPageCount: 1,
  totalDataCount: 0,
  filter: {},
  tableFilterData: {},
  status: '',
};

export const saasRentalPendingSlice = createSlice({
  name: 'transactionPending',
  initialState,
  reducers: {
    setTableFilterData: (state, action) => {
      state.tableFilterData = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    removeSaasDeviceRentalPendingById: (state, action) => {
      const pendingList = state.saasDeviceRentalPending;

      // Find the index to delete by ID
      const index = pendingList.findIndex((pending) => pending.id === action.payload);
      // If the index found, remove that index from list of saasDeviceRentalPending
      if (index !== -1) {
        pendingList.splice(index, 1);
      }
      state.saasDeviceRentalPending = pendingList; // Update the saasDeviceRentalPending state after deleted
    },
    resetSaasPendingState: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSaasDeviceRentalPending.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSaasDeviceRentalPending.fulfilled, (state, action) => {
        if (action?.payload?.code === 200) {
          state.pageCount = action.payload.page + 1;
          state.totalPageCount = action.payload.totalPages > 0
            ? action.payload.totalPages
            : 1;
          const fetchedData = action.payload.results;
          if (action.payload.page === 1) {
            state.saasDeviceRentalPending = fetchedData;
          } else {
            state.saasDeviceRentalPending.push(...fetchedData);
          }
        }
        state.status = 'succeed';
      });
  },
});

export const {
  setTableFilterData,
  setFilter,
  resetSaasPendingState,
  removeSaasDeviceRentalPendingById,
} = saasRentalPendingSlice.actions;

export default saasRentalPendingSlice.reducer;
