/* eslint-disable no-param-reassign */
/* eslint no-underscore-dangle: 0 */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { setErrorNotification } from './NotificationSlice';
import transaction from '../../utils/api/transaction';
import { currencySymbol } from '../../utils/helpers';

const formatSaasDataToDisplay = (data) => {
  const formattedData = data.map((item) => {
    let quantity;
    if (item?.event?.includes('addon')) {
      quantity = item?.addon?.storage?.count;
    } else if (item?.event?.includes('device')) {
      quantity = item?.device?.count;
    }

    return {
      ...item,
      id: item?._id,
      product: item?.plan?.product,
      order_id: item?.subscription.order_id,
      account: item?.company?.name,
      tenure: `${item?.subscription?.tenure} months`,
      price_to_show: `${currencySymbol(item?.company?.currency || 'USD')} ${item?.total_price}`,
      quantity,
    };
  });

  return formattedData;
};

export const fetchSaasPending = createAsyncThunk(
  'saasPending/fetchSaasPending',
  async (data, { dispatch }) => {
    const response = await transaction.getSaasPending(data)
      .then((res) => ({
        ...res.data,
        results: formatSaasDataToDisplay(res.data.results),
        code: 200,
      }))
      .catch((error) => dispatch(setErrorNotification(error?.response?.data)));
    return response;
  },
);

const initialState = {
  saasPending: [],
  pageCount: 0,
  totalPageCount: 1,
  totalDataCount: 0,
  filter: {},
  tableFilterData: {},
  status: '',
};

export const saasPendingSlice = createSlice({
  name: 'saasPending',
  initialState,
  reducers: {
    setTableFilterData: (state, action) => {
      state.tableFilterData = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    // Reducer to remove the saasPending by ID
    removeSaasPendingById: (state, action) => {
      const pendingList = state.saasPending;

      // Find the index to delete by ID
      const index = pendingList.findIndex((pending) => pending.id === action.payload);
      // If the index found, remove that index from list of saasPending
      if (index !== -1) {
        pendingList.splice(index, 1);
      }
      state.saasPending = pendingList; // Update the saasPending state after deleted
    },
    resetSaasPendingState: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchSaasPending.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSaasPending.fulfilled, (state, action) => {
        if (action?.payload?.code === 200) {
          state.pageCount = action.payload.page + 1;
          state.totalPageCount = action.payload.totalPages > 0 ? action.payload.totalPages : 1;
          const fetchedData = action.payload.results;
          if (action.payload.page === 1) {
            state.saasPending = fetchedData;
          } else {
            state.saasPending.push(...fetchedData);
          }
        }
        state.status = 'succeed';
      });
  },
});

export const {
  setFilter,
  setTableFilterData,
  setSelectedOrders,
  resetSaasPendingState,
  removeSaasPendingById,
} = saasPendingSlice.actions;

export default saasPendingSlice.reducer;
