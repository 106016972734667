/* eslint-disable no-param-reassign */
/* eslint no-underscore-dangle: 0 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import addonApi from '../../utils/api/addon';
import { setErrorNotification } from './NotificationSlice';

export const fetchAddons = createAsyncThunk(
  'addon/fetchAddon',
  async (data, { dispatch }) => {
    const response = await addonApi.getAddons(data)
      .then((res) => {
        const tableData = res?.data?.results?.map((item) => ({
          ...item,
          id: item?._id,
        }));
        return { ...res?.data, results: tableData, code: 200 };
      }).catch((error) => dispatch(setErrorNotification(error?.response?.data)));
    return response;
  },
);

const initialState = {
  addon: [],
  pageCount: 0,
  totalPageCount: 1,
  status: '',
  filter: {},
  totalDataCount: 0,
  tableFilterData: {},
};

export const addonSlice = createSlice({
  name: 'addon',
  initialState,
  reducers: {
    setTableFilterData: (state, action) => {
      state.tableFilterData = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    addAddonDetails: (state, action) => {
      state.addon.unshift({
        ...action.payload,
        id: action?.payload?._id,
      });
    },
    resetAddonState: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAddons.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAddons.fulfilled, (state, action) => {
        state.status = 'success';
        if (action?.payload?.code === 200) {
          const data = action?.payload?.results;
          state.pageCount = (action.payload.page + 1);
          state.totalPageCount = action?.payload?.totalPageCount === 0 ? 1 : action?.payload?.totalPageCount;
          if (action?.payload?.page === 1) {
            state.addon = data;
          } else {
            const finalData = [...state.addon];
            // Add fetchedData to finalData, but only if the id is not already present
            data.forEach((obj) => {
              if (!finalData.some((item) => item._id === obj._id)) {
                finalData.push(obj);
              }
            });
            state.addon = finalData;
          }
        }
      });
  },
});

export const {
  setTableFilterData,
  setFilter,
  addAddonDetails,
  resetAddonState,
} = addonSlice.actions;

export default addonSlice.reducer;
