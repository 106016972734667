/* eslint-disable no-param-reassign */
/* eslint no-underscore-dangle: 0 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import deviceOrderApi from '../../utils/api/device-order';
import { setErrorNotification } from './NotificationSlice';
import { currencySymbol } from '../../utils/helpers';
import { changeDateTimeFormat } from '../../pages/Global/timeFormat';

export const fetchProcessedDeviceOrder = createAsyncThunk(
  'subscription/fetchProcessedDeviceOrder',
  async (data, { dispatch }) => {
    const response = await deviceOrderApi.getDeviceOrder({ ...data, type: 'processed' })
      .then((res) => {
        const tableData = res?.data?.result?.map((item) => ({
          ...item,
          id: item?._id,
          deviceCatalogList: item?.device_catalog?.map((val) => val.name),
        }));
        return { ...res?.data, result: tableData, code: 200 };
      })
      .catch((err) => dispatch(setErrorNotification(err?.response?.data)));
    return response;
  },
);

export const fetchSingleDeviceOrder = createAsyncThunk(
  'subscription/fetchSingleDeviceOrder',
  async (data, { dispatch }) => {
    const response = await deviceOrderApi.getSingleDeviceOrder(data)
      .then((res) => (
        {
          accountName: res?.data?.[0]?.company?.name,
          orderId: res?.data?.[0]?.order_id,
          rentalDevice: res?.data?.map((device) => ({
            ...device,
            id: device._id,
            updatedAt: changeDateTimeFormat(device.updatedAt),
          })),
          currencySymbol: currencySymbol(res?.data?.[0]?.currency),
          totalPrice: res?.data?.reduce((arr, crr) => arr + crr.price, 0),
        }))
      .catch((err) => {
        dispatch(setErrorNotification(err?.response?.data));
      });
    return response;
  },
);

const initialState = {
  deviceOrder: [],

  singleDeviceOrder: {},
  status: 'success',

  pageCount: 0,
  totalPageCount: 1,
  tableFilterData: {},
  filter: {},
  totalDataCount: 0,
};

export const DeviceOrderProcessSlice = createSlice({
  name: 'deviceOrderProcess',
  initialState,
  reducers: {
    updatedSerialNumber: (state, action) => {
      state.singleDeviceOrder = {
        ...state?.singleDeviceOrder,
        rentalDevice: state?.singleDeviceOrder?.rentalDevice.map((device) => {
          let output = device;
          if (device.id === action.payload.id) {
            output = { ...output, device_serial_numbers: action.payload.serialNumber };
          }
          return output;
        }),
      };
    },
    setTableFilterData: (state, action) => {
      state.tableFilterData = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    resetDeviceProcessState: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProcessedDeviceOrder.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchProcessedDeviceOrder.fulfilled, (state, action) => {
        state.status = 'success';
        if (action?.payload?.code === 200) {
          const data = action?.payload?.result;
          state.pageCount = action.payload.page;
          state.totalPageCount = action?.payload?.totalPages === 0
            ? 1
            : action?.payload?.totalPages;
          state.totalDataCount = action?.payload?.totalResults;
          if (action?.payload?.page === 1) {
            state.deviceOrder = data;
          } else {
            state.deviceOrder = [...state.deviceOrder, ...data];
          }
        }
      });

    builder.addCase(fetchSingleDeviceOrder.fulfilled, (state, action) => {
      const data = action?.payload;
      state.singleDeviceOrder = data;
    });
  },
});

export const {
  updatedSerialNumber,
  setTableFilterData,
  setFilter,
  resetDeviceProcessState,
} = DeviceOrderProcessSlice.actions;

export default DeviceOrderProcessSlice.reducer;
