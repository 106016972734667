/* eslint-disable no-param-reassign */
/* eslint no-underscore-dangle: 0 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import deviceOrderApi from '../../utils/api/device-order';
import { setErrorNotification } from './NotificationSlice';
import { currencySymbol } from '../../utils/helpers';

export const fetchPendingDeviceOrder = createAsyncThunk(
  'subscription/fetchPendingDeviceOrder',
  async (data, { dispatch }) => {
    const response = await deviceOrderApi.getDeviceOrder({ ...data, type: 'pending' })
      .then((res) => {
        const tableData = res?.data?.result?.map((item) => ({
          ...item,
          id: item?._id,
          deviceCatalogList: item?.device_catalog?.map((val) => val.name),
        }));
        return { ...res?.data, result: tableData, code: 200 };
      })
      .catch((err) => dispatch(setErrorNotification(err?.response?.data)));
    return response;
  },
);

export const fetchSingleDeviceOrder = createAsyncThunk(
  'subscription/fetchSingleDeviceOrder',
  async (data, { dispatch }) => {
    const response = await deviceOrderApi.getSingleDeviceOrder(data)
      .then((res) => ({
        accountName: res?.data?.[0]?.company?.name,
        rentalDevice: res?.data?.map((device) => ({
          ...device,
          id: device._id,
        })),
        currencySymbol: currencySymbol(res?.data?.[0]?.currency),
        totalPrice: res?.data?.reduce((arr, crr) => arr + crr.price, 0),
      }))
      .catch((err) => {
        dispatch(setErrorNotification(err?.response?.data));
      });
    return response;
  },
);

const initialState = {
  deviceOrder: [],
  deviceOrderSummary: {},
  status: 'success',
  pageCount: 0,
  totalPageCount: 1,
  filter: {},
  tableFilterData: {},
  totalDataCount: 0,
};

export const deviceOrderPendingSlice = createSlice({
  name: 'deviceOrderPending',
  initialState,
  reducers: {
    addDeviceOrderSummary: (state, action) => {
      state.deviceOrderSummary = action.payload;
    },
    addDeviceOrder: (state, action) => {
      state.deviceOrder.unshift({
        ...action.payload,
        id: action?.payload?._id,
        deviceCatalogList: action?.payload?.device_catalog?.map((val) => val.name),
      });
    },
    setTableFilterData: (state, action) => {
      state.tableFilterData = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    resetDevicePendingState: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPendingDeviceOrder.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPendingDeviceOrder.fulfilled, (state, action) => {
        state.status = 'success';
        if (action?.payload?.code === 200) {
          const data = action?.payload?.result;
          state.pageCount = action.payload.page;
          state.totalPageCount = action?.payload?.totalPages === 0 ? 1 : action?.payload?.totalPages;
          state.totalDataCount = action?.payload?.totalResults;
          if (action?.payload?.page === 1) {
            state.deviceOrder = data;
          } else {
            state.deviceOrder = [...state.deviceOrder, ...data];
          }
        }
      });
    builder
      .addCase(fetchSingleDeviceOrder.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSingleDeviceOrder.fulfilled, (state, action) => {
        state.status = 'success';
        state.singleDeviceOrder = action.payload;
      });
  },
});

export const {
  addDeviceOrderSummary,
  addDeviceOrder,
  changeSuccessStatus,
  updatedSerialNumber,
  setTableFilterData,
  setFilter,
  resetDevicePendingState,
} = deviceOrderPendingSlice.actions;

export default deviceOrderPendingSlice.reducer;
