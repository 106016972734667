import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import TopContent from '../../Shared/Components/TopContent';

import DropDown from '../../Shared/Components/DropDown';
import more from '../../assets/icons/more.png';

import DeleteModal from '../../Shared/Components/DeleteModal';

import userApi from '../../utils/api/users';

import {
  fetchUsersList,
  setTableFilterData,
  setFilter,
  deleteUserDetails,
} from '../../redux/slices/UsersSlice';

import DataTable from '../Table';
import { setErrorNotification } from '../../redux/slices/NotificationSlice';
import { checkPermissionAllow } from '../../utils/helpers';
import height from '../../utils/size-variables';

function Users() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    users,
    pageCount,
    totalPageCount,
    filter,
    tableFilterData,
    totalDataCount,
  } = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const [deleteModel, setDeleteModel] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [userId, setUserId] = useState('');

  const [rowSelection, setRowSelection] = useState({});
  const [columnVisibility, setColumnVisibility] = useState({});

  const [load, setLoad] = useState(true);

  const getData = (count, filterData) => {
    if (totalPageCount >= count) {
      dispatch(fetchUsersList({ page: count, ...filterData }));
    } else {
      setLoad(false);
    }
  };

  const handleScroll = () => {
    getData(pageCount + 1, filter);
  };

  const deleteFunction = (id) => {
    setUserId(id);
    setDeleteModel(true);
  };

  const deleteUser = () => {
    userApi.deleteUser(userId).then(() => {
      dispatch(deleteUserDetails(userId));
      setDeleteModel(false);
      setUserId('');
    }).catch((error) => dispatch(setErrorNotification(error?.response?.data)));
  };

  const viewFunction = (id) => {
    navigate(`user-view/${id}`);
  };

  const actions = [
    {
      name: t('view'),
      url: '',
      function: viewFunction,
      key: 'view',
      enable: checkPermissionAllow('getUsers'),
    },
    {
      name: t('delete'),
      url: '',
      function: deleteFunction,
      key: 'delete',
      enable: checkPermissionAllow('deleteUser'),
    },
  ];

  const addPlaylist = () => {
    navigate('user-add');
  };

  const columns = [
    {
      header: t('userName'),
      accessorKey: 'name',
      Cell: ({ row }) => (
        <div className="content-thumbnail">
          <span className="content_name">
            {row.original.name}
          </span>
        </div>
      ),
    },
    {
      header: t('userRole'),
      accessorKey: 'role',
      filterFn: 'commonFIlterFn',
      Cell: ({ row }) => row.original.role_name,
    },
    {
      header: '',
      accessorKey: 'id',
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      enableHiding: true,
      filterVariant: 'none',
      size: 50,
      Cell: ({ row }) => (
        <DropDown
          action={actions}
          icon={more}
          value={row?.original}
          dropdown="tableForm"
          height="15px"
          tooltip=""
        />
      ),
    },
  ];

  const onFilterOrSortingChange = (filteredValues, reduxColumnFiltersSorting) => {
    dispatch(setTableFilterData(reduxColumnFiltersSorting));
    dispatch(setFilter(filteredValues));
    getData(1, filteredValues);
  };
  useEffect(() => {
    setIsFirstRender(false);
    if (pageCount === 0 && Object.keys(tableFilterData).length === 0) {
      getData(1, {});
    }
  }, []);

  return (
    <div className="main-container">
      <TopContent
        label={t('users')}
        button={checkPermissionAllow('addUser')}
        buttonLabel={t('newUser')}
        buttonClass="success-button"
        click={addPlaylist}
      />
      <DeleteModal
        status={deleteModel}
        setOpen={setDeleteModel}
        okFunction={deleteUser}
        buttonLabel={t('delete')}
        title={t('confirmDeleteUser')}
      />
      <div className="content-area">
        <div className="my-content">
          <div className="my-content-area">
            <DataTable
              header={columns}
              value={users}
              status={load}
              totalPageCount={totalPageCount}
              pageCount={pageCount}
              scrollData={handleScroll}
              reduxColumnFiltersSorting={tableFilterData}
              onFilterOrSortingChange={onFilterOrSortingChange}
              isFirstRender={isFirstRender}
              totalDataCount={totalDataCount}
              rowSelection={rowSelection}
              setRowSelection={setRowSelection}
              columnVisibility={columnVisibility}
              setColumnVisibility={setColumnVisibility}
              enable={Object.keys(filter).filter((val) => val !== 'sortField' && val !== 'sortBy').length > 0}
              height={height.onlyTableHeight}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
Users.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
  }),
};

Users.defaultProps = {
  row: null,
};

export default Users;
