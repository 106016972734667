/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import TopContent from '../../../Shared/Components/TopContent';
import { InputInnerSymbol } from '../../../Shared/Components/Form/Input';
import { currencySymbol } from '../../../utils/helpers';
import SelectBox from '../../../Shared/Components/Form/SelectBox';
import Button from '../../../Shared/Components/Button';
import addon from '../../../utils/api/addon';
import ModalBox from '../../../Shared/Components/ModalBox';
import Success from '../../../Shared/Components/Success';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import { addAddonDetails } from '../../../redux/slices/AddonSlice';

function AddonsAdd() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [value, setValue] = useState({});
  const [disabled, setDisabled] = useState(true);
  const [buttonStatus, setButtonStatus] = useState('');
  const navigate = useNavigate();
  const products = [
    {
      label: 'CMS',
      id: 'cms',
    },
    {
      label: t('advertiser'),
      id: 'advertiser',
    },
  ];

  const planTypes = [
    {
      label: t('subscription'),
      id: 'subscription',
    },
    // {
    //   label: 'Perpetual',
    //   id: 'perpetual',
    // },
  ];

  const [pricePerAddOn, setPricePerAddOn] = useState([
    {
      currency: 'SAR',
      price_in_months: [
        {
          month: 1,
          price: null,
        },
        {
          month: 6,
          price: null,
        },
        {
          month: 12,
          price: null,
        },
      ],
    },
    {
      currency: 'USD',
      price_in_months: [
        {
          month: 1,
          price: null,
        },
        {
          month: 6,
          price: null,
        },
        {
          month: 12,
          price: null,
        },
      ],
    },
    {
      currency: 'EUR',
      price_in_months: [
        {
          month: 1,
          price: null,
        },
        {
          month: 6,
          price: null,
        },
        {
          month: 12,
          price: null,
        },
      ],
    },
    {
      currency: 'INR',
      price_in_months: [
        {
          month: 1,
          price: null,
        },
        {
          month: 6,
          price: null,
        },
        {
          month: 12,
          price: null,
        },
      ],
    },
  ]);
  const backFunction = () => {
    navigate('../');
  };
  const submit = () => {
    setButtonStatus('');
    const data = {
      product: value?.product,
      plan_type: value?.plan_type,
      price_per_storage: pricePerAddOn,
    };
    addon.newAddOn(data)
      .then((response) => {
        setButtonStatus('success');
        setTimeout(() => {
          dispatch(addAddonDetails(response?.data));
          setButtonStatus('');
          navigate('../');
        }, 3000);
      })
      .catch((err) => {
        dispatch(setErrorNotification(err?.response?.data));
      });
  };
  const handleChange = (name, data) => {
    setValue({ ...value, [`${name}Label`]: data?.label, [name]: data?.id });
  };
  const handleChangeAddon = (currency, changeValue, index) => {
    const updatedPricePerAddOn = pricePerAddOn.map((device) => {
      if (device.currency === currency) {
        const updatedPriceInMonths = device.price_in_months.map((month) => {
          if (month.month === index) {
            return { ...month, price: changeValue ? Number(changeValue) : null };
          }
          return month;
        });
        return { ...device, price_in_months: updatedPriceInMonths };
      }
      return device;
    });
    setPricePerAddOn(updatedPricePerAddOn);
  };

  useEffect(() => {
    let allPricesSet = true;
    pricePerAddOn.forEach((currency) => {
      currency.price_in_months.forEach((price) => {
        if (price.price === null || price.price === 0) {
          allPricesSet = false;
        }
      });
    });

    if (allPricesSet && value?.product && value?.plan_type) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [value, pricePerAddOn]);

  return (
    <div className="main-container">
      <ModalBox
        status={buttonStatus === 'success'}
        setOpen={setButtonStatus}
        closeModal={() => {}}
        modalView={{ content: <Success message={t('addonCreatedSuccess')} /> }}
        notification
      />
      <TopContent
        label={[t('addon'), t('createNewAddon')]}
        buttonClass="success-button"
        button={false}
      />
      <div className="content-area">
        <div className="addOn-form">
          <div className="single-row">
            <SelectBox
              label={t('product')}
              name="product"
              required
              value={value.productLabel}
              onchange={handleChange}
              lists={products}
              id="select-product"
              placeholder={t('selectProduct')}
            />
            <SelectBox
              label={t('planType')}
              name="plan_type"
              required
              value={value.plan_typeLabel}
              onchange={handleChange}
              lists={planTypes}
              id="select-term"
              placeholder={t('selectPlanName')}
            />
          </div>
          <div className="pricing">
            <h2>{t('storage')} <span>{t('perGB')}</span></h2>
            <table className="add-ons">
              <tr>
                <th>{t('addonDuration')}</th>
                {
                  pricePerAddOn?.[0]?.price_in_months?.map((val) => (
                    <td
                      className={val?.month === 12 && 'last-data'}
                    >
                      {val.month} {val.month !== 1 ? t('months') : t('month')}

                    </td>
                  ))
                }
              </tr>
              {
                pricePerAddOn.map((item, index) => (
                  <tr>
                    <th
                      className={index === pricePerAddOn.length - 1 && 'bottom-data'}
                    >
                      <span>{currencySymbol(item.currency)}&nbsp;</span>
                      {item.currency}
                    </th>
                    {item?.price_in_months?.map((month) => (
                      <td
                        label="table-data"
                        className={
                          month?.month === 12
                            ? 'last-data'
                            : index === pricePerAddOn.length - 1
                              ? 'bottom-data'
                              : index === pricePerAddOn.length - 1 && month?.month === 12
                                ? 'last-data bottom-data'
                                : ''
                        }
                      >
                        <div className="input-container">
                          <InputInnerSymbol
                            type="number"
                            name={item?.currency}
                            index={month.month}
                            value={month.price}
                            change={handleChangeAddon}
                            placeholder={0}
                            symbol={currencySymbol(item.currency)}
                          />
                        </div>
                      </td>
                    ))}
                  </tr>
                ))
              }
            </table>
          </div>
          <div className="form-button-group">
            <div className="form-button">
              <Button label={t('back')} click={backFunction} classes="default-button" />
            </div>
            <div className="form-button">
              <Button
                label={buttonStatus === 'loading' ? t('creating') : t('create')}
                click={submit}
                classes="success-button"
                disabled={disabled}
                loading={buttonStatus === 'loading'}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddonsAdd;
