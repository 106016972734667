/* eslint-disable no-param-reassign */
/* eslint no-underscore-dangle: 0 */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { setErrorNotification } from './NotificationSlice';
import transaction from '../../utils/api/transaction';
import { changeDateFormat, currencySymbol } from '../../utils/helpers';

const formatTransactionSummaryData = (data) => {
  const formattedData = data?.map((item) => ({
    ...item,
    plan_name: item?.plan_name ? item.plan_name : '-',
    product_name: item?.product_name ? item.product_name : '-',
    transaction_data: item?.transaction_date ? changeDateFormat(item?.transaction_date) : '-',
    price_to_display: `${currencySymbol(item?.currency || 'USD')} ${item?.total_price}`,
  }));
  return formattedData;
};

export const fetchTransactionSummary = createAsyncThunk(
  'transactionPending/fetchTransactionSummary',
  async (data, { dispatch }) => {
    const response = await transaction.getTransactionSummary(data)
      .then((res) => ({
        results: formatTransactionSummaryData(res.data),
        code: 200,
      }))
      .catch((error) => dispatch(setErrorNotification(error?.response?.data)));
    return response;
  },
);

const initialState = {
  transactionSummary: [],
  pageCount: 0,
  totalPageCount: 1,
  totalDataCount: 0,
  filter: {},
  tableFilterData: {},
  status: 'succeed',
};

export const transactionSummarySlice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    setTableFilterData: (state, action) => {
      state.tableFilterData = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    resetTransactionState: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchTransactionSummary.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTransactionSummary.fulfilled, (state, action) => {
        if (action?.payload?.code === 200) {
          state.transactionSummary = action?.payload?.results;
        }
        state.status = 'succeed';
      });
  },
});

export const {
  setTableFilterData,
  setFilter,
  resetTransactionState,
} = transactionSummarySlice.actions;

export default transactionSummarySlice.reducer;
