import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import TopContent from '../../../Shared/Components/TopContent';
import {
  fetchProcessedDeviceOrder,
  fetchSingleDeviceOrder,
  updatedSerialNumber,
} from '../../../redux/slices/DeviceOrderProcessSlice';
import ModalBox from '../../../Shared/Components/ModalBox';
import DataTable from '../../Table';
import AddSerialNoPage from './AddSerialNoPage';
import PopupModel from '../../../Shared/Components/PopupModel';

import editIcon from '../../../assets/icons/edit.png';

import deviceOrderApi from '../../../utils/api/device-order';
import Success from '../../../Shared/Components/Success';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import { fetchFulfillDeviceOrder } from '../../../redux/slices/DeviceOrderFulfillSlice';
import height from '../../../utils/size-variables';

function DeviceOrderEditSerialNo() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const { singleDeviceOrder } = useSelector((state) => state.deviceOrderProcess);

  const [buttonStatus, setButtonStatus] = useState('');
  const [notification, setNotification] = useState(false);
  const [modalPageView, setModalpPageView] = useState({});
  const backButton = () => {
    navigate('../');
  };

  const submitValue = (id, serialNumbers) => {
    setButtonStatus('success');
    deviceOrderApi.updateSerialNumber({ device_serial_numbers: serialNumbers }, id).then((res) => {
      setNotification(true);
      setModalpPageView({
        title: '',
        content: <Success message={t('serialNumberUpdatedSuccess')} />,
      });
      dispatch(updatedSerialNumber({
        serialNumber: res?.data?.device_serial_numbers,
        id,
      }));
      dispatch(fetchProcessedDeviceOrder({ page: 1 }));
      dispatch(fetchFulfillDeviceOrder({ page: 1 }));
      setTimeout(() => {
        setButtonStatus('');
      }, 3000);
    }).catch((error) => {
      dispatch(setErrorNotification(error?.response?.data));
    });
  };

  const setSerialNumber = (data) => {
    setNotification(false);
    setButtonStatus('success');
    setModalpPageView({
      title: t('addSerialNumbers'),
      content: <AddSerialNoPage data={data} back={setButtonStatus} submitValue={submitValue} />,
    });
  };

  const columns = [
    {
      header: t('deviceName'),
      accessorKey: 'name',
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      Cell: ({ row }) => row?.original?.device_catalog?.name,
    },
    {
      header: t('rentalTenure'),
      accessorKey: 'type',
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      Cell: ({ row }) => `${row?.original?.tenure} Months`,
    },
    {
      header: t('requiredQuantity'),
      accessorKey: 'quantity',
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
    },
    {
      header: t('updatedAt'),
      accessorKey: 'updatedAt',
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
    },
    {
      header: t('serialNumber'),
      accessorKey: 'serialNumbers',
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      Cell: ({ row }) => {
        let output = (
          <div className="add-button" onClick={() => setSerialNumber(row.original)} role="presentation"> + Add</div>
        );
        if (row?.original?.device_serial_numbers?.length > 0) {
          output = (
            <div className="edit-button-serial">
              <PopupModel
                list={row?.original?.device_serial_numbers}
                name={`${row?.original?.device_serial_numbers?.length} Devices`}
                id={row?.original?.id}
              />
              <div className="edit-button" onClick={() => setSerialNumber(row.original)} role="presentation">
                <img alt="edit-button" src={editIcon} />
              </div>
            </div>
          );
        }
        return output;
      },
    },
  ];

  useEffect(() => {
    dispatch(fetchSingleDeviceOrder(params?.id));
  }, []);

  return (
    <div className="main-container">
      <ModalBox
        status={buttonStatus === 'success'}
        setOpen={setButtonStatus}
        modalView={modalPageView}
        notification={notification}
        closeModal={() => setButtonStatus('')}
      />
      <TopContent
        label={[t('deviceOrder'), singleDeviceOrder?.accountName]}
        buttonClass="success-button"
        click={backButton}
      />
      <div className="content-area">
        <DataTable
          header={columns}
          value={singleDeviceOrder?.rentalDevice || []}
          columnFilters={[]}
          rowSelection={[]}
          columnVisibility={[]}
          sorting={[]}
          onFilterOrSortingChange={() => { }}
          reduxColumnFiltersSorting={{}}
          isFirstRender={false}
          height={height.onlyTableHeight}
          disableFilterButton
          disableShowHideButton
          disableToggleButton
          disableFullScreen
        />
      </div>
    </div>
  );
}
DeviceOrderEditSerialNo.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      device_serial_numbers: PropTypes.arrayOf.isRequired,
    }).isRequired,
  }),
};

DeviceOrderEditSerialNo.defaultProps = {
  row: null,
};

export default DeviceOrderEditSerialNo;
