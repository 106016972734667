import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchSuspendedSubscription,
  setTableFilterData,
  setFilter,
} from '../../../redux/slices/SuspendedSlice';
import DataTable from '../../Table';
import height from '../../../utils/size-variables';

function Suspended() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    suspendedSubscription,
    pageCountExpired,
    totalPageCountExpired,
    status,
    filterExpired,
    tableFilterDataExpired,
    totalDataCountExpired,
  } = useSelector((state) => state.suspendedSubscription);

  const [isFirstRender, setIsFirstRender] = useState(true);
  const [rowSelection, setRowSelection] = useState({});
  const [columnVisibility, setColumnVisibility] = useState({});

  const getSubscriptions = (counts, search) => {
    dispatch(fetchSuspendedSubscription({ page: counts, ...search }));
  };

  const handleScroll = () => {
    getSubscriptions(pageCountExpired + 1, filterExpired);
  };

  const columns = [
    {
      header: t('product'),
      accessorKey: 'product',
    },
    {
      header: t('planType'),
      accessorKey: 'plan_type',
    },
    {
      header: t('planName'),
      accessorKey: 'name',
      filterFn: 'commonFIlterFn',
      Cell: ({ row }) => row.original.plan_name,
    },
    {
      header: t('account'),
      accessorKey: 'company_name',
    },
    {
      header: t('subscriptionID'),
      accessorKey: 'order_id',
    },
    {
      header: t('price'),
      accessorKey: 'total_price',
      filterFn: 'commonFIlterFn',
      Cell: ({ row }) => row.original.total_price_toShow,
    },
    {
      header: t('startDate'),
      accessorKey: 'start_date',
      enableColumnFilter: false,
    },
    {
      header: t('endDate'),
      accessorKey: 'end_date',
      enableColumnFilter: false,
    },
  ];

  const onFilterOrSortingChange = (filteredValues, reduxColumnFiltersSorting) => {
    dispatch(setTableFilterData(reduxColumnFiltersSorting));
    dispatch(setFilter(filteredValues));
    getSubscriptions(1, filteredValues);
  };
  useEffect(() => {
    setIsFirstRender(false);
    // if (pageCountExpired === 0 && Object.keys(tableFilterDataExpired).length === 0) {
    getSubscriptions(1, filterExpired);
    // }
  }, []);

  return (
    <div className="content-area">
      <div className="my-content">
        <div className="my-content-area">
          <DataTable
            header={columns}
            value={suspendedSubscription}
            status={status}
            totalPageCount={totalPageCountExpired}
            pageCount={pageCountExpired}
            scrollData={handleScroll}
            totalDataCount={totalDataCountExpired}
            reduxColumnFiltersSorting={tableFilterDataExpired}
            onFilterOrSortingChange={onFilterOrSortingChange}
            isFirstRender={isFirstRender}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            columnVisibility={columnVisibility}
            setColumnVisibility={setColumnVisibility}
            enable={Object.keys(filterExpired).filter((val) => val !== 'sortField' && val !== 'sortBy').length > 0}
            height={height.tableHeight}
          />
        </div>
      </div>
    </div>
  );
}

export default Suspended;
