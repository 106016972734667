import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { resetSubscriptionState as resetActiveSubscriptionState } from './ActiveSubscriptionSlice';
import { resetSubscriptionState as resetExpiredSubscriptionState } from './ExpiredSubscriptionSlice';
import { resetSubscriptionState as resetPendingActiveSubscriptionState } from './PendingSubscriptionSlice';
import { resetSubscriptionState as resetUpcomingSubscriptionState } from './UpcomingSubscriptionSlice';
import { resetAddonState } from './AddonSlice';
import { resetCompanyState } from './CompanySlice';
import { resetContactState } from './ContactSlice';
import { resetDeviceCatalog } from './DeviceCatalogueSlice';
import { resetDeviceActiveState } from './DeviceOrderActiveSlice';
import { resetDeviceExpiredState } from './DeviceOrderExpiredSlice';
import { resetDeviceFulfillState } from './DeviceOrderFulfillSlice';
import { resetDevicePendingState } from './DeviceOrderPendingSlice';
import { resetDeviceProcessState } from './DeviceOrderProcessSlice';
import { resetDistributorState } from './DistributorSlice';
import { resetFeatureState } from './FeatureControllerSlice';
import { resetIndirectCompanyState } from './IndirectCompanySlice';
import { resetNotification } from './NotificationSlice';
import { resetPlanState } from './PlanSlice';
import { resetPlayerAppState } from './PlayerAppSlice';
import { resetRentalPendingState } from './RentalPendingSlice';
import { resetRoleState } from './RolesSlice';
import { resetSaasPendingState } from './SaasPendingSlice';
import { resetStoreLocationState } from './StoreLocationSlice';
import { resetTransactionState as resetTransactionPendingState } from './TransactionPendingSlice';
import { resetTransactionState as resetTransactionSummeryState } from './TransactionSummarySlice';
import { resetUserState } from './UsersSlice';

export const resetAllSlices = createAsyncThunk(
  'reset/resetAllSlices',
  async (_, { dispatch }) => {
    dispatch(resetActiveSubscriptionState());
    dispatch(resetExpiredSubscriptionState());
    dispatch(resetPendingActiveSubscriptionState());
    dispatch(resetUpcomingSubscriptionState());
    dispatch(resetAddonState());
    dispatch(resetCompanyState());
    dispatch(resetContactState());
    dispatch(resetDeviceCatalog());
    dispatch(resetDeviceActiveState());
    dispatch(resetDeviceExpiredState());
    dispatch(resetDeviceFulfillState());
    dispatch(resetDevicePendingState());
    dispatch(resetDeviceProcessState());
    dispatch(resetDistributorState());
    dispatch(resetFeatureState());
    dispatch(resetIndirectCompanyState());
    dispatch(resetNotification());
    dispatch(resetPlanState());
    dispatch(resetPlayerAppState());
    dispatch(resetRentalPendingState());
    dispatch(resetRoleState());
    dispatch(resetSaasPendingState());
    dispatch(resetStoreLocationState());
    dispatch(resetTransactionPendingState());
    dispatch(resetTransactionSummeryState());
    dispatch(resetUserState());
  },
);

const initialState = {};

const resetReduxStateSlice = createSlice({
  name: 'resetReduxState',
  initialState,
  reducers: {},
});

export default resetReduxStateSlice.reducer;
