import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import TopContent from '../../Shared/Components/TopContent';
import DropDown from '../../Shared/Components/DropDown';
import {
  fetchPlans,
  setTableFilterData,
  setFilter,
} from '../../redux/slices/PlanSlice';
import DeleteModal from '../../Shared/Components/DeleteModal';
import more from '../../assets/icons/more.png';
import DataTable from '../Table';
import exportCSV from '../Global/exportCSV';
import planApi from '../../utils/api/plans';
import { setErrorNotification } from '../../redux/slices/NotificationSlice';
import { checkPermissionAllow } from '../../utils/helpers';
import height from '../../utils/size-variables';

function Plans() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    plans,
    pageCount,
    totalPageCount,
    status,
    filter,
    tableFilterData,
    totalDataCount,
  } = useSelector((state) => state.plans);

  const [rowSelection, setRowSelection] = useState({});
  const [columnVisibility, setColumnVisibility] = useState({});

  const [isFirstRender, setIsFirstRender] = useState(true);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const getPlans = (counts, search) => {
    dispatch(fetchPlans({ page: counts, ...search }));
  };

  const handleScroll = () => {
    getPlans(pageCount, filter);
  };

  const deleteUser = () => { };

  const viewPlans = (id) => {
    navigate(`plans-view/${id}`);
  };

  const actions = [
    // {
    //   name: 'Edit',
    //   url: '',
    //   function: () => {},
    //   key: 'edit',
    // },
    {
      name: t('view'),
      url: '',
      function: viewPlans,
      key: 'view',
      enable: checkPermissionAllow('getPlan'),
    },
    // {
    //   name: 'Delete',
    //   url: '',
    //   function: getDeleteUserId,
    //   key: 'delete',
    // },
  ];

  const clickFunction = () => {
    navigate('plans-add');
  };

  const columns = [
    {
      header: t('product'),
      accessorKey: 'product',
    },
    {
      header: t('planType'),
      accessorKey: 'plan_type',
    },
    {
      header: t('planName'),
      accessorKey: 'name',
    },
    {
      header: '',
      accessorKey: 'id',
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      enableHiding: true,
      filterVariant: 'none',
      size: 50,
      Cell: ({ row }) => (
        <DropDown
          action={actions}
          icon={more}
          value={row?.original}
          dropdown="tableForm"
          height="15px"
          tooltip=""
        />
      ),
    },
  ];

  const exportData = () => {
    const columnHeaders = ['name', 'plan_type', 'product'];

    // Filter the hidden column
    const filteredHeaders = columnHeaders.filter((header) => columnVisibility[header] !== false);

    // API call for export CSV
    planApi.exportPlanData({ fields: filteredHeaders, ...filter })
      .then((res) => {
        // Function to export as CSV file
        exportCSV(Object.keys(res?.data?.[0]), res?.data);
      })
      .catch((err) => {
        dispatch(setErrorNotification(err?.response?.data));
      });
  };

  const onFilterOrSortingChange = (filteredValues, reduxColumnFiltersSorting) => {
    dispatch(setTableFilterData(reduxColumnFiltersSorting));
    dispatch(setFilter(filteredValues));
    getPlans(1, filteredValues);
  };
  useEffect(() => {
    setIsFirstRender(false);
    if (pageCount === 0 && Object.keys(tableFilterData).length === 0) {
      getPlans(1, {});
    }
  }, []);

  return (
    <div className="main-container">
      <TopContent
        label={t('plansAndPricing')}
        buttonClass="success-button"
        button={checkPermissionAllow('addPlan')}
        buttonLabel={t('newPlan')}
        click={clickFunction}
      />
      <DeleteModal
        status={openDeleteModal}
        closeModal={setOpenDeleteModal}
        okFunction={deleteUser}
        buttonLabel="Delete"
        title="Are you sure you want to delete this User?"
        subTitle="This will delete this user permanently. You cannot undo this action."
      />
      <div className="content-area">
        <div className="my-content">
          <div className="my-content-area">
            <DataTable
              header={columns}
              value={plans.filter((val) => val.name !== 'enterprise')}
              status={status}
              totalPageCount={totalPageCount}
              pageCount={pageCount}
              scrollData={handleScroll}
              reduxColumnFiltersSorting={tableFilterData}
              onFilterOrSortingChange={onFilterOrSortingChange}
              isFirstRender={isFirstRender}
              totalDataCount={totalDataCount}
              rowSelection={rowSelection}
              setRowSelection={setRowSelection}
              columnVisibility={columnVisibility}
              setColumnVisibility={setColumnVisibility}
              enable={Object.keys(filter).filter((val) => val !== 'sortField' && val !== 'sortBy').length > 0}
              height={height.tableHeight}
              exportButton
              exportData={exportData}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
Plans.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
  }),
};

Plans.defaultProps = {
  row: null,
};

export default Plans;
