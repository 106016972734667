import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import loginImage from '../../assets/images/adminPortalLogo.jpg';
import userIcon from '../../assets/icons/mail.png';
import Button from '../../Shared/Components/Button';
import { Input1 } from '../../Shared/Components/Form/Input';

import auth from '../../utils/api/auth';
import { CloseIcon, SuccessTickIcon } from '../../Shared/Components/svgIcon';

function ForgotPassword() {
  const navigate = useNavigate();
  const [value, setValue] = useState({});
  const [disable, setDisable] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  // const [errorMessage, setError] = useState('');
  const [resetPassword, setResetPassword] = useState(false);

  useEffect(() => {
    if (value?.email) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [value]);

  const changeHandle = (name, changeValue) => {
    setValue({ ...value, [name]: changeValue });
  };

  const submit = () => {
    setDisable(true);
    setButtonLoading(true);
    auth.forgotPassword(value)
      .then(() => {
        setResetPassword(true);
        setDisable(false);
        setButtonLoading(false);
        setTimeout(() => {
          setResetPassword(false);
          navigate('/login');
        }, 10000);
      })
      .catch(() => {
        setDisable(false);
        setButtonLoading(false);
        setResetPassword(true);
        // if (error?.response?.data?.message) {
        //   setError(error?.response?.data?.message);
        // }
        setTimeout(() => {
          setResetPassword(false);
          navigate('/login');
        }, 10000);
      });
  };

  return (
    <div className="login-wrap">
      <div className="login-banner">
        <img src={loginImage} className="login-image" alt="" />
      </div>
      <div className="login-form-wrap">
        <div className="login-form-container">
          <div className="login-form-header">
            <h1 className="loginTitles">Forgot Your Password?</h1>
            <p className="loginDesc">
              Enter the registered email address and we&apos;ll send you instructions to reset your password.
            </p>
            {resetPassword
              && (
                <div className="reset-password-message">
                  <span><SuccessTickIcon /></span>
                  <p>If you are the existing user, a link to reset your password has been emailed to you.</p>
                  <span
                    role="presentation"
                    onClick={() => setResetPassword(false)}
                  >
                    <CloseIcon
                      width="20px"
                      height="20px"
                      color="#212B36"
                    />
                  </span>
                </div>
              )}
          </div>
          {/* {(errorMessage && !resetPassword) && <p className="text-danger">{errorMessage}</p>} */}
          <div className="login-form">
            <Input1
              icon={userIcon}
              label="Email Address"
              type="text"
              name="email"
              change={changeHandle}
            />
            <Button
              label="Send Mail"
              click={submit}
              classes={buttonLoading ? 'success-button loading-btn' : 'success-button'}
              disabled={disable}
              loading={buttonLoading}
            />
          </div>
          <div className="loginButton"><Link to="/login">Back to Login</Link></div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
