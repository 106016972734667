/* eslint-disable no-param-reassign */
/* eslint no-underscore-dangle: 0 */

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  errorNotification: {},
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setErrorNotification: (state, action) => {
      state.errorNotification = action.payload;
    },
    resetNotification: (state) => {
      Object.assign(state, initialState);
    },
  },
});

export const {
  setErrorNotification,
  resetNotification,
} = notificationSlice.actions;

export default notificationSlice.reducer;
