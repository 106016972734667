import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import PropTypes from 'prop-types';
import { CloseIcon } from '../../../Shared/Components/svgIcon';

function UpdateFunction(props) {
  const {
    rowSelection,
    setPopupOpen,
    updatePayment,
    closePopup,
  } = props;

  // Update functionality
  const submit = () => {
    updatePayment();
    setPopupOpen(false);
  };

  return (
    <div className="ota-update-popup">
      <div className="device-count">
        <span
          role="presentation"
          onClick={closePopup}
        >
          <CloseIcon />
        </span>
        <p> {rowSelection?.length} Items{rowSelection?.length > 1 ? 's' : ''} Selected</p>
      </div>
      <span
        role="presentation"
        onClick={submit}
      >
        Update Payment
      </span>
    </div>
  );
}

function MultiSelectPopup(props) {
  return (
    // Snackbar to to show update button
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={props?.openPopup}
      onClose={props?.closePopup}
      message={(
        <UpdateFunction
          rowSelection={props?.rowSelection}
          setRowSelection={props?.setRowSelection}
          setPopupOpen={props?.setPopupOpen}
          updatePayment={props?.updatePayment}
          closePopup={props?.closePopup}
        />
      )}
    />
  );
}

UpdateFunction.propTypes = {
  rowSelection: PropTypes.objectOf().isRequired,
  setPopupOpen: PropTypes.func.isRequired,
  updatePayment: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
};

MultiSelectPopup.propTypes = {
  openPopup: PropTypes.bool.isRequired,
  closePopup: PropTypes.func.isRequired,
  rowSelection: PropTypes.objectOf().isRequired,
  updatePayment: PropTypes.func.isRequired,
  setPopupOpen: PropTypes.func.isRequired,
  setRowSelection: PropTypes.func.isRequired,
};

export default MultiSelectPopup;
