import {
  get,
  patch,
} from './base/index';

export default {
  getFeatureControllerList: () => get(`${process.env.REACT_APP_API_URL}admin/v1/feature`),
  updateFeatureControllerList: (data) => patch(`${process.env.REACT_APP_API_URL}admin/v1/feature`, { ...data }),
  getFeatureSubFeatureList: () => get(`${process.env.REACT_APP_API_URL}admin/v1/feature/get-feature-list`),
};
