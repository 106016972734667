import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Count from './Components/Count';
import Installations from './Components/Total-Installations';
import Subscribers from './Components/Total-Subcribers';
import MarketPresencePieChart from './Components/Market-Presence';
import MarketMap from './Components/Market-Map';
import { resetAllSlices } from '../../redux/slices/ReduxStateResetSlice';

function Dashboard() {
  const { t } = useTranslation();
  const [user, setUser] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem('data'));
    if (!data) {
      dispatch(resetAllSlices());
      navigate('/login');
    } else {
      setUser(data.user);
    }
  }, []);

  return (
    <div className="dashboard-content">
      <div className="count-cards">
        <h1>{t('welcomeBack')} {user?.name}</h1>
        <Count />
      </div>
      <div className="barChart-component">
        <Installations />
        <Subscribers />
      </div>
      <div className="market-container">
        <MarketPresencePieChart />
        <MarketMap />
      </div>
    </div>
  );
}

export default Dashboard;
