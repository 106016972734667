import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import Checkbox from '@mui/material/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '../../../Shared/Components/Button';
import TopContent from '../../../Shared/Components/TopContent';
import SelectBox from '../../../Shared/Components/Form/SelectBox';
import { TickIcon } from '../../../Shared/Components/svgIcon';
import {
  Input,
  InputDate,
  InputInnerSymbol,
  RadioButton,
} from '../../../Shared/Components/Form/Input';
import ModalBox from '../../../Shared/Components/ModalBox';
import Success from '../../../Shared/Components/Success';
import licensesApi from '../../../utils/api/licenses';
import { currencySymbol } from '../../../utils/helpers';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import { fetchLicensesPending, setSelectedOrders } from '../../../redux/slices/LicensesPendingSlice';

function PaymentInfo() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedOrders } = useSelector((state) => state.licensesPending);

  const [disable, setDisable] = useState(true);
  const [buttonStatus, setButtonStatus] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [discountType, setDiscountType] = useState('');
  const currentDate = new Date().toISOString().slice(0, 10);
  const [discountValue, setDiscountValue] = useState({
    amount: 0,
    value: 0,
  });
  const [discountError, setDiscountError] = useState('');
  const [transactionAmountError, setTransactionAmountError] = useState('');

  // Modified to always use remaining amount after discount
  const getMaxTransactionAmount = () => {
    const totalRemaining = selectedOrders.pending_payment;
    // If discount is applied, subtract it from the remaining amount
    const remainingAfterDiscount = Math.max(0, totalRemaining - discountValue.amount);
    return remainingAfterDiscount;
  };

  const paymentOption = [
    { label: t('netBanking') },
    { label: t('upi') },
    { label: t('other') },
  ];

  const [payments, setPayments] = useState({
    transaction_type: '',
    transaction_id: '',
    transaction_date: '',
    transaction_amount: selectedOrders.pending_payment,
  });
  const [generateLicense, setGenerateLicense] = useState(selectedOrders.initial_payment_status !== 'pending');

  const removeLeadingZerosRegex = (str) => String(str).replace(/^0+(?=\d)/, '');

  const handleChange = (name, value) => {
    if (name === 'transaction_date') {
      if (value.format('YYYY-MM-DD') === 'Invalid Date') {
        setErrorMessage('Invalid Date');
      } else if (value.format('YYYY-MM-DD') > currentDate) {
        setErrorMessage('Enter valid Date');
      } else {
        setErrorMessage('');
        setPayments((prev) => ({
          ...prev,
          [name]: value,
          transaction_date_format: dayjs(value).format('YYYY-MM-DD'),
        }));
      }
    } else if (name === 'transaction_type') {
      setPayments((prev) => ({
        ...prev,
        [name]: value?.label || '',
      }));
    } else if (name === 'transaction_amount') {
      const maxAmount = getMaxTransactionAmount();
      const validatedAmount = Math.min(Number(value), maxAmount);

      if (Number(value) > maxAmount) {
        setTransactionAmountError(`Amount cannot exceed 
          ${currencySymbol(selectedOrders?.company?.currency)}${maxAmount}`);
      } else {
        setTransactionAmountError('');
      }

      setPayments((prev) => ({
        ...prev,
        [name]: removeLeadingZerosRegex(validatedAmount),
      }));
    } else {
      setPayments((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    setGenerateLicense(isChecked);
  };

  const submit = () => {
    setButtonStatus('loading');
    const request = {
      license_id: selectedOrders.id,
      transaction_id: payments.transaction_id,
      transaction_type: payments.transaction_type,
      transaction_date: payments.transaction_date_format,
      transaction_amount: payments.transaction_amount,
    };
    if (discountType === 'fixed') {
      request.discount_amount = discountValue.value;
    } else if (discountType === 'percentage') {
      request.discount_percentage = discountValue.value;
    }
    licensesApi.licensesPaymentTransaction(request).then((response) => {
      console.log(response);
      setButtonStatus('');
      dispatch(fetchLicensesPending({ page: 1, status: 'pending' }));
      dispatch(setSelectedOrders({}));
      navigate('/super-admin/license-transaction/pending');
    }).catch((error) => {
      setButtonStatus('');
      dispatch(setErrorNotification(error.response.data));
    });
  };

  const handleDiscountTypeChange = (event) => {
    const newDiscountType = event.target ? event.target.value : event;
    setDiscountType(newDiscountType);
    setDiscountValue({ value: 0, amount: 0 });

    // Reset transaction amount to the remaining amount
    const maxAmount = selectedOrders.pending_payment;
    setPayments((prev) => ({
      ...prev,
      transaction_amount: maxAmount,
    }));
  };

  const handleDiscountValueChange = (name, inputValue) => {
    let amount = 0;
    let error = '';
    let validatedValue = inputValue;
    const remainingAmount = selectedOrders.pending_payment;

    if (discountType === 'percentage') {
      if (inputValue > 100) {
        error = t('percentageCannotExceed100');
        validatedValue = 100;
      }
      amount = (remainingAmount * validatedValue) / 100;
    } else if (inputValue > remainingAmount) {
      error = `${t('DiscountCannotExceed')} ${currencySymbol(selectedOrders?.company?.currency)}${remainingAmount}`;
      validatedValue = remainingAmount;
      amount = remainingAmount;
    } else {
      amount = inputValue;
    }

    setDiscountError(error);
    setDiscountValue({ value: validatedValue, amount });

    // Update transaction amount if it exceeds new maximum
    const maxAmount = remainingAmount - amount;
    if (payments.transaction_amount > maxAmount) {
      setPayments((prev) => ({
        ...prev,
        transaction_amount: maxAmount,
      }));
    }
  };

  useEffect(() => {
    if (payments.transaction_type
      && payments.transaction_id
      && payments.transaction_date
      && payments.transaction_amount > 0
      && generateLicense
      && !transactionAmountError
    ) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [payments, generateLicense, transactionAmountError]);

  useEffect(() => {
    if (Object.keys(selectedOrders).length === 0) {
      navigate('/super-admin/license-transaction/pending');
    }
  }, []);
  return (
    <div className="main-container">
      <ModalBox
        status={buttonStatus === 'success'}
        closeModal={() => setButtonStatus('')}
        modalView={{ content: <Success message={t('paymentUpdatedSuccess')} /> }}
        notification
      />
      <TopContent
        label={[
          t('transactions'),
          selectedOrders?.company?.name || selectedOrders?.companyName,
        ]}
        labelClickFunc={() => navigate(-2)}
        customLabelClickFunc
      />
      <div className="content-area">
        <div className="product-summary-wrap">
          <div className="payment-info">
            <h2 className="summary-title">{t('paymentInformation')}</h2>
            <div className="payment-info-form" style={{ borderBottom: '1px solid #CACACA', marginBottom: '20px' }}>
              <div className="single-row">
                <SelectBox
                  label={t('transactionMode')}
                  name="transaction_type"
                  required
                  value={payments.transaction_type}
                  onchange={handleChange}
                  lists={paymentOption}
                  id="select-mode"
                  placeholder={t('selectCompanyName')}
                />
                <Input
                  type="text"
                  label={t('transactionID')}
                  required
                  name="transaction_id"
                  value={payments.transaction_id}
                  change={handleChange}
                  placeholder={t('enterTransactionID')}
                />
              </div>
              <div className="single-row">
                <div>
                  <InputDate
                    label={t('transactionDate')}
                    name="transaction_date"
                    required
                    value={payments?.transaction_date}
                    change={handleChange}
                    disableFuture
                  />
                  <div className="error-message">{errorMessage}</div>
                </div>
                <InputInnerSymbol
                  type="number"
                  label={t('transactionAmount')}
                  required
                  name="transaction_amount"
                  value={payments.transaction_amount}
                  change={handleChange}
                  placeholder="0"
                  symbol={currencySymbol(selectedOrders?.company?.currency)}
                  min={0}
                  max={getMaxTransactionAmount()}
                  error={transactionAmountError}
                />
                {transactionAmountError && (
                  <div className="error-message">{transactionAmountError}</div>
                )}
              </div>
            </div>
            <div style={{ marginLeft: '20px' }}>
              <h4>{t('licenseActiveDetails')}</h4>
              {
                selectedOrders.initial_payment_status === 'pending' ? (
                  <FormControlLabel
                    control={(
                      <Checkbox
                        size="small"
                        sx={{
                          color: '#1C75BC',
                          '&.Mui-checked': {
                            color: '#1C75BC',
                          },
                        }}
                        checked={generateLicense}
                        onChange={handleCheckboxChange}
                      />
                    )}
                    label={t('generateLicenseAndSendDetailsThroughMail')}
                  />
                ) : (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <TickIcon />
                    <p style={{ margin: '0 0 0 8px' }}>{t('licenseGeneratedAndSentSuccessfully')}</p>
                  </div>
                )
              }
            </div>
            <div className="form-button-group">
              <div className="form-button">
                <Button
                  label={t('back')}
                  click={() => navigate(-1)}
                  classes="default-button"
                />
              </div>
              <div className="form-button">
                <Button
                  label={t('updatePayment')}
                  click={submit}
                  loading={buttonStatus === 'loading'}
                  disabled={disable}
                  classes={buttonStatus === 'loading'
                    ? 'success-button loading-btn'
                    : 'success-button'}
                />
              </div>
            </div>
          </div>
          <div className="price-summary">
            <div className="price-details">
              <h2 className="summary-title">{t('transactionSummary')}</h2>
              <div className="single-detail">
                <span>
                  {t('orderAmount')}
                </span>
                <span>
                  {selectedOrders.license_price}
                </span>
              </div>
              <div className="single-detail">
                <span>
                  {t('receivedAmount')}
                </span>
                <span>
                  {selectedOrders.received_amount}
                </span>
              </div>
              <div className="single-detail">
                <span>
                  {t('discountAmount')}
                </span>
                <span>
                  {selectedOrders.discount_value}
                </span>
              </div>
              {selectedOrders?.discount_applied === false && (
                <>
                  <div className="single-detail">
                    <span>
                      {t('remainingAmount')}
                    </span>
                    <span>
                      {selectedOrders.pending_payment}
                    </span>
                  </div>
                  <div style={{
                    fontSize: '14px',
                    fontWeight: '500',
                    color: '#1F1F1F',
                    borderBottom: '1px solid #CACACA',
                  }}
                  >
                    <span>
                      {t('discountForOrderAmount')}
                    </span>
                    <div style={{ display: 'flex', gap: '50px', padding: '15px' }}>
                      <RadioButton
                        checked={discountType === 'percentage'}
                        label="%"
                        value="percentage"
                        onChange={handleDiscountTypeChange}
                      />
                      <RadioButton
                        checked={discountType === 'fixed'}
                        symbol={currencySymbol(selectedOrders?.company?.currency)}
                        label={currencySymbol(selectedOrders?.company?.currency)}
                        value="fixed"
                        onChange={handleDiscountTypeChange}
                      />
                    </div>
                    <div style={{ paddingBottom: '10px' }}>
                      {discountType && (
                        <>
                          <div className="discount_amount">
                            <InputInnerSymbol
                              type="number"
                              name="discountValue"
                              value={discountValue.value}
                              change={handleDiscountValueChange}
                              placeholder="0"
                              max={discountType === 'percentage' ? 100 : selectedOrders.license_price}
                              error={discountError}
                            />
                            <div>
                              - {currencySymbol(selectedOrders?.company?.currency)} {discountValue.amount}
                            </div>
                          </div>
                          <div style={{
                            color: 'red',
                            fontSize: '12px',
                            marginTop: '4px',
                            display: discountError ? 'block' : 'none',
                          }}
                          >
                            {discountError}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}
              <div className="single-detail">
                <span>
                  {t('remainingAmount')}
                </span>
                <span>
                  {/* {selectedOrders.pending_payment} */}
                  {/* {selectedOrders.license_price && discountValue.amount
                    ? (selectedOrders.license_price - discountValue.amount)
                    : selectedOrders.pending_payment} */}
                  {selectedOrders.pending_payment && discountValue.amount
                    ? Math.max(0, selectedOrders.pending_payment - discountValue.amount)
                    : selectedOrders.pending_payment}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentInfo;
