import {
  get,
  post,
  patch,
  deletes,
} from './base/index';

export default {
  getLocations: (data = {}) => {
    const params = { ...data };
    return get(`${process.env.REACT_APP_API_URL}admin/v1/stores`, { params });
  },
  getLocationsAll: (data = {}) => {
    const params = { ...data };
    return get(`${process.env.REACT_APP_API_URL}admin/v1/stores/list`, { params });
  },
  getSingleLocation: (data) => get(`${process.env.REACT_APP_API_URL}admin/v1/stores/${data}`, {}),
  addLocation: (data = {}) => {
    const body = { ...data };
    return post(`${process.env.REACT_APP_API_URL}admin/v1/stores`, body);
  },
  updateLocation: (data = {}) => {
    const params = {
      name: data.name,
      store_id: data.store_id,
      country: data.country,
      state: data.state,
      city: data.city,
      description: data.description,
      zone: data.zone,
      area: data.area,
      pincode: data.pincode.toString(),
      latitude: data.latitude,
      longitude: data.longitude,
      // company: data.company,
    };
    console.log(params);
    return patch(`${process.env.REACT_APP_API_URL}admin/v1/stores/${data.id}`, params);
  },
  deleteLocation: (data) => deletes(`${process.env.REACT_APP_API_URL}admin/v1/stores/${data}`, {}),
};
