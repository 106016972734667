import {
  get,
  post,
  // patch,
  deletes,
} from './base/index';

export default {
  getPlans: (data = {}) => {
    const params = { ...data, limit: 50 };
    return get(`${process.env.REACT_APP_API_URL}admin/v1/plans`, { params });
  },
  getSinglePlan: (data = 0) => get(`${process.env.REACT_APP_API_URL}admin/v1/plans/${data}`, {}),
  getPlansWithoutTrail: () => get(`${process.env.REACT_APP_API_URL}admin/v1/plans/get-plans-without-trial-plan`),
  addPlan: (data = {}) => {
    const body = {
      ...data,
      id: undefined,
    };
    return post(`${process.env.REACT_APP_API_URL}admin/v1/plans`, body, false);
  },
  updatePlan: (data = {}) => {
    const params = {
      ...data,
      id: undefined,
      _id: undefined,
    };
    console.log(params);
    return post(`${process.env.REACT_APP_API_URL}admin/v1/plans`, params, false);
  },
  deletePlan: (data = 0) => deletes(`${process.env.REACT_APP_API_URL}admin/v1/plans/${data}`, {}),
  exportPlanData: (data = {}) => post(`${process.env.REACT_APP_API_URL}admin/v1/plans/export`, { ...data }),
};
