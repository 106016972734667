/* eslint-disable no-param-reassign */
/* eslint no-underscore-dangle: 0 */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import playerApp from '../../utils/api/player-app';
import { setErrorNotification } from './NotificationSlice';
import { changeDateFormat } from '../../utils/helpers';

export const fetchPlayerAppVersionList = createAsyncThunk(
  'playerVersion/fetchPlayerAppVersionList',
  async (data, { dispatch }) => {
    const response = await playerApp.getPlayerAppVersions(data)
      .then((res) => {
        const tableValues = res?.data?.results.map((player) => ({
          ...player,
          id: player?._id,
          releaseNotesToShow: player?.releaseNotes?.join(', '),
          updatedAt: changeDateFormat(player?.updatedAt),
        }));
        return { ...res?.data, results: tableValues, code: 200 };
      }).catch((error) => dispatch(setErrorNotification(error?.response?.data)));
    return response;
  },
);

const initialState = {
  playerVersions: [],
  pageCount: 0,
  totalPageCount: 1,
  totalDataCount: 0,
  status: '',
  filter: {},
  tableFilterData: {},
};

export const playerAppSlice = createSlice({
  name: 'playerApp',
  initialState,
  reducers: {
    setTableFilterData: (state, action) => {
      state.tableFilterData = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    addPlayerAppDetails: (state, action) => {
      state.playerVersions.unshift({
        ...action.payload,
        id: action?.payload?._id,
        releaseNotesToShow: action?.payload?.releaseNotes?.join(', '),
        updatedAt: changeDateFormat(action?.payload?.updatedAt),
      });
    },
    updatePlayerAppDetails: (state, action) => {
      state.playerVersions = state.playerVersions.map((companyDetails) => {
        let output = companyDetails;
        if (companyDetails.id === action?.payload?._id) {
          output = {
            ...action.payload,
            id: action?.payload?._id,
            releaseNotesToShow: action?.payload?.releaseNotes?.join(', '),
            updatedAt: changeDateFormat(action?.payload?.updatedAt),
          };
        }
        return output;
      });
    },
    resetPlayerAppState: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchPlayerAppVersionList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPlayerAppVersionList.fulfilled, (state, action) => {
        // Add user to the state array
        if (action?.payload?.code === 200) {
          state.pageCount = action.payload.page;
          state.totalPageCount = action.payload.totalPages > 0 ? action.payload.totalPages : 1;
          const data = action.payload.results;
          if (action.payload.page === 1) {
            state.playerVersions = data;
          } else {
            const finalData = [...state.playerVersions];
            // Add fetchedData to finalData, but only if the id is not already present
            data.forEach((obj) => {
              if (!finalData.some((item) => item._id === obj._id)) {
                finalData.push(obj);
              }
            });
            state.playerVersions = finalData;
          }
        }
        state.status = 'success';
      });
  },
});

export const {
  setTableFilterData,
  setFilter,
  addPlayerAppDetails,
  updatePlayerAppDetails,
  resetPlayerAppState,
} = playerAppSlice.actions;

export default playerAppSlice.reducer;
