import axios, { defaultParams } from './axios';

export default async (url, body, file = false) => {
  try {
    const header = { ...defaultParams() };
    if (file) {
      header.headers = { ...header.headers, 'content-type': 'multipart/form-data' };
    }
    return await axios.post(url, body, { ...header });
  } catch (e) {
    console.log(e);
    throw e;
  }
};
