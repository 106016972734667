import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import TopContent from '../../Shared/Components/TopContent';

import ServerTable from './Component/ServerTable';
import { checkPermissionAllow } from '../../utils/helpers';
import IndirectServerTable from './Component/IndirectServerTable';

function Company() {
  const menus = [
    {
      key: 'direct',
      enable: checkPermissionAllow('getDirectCompany'),
    },
    {
      key: 'indirect',
      enable: checkPermissionAllow('getDistributors'),
    },
  ];
  const { t } = useTranslation();
  const { page } = useParams();
  const navigate = useNavigate();

  return (
    <div className="main-container">
      <TopContent
        label={t('cmsServer')}
        buttonClass="success-button"
        button={checkPermissionAllow('addCmsCompany') || checkPermissionAllow('addDistributorCompany')}
        buttonLabel={t('newAccount')}
        click={() => navigate('../company-add/cmsServer')}
      />
      <div className="content-area">
        <div className="my-content">
          <div className="my-content-area">
            <div className="subscription-menu">
              {
                menus?.map((menu) => menu.enable && (
                  <div
                    role="presentation"
                    className={page === menu.key ? 'each-menu menu-active' : 'each-menu'}
                    onClick={() => {
                      navigate(`../cmsServer/${menu.key}`);
                    }}
                  >
                    {t(menu.key)}
                  </div>
                ))
              }
            </div>
            {
              page === 'direct' && checkPermissionAllow('getServerCompany') && <ServerTable />
            }
            {
              page === 'indirect' && checkPermissionAllow('getDistributors') && <IndirectServerTable />
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default Company;
