import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

function PopupModel(props) {
  const {
    name,
    id,
    list,
    className,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <div
        role="presentation"
        className={`action-button ${className}`}
        id={`basic-button-${id}`}
        onClick={handleClick}
      >
        {name}
      </div>
      <Menu
        id={`basic-menu-${id}`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className="manus"
      >
        {
          list.map((key) => (
            <MenuItem
              key={key}
            >
              {key}
            </MenuItem>
          ))
        }
      </Menu>
    </div>
  );
}

PopupModel.propTypes = {
  id: PropTypes.objectOf().isRequired,
  list: PropTypes.arrayOf().isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
};

export default PopupModel;
