import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TopContent from '../../Shared/Components/TopContent';
import Button from '../../Shared/Components/Button';
import SelectBox from '../../Shared/Components/Form/SelectBox';
import { Input, TextArea } from '../../Shared/Components/Form/Input';

function FeatureMaster() {
  const { t } = useTranslation();
  const featureList = [
    {
      label: 'Content Management',
      id: '1234153',
    },
    {
      label: 'Store Management',
      id: '357566',
    },
    {
      label: 'Display Management',
      id: '97866',
    },
  ];

  const subFeatures = [
    {
      label: 'Limit',
      type: 'Integer',
    },
    {
      label: 'Create',
      type: 'Boolean',
    },
    {
      label: 'ExportCSV',
      type: 'Boolean',
    },
  ];

  const [subFeatureData, setSubFeatureData] = useState([
    {
      name: 'Limit',
      feature_key: 777,
      feature: [
        {
          label: 'Content Management',
          id: '1234153',
        },
        {
          label: 'Store Management',
          id: '357566',
        },
      ],
      description: 'Limit Features',
      feature_type: 'Integer',
      prefix: '',
      suffix: '',
      plan: ['Trail', 'Basic'],
    },
    {
      name: 'Create',
      feature_key: 10,
      feature: [
        {
          label: 'Limit',
          type: 'Integer',
        },
        {
          label: 'ExportCSV',
          type: 'Boolean',
        },
      ],
      description: 'Create Features',
      feature_type: 'Boolean',
      prefix: 'pre',
      suffix: 'suf',
      plan: ['Trail', 'Standard'],
    },
  ]);

  const newFeature = {
    name: '',
    feature_key: 0,
    feature: [],
    description: '',
    feature_type: '',
    prefix: '',
    suffix: '',
    plan: [],
  };

  const handleChange = (name, changeValue, updateIndex) => {
    setSubFeatureData((prevState) => prevState.map((value, index) => {
      if (updateIndex === index) {
        let updateValue;
        if (name === 'name') {
          updateValue = {
            ...value,
            [name]: changeValue?.label,
          };
        } else {
          updateValue = {
            ...value,
            [name]: changeValue,
          };
        }
        return updateValue;
      }
      return value;
    }));
  };

  const addRemoveNewFeature = (type, indexToRemove) => {
    if (type === 'add') {
      setSubFeatureData([
        ...subFeatureData,
        newFeature,
      ]);
    } else if (type === 'remove') {
      setSubFeatureData((prevState) => [...prevState.slice(0, indexToRemove), ...prevState.slice(indexToRemove + 1)]);
    } else {
      setSubFeatureData([newFeature]);
    }
  };

  return (
    <div className="main-container">
      <TopContent
        label="Sub Feature"
      />
      <div className="content-area">
        <div className="master-wrap">
          <div className="master-container">
            {subFeatureData?.map((feature, index) => (
              <div className="sub-feature-warp">
                <div className="sub-feature-container">
                  <div className="multi-field-row">
                    <SelectBox
                      type="text"
                      label="Sub Feature"
                      name="name"
                      value={feature?.name}
                      index={index}
                      onchange={handleChange}
                      lists={subFeatures}
                      placeholder="Enter Sub Feature"
                      id="sub-feature"
                    />
                    <Input
                      type="text"
                      label="Feature Key"
                      name="feature_key"
                      index={index}
                      value={feature?.feature_key}
                      change={handleChange}
                      placeholder="Enter Feature Key"
                    />
                  </div>
                  <SelectBox
                    type="text"
                    label="Feature"
                    name="feature"
                    value={feature?.feature}
                    limitTags={3}
                    index={index}
                    onchange={handleChange}
                    lists={featureList}
                    placeholder="Enter Feature"
                    id="feature-input01"
                    multiple
                  />
                  <TextArea
                    type="text"
                    label="Description"
                    name="description"
                    index={index}
                    value={feature?.description}
                    change={handleChange}
                    placeholder="Enter Description"
                  />
                  <div className="three-field-row">
                    <Input
                      type="text"
                      label="Field Type"
                      name="field_type"
                      index={index}
                      readonly
                      value={feature?.field_type}
                      change={handleChange}
                      placeholder="Enter Field Type"
                    />
                    <Input
                      type="text"
                      label="Prefix"
                      name="prefix"
                      index={index}
                      value={feature?.prefix}
                      change={handleChange}
                      placeholder="Enter Prefix"
                    />
                    <Input
                      type="text"
                      label="Suffix"
                      name="suffix"
                      index={index}
                      value={feature?.suffix}
                      change={handleChange}
                      placeholder="Enter Suffix"
                    />
                  </div>
                  <SelectBox
                    type="text"
                    label="Plan"
                    name="plan"
                    value={feature?.plan}
                    limitTags={4}
                    index={index}
                    onchange={handleChange}
                    lists={featureList}
                    placeholder="Enter Plan"
                    id="feature-input"
                    multiple
                  />
                </div>
                <div className="sub-feature-add">
                  {index === 0
                    ? (
                      <div
                        className="add-button"
                        role="presentation"
                        onClick={() => addRemoveNewFeature('add')}
                      >
                        {t('add')}
                      </div>
                    ) : (
                      <div
                        className="remove-button"
                        role="presentation"
                        onClick={() => addRemoveNewFeature('remove', index)}
                      >
                        {t('remove')}
                      </div>
                    )}
                </div>
              </div>
            ))}
          </div>
          <div className="master-header-button">
            <Button
              label="Reset"
              classes="default-button"
              click={addRemoveNewFeature}
            />
            <Button
              label="Create"
              classes="success-button"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeatureMaster;
