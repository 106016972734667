import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  PieChart,
  Pie,
  Tooltip,
  Cell,
} from 'recharts';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import { getRandomColor } from '../../../utils/helpers';
import dashboard from '../../../utils/api/dashboard';

function MarketPresencePieChart() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 }); // State to handle size of the area-chart
  const [countryCount, setCountryCount] = useState([]);
  const [userCount, setUserCount] = useState(0);

  const fetchCountryCount = () => {
    dashboard.getMarketPresence()
      .then((res) => {
        const finalValue = res?.data?.companies?.map((country) => ({
          ...country,
          color: getRandomColor(),
        }));
        setCountryCount(finalValue || []);
        setUserCount(res?.data?.userCount || 0);
      })
      .catch((err) => dispatch(setErrorNotification(err?.response?.data)));
  };

  // Update the width and height of the area chart container
  useEffect(() => {
    fetchCountryCount();
    const updateDimensions = () => {
      const containerWidth = document.getElementById('pieChart-container').clientWidth;
      const containerHeight = document.getElementById('pieChart-container').clientHeight;
      setDimensions({ width: containerWidth, height: containerHeight });
    };

    updateDimensions();
    window.addEventListener('resize', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);
  return (
    <div className="pieChart-wrap">
      <div className="pieChart-left">
        <h2>{t('marketPresence')}</h2>
      </div>
      <div className="pieChart-table">
        <div id="pieChart-container" className="pieChart-container">
          <PieChart
            width={dimensions.width}
            height={dimensions.height}
          >
            <Pie
              data={countryCount}
              dataKey="count"
              cx={dimensions.width / 2}
              cy={dimensions.height / 2}
              // Calculate based on parent width & height
              innerRadius={(Math.min(dimensions.width, dimensions.height) / 2) - 40}
              outerRadius={(Math.min(dimensions.width, dimensions.height) / 2) - 5}
              labelLine={false}
              fill="#0088FE"
            >
              {countryCount.map((entry) => (
                <Cell key={entry.name} fill={entry.color} />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>
          <div className="text-container">
            <span>{t('users')}</span>
            <span>{userCount}</span>
          </div>
        </div>
        <div className="legend">
          {countryCount.map((item) => (
            <div className="each-legend" key={item.name}>
              <div className="each-items">
                <div className="marker" style={{ backgroundColor: item.color }}>
                  <p />
                </div>
                <p>{item.name}</p>
              </div>
              <div className="each-items">
                <p>{item.value}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default MarketPresencePieChart;
