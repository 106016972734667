import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Button from '../../../Shared/Components/Button';

function RentalDeviceConfirm(props) {
  const { t } = useTranslation();
  const {
    title,
    okFunction,
    setOpen,
    buttonLabel,
  } = props;
  return (
    <div className="model-box">
      <div className="model-header-title model-header-title-rental">
        <h1 className="model-body-content">{title}</h1>
      </div>
      <div className="model-delete-buttons">
        <Button
          classes="default-button"
          click={setOpen}
          label={t('no')}
        />
        <Button
          classes="success-button btn-sx"
          click={okFunction}
          label={buttonLabel}
        />
      </div>
    </div>
  );
}
RentalDeviceConfirm.propTypes = {
  title: PropTypes.string.isRequired,
  okFunction: PropTypes.func.isRequired,
  setOpen: PropTypes.func.isRequired,
  buttonLabel: PropTypes.string.isRequired,
};

export default RentalDeviceConfirm;
