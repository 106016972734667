import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import TopContent from '../../Shared/Components/TopContent';
import { fetchAddons, setTableFilterData, setFilter } from '../../redux/slices/AddonSlice';
import DropDown from '../../Shared/Components/DropDown';
import more from '../../assets/icons/more.png';
import DataTable from '../Table';
import { checkPermissionAllow } from '../../utils/helpers';
import height from '../../utils/size-variables';

function Addons() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    addon,
    pageCount,
    totalPageCount,
    status,
    filter,
    tableFilterData,
    totalDataCount,
  } = useSelector((state) => state.addon);

  const [rowSelection, setRowSelection] = useState({});
  const [columnVisibility, setColumnVisibility] = useState({});
  const [isFirstRender, setIsFirstRender] = useState(true);

  const handleClick = () => {
    navigate('add');
  };
  const getAddon = (counts, search) => {
    dispatch(fetchAddons({ page: counts, ...search }));
  };

  const handleView = (id) => {
    navigate(`view/${id}`);
  };

  const actions = [
    {
      name: t('view'),
      url: '',
      function: handleView,
      key: 'view',
      enable: checkPermissionAllow('getAddons'),
    },
  ];

  const handleScroll = () => {
    getAddon(pageCount, filter);
  };

  const columns = [
    {
      header: t('product'),
      accessorKey: 'product',
    },
    {
      header: t('planType'),
      accessorKey: 'plan_type',
    },
    {
      header: '',
      accessorKey: 'id',
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      enableHiding: true,
      filterVariant: 'none',
      size: 50,
      Cell: ({ row }) => (
        <DropDown
          action={actions}
          icon={more}
          value={row?.original}
          dropdown="tableForm"
          height="15px"
          tooltip=""
        />
      ),
    },
  ];

  const onFilterOrSortingChange = (filteredValues, reduxColumnFiltersSorting) => {
    dispatch(setTableFilterData(reduxColumnFiltersSorting));
    dispatch(setFilter(filteredValues));
    getAddon(1, filteredValues);
  };
  useEffect(() => {
    setIsFirstRender(false);
    if (pageCount === 0 && Object.keys(tableFilterData).length === 0) {
      getAddon(1);
    }
  }, []);

  return (
    <div className="main-container">
      <TopContent
        label={t('addons')}
        buttonClass="success-button"
        button={checkPermissionAllow('addAddons')}
        buttonLabel={t('newAddon')}
        click={handleClick}
      />
      <div className="content-area">
        <div className="my-content">
          <div className="my-content-area">
            <div className="my-content-area">
              <DataTable
                header={columns}
                value={addon}
                status={status}
                totalPageCount={totalPageCount}
                pageCount={pageCount}
                scrollData={handleScroll}
                totalDataCount={totalDataCount}
                reduxColumnFiltersSorting={tableFilterData}
                onFilterOrSortingChange={onFilterOrSortingChange}
                isFirstRender={isFirstRender}
                rowSelection={rowSelection}
                columnVisibility={columnVisibility}
                setColumnVisibility={setColumnVisibility}
                setRowSelection={setRowSelection}
                enable={Object.keys(filter).filter((val) => val !== 'sortField' && val !== 'sortBy').length > 0}
                height={height.tableHeight}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
Addons.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.objectOf().isRequired,
  }).isRequired,
};

export default Addons;
