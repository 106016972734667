import {
  get,
  post,
  patch,
} from './base';

export default {
  newLicenses: (data = {}) => {
    const params = { ...data };
    return post(`${process.env.REACT_APP_API_URL}admin/v1/license`, { ...params });
  },
  getIndirectSeverCompanies: (data = {}) => {
    const params = { ...data };
    return get(`${process.env.REACT_APP_API_URL}admin/v1/companies/cms-server-indirect-company-list`, { params });
  },
  getLicenses: (data = {}) => {
    const params = { ...data };
    return get(`${process.env.REACT_APP_API_URL}admin/v1/license`, { params });
  },
  getLicensesPriceCalculating: (data = {}) => {
    const params = { ...data };
    return post(`${process.env.REACT_APP_API_URL}admin/v1/licensePlans/get-license-price`, { ...params });
  },
  getSingleLicenses: (data) => get(`${process.env.REACT_APP_API_URL}admin/v1/license/${data}`, {}),
  exportLicensesData: (data = {}) => post(
    `${process.env.REACT_APP_API_URL}admin/v1/license/export`,
    { ...data },
  ),
  licensesPaymentTransaction: (data = {}) => {
    const params = { ...data };
    return post(`${process.env.REACT_APP_API_URL}admin/v1/license/license-payment`, { ...params });
  },
  getLicenseTransactionSummary: (data = {}) => {
    const params = { ...data };
    return get(`${process.env.REACT_APP_API_URL}admin/v1/license/get-installment-payments`, { params });
  },
  verifyPayment: (data = {}) => {
    const params = {
      ...data,
      id: undefined,
    };
    console.log(data, 'UPDATE');
    return patch(
      `${process.env.REACT_APP_API_URL}admin/v1/license/verify-payments/${data.id}`,
      params,
    );
  },
  getAccountAll: (data = {}) => {
    const params = { ...data };
    return get(`${process.env.REACT_APP_API_URL}admin/v1/companies/cmsServerCompanyList`, { params });
  },
};
