/* eslint-disable no-param-reassign */
/* eslint no-underscore-dangle: 0 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import licensesPlansApi from '../../utils/api/licenses-plan';
import { setErrorNotification } from './NotificationSlice';
import { changeDateTimeFormat } from '../../pages/Global/timeFormat';

export const fetchLicensesPlans = createAsyncThunk(
  'licensesPlans/fetchLicensesPlans',
  async (data, { dispatch }) => {
    const response = await licensesPlansApi.getLicensesPlans(data)
      .then((res) => {
        const tableData = res?.data?.results?.map((item) => ({
          ...item,
          create_by: item?.createdBy?.name,
          id: item?._id,
          create_at: changeDateTimeFormat(item?.createdAt),
        }));
        return { ...res?.data, results: tableData, code: 200 };
      }).catch((error) => dispatch(setErrorNotification(error?.response?.data)));
    return response;
  },
);
export const fetchAllLicensesPlans = createAsyncThunk(
  'licensesPlans/fetchAllLicensesPlans',
  async (data, { dispatch }) => {
    const response = await licensesPlansApi.getAllLicensesPlans(data)
      .then((res) => res?.data.map((val) => ({
        ...val,
        id: val._id,
        label: val.name,
      }))).catch((error) => dispatch(setErrorNotification(error?.response?.data)));
    return response;
  },
);

const initialState = {
  licensesPlans: [],
  allLicensesPlans: [],
  singleLicensesPlan: {},
  pageCount: 0,
  totalPageCount: 1,
  status: '',
  filter: {},
  totalDataCount: 0,
  tableFilterData: {},
};

export const licensesPlanSlice = createSlice({
  name: 'licensesPlans',
  initialState,
  reducers: {
    setTableFilterData: (state, action) => {
      state.tableFilterData = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    addLicensesPlanDetails: (state, action) => {
      state.licensesPlans.unshift({
        ...action.payload,
        create_by: action?.payload?.createdBy?.name,
        create_at: changeDateTimeFormat(action?.payload?.createdAt),
        id: action?.payload?._id,
      });
    },
    getSingleLicensesPlan: (state, action) => {
      state.singleLicensesPlan = state.licensesPlans.find((val) => val.id === action?.payload);
    },
    resetLicensesPlanState: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLicensesPlans.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchLicensesPlans.fulfilled, (state, action) => {
        state.status = 'success';
        if (action?.payload?.code === 200) {
          const data = action?.payload?.results;
          state.pageCount = action.payload.page;
          state.totalPageCount = action?.payload?.totalPages === 0 ? 1 : action?.payload?.totalPages;
          if (action?.payload?.page === 1) {
            state.licensesPlans = data;
          } else {
            const finalData = [...state.licensesPlans];
            // Add fetchedData to finalData, but only if the id is not already present
            data.forEach((obj) => {
              if (!finalData.some((item) => item._id === obj._id)) {
                finalData.push(obj);
              }
            });
            state.licensesPlans = finalData;
          }
        }
      });
    builder
      .addCase(fetchAllLicensesPlans.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAllLicensesPlans.fulfilled, (state, action) => {
        state.status = 'success';
        state.allLicensesPlans = action?.payload;
      });
  },
});

export const {
  setTableFilterData,
  setFilter,
  addLicensesPlanDetails,
  getSingleLicensesPlan,
  resetLicensesPlanState,
} = licensesPlanSlice.actions;

export default licensesPlanSlice.reducer;
