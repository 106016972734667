/* eslint-disable no-param-reassign */
/* eslint no-underscore-dangle: 0 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import deviceOrderApi from '../../utils/api/device-order';
import { setErrorNotification } from './NotificationSlice';

export const fetchFulfillDeviceOrder = createAsyncThunk(
  'subscription/fetchFulfillDeviceOrder',
  async (data, { dispatch }) => {
    const response = await deviceOrderApi.getDeviceOrder({ ...data, type: 'fulfillment' })
      .then((res) => {
        const tableData = res?.data?.result?.map((item) => ({
          ...item,
          id: item?._id,
          deviceCatalogList: item?.device_catalog.map((val) => val.name),
        }));
        return { ...res?.data, result: tableData, code: 200 };
      })
      .catch((err) => dispatch(setErrorNotification(err?.response?.data)));
    return response;
  },
);

const initialState = {
  deviceOrder: [],
  status: 'success',
  pageCount: 0,
  totalPageCount: 1,
  filter: {},
  tableFilterData: {},
  totalDataCount: 0,
};

export const deviceOrderFulfill = createSlice({
  name: 'deviceOrderFulfill',
  initialState,
  reducers: {
    setTableFilterData: (state, action) => {
      state.tableFilterData = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    resetDeviceFulfillState: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFulfillDeviceOrder.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchFulfillDeviceOrder.fulfilled, (state, action) => {
        state.status = 'success';
        if (action?.payload?.code === 200) {
          const data = action?.payload?.result;
          state.pageCount = action.payload.page;
          state.totalPageCount = action?.payload?.totalPages === 0
            ? 1
            : action?.payload?.totalPages;
          state.totalDataCount = action?.payload?.totalResults;
          if (action?.payload?.page === 1) {
            state.deviceOrder = data;
          } else {
            state.deviceOrder = [...state.deviceOrder, ...data];
          }
        }
      });
  },
});

export const {
  updatedSerialNumber,
  setTableFilterData,
  setFilter,
  resetDeviceFulfillState,
} = deviceOrderFulfill.actions;

export default deviceOrderFulfill.reducer;
