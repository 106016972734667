import {
  get,
} from './base/index';

export default {
  getCardDetails: () => get(`${process.env.REACT_APP_API_URL}admin/v1/dashboard/card-details`),
  getSubscribers: (data = {}) => {
    const params = { ...data };
    return get(`${process.env.REACT_APP_API_URL}admin/v1/dashboard/total-subscribers`, { params });
  },
  getInstallations: (data = {}) => {
    const params = { ...data };
    return get(`${process.env.REACT_APP_API_URL}admin/v1/dashboard/total-installation`, { params });
  },
  getMarketMap: () => get(`${process.env.REACT_APP_API_URL}admin/v1/dashboard/market-presence`),
  getMarketPresence: () => get(`${process.env.REACT_APP_API_URL}admin/v1/dashboard/country-count`),
};
