/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import TopContent from '../../../Shared/Components/TopContent';
import { Input, InputGroup, InputInnerSymbol } from '../../../Shared/Components/Form/Input';
import Button from '../../../Shared/Components/Button';
import SelectBox from '../../../Shared/Components/Form/SelectBox';
import ImageUpload from './ImageUpload';

import { addDeviceCatalogueDetails } from '../../../redux/slices/DeviceCatalogueSlice';
import deviceCatalogue from '../../../utils/api/device-catalogue';
import { currencySymbol, currencyName } from '../../../utils/helpers';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import ModalBox from '../../../Shared/Components/ModalBox';
import Success from '../../../Shared/Components/Success';

function DeviceCatalogueAdd() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [buttonStatus, setButtonStatus] = useState('');
  const [deviceValue, setDeviceValue] = useState({
    device_images: [],
    removed_image: [],
    newImages: [],
    pricePerDevice: [
      {
        currency: 'SAR',
        price_in_months: [
          {
            month: '12',
            price: '',
          },
          {
            month: '24',
            price: '',
          },
          {
            month: '36',
            price: '',
          },
        ],
      },
      {
        currency: 'USD',
        price_in_months: [
          {
            month: '12',
            price: '',
          },
          {
            month: '24',
            price: '',
          },
          {
            month: '36',
            price: '',
          },
        ],
      },
      {
        currency: 'EUR',
        price_in_months: [
          {
            month: '12',
            price: '',
          },
          {
            month: '24',
            price: '',
          },
          {
            month: '36',
            price: '',
          },
        ],
      },
      {
        currency: 'INR',
        price_in_months: [
          {
            month: '12',
            price: '',
          },
          {
            month: '24',
            price: '',
          },
          {
            month: '36',
            price: '',
          },
        ],
      },
    ],
  });
  // const [image, setImage] = useState([]);
  const [disable, setDisable] = useState(true);

  const addPlaylist = () => {
    navigate('../');
  };

  useEffect(() => {
    if (
      deviceValue?.name
      && deviceValue?.os
      && deviceValue?.orientation
      && deviceValue?.pixel
      && deviceValue?.ram
      && deviceValue?.rom
      && deviceValue?.device_images.length > 0
    ) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [deviceValue]);
  const submit = () => {
    setButtonStatus('loading');
    let hasZeroPrice = false;
    deviceValue.pricePerDevice.forEach((priceDevice) => {
      Object.keys(priceDevice).forEach((key) => {
        if (typeof priceDevice[key] === 'object') {
          priceDevice[key].forEach((price) => {
            Object.keys(price).forEach((key1) => {
              if (price[key1] === '0') {
                hasZeroPrice = true;
              }
            });
          });
        } else if (priceDevice[key] === '0') {
          hasZeroPrice = true;
        }
      });
    });
    // If any price value is 0, show an error and return early
    if (hasZeroPrice) {
      setButtonStatus('');
      const message = {
        message: 'Price value cannot be zero.'
      };
      dispatch(setErrorNotification(message));
      return;
    }
    const formData = new FormData();
    formData.append('name', deviceValue.name);
    formData.append('os', deviceValue.os);
    formData.append('screen_size', deviceValue.screen_size);
    formData.append('orientation', deviceValue.orientation);
    formData.append('pixel', deviceValue.pixel);
    formData.append('ram', deviceValue.ram);
    formData.append('rom', deviceValue.rom);
    // formData.append('price_per_device', JSON.stringify(deviceValue.pricePerDevice));
    deviceValue.pricePerDevice.map((priceDevice, index) => {
      Object.keys(priceDevice).map((key) => {
        if (typeof priceDevice[key] === 'object') {
          priceDevice[key].map((price, index1) => {
            Object.keys(price).map((key1) => {
              formData.append(`price_per_device[${index}][${key}][${index1}][${key1}]`, price[key1]);
              return key1;
            });
            return price;
          });
        } else {
          formData.append(`price_per_device[${index}][${key}]`, priceDevice[key]);
        }
        return key;
      });
      return priceDevice;
    });
    deviceValue?.newImages.forEach((file) => {
      formData.append('device_images', file);
    });
    deviceCatalogue.addDeviceCatalogue(formData)
      .then((response) => {
        setButtonStatus('success');
        setTimeout(() => {
          setButtonStatus('');
          dispatch(addDeviceCatalogueDetails(response?.data));
          navigate('../');
        }, 3000);
      })
      .catch((err) => {
        setButtonStatus('error');
        dispatch(setErrorNotification(err?.response?.data));
      });
  };

  const handleChange = (name, changeValue) => {
    if (changeValue && name === 'orientation') {
      setDeviceValue({ ...deviceValue, orientation: changeValue.label });
    } else {
      setDeviceValue({ ...deviceValue, [name]: changeValue });
    }
  };

  const handleChangeDevice = (name, changeValue, index) => {
    const pricePerDevice = deviceValue.pricePerDevice.map((price) => {
      let priceValue = price.price_in_months;
      if (price.currency === name) {
        priceValue = price.price_in_months.map((amount) => ({
          ...amount,
          price: amount.month === index ? changeValue : amount.price,
        }));
      }
      return {
        ...price,
        price_in_months: priceValue,
      };
    });
    setDeviceValue({ ...deviceValue, pricePerDevice });
  };

  const orientation = [
    {
      label: 'Landscape',
    },
    {
      label: 'Portrait',
    },
  ];

  return (
    <div className="main-container">
      <ModalBox
        status={buttonStatus === 'success'}
        closeModal={setButtonStatus}
        modalView={{ content: <Success message={t('deviceCatalogueCreatedSuccess')} /> }}
        notification
      />
      <TopContent
        label={[t('deviceCatalogue'), t('createNewProductCatalogue')]}
        button={false}
      />
      <div className="content-area">
        <div className="catalogue-form-wrap">
          <div className="profile-upload">
            <ImageUpload deviceValue={deviceValue} setDeviceValue={setDeviceValue} />
          </div>
          <div className="catalogue-form">
            <div className="catalogue-form-top">
              <h1>{t('productInformation')}</h1>
              <Input
                icon={' '}
                label={t('productName')}
                type="text"
                name="name"
                required
                value={deviceValue.name}
                change={handleChange}
                placeholder={t('enterProductName')}
              />
              <div className="single-row-3">
                <Input
                  icon={' '}
                  label={t('os')}
                  type="text"
                  name="os"
                  required
                  value={deviceValue.os}
                  change={handleChange}
                  placeholder={t('enterOs')}
                />
                <Input
                  icon={' '}
                  label={t('size')}
                  type="text"
                  name="screen_size"
                  required
                  value={deviceValue.screen_size}
                  change={handleChange}
                  placeholder={t('enterSizeInches')}
                />
                <SelectBox
                  label={t('orientation')}
                  name="orientation"
                  required
                  value={deviceValue?.orientation}
                  onchange={handleChange}
                  lists={orientation}
                  id="select-orientation"
                  placeholder={t('selectOrientation')}
                />
              </div>
              <div className="single-row-3">
                <Input
                  icon={' '}
                  label={t('pixels')}
                  type="text"
                  name="pixel"
                  required
                  value={deviceValue.pixel}
                  change={handleChange}
                  placeholder={t('enterPixelsWH')}
                />
                <InputGroup
                  icon={' '}
                  label={t('ram')}
                  type="number"
                  name="ram"
                  required
                  value={deviceValue.ram}
                  change={handleChange}
                  placeholder={t('enterRam')}
                  tag={t('gb')}
                />
                <InputGroup
                  icon={' '}
                  label={t('rom')}
                  type="number"
                  name="rom"
                  required
                  value={deviceValue.rom}
                  change={handleChange}
                  placeholder={t('enterRom')}
                  tag={t('gb')}
                />
              </div>
            </div>
            <div className="catalogue-form-bottom">
              <h1>{t('pricing')}<span className="subtitle">{t('perDevice')}</span></h1>
              {
                deviceValue.pricePerDevice.map((currencies) => (
                  <div className="single-row">
                    <div className="currency-name">
                      <div className="currency-symbol">{currencySymbol(currencies.currency)}</div>
                      <div className="currency-code">{currencies.currency}</div>
                      <div className="currency-label">({currencyName(currencies.currency)})</div>
                    </div>
                    {
                      currencies.price_in_months.map((price) => (
                        <InputInnerSymbol
                          label={`${price.month} ${t('months')}`}
                          type="number"
                          index={price.month}
                          name={currencies.currency}
                          required
                          value={price.price}
                          change={handleChangeDevice}
                          symbol={currencySymbol(currencies.currency)}
                        />
                      ))
                    }
                  </div>
                ))
              }
            </div>
            <div className="form-button-group">
              <div className="form-button">
                <Button label={t('back')} click={addPlaylist} classes="default-button" />
              </div>
              <div className="form-button">
                <Button
                  label={buttonStatus === 'loading' ? t('creating') : t('addProduct')}
                  click={submit}
                  classes={buttonStatus === 'loading' ? 'success-button loading-btn' : 'success-button'}
                  disabled={disable}
                  loading={buttonStatus === 'loading'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeviceCatalogueAdd;
