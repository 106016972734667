import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  AreaChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Area,
} from 'recharts';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import dashboard from '../../../utils/api/dashboard';

function Subscribers() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 }); // State to handle size of the area-chart
  const [areaChartData, setAreaChartData] = useState([]);

  // Function to get subscribers data for the year
  const getSubscribersData = () => {
    dashboard.getSubscribers({ year: 2024 })
      .then((res) => {
        setAreaChartData(res?.data);
      })
      .catch((err) => {
        dispatch(setErrorNotification(err?.response?.data));
      });
  };

  // Update the width and height of the area chart container
  useEffect(() => {
    getSubscribersData(); // fetch subscribers data
    const updateDimensions = () => {
      const containerWidth = document.getElementById('chart-container').clientWidth;
      const containerHeight = document.getElementById('chart-container').clientHeight;
      setDimensions({ width: containerWidth, height: containerHeight });
    };

    updateDimensions();
    window.addEventListener('resize', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  return (
    <div className="lineChart-wrap">
      <div className="lineChart-header">
        <div className="lineChart-right">
          <h2>{t('totalSubscribers')}</h2>
        </div>
      </div>
      <div id="chart-container" className="lineChart-table">
        <AreaChart
          width={dimensions.width}
          height={dimensions.height}
          data={areaChartData}
          margin={{
            top: 20,
            left: 0,
            bottom: 5,
            right: 20,
          }}
        >
          {/* Define linear gradient */}
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="30%" stopColor="#93CAFD" stopOpacity={0.95} />
              <stop offset="100%" stopColor="#93CAFD" stopOpacity={0} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" strokeWidth={0.5} />
          <XAxis
            dataKey="month"
            tick={{ fontSize: 12 }}
            axisLine={false}
            tickLine={false}
          />
          <YAxis
            tick={{ fontSize: 12 }}
            axisLine={false}
            tickLine={false}
          />
          <Tooltip />
          {/* Apply the linear gradient as fill */}
          <Area type="monotone" dataKey="subscribers" fill="url(#colorUv)" />
        </AreaChart>
      </div>
    </div>
  );
}

export default Subscribers;
