import axios, { defaultParams } from './axios';

export default async (url, params) => {
  try {
    return await axios.get(url, { ...defaultParams(), ...params });
  } catch (e) {
    console.log(e);
    throw e;
  }
};
