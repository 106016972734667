import React, { useState, useEffect, forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { useSelector, useDispatch } from 'react-redux';

import { resetSubscriptionState as resetActiveSubscriptionState } from '../../redux/slices/ActiveSubscriptionSlice';
import { resetSubscriptionState as resetPendingSubscriptionState } from '../../redux/slices/PendingSubscriptionSlice';
import { resetSubscriptionState as resetUpcomingSubscriptionState } from '../../redux/slices/UpcomingSubscriptionSlice';
import { resetSubscriptionState as resetExpiredSubscriptionState } from '../../redux/slices/ExpiredSubscriptionSlice';
import { resetCompanyState } from '../../redux/slices/CompanySlice';
import { resetPlanState } from '../../redux/slices/PlanSlice';
import { resetDeviceCatalog } from '../../redux/slices/DeviceCatalogueSlice';
import { resetAddonState } from '../../redux/slices/AddonSlice';
import { resetLicensesPlanState } from '../../redux/slices/LicensesPlanSlice';
import { resetRoleState } from '../../redux/slices/RolesSlice';
import { resetUserState } from '../../redux/slices/UsersSlice';
import { resetFeatureState } from '../../redux/slices/FeatureControllerSlice';
import { resetNotification } from '../../redux/slices/NotificationSlice';
import { resetDeviceExpiredState } from '../../redux/slices/DeviceOrderExpiredSlice';
import { resetDeviceFulfillState } from '../../redux/slices/DeviceOrderFulfillSlice';
import { resetDevicePendingState } from '../../redux/slices/DeviceOrderPendingSlice';
import { resetDeviceProcessState } from '../../redux/slices/DeviceOrderProcessSlice';
import { resetDeviceActiveState } from '../../redux/slices/DeviceOrderActiveSlice';

const Alert = forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);

function Notification() {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { errorNotification } = useSelector((state) => state.notification);

  const [errorMessage, setErrorMessage] = useState('');

  const resetReduxStore = () => {
    dispatch(resetAddonState());
    dispatch(resetCompanyState());
    dispatch(resetActiveSubscriptionState());
    dispatch(resetPendingSubscriptionState());
    dispatch(resetUpcomingSubscriptionState());
    dispatch(resetExpiredSubscriptionState());
    dispatch(resetRoleState());
    dispatch(resetUserState());
    dispatch(resetPlanState());
    dispatch(resetDeviceCatalog());
    dispatch(resetFeatureState());
    dispatch(resetNotification());
    dispatch(resetDeviceExpiredState());
    dispatch(resetDeviceFulfillState());
    dispatch(resetDevicePendingState());
    dispatch(resetDeviceProcessState());
    dispatch(resetDeviceActiveState());
    dispatch(resetLicensesPlanState());
  };

  useEffect(() => {
    if (errorNotification && Object.keys(errorNotification).length > 0) {
      setOpen(true);
      if (errorNotification?.code === 401) {
        setErrorMessage('Session Expired!!');
        navigate('/login');
        resetReduxStore();
      } else if (errorNotification?.message) {
        setErrorMessage(errorNotification.message);
      }
    }
  }, [errorNotification]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(resetNotification({}));
    setOpen(false);
  };
  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
        {errorMessage}
      </Alert>
    </Snackbar>
  );
}

export default Notification;
