import React from 'react';
import logoImage from '../../assets/images/adminLogo.png';

function Logo() {
  return (
    <div className="logo">
      <img src={logoImage} alt="logo" />
    </div>
  );
}

export default Logo;
