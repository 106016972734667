import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from './Button';

function DeleteModal(props) {
  const { t } = useTranslation();
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '0.2px solid #bab8b8',
    boxShadow: 24,
    borderRadius: '10px',
    p: 3,
    overflowY: 'scroll',
  };
  return (
    <Modal
      open={props?.status}
      onClose={() => props?.setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="model-box">
          <div className="model-header-title">
            <h1 className="model-title">{props?.title}</h1>
            <p className="model-body-content">{props?.subTitle}</p>
          </div>
          <div className="model-delete-buttons">
            <Button
              classes="default-button"
              click={() => props?.setOpen(false)}
              label={props?.displayLabelNo ? t('no') : t('cancel')}
            />
            <Button
              classes="danger-button"
              click={props?.okFunction}
              label={props?.buttonLabel}
            />
          </div>
        </div>
      </Box>
    </Modal>
  );
}

DeleteModal.propTypes = {
  status: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  okFunction: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  displayLabelNo: PropTypes.bool.isRequired,
  buttonLabel: PropTypes.string.isRequired,
};

export default DeleteModal;
