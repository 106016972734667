import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Input } from '../../../Shared/Components/Form/Input';
import Button from '../../../Shared/Components/Button';

function AddSerialNoPage(props) {
  const { t } = useTranslation();
  const { data, submitValue, back } = props;
  const emptyValue = [];
  for (let index = 0; index < data.quantity - data.device_serial_numbers.length; index += 1) {
    emptyValue.push({ serial_number: '' });
  }
  const [serialNumbers, setSerialNumbers] = useState([...data.device_serial_numbers, ...emptyValue]);

  const changeValue = (name, changedValue, index) => {
    serialNumbers[index] = { ...serialNumbers[index], serial_number: changedValue };
    setSerialNumbers([...serialNumbers]);
  };

  const submit = () => {
    submitValue(data.id, serialNumbers);
  };

  return (
    <div className="serial-numbers-list">
      <p>{t('enterTheDeviceSerialNumberSuppliedToCustomers')}</p>
      {
        serialNumbers.map((val, index) => (
          <Input
            icon={' '}
            label=" "
            type="text"
            name="serialNumber"
            required={false}
            index={index}
            value={val.serial_number}
            change={changeValue}
          />
        ))
      }
      <div className="form-button-group">
        <div className="form-button">
          <Button
            label={t('back')}
            click={() => back('')}
            classes="default-button"
          />
        </div>
        <div className="form-button">
          <Button
            label={t('approve')}
            click={submit}
            classes="success-button"
          />
        </div>
      </div>
    </div>
  );
}
AddSerialNoPage.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    device_serial_numbers: PropTypes.arrayOf.isRequired,
    quantity: PropTypes.number.isRequired,
  }).isRequired,
  submitValue: PropTypes.func.isRequired,
  back: PropTypes.func.isRequired,
};

export default AddSerialNoPage;
