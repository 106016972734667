import React, { useState } from 'react';
import PropTypes from 'prop-types';

function ImageView({ images }) {
  const [imagePreview, setImagePreview] = useState(images[0].signedUrl || '');

  return (
    <div className="upload-container">
      <div className="image-slider-wrap">
        <div className="slider">
          <img src={imagePreview} alt="slider" className="slider-image" />
        </div>
      </div>
      <div className="selected-images">
        {images?.map((image) => (
          <div
            className="image-container"
            role="presentation"
            onClick={() => setImagePreview(image.signedUrl)}
          >
            <img src={image.signedUrl} alt="box" className="each-image" />
          </div>
        ))}
      </div>
    </div>
  );
}
ImageView.propTypes = {
  images: PropTypes.arrayOf().isRequired,
};

export default ImageView;
