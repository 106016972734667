import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

function SubscriptionView({ data }) {
  const { t } = useTranslation();
  const [term, setTerm] = useState('Monthly');

  useEffect(() => {
    switch (data?.subscription?.tenure) {
      case 1:
        return setTerm('Monthly');
      case 6:
        return setTerm('Semi-Annual');
      default:
        return setTerm('Annual');
    }
  }, [data]);

  const subscriptionDetails = [
    {
      title: t('accountColen'),
      detail: `${data?.company?.name}`,
    },
    {
      title: t('planName'),
      detail: `${data?.plan?.name}`,
    },
    {
      title: t('subscriptionDuration'),
      detail: `${data?.start_date} - ${data?.end_date} [${term}]`,
    },
  ];

  return (
    <div className="subscription-view">
      <div className="view-header">
        <h2>{t('orderId')}- <span>{data?.order_id}</span></h2>
        {/* <p>Billed on
          {
            data?.transaction_details?.transaction_date
              ? new Date(data?.transaction_details?.transaction_date)?.toISOString().split('T')[0]
              : '-'
          }
        </p> */}
      </div>
      <div className="view-content">
        {subscriptionDetails?.map((item) => (
          <div className="each-content">
            <h3>{item?.title}</h3>
            <p>{item?.detail}</p>
          </div>
        ))}
        <div className="plan_details">
          <h1>{t('planDetails')}</h1>
          <div className="each-content">
            <h3>{data?.plan?.[0]?.name}<span>[{data?.device_limit} Device{data?.device_limit > 1 && 's'}]</span></h3>
            <p>{data?.total_price_toShow}</p>
          </div>
        </div>
      </div>
      {/* <div className="bill-total">
        <div className="each-content">
          <h3>Bill Total</h3>
          <p>{data?.total_price_toShow}</p>
        </div>
      </div> */}
    </div>
  );
}
SubscriptionView.propTypes = {
  data: PropTypes.shape({
    subscription: PropTypes.arrayOf(
      PropTypes.shape({
        tenure: PropTypes.number.isRequired,
      }),
    ),
    company: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
    ),
    plan: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
    ),
    start_date: PropTypes.string.isRequired,
    end_date: PropTypes.string.isRequired,
    order_id: PropTypes.string.isRequired,
    transaction_details: PropTypes.shape({
      transaction_date: PropTypes.string.isRequired,
    }),
    device_limit: PropTypes.number.isRequired,
    device_count: PropTypes.number.isRequired,
    total_price_toShow: PropTypes.string.isRequired,
    device_rental: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
};

export default SubscriptionView;
