/* eslint-disable no-param-reassign */
/* eslint no-underscore-dangle: 0 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import subscription from '../../utils/api/subscription';
import { currencySymbol } from '../../utils/helpers';
import { setErrorNotification } from './NotificationSlice';

export const fetchPendingSubscription = createAsyncThunk(
  'subscription/fetchPendingSubscription',
  async (data, { dispatch }) => {
    const response = await subscription.getSubscription({ ...data, status: 'pending' })
      .then((res) => {
        const tableData = res?.data?.results?.map((item) => ({
          ...item,
          id: item?._id,
          product: item?.plan?.product,
          plan_type: item?.plan?.plan_type,
          plan_name: item?.plan?.name,
          plan_storage: `${item?.plan?.storage_limit?.storage} ${item?.plan?.storage_limit?.unit}`,
          company_id: item?.company?._id,
          company_name: item?.company?.name,
          device_limit: item?.device?.count,
          total_price_toShow: currencySymbol(item?.currency, item?.total_price),
          order_id: item?.subscription?.order_id,
          start_date: item?.start_date ? new Date(item?.start_date).toISOString().split('T')[0] : '-',
          end_date: item?.end_date ? new Date(item?.end_date).toISOString().split('T')[0] : '-',
          device_rental: item?.device_rental?.map((device) => ({
            ...device,
            total_price_toShow: currencySymbol(device?.currency, device?.price),
          })),
        }));
        return { ...res?.data, results: tableData, code: 200 };
      })
      .catch((err) => dispatch(setErrorNotification(err?.response?.data)));
    return response;
  },
);

export const updateTransaction = createAsyncThunk(
  'subscription/updateSubscription',
  async (data, { dispatch }) => {
    const response = await subscription.updateSubscription(data)
      .then((res) => res?.data)
      .catch((err) => dispatch(setErrorNotification(err?.response?.data)));
    return response;
  },
);

const initialState = {
  pendingSubscription: [],
  filterPending: {},
  tableFilterDataPending: {},
  totalDataCountPending: 0,
  pageCount: 0,
  totalPageCount: 1,
  status: 'success',
  successNotification: null,
};

export const pendingSubscriptionSlice = createSlice({
  name: 'pendingSubscription',
  initialState,
  reducers: {
    changeSuccessStatus: (state, action) => {
      state.successNotification = action.payload;
    },
    setTableFilterData: (state, action) => {
      state.tableFilterDataPending = action.payload;
    },
    setFilter: (state, action) => {
      state.filterPending = action.payload;
    },
    resetSubscriptionState: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPendingSubscription.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPendingSubscription.fulfilled, (state, action) => {
        state.status = 'success';
        if (action?.payload?.code === 200) {
          const data = action?.payload?.results;
          state.pageCount = action.payload.page;
          state.totalPageCount = action?.payload?.totalPages === 0 ? 1 : action?.payload?.totalPages;
          state.totalDataCountPending = action?.payload?.totalResults;
          if (action?.payload?.page === 1) {
            state.pendingSubscription = data;
          } else {
            state.pendingSubscription.push(...data);
          }
        }
      });

    builder.addCase(updateTransaction.fulfilled, (state, action) => {
      const data = action?.payload;
      const pendingData = [...state.pendingSubscription]; // Create a copy

      const index = pendingData.findIndex((obj) => obj.id === data?._id);

      if (index !== -1) {
        pendingData[index].transaction_details = data?.transaction_details;
      }

      state.pendingSubscription = pendingData; // Update state with the new array
      state.successNotification = true;
    });
  },
});

export const {
  changeSuccessStatus,
  setTableFilterData,
  setFilter,
  resetSubscriptionState,
} = pendingSubscriptionSlice.actions;

export default pendingSubscriptionSlice.reducer;
