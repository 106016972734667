import {
  get,
  post,
  patch,
} from './base/index';

export default {
  getDeviceOrder: (data = {}) => {
    const params = { ...data, limit: 10 };
    return get(`${process.env.REACT_APP_API_URL}admin/v1/deviceRental`, { params });
  },
  getSingleDeviceOrder: (data) => get(`${process.env.REACT_APP_API_URL}admin/v1/deviceRental/${data}`, {}),
  addDeviceOrder: (data = {}) => post(`${process.env.REACT_APP_API_URL}admin/v1/deviceRental`, data),
  updateSerialNumber:
    (data = {}, id = '') => patch(
      `${process.env.REACT_APP_API_URL}admin/v1/deviceRental/update-serial-number/${id}`,
      data,
    ),
  cancelDeviceOrder:
    (id, data = {}) => patch(`${process.env.REACT_APP_API_URL}admin/v1/deviceRental/cancel-device-rental/${id}`, data),
  getDeviceOrderRentalBreak: (data = {}) => {
    const params = { ...data, limit: 10 };
    return get(`${process.env.REACT_APP_API_URL}admin/v1/deviceRental/device-rental-break-down`, { params });
  },
};
