/* eslint no-underscore-dangle: 0 */

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import TopContent from '../../../Shared/Components/TopContent';
import { Input, InputGroup, InputInnerSymbol } from '../../../Shared/Components/Form/Input';
import ImageView from './ImageView';
import deviceCatalogue from '../../../utils/api/device-catalogue';
import { currencySymbol, currencyName, checkPermissionAllow } from '../../../utils/helpers';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';

function DeviceCatalogueAdd() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  const deviceCatalog = useSelector((state) => state.deviceCatalogue.deviceCatalogue);
  const [value, setValue] = useState(deviceCatalog.find((device) => device?.id === params?.id));
  const addPlaylist = () => {
    navigate(`../device-edit/${value?.id}`);
  };

  useEffect(() => {
    if (!value) {
      deviceCatalogue.getSingleDeviceCatalogue(params?.id)
        .then((response) => {
          setValue({
            ...response?.data,
            id: response?.data?._id,
            months_12_price: `$${response?.data?.pricing_in_months?.find((each) => each.for === 12)?.price || '0.00'}`,
            months_24_price: `$${response?.data?.pricing_in_months?.find((each) => each.for === 24)?.price || '0.00'}`,
            months_36_price: `$${response?.data?.pricing_in_months?.find((each) => each.for === 36)?.price || '0.00'}`,
          });
        })
        .catch((err) => {
          dispatch(setErrorNotification(err.response.data));
        });
    }
  }, [params]);

  return (
    <div className="main-container">
      <TopContent
        label={[t('deviceCatalogue'), value?.name]}
        button={checkPermissionAllow('editDeviceCatalogue')}
        buttonLabel={t('editDevice')}
        buttonClass="default-button button-outline-success-1"
        click={addPlaylist}
      />
      <div className="content-area">
        <div
          className={value?.device_images?.length > 0 ? 'catalogue-form-wrap' : 'catalogue-form-wrap no-image-view'}
        >
          {value?.device_images?.length > 0
            && (
              <div className="profile-upload">
                <ImageView images={value?.device_images || []} />
              </div>
            )}
          <div className="catalogue-form">
            <div className="catalogue-form-top">
              <h1>{t('productInformation')}</h1>
              <Input
                icon={' '}
                label={t('productName')}
                type="text"
                name="name"
                value={value?.name}
                readonly
                placeholder={t('enterProductName')}
              />
              <div className="single-row-3">
                <Input
                  icon={' '}
                  label={t('os')}
                  type="text"
                  name="os"
                  value={value?.os}
                  readonly
                  placeholder={t('enterOs')}
                />
                <Input
                  icon={' '}
                  label={t('size')}
                  type="text"
                  name="screen_size"
                  value={value?.screen_size}
                  readonly
                  placeholder={t('enterSizeInches')}
                />
                <Input
                  label={t('orientation')}
                  name="orientation"
                  id="select-orientation"
                  value={value?.orientation}
                  readonly
                  placeholder={t('selectOrientation')}
                />
              </div>
              <div className="single-row-3">
                <Input
                  icon={' '}
                  label={t('pixels')}
                  type="text"
                  name="pixel"
                  value={value?.pixel}
                  readonly
                  placeholder={t('enterPixelsWH')}
                />
                <InputGroup
                  icon={' '}
                  label={t('ram')}
                  type="number"
                  name="ram"
                  value={value?.ram}
                  readonly
                  placeholder={t('enterRam')}
                  tag={t('gb')}
                />
                <InputGroup
                  icon={' '}
                  label={t('rom')}
                  type="number"
                  name="rom"
                  value={value?.rom}
                  readonly
                  placeholder={t('enterRom')}
                  tag={t('gb')}
                />
              </div>
            </div>
            <div className="catalogue-form-bottom">
              <h1>{t('pricing')}<span className="subtitle">{t('perDevice')}</span></h1>
              {
                value?.price_per_device?.map((currencies) => (
                  <div className="single-row">
                    <div className="currency-name">
                      <div className="currency-symbol">{currencySymbol(currencies.currency)}</div>
                      <div className="currency-code">{currencies.currency}</div>
                      <div className="currency-label">({currencyName(currencies.currency)})</div>
                    </div>
                    {
                      currencies?.price_in_months?.map((price) => (
                        <InputInnerSymbol
                          label={`${price.month} ${t('months')}`}
                          type="number"
                          index={price.month}
                          name={currencies.currency}
                          required
                          readonly
                          value={price.price}
                          change={() => { }}
                          symbol={currencySymbol(currencies.currency)}
                        />
                      ))
                    }
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeviceCatalogueAdd;
