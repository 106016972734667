import {
  get,
  post,
} from './base';

export default {
  newLicensesPlan: (data = {}) => {
    const params = { ...data };
    return post(`${process.env.REACT_APP_API_URL}admin/v1/licensePlans`, { ...params });
  },
  getLicensesPlans: (data = {}) => {
    const params = { ...data };
    return get(`${process.env.REACT_APP_API_URL}admin/v1/licensePlans`, { params });
  },
  getAllLicensesPlans: (data = {}) => {
    const params = { ...data };
    return get(`${process.env.REACT_APP_API_URL}admin/v1/licensePlans/get-license-plans`, { params });
  },
  getLicensesPlan: (data) => get(`${process.env.REACT_APP_API_URL}admin/v1/licensePlans/${data}`, {}),
  exportLicensesPlansData: (data = {}) => post(
    `${process.env.REACT_APP_API_URL}admin/v1/licensePlans/export`,
    { ...data },
  ),
};
