import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { useTranslation } from 'react-i18next';
import FormControlLabel from '@mui/material/FormControlLabel';

import productSummaryHeader from '../../../assets/json/product-summary-header.json';
import deviceSummaryHeader from '../../../assets/json/device-summary-header.json';
import rentalDeviceSummaryHeader from '../../../assets/json/rental-device-summary-header.json';
import TableForm from '../../../Shared/Components/TableForm';
import TopContent from '../../../Shared/Components/TopContent';
import { EditIcon } from '../../../Shared/Components/svgIcon';
import Button from '../../../Shared/Components/Button';
import ModalBox from '../../../Shared/Components/ModalBox';
// import SelectDevice from './SelectDevice';
import { addDeviceSummary, addProductSummary } from '../../../redux/slices/ActiveSubscriptionSlice';

import subscriptionApi from '../../../utils/api/subscription';
import Success from '../../../Shared/Components/Success';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import RentalDeviceAdd from './RentalDeviceAdd';

function Summary() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { productSummary, deviceSummary } = useSelector((state) => state.activeSubscription);
  const rentalTotalAmount = productSummary?.rentalDevice
    ?.filter((device) => device.device_catalog_id_label !== undefined)
    ?.reduce((acc, cur) => acc + parseInt(cur.price, 10), 0);
  const rentalDeviceCount = productSummary?.rentalDevice
    ?.filter((device) => device.device_catalog_id_label !== undefined)?.length;
  const [buttonStatus, setButtonStatus] = useState('');
  const [paymentType, setPaymentType] = useState(false);

  const [modalView, setModalView] = useState(false);

  const rentalSubmit = (rentalDetails) => {
    dispatch(addProductSummary({
      ...productSummary,
      rentalDevice: rentalDetails,
    }));
    setButtonStatus('');
  };

  const deviceModal = () => {
    setButtonStatus('success');
    setModalView({
      title: 'Add Device',
      content: <RentalDeviceAdd
        currency={productSummary.currency}
        currencyCode={productSummary?.currencyCode || 'USD'}
        back={setButtonStatus}
        rentalSubmit={rentalSubmit}
        oldValue={productSummary.rentalDevice || []}
        deviceRentalEdit
      />,
    });
  };

  const backButton = () => {
    dispatch(addProductSummary({}));
    dispatch(addDeviceSummary([]));
    navigate('../');
  };

  const paymentOptionChange = (event) => {
    setPaymentType(event.target.value === 'online');
  };

  const submit = () => {
    setButtonStatus('loading');
    const subscriptionData = {
      // product: productSummary?.[0]?.product,
      plan: productSummary?.plan,
      start_date: productSummary?.start_date,
      tenure: productSummary?.tenure,
      device_limit: productSummary?.device_limit,
      company: productSummary?.company,
      currency: productSummary?.currencyCode || 'USD',
      device_rental: productSummary?.rentalDevice.length > 0,
      create_payment_link: paymentType,
      device_rental_information: productSummary?.rentalDevice?.map((device) => ({
        device_catalog_id: device.device_catalog_id,
        tenure: device.tenure,
        quantity: parseInt(device.quantity, 10),
        price: device.unitPrice,
      })),
    };
    if (productSummary?.price_per_device) {
      subscriptionData.price_per_device = Number(productSummary?.price_per_device);
    }

    subscriptionApi.addSubscription(subscriptionData)
      .then(() => {
        setModalView({ content: <Success message={t('subscriptionCreatedSuccessfully')} /> });
        setButtonStatus('success');
        setTimeout(() => {
          setButtonStatus('');
          dispatch(addProductSummary({}));
          dispatch(addDeviceSummary([]));
          navigate('../pending');
        }, 3000);
      })
      .catch((err) => {
        setButtonStatus('');
        dispatch(setErrorNotification(err?.response?.data));
      });
  };

  const productSummaryEdit = () => {
    navigate('../add', { state: { edit: true } });
  };

  useEffect(() => {
    // if (deviceSummary.length > 0) {
    //   // Calculate total price
    //   const calculatedTotal = deviceSummary.reduce((acc, item) => acc + item.amount, 0);
    //   setTotalPrice(calculatedTotal);
    // }
    if (!productSummary?.plan) {
      navigate('../add');
    }
  }, []);

  useEffect(() => console.log(productSummary), [productSummary]);

  return (
    <div className="main-container">
      <ModalBox
        status={buttonStatus === 'success'}
        setOpen={setButtonStatus}
        closeModal={() => { }}
        modalView={modalView}
        notification
      />
      <TopContent
        label={[t('subscriptions'), t('newSubscription')]}
        buttonClass="success-button"
        click={backButton}
      />
      <div className="content-area">
        <div className="summary-wrap">
          <div className="summary-container">
            <div className="summary-header">
              <h3>{t('productSummary')}</h3>
              <div
                role="presentation"
                onClick={() => productSummaryEdit(false)}
                className="edit-container"
              >
                <EditIcon color="#9747FF" />
                <span>{t('edit')}</span>
              </div>
            </div>
            <div className="summery-sub">Account : {productSummary.companyLabel}</div>
            <TableForm
              header={productSummaryHeader}
              value={[productSummary]}
              checkBox={false}
            />
          </div>
          {deviceSummary[0]?.amount
            && (
              <div className="summary-container">
                <div className="summary-header">
                  <h3>{t('deviceSummary')}</h3>
                  <div
                    role="presentation"
                    // onClick={() => setModal(true)}
                    className="edit-container"
                  >
                    <EditIcon color="#9747FF" />
                    <span>{t('edit')}</span>
                  </div>
                </div>
                <TableForm
                  header={deviceSummaryHeader}
                  value={deviceSummary}
                  checkBox={false}
                />
                <div className="total-wrap">
                  <p>{t('totalPrice')}</p>
                  <span>{productSummary?.currency}{productSummary?.totalPrice}</span>
                </div>
              </div>
            )}
          {
            rentalDeviceCount > 0 && (
              <div className="summary-container">
                <div className="summary-header">
                  <h3>{t('rentalDeviceSummary')}</h3>
                  <div
                    role="presentation"
                    onClick={deviceModal}
                    className="edit-container"
                  >
                    <EditIcon color="#9747FF" />
                    <span>{t('edit')}</span>
                  </div>
                </div>
                <TableForm
                  header={rentalDeviceSummaryHeader}
                  value={productSummary?.rentalDevice}
                  checkBox={false}
                />
                <div className="price">
                  <h2>{t('totalRentalPrice')}</h2>
                  <p>
                    {productSummary?.currency}
                    {rentalTotalAmount}
                  </p>
                </div>
              </div>
            )
          }
          <div className="summary-container">
            <div className="price-summary-wrap">
              <h1>{t('priceSummary')}</h1>
              <div className="price-summary">
                <div className="price">
                  <h2>{productSummary?.device_limit} {t('devices')}</h2>
                  {productSummary.planLabel === 'enterprise'
                    ? (
                      <p>
                        {productSummary?.currency}{productSummary
                        && productSummary.price_per_device * productSummary.device_limit}
                        <span>({productSummary?.price_per_device}/device)</span>
                      </p>
                    ) : (
                      <p>
                        {productSummary?.currency}{productSummary?.totalPrice}
                        <span>({productSummary?.devicePrice}/device)</span>
                      </p>
                    )}
                </div>
                {
                  rentalDeviceCount > 0 && (
                    <div className="price">
                      <h2>{t('rentedDevices')}( {rentalDeviceCount} Rental )</h2>
                      <p>
                        {productSummary?.currency}
                        {rentalTotalAmount}
                      </p>
                    </div>
                  )
                }
                <div className="price">
                  <h2>{t('totalPrice')}</h2>
                  {productSummary.planLabel === 'enterprise'
                    ? (
                      <p>
                        {productSummary?.currency}
                        {productSummary && productSummary.price_per_device * productSummary.device_limit}
                      </p>
                    ) : (
                      <p>
                        {productSummary?.currency}
                        {productSummary && productSummary.totalPrice + rentalTotalAmount}
                      </p>
                    )}
                </div>
              </div>
            </div>
          </div>
          <div className="summary-container">
            <div className="price-summary-wrap">
              <h1>{t('paymentMode')}</h1>
              <div className="price-summary">
                <div className="price">
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    defaultValue="offline"
                    onChange={paymentOptionChange}
                  >
                    <FormControlLabel
                      value="online"
                      control={<Radio />}
                      label={t('online')}
                      disabled
                      sx={{
                        '& .MuiTypography-root': {
                          fontFamily: 'Poppins',
                        },
                      }}
                    />
                    <FormControlLabel
                      value="offline"
                      control={<Radio />}
                      label={t('offline')}
                      sx={{
                        '& .MuiTypography-root': {
                          fontFamily: 'Poppins',
                        },
                      }}
                    />
                  </RadioGroup>
                </div>
                {
                  paymentType && (
                    <p>{t('onlinePaymentMessage')}</p>
                  )
                }
              </div>
              <div className="form-button-group">
                <div className="form-button">
                  <Button
                    label={t('back')}
                    click={backButton}
                    classes="default-button"
                  />
                </div>
                <div className="form-button">
                  <Button
                    label={buttonStatus === 'loading' ? t('creating') : t('create')}
                    click={submit}
                    classes="success-button"
                    loading={buttonStatus === 'loading'}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Summary;
