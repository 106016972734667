import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  ZoomableGroup,
} from 'react-simple-maps';

import { setErrorNotification } from '../../../redux/slices/NotificationSlice';

import geoUrl from '../../../assets/json/mapjs.json';
import dashboard from '../../../utils/api/dashboard';

function MarketMap() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [countries, setCountries] = useState([]);
  const [zoom, setZoom] = useState(1);

  const handleZoomIn = () => {
    setZoom(zoom + 1);
  };

  const handleZoomOut = () => {
    if (zoom > 1) {
      setZoom(zoom - 1);
    }
  };

  const fetchCountryLatLong = () => {
    dashboard.getMarketMap()
      .then((res) => {
        setCountries(res?.data?.flatMap(
          (company) => company?.stores?.filter((store) => store?.longitude && store?.latitude).map((store) => ({
            name: company?.companyName,
            coordinates: [store?.longitude, store?.latitude],
          })),
        ));
      })
      .catch((err) => dispatch(setErrorNotification(err?.response?.data)));
  };

  useEffect(() => {
    fetchCountryLatLong();
  }, []);

  return (
    <div className="map-wrap">
      <div className="map-left">
        <h2>{t('marketPresenceByCountries')}</h2>
      </div>
      <div className="map-chart">
        <ComposableMap height={400}>
          <ZoomableGroup zoom={zoom}>
            <Geographies geography={geoUrl}>
              {({ geographies }) => geographies.map((geo) => (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  style={{
                    default: {
                      fill: '#ccc',
                      stroke: '#ccc',
                      strokeWidth: 0.5,
                      outline: 'none',
                    },
                  }}
                />
              ))}
            </Geographies>
            {countries.map(({ name, coordinates }) => (
              <Marker key={name} coordinates={coordinates}>
                <circle r={4} fill="#1A5EEC" stroke="#1A5EEC" strokeWidth={2} />
              </Marker>
            ))}
          </ZoomableGroup>
        </ComposableMap>
      </div>
      <div className="map-controls">
        <button type="button" onClick={handleZoomIn}>+</button>
        <button type="button" onClick={handleZoomOut}>-</button>
      </div>
    </div>
  );
}

export default MarketMap;
