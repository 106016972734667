import {
  get,
  post,
} from './base';

export default {
  newAddOn: (data = {}) => {
    const params = { ...data };
    return post(`${process.env.REACT_APP_API_URL}admin/v1/addon`, { ...params });
  },
  getAddons: (data = {}) => {
    const params = { ...data };
    return get(`${process.env.REACT_APP_API_URL}admin/v1/addon`, { params });
  },
};
