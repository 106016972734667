import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  fetchFulfillDeviceOrder,
  setTableFilterData,
  setFilter,
} from '../../../redux/slices/DeviceOrderFulfillSlice';
import DeleteModal from '../../../Shared/Components/DeleteModal';
import DropDown from '../../../Shared/Components/DropDown';
import Success from '../../../Shared/Components/Success';
import more from '../../../assets/icons/more.png';
import DataTable from '../../Table';
import PopupModel from '../../../Shared/Components/PopupModel';
import deviceOrderApi from '../../../utils/api/device-order';
import ModalBox from '../../../Shared/Components/ModalBox';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import { checkPermissionAllow } from '../../../utils/helpers';
import height from '../../../utils/size-variables';

function FulfillTable() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    deviceOrder,
    pageCount,
    totalPageCount,
    status,
    filter,
    tableFilterData,
    totalDataCount,
  } = useSelector((state) => state.deviceOrderFulfill);

  const [rowSelection, setRowSelection] = useState({});
  const [columnVisibility, setColumnVisibility] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [notification, setNotification] = useState(false);
  const [orderId, setOrderId] = useState({});
  const [open, setOpen] = useState(false);
  const [modalView, setModalView] = useState({});
  const [isFirstRender, setIsFirstRender] = useState(true);

  const getFulfillOrder = (counts, search) => {
    dispatch(fetchFulfillDeviceOrder({ page: counts, ...search }));
  };

  const handleScroll = () => {
    if (status === 'success') {
      getFulfillOrder(pageCount + 1, filter);
    }
  };

  const viewFunction = (id) => {
    navigate(`../view/${id}`);
  };

  const editFunction = (id) => {
    navigate(`../edit-serial-no/${id}`);
  };

  const closeModal = () => {
    setOpen(false);
    setNotification(false);
  };

  const deleteFunction = (id) => {
    setOrderId(id);
    setDeleteModal(true);
  };
  // Cancel the existing subscription
  const cancelFunction = () => {
    setDeleteModal(false);
    const request = {
      status: 'cancelled',
      currency: orderId.currency,
      company: orderId.company,
    };
    deviceOrderApi.cancelDeviceOrder(orderId.id, request)
      .then(() => {
        setNotification(true);
        setModalView({ content: <Success message={t('subscriptionCancelledSuccess')} /> });
        setOpen(true);
        setOrderId({});
        setTimeout(() => {
          setOpen(false);
          getFulfillOrder(1, {});
        }, 3000);
      })
      .catch((err) => dispatch(setErrorNotification(err?.response?.data)));
  };

  const actions = [
    {
      name: t('view'),
      url: '',
      function: viewFunction,
      key: 'view',
      enable: checkPermissionAllow('getDeviceOrder'),
    },
    {
      name: t('updateSerialNo'),
      url: '',
      function: editFunction,
      key: 'update',
      enable: checkPermissionAllow('setSerialNoDeviceOrder'),
    },
    {
      name: t('cancel'),
      url: '',
      function: deleteFunction,
      sendFullDetails: true,
      key: 'cancel',
      enable: checkPermissionAllow('deleteDeviceOrder'),
    },
  ];

  const columns = [
    {
      header: t('sNo'),
      size: 60,
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      Cell: ({ row }) => row.index + 1,
    },
    {
      header: t('account'),
      accessorKey: 'company_name',
      filterFn: 'commonFIlterFn',
      Cell: ({ row }) => row?.original?.companyName,
    },
    {
      header: t('orderId'),
      accessorKey: 'order_id',
      filterFn: 'commonFIlterFn',
      Cell: ({ row }) => row?.original?.id,
    },
    {
      header: t('rentalDevices'),
      accessorKey: 'rental_device_count',
      filterFn: 'commonFIlterFn',
      Cell: ({ row }) => (
        <PopupModel
          list={row?.original?.deviceCatalogList}
          name={row?.original?.deviceCatalogList?.length}
          id={row?.original?.id}
        />
      ),
    },
    {
      header: t('status'),
      accessorKey: 'status',
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      Cell: ({ row }) => <div className={row?.original?.status}>{row?.original?.status}</div>,
    },
    {
      header: '',
      accessorKey: 'action',
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      enableHiding: true,
      filterVariant: 'none',
      size: 50,
      Cell: ({ row }) => (
        <DropDown
          action={actions}
          icon={more}
          value={row?.original}
          dropdown="tableForm"
          height="15px"
          tooltip=""
        />
      ),
    },
  ];
  const onFilterOrSortingChange = (filteredValues, reduxColumnFiltersSorting) => {
    dispatch(setTableFilterData(reduxColumnFiltersSorting));
    dispatch(setFilter(filteredValues));
    getFulfillOrder(1, filteredValues);
  };
  useEffect(() => {
    setIsFirstRender(false);
    getFulfillOrder(1, {});
  }, []);
  return (
    <div className="content-area">
      <ModalBox
        status={open}
        closeModal={closeModal}
        modalView={modalView}
        notification={notification}
      />
      <DeleteModal
        title={t('confirmExtendSubscription')}
        subTitle={t('confirmCancelDeviceRentalMessage')}
        buttonLabel={t('yes')}
        displayLabelNo
        status={deleteModal}
        setOpen={setDeleteModal}
        okFunction={cancelFunction}
      />
      <div className="my-content">
        <div className="my-content-area">
          <DataTable
            header={columns}
            value={deviceOrder}
            status={status}
            totalPageCount={totalPageCount}
            pageCount={pageCount}
            reduxColumnFiltersSorting={tableFilterData}
            onFilterOrSortingChange={onFilterOrSortingChange}
            isFirstRender={isFirstRender}
            scrollData={handleScroll}
            totalDataCount={totalDataCount}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            columnVisibility={columnVisibility}
            setColumnVisibility={setColumnVisibility}
            enable={Object.keys(filter).filter((val) => val !== 'sortField' && val !== 'sortBy').length > 0}
            height={height.tableHeight}
          />
        </div>
      </div>
    </div>
  );
}

FulfillTable.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      deviceCatalogList: PropTypes.arrayOf.isRequired,
      status: PropTypes.string.isRequired,
    }).isRequired,
  }),
};

FulfillTable.defaultProps = {
  row: null,
};

export default FulfillTable;
