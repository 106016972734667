/* eslint-disable no-param-reassign */
/* eslint no-underscore-dangle: 0 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import storeLocation from '../../utils/api/store-location';
import { setErrorNotification } from './NotificationSlice';

export const fetchStoreLocationList = createAsyncThunk(
  'storeLocation/fetchStoreLocation',
  async (data, { dispatch }) => {
    const response = await storeLocation.getLocations(data).then((response1) => {
      const tableValues = response1?.data?.results.map((val) => ({
        ...val, area_city: `${val?.area}, ${val?.city}`, state_country: `${val?.state}, ${val?.country}`,
      }));
      return { ...response1?.data, results: tableValues, code: 200 };
    }).catch((error) => dispatch(setErrorNotification(error?.response?.data)));
    return response;
  },
);

export const fetchStoreLocationListAll = createAsyncThunk(
  'storeLocation/fetchStoreLocationAll',
  async (data, { dispatch }) => {
    const response = await storeLocation.getLocationsAll()
      .then((response1) => {
        const storeList = response1?.data?.map((store) => ({
          ...store,
          id: store?._id,
        }));
        return { storeList, code: 200 };
      })
      .catch((error) => dispatch(setErrorNotification(error?.response?.data)));
    return response;
  },
);

const initialState = {
  storeLocation: [],
  storeLocationAll: [],
  pageCount: 1,
  totalPageCount: 1,
  singleStoreLocation: {},
};

export const storeLocationSlice = createSlice({
  name: 'storeLocation',
  initialState,
  reducers: {
    singleStoreLocation: (state, action) => {
      state.singleStoreLocation = state.storeLocation.find((val) => val.id === action.payload);
    },
    resetStoreLocationState: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchStoreLocationList.fulfilled, (state, action) => {
      // Add user to the state array
      if (action?.payload?.code === 200) {
        state.pageCount = (action.payload.page + 1);
        state.totalPageCount = action.payload.totalPages;
        const datas = action.payload.results;
        if (action.payload.page === 1) {
          state.storeLocation = datas;
        } else {
          state.storeLocation.push(...datas);
        }
      }
    });
    builder.addCase(fetchStoreLocationListAll.fulfilled, (state, action) => {
      // Add user to the state array
      if (action?.payload?.code === 200) {
        state.storeLocationAll = action?.payload?.storeList;
      }
    });
  },
});

export const { singleStoreLocation, resetStoreLocationState } = storeLocationSlice.actions;

export default storeLocationSlice.reducer;
