import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button';

function ConfirmationPopup(props) {
  return (
    <div className="confirm-wrap">
      <div className="confirm-content">
        <h2>{props?.title}</h2>
        <p>{props?.description}</p>
      </div>
      <div className="confirm-buttons">
        <Button
          classes="default-button"
          click={props?.onCloseFunction}
          label={props?.closeButtonLabel}
        />
        <Button
          classes="default-button"
          click={props?.onConfirmFunction}
          label={props?.confirmButtonLabel}
          fontColor={props?.confirmButtonFontColor}
          buttonColor={props?.confirmButtonColor}
          buttonHoverColor={props?.confirmButtonHoverColor}
        />
      </div>
    </div>
  );
}

ConfirmationPopup.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onCloseFunction: PropTypes.func.isRequired,
  closeButtonLabel: PropTypes.string.isRequired,
  onConfirmFunction: PropTypes.func.isRequired,
  confirmButtonLabel: PropTypes.string.isRequired,
  confirmButtonFontColor: PropTypes.string.isRequired,
  confirmButtonColor: PropTypes.string.isRequired,
  confirmButtonHoverColor: PropTypes.string.isRequired,
};

export default ConfirmationPopup;
