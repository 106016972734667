/* eslint no-underscore-dangle: 0 */

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import TopContent from '../../../Shared/Components/TopContent';
import SelectBox from '../../../Shared/Components/Form/SelectBox';
import Button from '../../../Shared/Components/Button';
import { addDeviceOrderSummary } from '../../../redux/slices/DeviceOrderPendingSlice';
import { fetchCompanyListAll } from '../../../redux/slices/CompanySlice';
import { fetchDeviceCatalogueListAll } from '../../../redux/slices/DeviceCatalogueSlice';
import { Input, InputInnerSymbol } from '../../../Shared/Components/Form/Input';

function DeviceOrderAdd() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();

  const { deviceOrderSummary } = useSelector((state) => state.deviceOrderPending);
  const companyList = useSelector((state) => state.company.companyAll);
  const { deviceCatalogueAllList } = useSelector((state) => state.deviceCatalogue);
  const [value, setValue] = useState(deviceOrderSummary);
  const [disabled, setDisabled] = useState(true);
  const [currency, setCurrency] = useState(deviceOrderSummary?.currency || '');
  const [rentalDevice, setRentalDevice] = useState(deviceOrderSummary?.rentalDevice || [
    {
      device_catalog_id: '',
      tenure: '',
      quantity: 1,
      price: 0,
      unitPrice: 0,
    },
  ]);
  // const [modal, setModal] = useState(false);
  const navigate = useNavigate();

  const backButton = () => {
    navigate('../processed');
    dispatch(addDeviceOrderSummary({}));
  };

  const inputHandleChange = (name, changeValue, index) => {
    setRentalDevice((prevState) => prevState.map((device, i) => {
      let values = device;
      if (i === index) {
        if (name === 'device_catalog_id') {
          const tenureList = changeValue?.price_per_device?.find(
            (currencies) => currencies.currency === value.currencyCode,
          );
          const price = tenureList?.price_in_months.find((month) => month.month === device.tenure);
          values = {
            ...device,
            [name]: changeValue?.id,
            [`${name}_label`]: changeValue?.label,
            tenureList: tenureList?.price_in_months?.map((val) => (
              {
                ...val,
                label: `${val.month} months`,
                id: val.month,
              }
            )),
            unitPrice: price?.price || 0,
            price: price ? price.price * device.quantity : 0,
            tenure_label: price ? `${price.month} months` : '',
            tenure: price ? price.month : '',
          };
        }
        if (name === 'tenure') {
          values = {
            ...device,
            [name]: changeValue?.id,
            [`${name}_label`]: changeValue?.label,
            unitPrice: changeValue?.price,
            price: changeValue ? changeValue.price * device.quantity : 0,
          };
        }
        if (name === 'quantity') {
          values = {
            ...device,
            [name]: changeValue,
            price: changeValue * device.unitPrice,
          };
        }
      }
      return values;
    }));
  };

  const addRentalDevice = () => {
    setRentalDevice(
      (prevState) => [
        ...prevState,
        {
          device_catalog_id: '',
          tenure: '',
          quantity: 1,
          price: 0,
          unitPrice: 0,
        },
      ],
    );
  };

  const removeRentalDevice = (val) => {
    setRentalDevice((prevState) => prevState.filter((device, i) => val !== i));
  };

  const handleChange = (name, data) => {
    if (data && (name === 'account')) {
      setValue({
        ...value,
        [`${name}Label`]: data?.label,
        [name]: data?.id,
        currencyCode: data?.currency || 'USD',
      });
      setCurrency(data?.currency_symbol || 'USD');
    } else {
      setValue({
        ...value,
        [`${name}Label`]: undefined,
        [name]: undefined,
        currencyCode: '',
      });
      setCurrency('');
    }
  };

  const submit = () => {
    if (rentalDevice?.filter((device) => device.device_catalog_id_label !== undefined)?.length > 0) {
      dispatch(addDeviceOrderSummary({
        account: value?.account,
        accountLabel: value?.accountLabel,
        currencyCode: value?.currencyCode,
        totalPrice: rentalDevice?.reduce((acc, cur) => acc + parseInt(cur.price, 10), 0),
        rentalDevice: rentalDevice?.filter((device) => device.device_catalog_id_label !== undefined),
        currency,
      }));
      navigate('../summary');
    }
  };

  useEffect(() => {
    if (value?.account) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
    let isDisabled = 0;
    rentalDevice.map((device) => {
      if (
        device?.device_catalog_id_label
        && device?.tenure_label
        && device?.quantity > 0
        && device?.price > 0
      ) {
        isDisabled += 1;
      }
      return device;
    });
    setDisabled(isDisabled !== rentalDevice.length);
  }, [value, rentalDevice]);

  useEffect(() => {
    dispatch(fetchCompanyListAll());
    dispatch(fetchDeviceCatalogueListAll());
    if (!location?.state?.edit) {
      dispatch(addDeviceOrderSummary({}));
    }
  }, []);

  return (
    <div className="main-container">
      <TopContent
        label={[t('deviceOrder'), t('newRental')]}
        buttonClass="success-button"
        click={backButton}
      />
      <div className="content-area">
        <div className="device-order-form">
          <h1>{t('customerInformation')}</h1>
          <div className="single-row">
            <SelectBox
              label={t('account')}
              name="account"
              required
              value={value.accountLabel}
              onchange={handleChange}
              lists={companyList}
              id="select-product"
              placeholder={t('selectProduct')}
            />
          </div>
        </div>
        <div className="device-order-form">
          <h1>{t('rentalInformation')}</h1>
          <div className="rental-devices-form">
            {
              rentalDevice.map((device, index) => (
                <div className="rental-device-row">
                  <SelectBox
                    label={t('deviceName')}
                    name="device_catalog_id"
                    required
                    value={device.device_catalog_id_label}
                    onchange={inputHandleChange}
                    lists={value.account === undefined ? [] : deviceCatalogueAllList}
                    index={index}
                    id="select-device"
                    placeholder={t('selectDeviceName')}
                  />
                  <SelectBox
                    label={t('rentalTenure')}
                    name="tenure"
                    required
                    value={device.tenure_label}
                    onchange={inputHandleChange}
                    index={index}
                    lists={value.account === undefined ? [] : device.tenureList}
                    id="select-rental"
                    placeholder={t('selectRentalTenure')}
                  />
                  <Input
                    icon={' '}
                    label={t('quantity')}
                    type="number"
                    name="quantity"
                    required
                    index={index}
                    value={device.quantity}
                    change={inputHandleChange}
                    min={1}
                    disabled={value.account === undefined}
                  />
                  <InputInnerSymbol
                    type="number"
                    label={t('price')}
                    name="price"
                    value={device.price}
                    readonly
                    index={index}
                    change={inputHandleChange}
                    placeholder={0}
                    symbol={currency}
                    disabled={value.account === undefined}
                  />
                  {
                    index === 0 ? (
                      <div onClick={addRentalDevice} role="presentation" className="add-button">{t('add')}</div>
                    ) : (
                      <div
                        onClick={() => removeRentalDevice(index)}
                        role="presentation"
                        className="delete-button"
                      >
                        {t('remove')}
                      </div>
                    )
                  }

                </div>
              ))
            }
          </div>
          <div className="form-button-group">
            <div className="form-button">
              <Button
                label={t('back')}
                click={backButton}
                classes="default-button"
              />
            </div>
            <div className="form-button">
              <Button
                label={location?.state?.edit ? t('update') : t('create')}
                click={submit}
                classes="success-button"
                disabled={disabled}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeviceOrderAdd;
