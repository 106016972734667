/* eslint-disable no-param-reassign */
/* eslint no-underscore-dangle: 0 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import subscription from '../../utils/api/subscription';
import { currencySymbol } from '../../utils/helpers';
import { setErrorNotification } from './NotificationSlice';

export const fetchActiveSubscription = createAsyncThunk(
  'subscription/fetchActiveSubscription',
  async (data, { dispatch }) => {
    const response = await subscription.getSubscription({ ...data, status: 'active' })
      .then((res) => {
        const tableData = res?.data?.results?.map((item) => ({
          ...item,
          id: item?._id,
          plan: item?.currentPlan,
          product: item?.currentPlan?.product,
          plan_type: item?.currentPlan?.plan_type,
          plan_name: item?.currentPlan?.name,
          plan_storage: `${item?.subscription?.storage} GB`,
          company_id: item?.company?._id,
          company_name: item?.company?.name,
          order_id: item?.subscription?.order_id,
          device: item?.subscription?.device,
          addon: item?.subscription?.addon,
          device_limit: item?.subscription?.device?.count || null,
          addon_storage: item?.subscription?.addon?.storage?.count || null,
          total_price_toShow: currencySymbol(item?.currency, item?.subscription?.total_price),
          start_date: new Date(item?.subscription?.start_date).toISOString().split('T')[0] || '-',
          end_date: new Date(item?.subscription?.end_date).toISOString().split('T')[0] || '-',
          start_dated: item?.subscription?.start_date,
          end_dated: item?.subscription?.end_date,
          device_rental: item?.device_rental?.map((device) => ({
            ...device,
            total_price_toShow: currencySymbol(device?.currency, device?.price),
          })),
        }));
        return { ...res?.data, results: tableData, code: 200 };
      })
      .catch((err) => dispatch(setErrorNotification(err?.response?.data)));
    return response;
  },
);

const initialState = {
  activeSubscription: [],
  successNotification: null,
  pageCountActive: 0,
  totalPageCountActive: 1,
  tableFilterDataActive: {},
  filterActive: {},
  totalDataCountActive: 0,
  productSummary: {},
  status: 'success',
  deviceSummary: [
    {
      device_name: '',
      deviceId: '',
      duration: '',
      duration_key: '',
      quantity: '',
      amount: '',
      error: {},
    },
  ],
};

export const activeSubscriptionSlice = createSlice({
  name: 'activeSubscription',
  initialState,
  reducers: {
    addProductSummary: (state, action) => {
      state.productSummary = action.payload;
    },
    addDeviceSummary: (state, action) => {
      const updatedDeviceSummary = action.payload?.map((summary) => ({
        ...summary,
        price: `$${summary.amount}`,
      }));
      state.deviceSummary = updatedDeviceSummary;
    },
    changeSuccessStatus: (state, action) => {
      state.successNotification = action.payload;
    },
    setTableFilterData: (state, action) => {
      state.tableFilterDataActive = action.payload;
    },
    setFilter: (state, action) => {
      state.filterActive = action.payload;
    },
    resetSubscriptionState: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchActiveSubscription.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchActiveSubscription.fulfilled, (state, action) => {
        state.status = 'success';
        if (action?.payload?.code === 200) {
          const data = action?.payload?.results;
          state.pageCountActive = action.payload.page;
          state.totalPageCountActive = action?.payload?.totalPages === 0
            ? 1
            : action?.payload?.totalPages;
          state.totalDataCountActive = action?.payload?.totalResults;
          if (action?.payload?.page === 1) {
            state.activeSubscription = data;
          } else {
            state.activeSubscription.push(...data);
          }
        }
      });
  },
});

export const {
  addProductSummary,
  addDeviceSummary,
  changeSuccessStatus,
  setTableFilterData,
  setFilter,
  resetSubscriptionState,
} = activeSubscriptionSlice.actions;

export default activeSubscriptionSlice.reducer;
