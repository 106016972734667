import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import profile from '../../../assets/images/download.jpg';

import TopContent from '../../../Shared/Components/TopContent';
import { Input } from '../../../Shared/Components/Form/Input';
import { singleUsers } from '../../../redux/slices/UsersSlice';
import { checkPermissionAllow } from '../../../utils/helpers';

function UserView() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const userData = useSelector((state) => state.users.users);
  const value = useSelector((state) => state.users.singleUsers);

  useEffect(() => {
    if (userData.length === 0) {
      navigate('../');
    }
    dispatch(singleUsers(params?.id));
  }, [params]);

  const editUser = () => {
    navigate(`../user-edit/${params?.id}`);
  };

  return (
    <div className="main-container">
      <TopContent
        label={[t('users'), value?.name]}
        buttonClass="default-button button-outline-success-1"
        button={checkPermissionAllow('editUser')}
        buttonLabel={t('editUser')}
        click={editUser}
      />
      <div className="content-area">
        <div className="my-content">
          <div className="my-content-area">
            <div className="user-form-wrap">
              <div className="profile-upload" style={{ height: '100%' }}>
                <label htmlFor="file" className="upload-click">
                  <img src={value?.signedUrl || profile} alt="profile" />
                </label>
              </div>
              <div className="user-form" style={{ padding: '30px 25px' }}>
                <div className="single-row">
                  <Input
                    icon={' '}
                    label={t('name')}
                    type="text"
                    name="name"
                    readonly
                    value={value?.name}
                  />
                  <Input
                    icon={' '}
                    label={t('username')}
                    type="text"
                    name="user_name"
                    readonly
                    value={value?.user_name}
                  />
                </div>
                <div className="single-row">
                  <Input
                    icon={' '}
                    label={t('mailId')}
                    type="text"
                    name="email"
                    readonly
                    value={value?.email}
                  />
                  <Input
                    icon={' '}
                    label={t('role')}
                    type="text"
                    name="role"
                    value={value?.role?.name}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserView;
