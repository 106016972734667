/* eslint-disable no-param-reassign */
/* eslint no-underscore-dangle: 0 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import roles from '../../utils/api/roles';
import { setErrorNotification } from './NotificationSlice';

export const fetchRolesList = createAsyncThunk(
  'roles/fetchRoles',
  async (data, { dispatch }) => {
    const response = await roles.getRoles(data).then((response1) => ({
      ...response1?.data,
      results: response1?.data.results.map((val) => ({ ...val, id: val._id })),
      code: 200,
    }))
      .catch((error) => dispatch(setErrorNotification(error?.response?.data)));
    return response;
  },
);

export const fetchPermissionList = createAsyncThunk(
  'roles/fetchPermissions',
  async (data, { dispatch }) => {
    const response = await roles.getPermissions(data).then((response1) => (
      response1?.data?.map((e) => ({
        ...e,
        id: e?._id,
      }))
    )).catch((error) => {
      dispatch(setErrorNotification(error?.response?.data));
      return [];
    });
    return response;
  },
);

export const fetchRolesListAll = createAsyncThunk(
  'roles/fetchRolesAll',
  async (data, { dispatch }) => {
    const response = await roles.getRolesAll().then((response1) => (
      response1?.data?.map((e) => ({
        ...e,
        id: e?._id,
      }))
    ))
      .catch((error) => dispatch(setErrorNotification(error?.response?.data)));
    return response;
  },
);

const initialState = {
  roles: [],
  pageCount: 0,
  totalPageCount: 1,
  singleRoles: {},
  rolesAllList: [],
  permissions: [],
  status: 'success',
  tableFilterData: {},
  filter: {},
};

export const rolesSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    singleRoles: (state, action) => {
      state.singleRoles = state.roles.find((val) => val.id === action.payload);
    },
    addRoleDetails: (state, action) => {
      state.roles.unshift({
        ...action.payload,
        id: action.payload._id,
      });
    },
    updateRoleDetails: (state, action) => {
      state.roles = state.roles.map((role) => {
        let output = role;
        if (role.id === action.payload._id) {
          output = {
            ...action.payload,
            id: action.payload._id,
          };
        }
        return output;
      });
    },
    deleteRoleDetails: (state, action) => {
      state.roles = state.roles.filter((val) => val.id !== action.payload);
    },
    singleRolesAdd: (state, action) => {
      state.singleRoles = action.payload;
    },
    setTableFilterData: (state, action) => {
      state.tableFilterData = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    resetRoleState: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchRolesList.pending, (state) => {
      state.status = 'loading';
    }).addCase(fetchRolesList.fulfilled, (state, action) => {
      state.status = 'success';
      // Add user to the state array
      if (action?.payload?.code === 200) {
        state.pageCount = action.payload.page;
        state.totalPageCount = action?.payload?.totalPages > 0 ? action?.payload?.totalPages : 1;
        const data = action.payload.results;
        if (action.payload.page === 1) {
          state.roles = data;
        } else {
          const finalData = [...state.roles];
          // Add fetchedData to finalData, but only if the id is not already present
          data.forEach((obj) => {
            if (!finalData.some((item) => item._id === obj._id)) {
              finalData.push(obj);
            }
          });
          state.roles = finalData;
        }
      }
    });
    builder.addCase(fetchRolesListAll.fulfilled, (state, action) => {
      // Add user to the state array
      state.rolesAllList = action.payload;
    });
    builder.addCase(fetchPermissionList.fulfilled, (state, action) => {
      // Add user to the state array
      state.permissions = action.payload;
    });
  },
});

export const {
  singleRoles,
  singleRolesAdd,
  setTableFilterData,
  addRoleDetails,
  updateRoleDetails,
  deleteRoleDetails,
  setFilter,
  resetRoleState,
} = rolesSlice.actions;

export default rolesSlice.reducer;
