import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';

import TopContent from '../../Shared/Components/TopContent';
import ExpiredDeviceTable from './Components/ExpiredDeviceTable';
import ActiveDeviceTable from './Components/ActiveDeviceTable';
import { checkPermissionAllow } from '../../utils/helpers';

function DeviceRental() {
  const { t } = useTranslation();
  const params = useParams();
  const menus = [
    {
      key: 'active',
      enable: checkPermissionAllow('getActiveDeviceRental'),
    },
    {
      key: 'expired',
      enable: checkPermissionAllow('getExpiredDeviceRental'),
    },
  ];
  const navigate = useNavigate();
  return (
    <div className="main-container">
      <TopContent
        label={t('deviceRental')}
        buttonClass="success-button"
        button={false}
        buttonLabel={t('newOrder')}
        click={() => { }}
      />
      <div className="content-area">
        <div className="my-content">
          <div className="my-content-area">
            <div className="subscription-menu">
              {
                menus?.map((menu) => menu.enable && (
                  <div
                    role="presentation"
                    className={params?.page === menu.key ? 'each-menu menu-active' : 'each-menu'}
                    onClick={() => navigate(`../${menu.key}`)}
                  >
                    {t(menu.key)}
                  </div>
                ))
              }
            </div>
            {
              params?.page === 'active' && checkPermissionAllow('getActiveDeviceRental') && <ActiveDeviceTable />
            }
            {
              params?.page === 'expired' && checkPermissionAllow('getExpiredDeviceRental') && <ExpiredDeviceTable />
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeviceRental;
