import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ModalBox from '../../../Shared/Components/ModalBox';
import {
  fetchProcessedDeviceOrder,
  setTableFilterData,
  setFilter,
} from '../../../redux/slices/DeviceOrderProcessSlice';
import DeleteModal from '../../../Shared/Components/DeleteModal';
import deviceOrderApi from '../../../utils/api/device-order';
import { changeDateFormat } from '../../Global/timeFormat';
import ConfirmModal from '../../../Shared/Components/ConfirmModal';
import DropDown from '../../../Shared/Components/DropDown';
import Success from '../../../Shared/Components/Success';
import more from '../../../assets/icons/more.png';
import DataTable from '../../Table';
import exportCSV from '../../Global/exportCSV';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import PopupModel from '../../../Shared/Components/PopupModel';
import { checkPermissionAllow } from '../../../utils/helpers';
import height from '../../../utils/size-variables';

function ProcessedTable() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Status of the popup model
  const [open, setOpen] = useState(false);
  const [confirm, setConfirm] = useState(false);

  // Content of popup model
  const [modalView, setModalView] = useState({});

  // Delete Popup model
  const [deleteModal, setDeleteModal] = useState(false);

  // To show close icon popup model
  const [notification, setNotification] = useState(false);

  const [orderId, setOrderId] = useState({});

  const {
    pageCount,
    totalPageCount,
    deviceOrder,
    status,
    filter,
    tableFilterData,
    totalDataCount,
  } = useSelector((state) => state.deviceOrderProcess);

  const [rowSelection, setRowSelection] = useState({});
  const [columnVisibility, setColumnVisibility] = useState({});
  const [isFirstRender, setIsFirstRender] = useState(true);

  // Subscription view popup
  const viewFunction = (id) => {
    navigate(`../view/${id}`);
  };

  const getProcessedOrder = (counts, search) => {
    if (status === 'success') {
      dispatch(fetchProcessedDeviceOrder({ page: counts, ...search }));
    }
  };

  // Redirect to existing page
  const editFunction = (id) => {
    navigate(`../edit-serial-no/${id}`);
  };

  // Open subscription extend popup
  const extendSubscriptionFunction = () => {
    setConfirm(false);
    setNotification(false);
    setOpen(true);
    setOrderId({});
  };

  const deleteFunction = (id) => {
    setOrderId(id);
    setDeleteModal(true);
  };

  // Cancel the existing subscription
  const cancelFunction = () => {
    setDeleteModal(false);
    const request = {
      status: 'cancelled',
      company: orderId.company,
    };
    deviceOrderApi.cancelDeviceOrder(orderId.id, request)
      .then(() => {
        setNotification(true);
        setModalView({ content: <Success message={t('subscriptionCancelledSuccess')} /> });
        setOpen(true);
        setOrderId({});
        setTimeout(() => {
          setOpen(false);
          getProcessedOrder(1, {});
        }, 3000);
      })
      .catch((err) => dispatch(setErrorNotification(err?.response?.data)));
  };

  const handleScroll = () => {
    getProcessedOrder(pageCount + 1, filter);
  };

  const closeModal = () => {
    setOpen(false);
    setNotification(false);
  };

  const actions = [
    {
      name: t('view'),
      url: '',
      function: viewFunction,
      key: 'view',
      enable: checkPermissionAllow('getDeviceOrder'),
    },
    {
      name: t('editSerialNos'),
      url: '',
      function: editFunction,
      key: 'update',
      enable: checkPermissionAllow('setSerialNoDeviceOrder'),
    },
    {
      name: t('cancel'),
      url: '',
      function: deleteFunction,
      sendFullDetails: true,
      key: 'cancel',
      enable: checkPermissionAllow('deleteDeviceOrder'),
    },
  ];

  const columns = [
    {
      header: t('sNo'),
      size: 60,
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      Cell: ({ row }) => row.index + 1,
    },
    {
      header: t('account'),
      accessorKey: 'company_name',
      filterFn: 'commonFIlterFn',
      Cell: ({ row }) => row?.original?.companyName,
    },
    {
      header: t('orderId'),
      accessorKey: 'order_id',
      filterFn: 'commonFIlterFn',
      muiFilterTextFieldProps: { type: 'number' },
      Cell: ({ row }) => row?.original?.id,
    },
    {
      header: t('rentalDevices'),
      accessorKey: 'rental_device_count',
      filterFn: 'commonFIlterFn',
      Cell: ({ row }) => (
        <PopupModel
          list={row?.original?.deviceCatalogList}
          name={row?.original?.deviceCatalogList?.length}
          id={row?.original?.id}
        />
      ),
    },
    {
      header: t('status'),
      accessorKey: 'status',
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      Cell: ({ row }) => <div className={row?.original?.status}>{row?.original?.status}</div>,
    },
    {
      header: '',
      accessorKey: 'action',
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      enableHiding: true,
      filterVariant: 'none',
      size: 50,
      Cell: ({ row }) => (
        <DropDown
          action={actions}
          icon={more}
          value={row?.original}
          dropdown="tableForm"
          height="15px"
          tooltip=""
        />
      ),
    },
  ];

  const exportData = () => {
    const columnHeaders = [
      'plan_type',
      'plan_name',
      'order_id',
      'product',
      'company',
      'total_price',
      'start_date',
      'end_date',
    ];

    // Filter the hidden column
    const filteredHeaders = columnHeaders.filter((header) => {
      if (header === 'plan_name') {
        return columnVisibility.name !== false;
      }
      return columnVisibility[header] !== false;
    });

    // API call for export CSV
    deviceOrderApi.exportSubscriptionData({ fields: filteredHeaders, ...filter, status: 'active' })
      .then((res) => {
        const rows = res?.data?.map((row) => ({
          ...row,
          start_date: row?.start_date ? changeDateFormat(row?.start_date) : undefined,
          end_date: row?.end_date ? changeDateFormat(row?.end_date) : undefined,
        }));

        // Function to export as CSV file
        exportCSV(Object.keys(rows?.[0]), rows);
      })
      .catch((err) => {
        dispatch(setErrorNotification(err?.response?.data));
      });
  };
  const onFilterOrSortingChange = (filteredValues, reduxColumnFiltersSorting) => {
    dispatch(setTableFilterData(reduxColumnFiltersSorting));
    dispatch(setFilter(filteredValues));
    getProcessedOrder(1, filteredValues);
  };
  useEffect(() => {
    setIsFirstRender(false);
    getProcessedOrder(1, {});
  }, []);

  return (
    <div className="content-area">
      <ModalBox
        status={open}
        closeModal={closeModal}
        modalView={modalView}
        notification={notification}
      />
      <DeleteModal
        title={t('confirmCancelDeviceRental')}
        subTitle={t('confirmCancelDeviceRentalMessage')}
        buttonLabel={t('yes')}
        displayLabelNo
        status={deleteModal}
        setOpen={setDeleteModal}
        okFunction={cancelFunction}
      />
      <ConfirmModal
        title={t('confirmExtendSubscription')}
        subTitle={t('extendSubscriptionMessage')}
        subTitle1={t('cannotUndoAction')}
        buttonLabel="Yes"
        status={confirm}
        setOpen={setConfirm}
        okFunction={extendSubscriptionFunction}
      />
      <div className="my-content">
        <div className="my-content-area">
          <DataTable
            header={columns}
            value={deviceOrder}
            status={status}
            totalPageCount={totalPageCount}
            pageCount={pageCount}
            reduxColumnFiltersSorting={tableFilterData}
            onFilterOrSortingChange={onFilterOrSortingChange}
            isFirstRender={isFirstRender}
            scrollData={handleScroll}
            totalDataCount={totalDataCount}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            columnVisibility={columnVisibility}
            setColumnVisibility={setColumnVisibility}
            enable={
              Object.keys(filter).filter(
                (val) => val !== 'sortField' && val !== 'sortBy' && val !== 'type',
              ).length > 0
            }
            height={height.tableHeight}
            exportButton={false}
            exportData={exportData}
          />
        </div>
      </div>
    </div>
  );
}
ProcessedTable.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      deviceCatalogList: PropTypes.arrayOf.isRequired,
      status: PropTypes.string.isRequired,
    }).isRequired,
  }),
};

ProcessedTable.defaultProps = {
  row: null,
};
export default ProcessedTable;
