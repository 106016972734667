/* eslint no-underscore-dangle: 0 */

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { fetchPlans } from '../../../redux/slices/PlanSlice';
import { Input, InputDate, InputIncreaseDecrease } from '../../../Shared/Components/Form/Input';
import SelectBox from '../../../Shared/Components/Form/SelectBox';
import TopContent from '../../../Shared/Components/TopContent';
import Button from '../../../Shared/Components/Button';
import { currencySymbol } from '../../../utils/helpers';
import subscription from '../../../utils/api/subscription';
import { fetchActiveSubscription } from '../../../redux/slices/ActiveSubscriptionSlice';
import ModalBox from '../../../Shared/Components/ModalBox';
import Success from '../../../Shared/Components/Success';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';

function SubscriptionEdit() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const terms = [
    {
      label: 'Monthly',
      id: 1,
    },
    {
      label: 'Semi-Annual',
      id: 6,
    },
    {
      label: 'Annual',
      id: 12,
    },
  ];
  const params = useParams();

  const { plans } = useSelector((state) => state.plans);
  const { activeSubscription } = useSelector((state) => state.activeSubscription);

  const [planData, setPlanData] = useState([]);
  const [changePlan, setChangePlan] = useState(true);
  const [value, setValue] = useState(activeSubscription.find((each) => each.id === params?.id));
  const [buttonStatus, setButtonStatus] = useState('');
  const [devicePrice, setDevicePrice] = useState({
    totalPrice: 0.00,
    singleDevicePrice: 0.00,
    changeDeviceCount: 0,
  });
  const [storagePrice, setStoragePrice] = useState({
    totalPrice: 0.00,
    singleStoragePrice: 0.00,
    changeStorageCount: 0,
  });
  const currency = currencySymbol(value?.company?.currency || 'USD');
  const summary = [
    {
      title: 'Devices :',
      price: `${currency} ${devicePrice.totalPrice}`,
    },
    {
      title: 'Storage :',
      price: `${currency} ${storagePrice.totalPrice}`,
    },
    {
      title: 'Total Price :',
      price: `${currency} ${(parseFloat(storagePrice.totalPrice) + parseFloat(devicePrice.totalPrice)).toFixed(2)}`,
      className: 'total-price',
    },
  ];

  const backButton = () => {
    navigate('../');
  };

  const calculateDevicePrice = () => {
    let changeInDeviceCount = 0;
    let finalPlan;
    if ((value?.planId) && value?.planId !== value?.plan?._id) {
      finalPlan = value?.planId;
      changeInDeviceCount = value.device_limit;
    } else {
      finalPlan = value?.plan?._id;
      changeInDeviceCount = value.device_limit - value.device.count;
    }
    const data = {
      planId: finalPlan,
      tenure: value?.subscription?.tenure,
      start_date: value?.start_date,
      end_date: value?.end_date,
      currency: value?.currency || 'USD',
      deviceCountChange: changeInDeviceCount,
    };
    subscription.calculatePrice(data)
      .then((res) => setDevicePrice({
        ...devicePrice,
        totalPrice: res?.data?.additionalDevicePrice,
        singleDevicePrice: ((res.data.additionalDevicePrice || 0) / changeInDeviceCount).toFixed(2),
        changeDeviceCount: changeInDeviceCount,
      }))
      .catch((err) => dispatch(setErrorNotification(err?.response?.data)));
  };

  const calculateStoragePrice = () => {
    let changeInStorageCount = 0;
    if (value?.addon?.storage?.count) {
      changeInStorageCount = value.addon_storage - value.addon.storage.count;
    } else {
      changeInStorageCount = value.addon_storage;
    }
    const data = {
      planId: value?.plan?._id,
      tenure: value?.subscription?.tenure,
      start_date: value?.start_date,
      end_date: value?.end_date,
      currency: value?.currency || 'USD',
      storageCountChange: changeInStorageCount,
    };

    subscription.calculatePrice(data)
      .then((res) => setStoragePrice({
        ...devicePrice,
        totalPrice: res?.data?.additionalStoragePrice,
        singleStoragePrice: ((res.data.additionalStoragePrice || 0) / changeInStorageCount).toFixed(2),
        changeStorageCount: changeInStorageCount,
      }))
      .catch((err) => dispatch(setErrorNotification(err?.response?.data)));
  };

  const handleChange = (name, changeValue) => {
    if (name === 'planId') {
      if (activeSubscription?.new_plan_id === changeValue?.id) {
        setChangePlan(true);
      } else {
        setChangePlan(false);
      }
      setValue({ ...value, [`${name}Label`]: changeValue?.label, [name]: changeValue?.id });
    } else if (name === 'device_limit') {
      setValue({ ...value, [name]: changeValue });
    } else if (name === 'addon_storage') {
      setValue({ ...value, [name]: changeValue });
    }
  };

  const submit = () => {
    let finalPlan;
    if ((value?.planId) && value?.planId !== value?.plan?._id) {
      finalPlan = value?.planId;
    } else {
      finalPlan = value?.plan?._id;
    }
    const data = {
      id: value?.id,
      device: value?.device_limit,
      storage: value?.addon_storage || 0,
      plan: finalPlan,
    };
    subscription.editSubscription(data)
      .then(() => {
        setButtonStatus('success');
        setTimeout(() => {
          setButtonStatus('');
          dispatch(fetchActiveSubscription({ page: 1 }));
          navigate('../');
        }, 3000);
      })
      .catch((err) => dispatch(setErrorNotification(err?.response?.data)));
  };

  useEffect(() => {
    setPlanData(plans.map((plan) => ({
      ...plan,
      id: plan?._id,
      label: plan?.name,
    })));
  }, [plans]);

  useEffect(() => {
    if (value?.device?.count !== value?.device_limit) {
      calculateDevicePrice();
    } else {
      setDevicePrice({
        ...devicePrice,
        totalPrice: 0.00,
        singleDevicePrice: 0.00,
        changeDeviceCount: 0,
      });
    }
    if (value?.addon?.storage?.count !== value?.addon_storage) {
      calculateStoragePrice();
    } else {
      setStoragePrice({
        ...storagePrice,
        totalPrice: 0.00,
        singleStoragePrice: 0.00,
        changeStorageCount: 0,
      });
    }
    if ((value?.planId) && value?.plan?._id !== value?.planId) {
      calculateDevicePrice();
    }
  }, [value]);

  useEffect(() => {
    if (!value?.id) {
      navigate('../');
    }
  }, [params]);

  useEffect(() => {
    dispatch(fetchPlans({ page: 1 }));
  }, []);
  return (
    <div className="main-container">
      <ModalBox
        status={buttonStatus === 'success'}
        setOpen={setButtonStatus}
        modalView={{ content: <Success message={t('subscriptionUpdatedSuccess')} /> }}
        notification
        closeModal={() => { }}
      />
      <TopContent
        label={[t('subscriptions'), 'Edit Subscription']}
        buttonClass="success-button"
        click={backButton}
      />
      <div className="content-area">
        <div className="subscription-edit">
          <div className="subscription-edit-form">
            <h1>Product Information</h1>
            <div className="single-row">
              <Input
                label={t('product')}
                name="product"
                value={value?.product}
                readonly
              />
              <Input
                label={t('account')}
                name="company"
                readonly
                value={value?.company_name}
              />
            </div>
            <div className="single-row">
              <Input
                label="Plan Type"
                name="plan_type"
                value={value?.plan?.plan_type}
                readonly
              />
              <SelectBox
                label="Plan Name"
                name="planId"
                required
                value={value?.planIdLabel || value?.plan?.name}
                onchange={handleChange}
                lists={planData}
                id="select-term"
                placeholder="Select Plan Name"
              />
            </div>
            <div className="single-row">
              <Input
                label="Subscription Duration"
                name="tenure"
                value={terms?.find((each) => each?.id === value?.subscription?.tenure)?.label}
                readonly
              />
              <div className="input-with-price">
                <InputIncreaseDecrease
                  icon={' '}
                  label="No of Devices"
                  type="number"
                  name="device_limit"
                  required
                  value={value?.device_limit}
                  change={handleChange}
                  placeholder="Enter number of devices"
                  min={0}
                />
                <p>
                  {devicePrice.changeDeviceCount}
                  * {currency}{devicePrice.singleDevicePrice}/device =
                  {currency}{devicePrice.totalPrice}
                </p>
              </div>
            </div>
            <div className="single-row">
              <InputDate
                label="Valid From"
                name="start_date"
                value={dayjs(value?.start_date)}
                change={handleChange}
                readOnly
                disablePast
              />
              <InputDate
                label="Valid Till"
                name="end_date"
                value={dayjs(value?.end_date)}
                change={handleChange}
                readOnly
              />
            </div>
          </div>
          <div className="addOn-summary">
            {
              changePlan && (
                <div className="addOn">
                  <p>Plan Storage : {value?.plan_storage}</p>
                  <h1 className="title">Storage Summary</h1>
                  <div className="input-with-price">
                    <InputIncreaseDecrease
                      icon={' '}
                      label="Addon storage"
                      type="number"
                      name="addon_storage"
                      required={false}
                      value={value?.addon_storage}
                      change={handleChange}
                      placeholder="Enter number of devices"
                      min={0}
                    />
                    <p>
                      {storagePrice.changeStorageCount}
                      * {currency}{storagePrice.singleStoragePrice}/GB =
                      {currency}{storagePrice.totalPrice}
                    </p>
                  </div>
                </div>
              )
            }
            <div className="summary-wrapper">
              <h1 className="title">Summary</h1>
              <div className="summary-details">
                {summary?.map((each) => (
                  <div className={`summary-detail ${each?.className}`}>
                    <h3>{each?.title}</h3>
                    <p>{each?.price}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="form-button-group">
            <div className="form-button">
              <Button
                label="Back"
                click={backButton}
                classes="default-button"
              />
            </div>
            <div className="form-button">
              <Button
                label="Update"
                click={submit}
                classes="success-button"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubscriptionEdit;
