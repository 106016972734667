/* eslint-disable no-param-reassign */
/* eslint no-underscore-dangle: 0 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import licenseFeature from '../../utils/api/licenses-featureController';
import { setErrorNotification } from './NotificationSlice';

export const fetchFeatureController = createAsyncThunk(
  'licenseFeature/fetchFeatureController',
  async (data, { dispatch }) => {
    const response = await licenseFeature.getFeatureControllerList()
      .then((res) => ({ data: res?.data, code: 200 }))
      .catch((error) => dispatch(setErrorNotification(error?.response?.data)));
    console.log(response, 'response');
    return response;
  },
);

const initialState = {
  licenseFeatureList: [],
  licenseFeatureController: [],
  status: '',
};

export const featureControllerSlice = createSlice({
  name: 'licenseFeature',
  initialState,
  reducers: {
    resetFeatureState: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFeatureController.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchFeatureController.fulfilled, (state, action) => {
        state.status = 'success';
        if (action?.payload?.code === 200) {
          state.licenseFeatureController = action?.payload?.data;
        }
      });
  },
});

export const {
  resetFeatureState,
} = featureControllerSlice.actions;

export default featureControllerSlice.reducer;
