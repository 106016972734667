/* eslint no-underscore-dangle: 0 */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, createTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import deepEqual from 'deep-equal';

import { fetchPlans } from '../../redux/slices/PlanSlice';
import {
  fetchFeatureController,
} from '../../redux/slices/LicenseFeatureControllerSlice';

import TopContent from '../../Shared/Components/TopContent';
import Button from '../../Shared/Components/Button';
import ModalBox from '../../Shared/Components/ModalBox';
import Success from '../../Shared/Components/Success';
import featureApi from '../../utils/api/licenses-featureController';
import { setErrorNotification } from '../../redux/slices/NotificationSlice';
import ConfirmationPopup from '../../Shared/Components/ConfirmationPopup';
import { checkPermissionAllow } from '../../utils/helpers';

function FeatureController() {
  const { t } = useTranslation();
  const theme = createTheme({
    components: {
      MuiCheckbox: {
        styleOverrides: {
          root: {
            padding: '0px',
            color: '#CACACA',
          },
        },
      },
    },
  });

  const dispatch = useDispatch();
  const { licenseFeatureController } = useSelector((state) => state.licenseFeature);

  const [featureControllerList, setFeatureControllerList] = useState([]);
  const [buttonState, setButtonState] = useState('disable');
  const [updateNotification, setUpdateNotification] = useState('close');
  const [modalView, setModalView] = useState({});
  console.log(featureControllerList);
  const handleChange = (event, planName, featureName) => {
    setFeatureControllerList((prevState) => prevState.map((plan) => {
      if (plan.plan_name === planName) {
        return {
          ...plan,
          features: {
            ...plan.features,
            [featureName]: event.target.checked,
          },
        };
      }
      return plan;
    }));
  };

  const handleReset = () => {
    setUpdateNotification('close');
    setFeatureControllerList(licenseFeatureController);
  };

  const handleSubmit = () => {
    const featuresToUpdate = featureControllerList.map((plan) => ({
      plan_name: plan.plan_name,
      features: plan.features,
    }));
    setUpdateNotification('loading');
    featureApi.updateFeatureControllerList({
      features: featuresToUpdate,
    })
      .then(() => {
        setModalView({ content: <Success message={t('featureControllerUpdated')} /> });
        setUpdateNotification('open');
        setTimeout(() => {
          dispatch(fetchFeatureController());
          setUpdateNotification('close');
        }, 3000);
      })
      .catch((err) => {
        dispatch(setErrorNotification(err?.response?.data));
        setUpdateNotification('close');
      });
  };

  const resetConfirmation = () => {
    setUpdateNotification('open');
    setModalView({
      content: <ConfirmationPopup
        title={t('confirmResetFeatureController')}
        description={t('resetConfirmationMessage')}
        onCloseFunction={() => setUpdateNotification('close')}
        closeButtonLabel={t('back')}
        onConfirmFunction={() => handleReset()}
        confirmButtonLabel={t('confirm')}
        confirmButtonFontColor="#FFF"
        confirmButtonColor="#E8212E"
        confirmButtonHoverColor="#E8212E"
      />,
    });
  };

  useEffect(() => {
    setFeatureControllerList(licenseFeatureController);
  }, [licenseFeatureController]);

  useEffect(() => {
    if (!deepEqual(featureControllerList, licenseFeatureController)) {
      setButtonState('enable');
    } else {
      setButtonState('disable');
    }
  }, [featureControllerList]);

  useEffect(() => {
    dispatch(fetchPlans({ page: 1 }));
    dispatch(fetchFeatureController());
  }, []);

  return (
    <div className="main-container">
      <ModalBox
        status={updateNotification === 'open'}
        closeModal={setUpdateNotification}
        modalView={modalView}
        notification
      />
      <TopContent
        label={t('licenseFeatureController')}
      />
      <div className="content-area">
        <div className="controller-wrap">
          <table>
            <thead className="sticky-header">
              <tr>
                <th label="empty-header" />
                {featureControllerList.map((plan) => (
                  <th key={plan._id}>{plan.plan_name}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {Object.keys(featureControllerList[0]?.features || {}).map((feature) => (
                <tr key={feature}>
                  <td className="feature-data">{t(feature)}</td>
                  {featureControllerList.map((plan) => (
                    <td key={`${plan._id}-${feature}`} className="feature-data">
                      <Checkbox
                        theme={theme}
                        aria-label="Checkbox"
                        checked={plan.features[feature]}
                        onChange={(e) => handleChange(e, plan.plan_name, feature)}
                      />
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          <div className="controller-buttons">
            <Button
              label={t('reset')}
              classes="default-button"
              click={resetConfirmation}
            />
            {checkPermissionAllow('editFeatureController') && (
              <Button
                label={updateNotification === 'loading' ? t('updating') : t('update')}
                loading={updateNotification === 'loading'}
                classes={
                  updateNotification === 'loading'
                    ? 'success-button loading-btn'
                    : 'success-button'
                }
                click={handleSubmit}
                disabled={buttonState === 'disable'}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeatureController;
