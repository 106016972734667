import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import Button from '../../../Shared/Components/Button';
import TopContent from '../../../Shared/Components/TopContent';
import SelectBox from '../../../Shared/Components/Form/SelectBox';
import { Input, InputDate, InputInnerSymbol } from '../../../Shared/Components/Form/Input';

import { currencySymbol } from '../../../utils/helpers';
import transaction from '../../../utils/api/transaction';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import { setSelectedOrders } from '../../../redux/slices/TransactionPendingSlice';
import ModalBox from '../../../Shared/Components/ModalBox';
import Success from '../../../Shared/Components/Success';

function PaymentInfo() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedOrders, updatePaymentType } = useSelector((state) => state.transactionPending);
  const currency = currencySymbol(selectedOrders?.[0]?.company?.currency || 'USD');

  const [disable, setDisable] = useState(true);
  const [buttonStatus, setButtonStatus] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [priceToShow, setPriceToShow] = useState({
    actualPrice: 0,
    wallet: 0,
    totalPrice: 0,
  });
  const currentDate = new Date().toISOString().slice(0, 10);
  const priceData = [
    {
      label: t('price'),
      amount: `${currency} ${priceToShow.actualPrice.toFixed(2)}`,
    },
    {
      label: t('wallet'),
      amount: `- ${currency} ${priceToShow.wallet.toFixed(2)}`,
    },
    {
      label: t('totalPrice'),
      amount: `${currency} ${priceToShow.totalPrice.toFixed(2)}`,
    },
  ];

  // Available payment options
  const paymentOption = [
    {
      label: 'Net banking',
    },
    {
      label: 'UPI',
    },
    {
      label: 'Other',
    },
  ];

  const [payment, setPayment] = useState({});

  // To handle date in Mui date picker
  const [selectedDate, setSelectedDate] = useState({});
  // To handle change event from input and select tag
  const handleChange = (name, value) => {
    if (name === 'transaction_date') {
      if (value.format('YYYY-MM-DD') !== 'Invalid Date') {
        if (value.format('YYYY-MM-DD') <= currentDate) {
          setSelectedDate({ ...selectedDate, [name]: value });
          setPayment({ ...payment, [name]: dayjs(value).format('YYYY-MM-DD') });
          setErrorMessage('');
        } else {
          setErrorMessage('Enter valid Date');
        }
      } else {
        setErrorMessage('Invalid Date');
      }
    } else if (name === 'start_date') {
      setSelectedDate({
        ...selectedDate,
        [name]: value,
      });
      setPayment({
        ...payment,
        [name]: dayjs(value).format('YYYY-MM-DD'),
      });
    } else if (name === 'transaction_type' && value) {
      setPayment({ ...payment, [name]: value?.label });
    } else {
      setPayment({ ...payment, [name]: value });
    }
  };

  const submit = () => {
    setButtonStatus('loading');
    if (updatePaymentType === 'saas-pending') {
      const paymentInfo = {
        ...payment,
        subscription_transaction_id: selectedOrders.map((order) => order.id),
        transaction_amount: priceToShow.totalPrice,
      };
      transaction.updateSaasPayment(paymentInfo)
        .then(() => {
          setButtonStatus('success');
          setTimeout(() => {
            setButtonStatus('');
            dispatch(setSelectedOrders({ selectedOrders: [] }));
            navigate(-2);
          }, 3000);
        })
        .catch((err) => {
          setButtonStatus('error');
          dispatch(setErrorNotification(err?.response?.data));
        });
    } else if (updatePaymentType === 'device-rental') {
      const paymentInfo = {
        ...payment,
        order_id: selectedOrders?.[0]?.order_id,
        transaction_amount: priceToShow.totalPrice,
      };
      transaction.updateDeviceRentalPayment(paymentInfo)
        .then(() => {
          setButtonStatus('success');
          setTimeout(() => {
            setButtonStatus('');
            dispatch(setSelectedOrders({ selectedOrders: [] }));
            navigate(-2);
          }, 3000);
        })
        .catch((err) => {
          setButtonStatus('error');
          dispatch(setErrorNotification(err?.response?.data));
        });
    } else if (updatePaymentType === 'saas-device-rental') {
      const paymentInfo = {
        ...payment,
        id: selectedOrders?.[0]?.id,
        transaction_amount: priceToShow.totalPrice,
      };
      transaction.updateSaasDeviceRentalPayment(paymentInfo)
        .then(() => {
          setButtonStatus('success');
          setTimeout(() => {
            setButtonStatus('');
            dispatch(setSelectedOrders({ selectedOrders: [] }));
            navigate(-2);
          }, 3000);
        })
        .catch((err) => {
          setButtonStatus('error');
          dispatch(setErrorNotification(err?.response?.data));
        });
    }
  };

  useEffect(() => {
    if (payment.transaction_id
      && payment.transaction_date
      && payment.transaction_type
    ) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [payment]);

  useEffect(() => {
    let actualPrice = 0;
    let totalPrice = 0;
    selectedOrders.forEach((order) => {
      actualPrice = actualPrice + order.actual_price || 0;
      totalPrice = totalPrice + order.total_price || 0;
    });
    setPriceToShow({
      actualPrice,
      wallet: actualPrice - totalPrice,
      totalPrice,
    });
  }, [selectedOrders]);

  useEffect(() => {
    if (selectedOrders.length === 0) {
      navigate(-2);
    }
  }, []);

  return (
    <div className="main-container">
      <ModalBox
        status={buttonStatus === 'success'}
        closeModal={() => setButtonStatus('')}
        modalView={{ content: <Success message={t('paymentUpdatedSuccess')} /> }}
        notification
      />
      <TopContent
        label={[
          t('transactions'),
          selectedOrders?.[0]?.company?.name || selectedOrders?.[0]?.companyName,
        ]}
        labelClickFunc={() => navigate(-2)}
        customLabelClickFunc
      />
      <div className="content-area">
        <div className="product-summary-wrap">
          <div className="payment-info">
            <h2 className="summary-title">{t('paymentInformation')}</h2>
            <div className="payment-info-form">
              <div className="single-row">
                <Input
                  type="text"
                  label={t('transactionID')}
                  required
                  name="transaction_id"
                  value={payment?.transaction_id}
                  change={handleChange}
                  placeholder={t('enterTransactionID')}
                />
                <div>
                  <InputDate
                    label={t('transactionDate')}
                    name="transaction_date"
                    required
                    value={selectedDate?.transaction_date}
                    change={handleChange}
                    disableFuture
                  />
                  <div className="error-message">{errorMessage}</div>
                </div>
              </div>
              <div className="single-row">
                <SelectBox
                  label={t('transactionMode')}
                  name="transaction_type"
                  required
                  value={payment.transaction_type}
                  onchange={handleChange}
                  lists={paymentOption}
                  id="select-account"
                  placeholder={t('selectCompanyName')}
                />
                <InputInnerSymbol
                  type="number"
                  label={t('transactionAmount')}
                  required
                  value={priceToShow.totalPrice}
                  placeholder="0"
                  symbol={currency}
                  min={0}
                  readonly
                />
              </div>
            </div>
            <div className="form-button-group">
              <div className="form-button">
                <Button
                  label={t('back')}
                  click={() => navigate(-1)}
                  classes="default-button"
                />
              </div>
              <div className="form-button">
                <Button
                  label={t('updatePayment')}
                  click={submit}
                  loading={buttonStatus === 'loading'}
                  disabled={disable}
                  classes={buttonStatus === 'loading'
                    ? 'success-button loading-btn'
                    : 'success-button'}
                />
              </div>
            </div>
          </div>
          <div className="price-summary">
            <h2 className="summary-title">{t('priceSummary')}</h2>
            <div className="price-details">
              {priceData.map((price) => (
                <div
                  className={price.label === 'Total Price' ? 'single-detail total' : 'single-detail'}
                >
                  <span>
                    {price.label}
                  </span>
                  <span
                    className={price.label === 'Wallet' && 'wallet-price'}
                  >
                    {price.amount}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentInfo;
