/* eslint-disable no-nested-ternary */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import TopContent from '../../../Shared/Components/TopContent';
import { Input, InputInnerSymbol } from '../../../Shared/Components/Form/Input';
import { currencySymbol } from '../../../utils/helpers';

function AddonsView() {
  const { t } = useTranslation();
  const params = useParams();
  const { addon } = useSelector((state) => state.addon);
  const value = addon.find((each) => each?.id === params?.id);
  const pricePerAddOn = value?.price_per_storage || [];

  return (
    <div className="main-container">
      <TopContent
        label={[t('addon'), t('view')]}
        buttonClass="success-button"
        button={false}
      />
      <div className="content-area">
        <div className="addOn-form">
          <div className="single-row">
            <Input
              label={t('product')}
              name="product"
              value={value?.product}
              readonly
              id="select-product"
              placeholder={t('selectProduct')}
            />
            <Input
              label={t('planType')}
              name="plan_type"
              value={value?.plan_type}
              readonly
              id="select-term"
              placeholder={t('selectPlanName')}
            />
          </div>
          <div className="pricing">
            <h2>{t('storage')}<span>{t('perGB')}</span></h2>
            <table className="add-ons">
              <tr>
                <th>{t('addonDuration')}</th>
                {
                  pricePerAddOn?.[0]?.price_in_months?.map((val) => (
                    <td
                      className={val?.month === 12 && 'last-data'}
                    >
                      {val.month} {val.month !== 1 ? t('months') : t('month')}
                    </td>
                  ))
                }
              </tr>
              {
                pricePerAddOn.map((item, index) => (
                  <tr>
                    <th
                      className={index === pricePerAddOn.length - 1 && 'bottom-data'}
                    >
                      <span>{currencySymbol(item.currency)}&nbsp;</span>
                      {item.currency}
                    </th>
                    {item?.price_in_months?.map((month) => (
                      <td
                        label="table-data"
                        className={
                          month?.month === 12
                            ? 'last-data'
                            : index === pricePerAddOn.length - 1
                              ? 'bottom-data'
                              : index === pricePerAddOn.length - 1 && month?.month === 12
                                ? 'last-data bottom-data'
                                : ''
                        }
                      >
                        <div className="input-container">
                          <InputInnerSymbol
                            type="number"
                            name={item?.currency}
                            index={month.month}
                            value={month.price}
                            readonly
                            placeholder={0}
                            symbol={currencySymbol(item.currency)}
                          />
                        </div>
                      </td>
                    ))}
                  </tr>
                ))
              }
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddonsView;
