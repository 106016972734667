/* eslint-disable no-param-reassign */
/* eslint no-underscore-dangle: 0 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import deviceOrderApi from '../../utils/api/device-order';
import { setErrorNotification } from './NotificationSlice';
import { changeDateFormat } from '../../pages/Global/timeFormat';

export const fetchActiveDeviceOrder = createAsyncThunk(
  'subscription/fetchActiveDeviceOrder',
  async (data, { dispatch }) => {
    const response = await deviceOrderApi.getDeviceOrderRentalBreak({ ...data, status: 'active' })
      .then((res) => {
        const tableData = res?.data?.results?.map((item) => ({
          ...item,
          id: item?._id,
          deviceSerialNumber: item?.device_serial_numbers.map((val) => val.serial_number),
          startDate: changeDateFormat(item?.start_date),
          endDate: changeDateFormat(item?.end_date),
        }));
        return { ...res?.data, results: tableData, code: 200 };
      })
      .catch((err) => dispatch(setErrorNotification(err?.response?.data)));
    return response;
  },
);

const initialState = {
  deviceOrder: [],
  status: 'success',
  pageCount: 0,
  totalPageCount: 1,
  filter: {},
  tableFilterData: {},
  totalDataCount: 0,

};

export const deviceOrderActiveSlice = createSlice({
  name: 'deviceOrderActive',
  initialState,
  reducers: {
    setTableFilterData: (state, action) => {
      state.tableFilterData = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    resetDeviceActiveState: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchActiveDeviceOrder.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchActiveDeviceOrder.fulfilled, (state, action) => {
        state.status = 'success';
        if (action?.payload?.code === 200) {
          const data = action?.payload?.results;
          state.pageCount = action.payload.page;
          state.totalPageCount = action?.payload?.totalPages === 0
            ? 1
            : action?.payload?.totalPages;
          state.totalDataCount = action?.payload?.totalResults;
          if (action?.payload?.page === 1) {
            state.deviceOrder = data;
          } else {
            state.deviceOrder = [...state.deviceOrder, ...data];
          }
        }
      });
  },
});

export const {
  setTableFilterData,
  setFilter,
  resetDeviceActiveState,
} = deviceOrderActiveSlice.actions;

export default deviceOrderActiveSlice.reducer;
