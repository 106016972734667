/* eslint no-underscore-dangle: 0 */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Country, State, City } from 'country-state-city';
import { updateCompanyDetails } from '../../../redux/slices/CompanySlice';
import { updateServerDetails } from '../../../redux/slices/ServerSlice';

import profile from '../../../assets/images/download.jpg';
import TopContent from '../../../Shared/Components/TopContent';
import Button from '../../../Shared/Components/Button';
import { Input } from '../../../Shared/Components/Form/Input';
import SelectBox from '../../../Shared/Components/Form/SelectBox';
import ModalBox from '../../../Shared/Components/ModalBox';
import Success from '../../../Shared/Components/Success';

import company from '../../../utils/api/company';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import {
  descriptionValidation,
  emailValidation,
  integerValidation,
  nameValidation,
  phoneNumberValidation,
  pincodeValidation,
  textNumberValidation,
} from '../../../Shared/Components/Form/Validation';
import { updateDistributorDetails } from '../../../redux/slices/DistributorSlice';
import { acceptedImageTypes, fileUploadValidation } from '../../../utils/helpers';
import AutocompleteBox from '../../../Shared/Components/Form/AutoComplete';

function CompanyAdd() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [countries, setCountries] = useState([]);
  const [disable, setDisable] = useState(true);
  const [countryCode, setCountryCode] = useState();
  const [states, setStates] = useState([]);
  const [city, setCity] = useState([]);
  const params = useParams();
  const [singleCompanyDetail, setSingleCompanyDetail] = useState({});
  const [modalView, setModalView] = useState({});
  const [image, setImage] = useState();
  const [buttonStatus, setButtonStatus] = useState('');
  const [value, setValue] = useState({
    country: '',
    state: '',
    city: '',
  });
  const [route, setRoute] = useState({
    route: '',
    text: '',
  });
  const [userData, setUserData] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (name, data) => {
    if (data) {
      if (name === 'country') {
        setValue({
          ...value,
          country: data.label,
          state: '',
          city: '',
        });
        const state1 = State.getStatesOfCountry(data.id).map((val) => ({ id: val.isoCode, label: val.name }));
        setStates(state1);
        setCountryCode(data.id);
      } else if (name === 'state') {
        const city1 = City.getCitiesOfState(countryCode, data.id)
          .map((val) => ({ id: val.isoCode, label: val.name }));
        setCity(city1);
        setValue({
          ...value,
          state: data.label,
          city: '',
        });
      } else if (name === 'city') {
        setValue({ ...value, city: data.label });
      }
    }
    if (name !== 'country' && name !== 'state' && name !== 'city') {
      setValue({ ...value, [name]: data });
    }
  };

  const handleChangeUser = (name, data) => {
    setUserData({ ...userData, [name]: data });
  };

  useEffect(() => {
    setDisable(false);
  }, [value]);

  const onChangePicture = (e) => {
    const maxFileSizeInMB = 1;
    const checkFileValidation = fileUploadValidation(e.target.files[0], maxFileSizeInMB, 'image');
    if (checkFileValidation === '') {
      setValue({ ...value, image: e.target.files[0] });
      setImage(URL.createObjectURL(e.target.files[0]));
    } else {
      dispatch(setErrorNotification({ message: checkFileValidation }));
    }
  };

  const addCompany = () => {
    navigate('../');
  };

  const submit = () => {
    setIsSubmitted(true);
    if (
      nameValidation(1, 100, value?.name)
      && textNumberValidation(0, 50, value?.tax_id)
      && emailValidation(value?.email)
      && descriptionValidation(255, value?.address)
      && pincodeValidation(value?.pincode)
      && phoneNumberValidation(userData?.phone_number)
      && nameValidation(1, 50, userData?.name)
      && nameValidation(1, 50, userData?.user_name)
      && emailValidation(userData?.email)
      && (value?.account_type === 'cms'
        || value?.account_type === 'cmsServer'
        || integerValidation(0, 100, value?.margin_percentage))
      && value.city
      && value.state
      && value.country
    ) {
      setButtonStatus('loading');
      const body = {
        company_name: value?.name,
        company_email: value?.email,
        tax_id: value?.tax_id ? value?.tax_id : null,
        address: value?.address,
        country: value?.country,
        state: value?.state,
        city: value?.city,
        pincode: value?.pincode,
        margin_percentage: value?.margin_percentage,
        logo: image === singleCompanyDetail?.signedUrl ? undefined : value?.image,
        // User Details
        admin_user_id: userData?._id,
        user_full_name: userData?.name,
        phone_number: userData?.phone_number,
        user_email: userData?.email,
        user_name: userData?.user_name,
      };
      let message = '';
      let redirectPath = '';
      // Set message and redirect path based on account type
      switch (value.account_type) {
        case 'cms':
          message = t('companyUpdatedSuccess');
          redirectPath = '../direct';
          break;
        case 'cmsServer':
          message = t('serverUpdateSuccess');
          redirectPath = '../cmsServer/direct';
          break;
        case 'distributor':
          message = t('distributorCreatedSuccess');
          redirectPath = '../distributor';
          break;
        default:
          redirectPath = '../';
      }
      company.companyUpdate(value?._id, body).then((response) => {
        setButtonStatus('success');
        setModalView({ content: <Success message={message} /> });
        setTimeout(() => {
          setButtonStatus('');
          // Update store based on account type
          switch (value.account_type) {
            case 'cms':
              dispatch(updateCompanyDetails(response.data));
              break;
            case 'cmsServer':
              dispatch(updateServerDetails(response.data));
              break;
            case 'distributor':
              dispatch(updateDistributorDetails(response.data));
              break;
            default:
              break;
          }
          // Navigate to the correct path based on account type
          navigate(redirectPath);
        }, 3000);
      }).catch((err) => {
        setButtonStatus('');
        dispatch(setErrorNotification(err?.response?.data));
      });
    }
  };

  useEffect(() => {
    const country = Country.getAllCountries().map((val) => ({ id: val.isoCode, label: val.name }));
    setCountries(country);
    company.getSingleCompany(params?.id)
      .then((res) => {
        console.log(res);
        setSingleCompanyDetail(res?.data);
      })
      .catch((err) => {
        dispatch(setErrorNotification(err?.response?.data));
      });
  }, [params]);

  useEffect(() => {
    if (singleCompanyDetail) {
      const country = Country.getAllCountries().find((val) => val.name === singleCompanyDetail?.country);
      setCountryCode(country?.isoCode);
      const state1 = State.getStatesOfCountry(country?.isoCode).map((val) => ({ id: val.isoCode, label: val.name }));
      setStates(state1);
      const city1 = City.getCitiesOfState(country?.isoCode, state1
        .find((val) => val.label === singleCompanyDetail?.state)?.id)
        .map((val) => ({ id: val.isoCode, label: val.name }));
      setCity(city1);
      setValue({ ...singleCompanyDetail, admin_user_id: undefined });
      setUserData(singleCompanyDetail?.admin_user_id);
      setImage(singleCompanyDetail?.signedUrl);
    }
    console.log(singleCompanyDetail.account_type);
    if (singleCompanyDetail.account_type === 'cms') {
      setRoute({ route: '../direct', text: t('cmsCloud') });
    } else if (singleCompanyDetail.account_type === 'cmsServer') {
      console.log(singleCompanyDetail.account_type, 558966);
      setRoute({ route: '../cmsServer/direct', text: t('cmsServer') });
    } else if (singleCompanyDetail.account_type === 'distributor') {
      setRoute({ route: '../distributor', text: t('distributor') });
    }
  }, [singleCompanyDetail]);
  const getAccountLabel = () => {
    if (value.account_type === 'cms') {
      return t('cmsAdmin');
    } if (value.account_type === 'cmsServer') {
      return t('cmsServer');
    } return t('distributorAdmin');
  };
  return (
    <div className="main-container">
      <ModalBox
        status={buttonStatus === 'success'}
        setOpen={setButtonStatus}
        closeModal={() => setButtonStatus('')}
        modalView={modalView}
        notification
      />
      <TopContent
        label={[route?.text, singleCompanyDetail?.name]}
        click={addCompany}
        labelClickFunc={() => navigate(route?.route)}
        customLabelClickFunc
      />
      <div className="content-area">
        <div className="my-content">
          <div className="my-content-area-form-1">
            <div className="company-form">
              <div className="profile-upload">
                <label htmlFor="file" className="upload-click">
                  <img src={image || profile} alt="profile" />
                  {!value.signedUrl ? <p>{t('imageFormat')}</p> : <p><span>{t('changeProfile')}</span></p>}
                </label>
                <input
                  type="file"
                  id="file"
                  onChange={onChangePicture}
                  style={{ display: 'none' }}
                  accept={acceptedImageTypes}
                />
              </div>
              <div className="details-upload">
                <div className="company-info">
                  <h2>{t('companyInformation')}</h2>
                  <div className="form-field">
                    <Input
                      type="text"
                      label={t('companyName')}
                      required
                      name="name"
                      value={value?.name}
                      change={handleChange}
                      placeholder={t('enterCompanyName')}
                      error={!nameValidation(1, 100, value?.name) && isSubmitted}
                      errorMessage={t('companyNameValidation')}
                    />
                    <Input
                      type="text"
                      label={t('taxId')}
                      name="tax_id"
                      value={value?.tax_id}
                      change={handleChange}
                      placeholder={t('enterTaxId')}
                      error={!textNumberValidation(0, 50, value?.tax_id)}
                      errorMessage={t('taxIdValidation')}
                    />
                  </div>
                  <div className="form-field">
                    <Input
                      type="tel"
                      label={t('phoneNumber')}
                      required
                      name="phone_number"
                      value={userData?.phone_number}
                      change={handleChangeUser}
                      placeholder={t('enterPhoneNumber')}
                      error={!phoneNumberValidation(userData?.phone_number) && isSubmitted}
                      errorMessage={t('invalidPhoneNumber')}
                    />
                    <Input
                      type="email"
                      label={t('mailId')}
                      required
                      name="email"
                      value={value?.email}
                      change={handleChange}
                      placeholder={t('enterCompanyMailId')}
                      error={!emailValidation(value?.email) && isSubmitted}
                      errorMessage={t('invalidEmail')}
                    />
                  </div>
                  <Input
                    type="text"
                    label={t('address')}
                    required
                    name="address"
                    value={value?.address}
                    change={handleChange}
                    placeholder={t('enterCompanyAddress')}
                    error={!descriptionValidation(255, value?.address) && isSubmitted}
                    errorMessage={t('addressValidation')}
                  />
                  <div className="form-field">
                    <SelectBox
                      type="text"
                      label={t('country')}
                      required
                      name="country"
                      value={value?.country}
                      onchange={handleChange}
                      lists={countries}
                      placeholder={t('selectCountry')}
                      id="country"
                    />
                    <AutocompleteBox
                      type="text"
                      label={t('state')}
                      required
                      name="state"
                      value={value?.state}
                      onchange={handleChange}
                      lists={states}
                      placeholder={t('selectState')}
                      id="state"
                      allowNewOption
                    />
                  </div>
                  <div className="form-field">
                    <AutocompleteBox
                      type="text"
                      label={t('city')}
                      required
                      name="city"
                      value={value?.city}
                      onchange={handleChange}
                      lists={city}
                      placeholder={t('selectCity')}
                      id="city"
                      allowNewOption
                    />
                    <Input
                      type="text"
                      label={t('pinCode')}
                      required
                      name="pincode"
                      value={value?.pincode}
                      change={handleChange}
                      placeholder={t('enterPinCode')}
                      error={!pincodeValidation(value?.pincode)}
                      errorMessage={t('invalidPincode')}
                    />
                  </div>
                </div>
                <div className="user-info">
                  <h2>{t('userInformation')}</h2>
                  <div className="form-field">
                    <Input
                      type="text"
                      label={t('fullName')}
                      required
                      name="name"
                      value={userData?.name}
                      change={handleChangeUser}
                      placeholder={t('enterFullName')}
                      error={!nameValidation(1, 50, userData?.name) && isSubmitted}
                      errorMessage={t('companyUserFullNameValidation')}
                    />
                    <Input
                      type="text"
                      label={t('username')}
                      required
                      name="user_name"
                      value={userData?.user_name}
                      change={handleChangeUser}
                      placeholder={t('enterUsername')}
                      error={!nameValidation(1, 50, userData?.user_name) && isSubmitted}
                      errorMessage={t('userNameValidation')}
                    />
                  </div>
                  <div className="form-field">
                    <Input
                      type="text"
                      label={t('role')}
                      // required
                      name="role"
                      value={getAccountLabel()}
                      readonly
                    />
                    <Input
                      type="text"
                      label={t('mailId')}
                      required
                      name="email"
                      value={userData?.email}
                      change={handleChangeUser}
                      placeholder={t('enterUserMailId')}
                      error={!emailValidation(userData?.email)}
                      errorMessage={t('invalidEmail') && isSubmitted}
                    />
                    {/* <Input
                        type="password"
                        label="Password"
                        required
                        name="password"
                        value={value.password}
                        change={handleChange}
                        placeholder="Enter Password"
                      /> */}
                  </div>
                </div>
                {
                  value.account_type === 'distributor' && (
                    <div className="user-info">
                      <h2>{t('revenueInformation')}</h2>
                      <div className="form-field">
                        <Input
                          type="text"
                          label={t('distributorMargin%')}
                          required
                          name="margin_percentage"
                          value={value.margin_percentage}
                          change={handleChange}
                          placeholder={t('enterRevenue%')}
                          error={!integerValidation(0, 100, value?.margin_percentage)}
                          errorMessage={t('inValidPercentage')}
                        />
                      </div>
                    </div>
                  )
                }
                <div className="form-button-group">
                  <div className="form-button">
                    <Button label={t('back')} click={addCompany} classes="default-button" />
                  </div>
                  <div className="form-button">
                    <Button
                      label={buttonStatus === 'loading' ? t('updating') : t('update')}
                      click={submit}
                      classes={buttonStatus === 'loading' ? 'success-button loading-btn' : 'success-button'}
                      disabled={disable}
                      loading={buttonStatus === 'loading'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompanyAdd;
