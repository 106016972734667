import axios, { defaultParams } from './axios';

export default async (url, body) => {
  try {
    return await axios.delete(url, { ...defaultParams() }, body);
  } catch (e) {
    console.log(e);
    throw e;
  }
};
