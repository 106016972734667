import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import TopContent from '../../../Shared/Components/TopContent';
import OrderSummary from './OrderSummary';
import Button from '../../../Shared/Components/Button';
import { currencySymbol } from '../../../utils/helpers';
import { setSelectedOrders } from '../../../redux/slices/TransactionPendingSlice';
import deviceOrder from '../../../utils/api/device-order';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';

function PaymentSummary() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedOrders, updatePaymentType } = useSelector((state) => state.transactionPending);
  const currency = currencySymbol(selectedOrders?.[0]?.company?.currency || 'USD');

  const [summary, setSummary] = useState([]);
  const [priceToShow, setPriceToShow] = useState({
    actualPrice: 0,
    wallet: 0,
    totalPrice: 0,
  });

  const priceData = [
    {
      label: t('price'),
      amount: `${currency} ${priceToShow.actualPrice.toFixed(2)}`,
    },
    {
      label: t('wallet'),
      amount: `- ${currency} ${priceToShow.wallet.toFixed(2)}`,
    },
    {
      label: t('totalPrice'),
      amount: `${currency} ${priceToShow.totalPrice.toFixed(2)}`,
    },
  ];

  const navigateBack = () => {
    dispatch(setSelectedOrders({ selectedOrders: [] }));
    navigate(-1);
  };

  const submit = () => {
    navigate('../info');
  };

  const formatDeviceDataToDisplay = (data) => {
    const returnValue = data?.map((order) => ({
      ...order,
      device_name: order?.device_catalog?.name,
      tenure: `${order?.tenure} months`,
      unit_price: `${currency} ${order.price / order.quantity}`,
      price_to_show: `${currency} ${order?.price}`,
    }));
    return returnValue;
  };

  useEffect(() => {
    let actualPrice = 0;
    let totalPrice = 0;
    selectedOrders.forEach((order) => {
      actualPrice = actualPrice + order.actual_price || 0;
      totalPrice = totalPrice + order.total_price || 0;
    });
    setPriceToShow({
      actualPrice,
      wallet: actualPrice - totalPrice,
      totalPrice,
    });
    setSummary(selectedOrders);
  }, [selectedOrders]);

  useEffect(() => {
    if (selectedOrders.length === 0) {
      navigate(-1);
    }
    if (updatePaymentType === 'device-rental') {
      deviceOrder.getSingleDeviceOrder(selectedOrders?.[0]?.order_id)
        .then((res) => {
          setSummary(formatDeviceDataToDisplay(res?.data));
        })
        .catch((err) => {
          dispatch(setErrorNotification(err?.response?.data));
        });
    } else if (updatePaymentType === 'saas-device-rental') {
      setSummary([
        ...selectedOrders,
        ...formatDeviceDataToDisplay(selectedOrders?.[0]?.device_rentals),
      ]);
    }
  }, []);

  return (
    <div className="main-container">
      <TopContent
        label={[
          t('transactions'),
          selectedOrders?.[0]?.company?.name || selectedOrders?.[0]?.companyName,
        ]}
        labelClickFunc={() => navigate(-1)}
        customLabelClickFunc
      />
      <div className="content-area">
        <div className="product-summary-wrap">
          <div className="product-summary">
            <h2 className="summary-title">{t('productSummary')}</h2>
            <OrderSummary selectedOrders={summary} />
            <div className="form-button-group">
              <div className="form-button">
                <Button
                  label={t('back')}
                  click={navigateBack}
                  classes="default-button"
                />
              </div>
              <div className="form-button">
                <Button
                  label={t('continue')}
                  click={submit}
                  classes="success-button"
                />
              </div>
            </div>
          </div>
          <div className="price-summary">
            <h2 className="summary-title">{t('priceSummary')}</h2>
            <div className="price-details">
              {priceData.map((price) => (
                <div
                  className={price.label === 'Total Price' ? 'single-detail total' : 'single-detail'}
                >
                  <span>
                    {price.label}
                  </span>
                  <span
                    className={price.label === 'Wallet' && 'wallet-price'}
                  >
                    {price.amount}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentSummary;
