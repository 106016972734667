import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import TopContent from '../../Shared/Components/TopContent';
import DirectCompanyTable from './Components/DirectCompanyTable';
import IndirectCompanyTable from './Components/IndirectCompanyTable';
// import ServerTable from '../CmsServer/Component/ServerTable';
import { checkPermissionAllow } from '../../utils/helpers';
import DistributorTable from './Components/DistributorTable';

function Company() {
  const menus = [
    {
      key: 'direct',
      enable: checkPermissionAllow('getDirectCompany'),
    },
    {
      key: 'indirect',
      enable: checkPermissionAllow('getIndirectCompany'),
    },
  ];
  const { t } = useTranslation();
  const { page } = useParams();
  // console.log(page);
  const navigate = useNavigate();

  return (
    <div className="main-container">
      <TopContent
        label={t('cmsCloud')}
        buttonClass="success-button"
        button={checkPermissionAllow('addCmsCompany') || checkPermissionAllow('addDistributorCompany')}
        buttonLabel={t('newAccount')}
        click={() => navigate('../company-add/cms')}
      />
      <div className="content-area">
        <div className="my-content">
          <div className="my-content-area">
            <div className="subscription-menu">
              {
                menus?.map((menu) => menu.enable && (
                  <div
                    role="presentation"
                    className={page === menu.key ? 'each-menu menu-active' : 'each-menu'}
                    onClick={() => navigate(`../${menu.key}`)}
                  >
                    {t(menu.key)}
                  </div>
                ))
              }
            </div>
            {
              page === 'direct' && checkPermissionAllow('getDirectCompany') && <DirectCompanyTable />
            }
            {
              page === 'indirect' && checkPermissionAllow('getIndirectCompany') && <IndirectCompanyTable />
            }
            {
              page === 'distributor' && checkPermissionAllow('getDistributors') && <DistributorTable />
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default Company;
