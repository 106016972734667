/* eslint-disable no-param-reassign */
/* eslint no-underscore-dangle: 0 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import deviceCatalogue from '../../utils/api/device-catalogue';
import { setErrorNotification } from './NotificationSlice';

export const fetchDeviceCatalogue = createAsyncThunk(
  'deviceCatalogue/fetchDeviceCatalogue',
  async (data, { dispatch }) => {
    const response = await deviceCatalogue.getDeviceCatalogues(data)
      .then((res) => {
        const tableData = res?.data?.results?.map((item) => ({
          ...item,
          id: item._id,
          screen_size_to_show: `${item.screen_size}''`,
          uploadedImages: item?.device_images?.length,
          removedImage: [],
          newImages: [],
        }));
        return { ...res?.data, results: tableData, code: 200 };
      })
      .catch((error) => {
        dispatch(setErrorNotification(error?.response?.data));
      });
    return response;
  },
);

export const fetchDeviceCatalogueListAll = createAsyncThunk(
  'company/fetchDeviceCatalogueListAll',
  async (data, { dispatch }) => {
    const response = await deviceCatalogue.getDeviceCatalogueAll()
      .then((response1) => response1?.data?.map((val) => ({
        ...val,
        id: val._id,
        label: val.name,
      })))
      .catch((error) => {
        dispatch(setErrorNotification(error?.response?.data));
      });
    return response;
  },
);

const initialState = {
  deviceCatalogue: [],
  deviceCatalogueAllList: [],
  pageCount: 0,
  totalPageCount: 1,
  status: 'success',
  filter: {},
  tableFilterData: {},
  totalDataCount: 0,
};

export const deviceCatalogSlice = createSlice({
  name: 'deviceCatalogue',
  initialState,
  reducers: {
    setTableFilterData: (state, action) => {
      state.tableFilterData = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    addDeviceCatalogueDetails: (state, action) => {
      state.deviceCatalogue.unshift({
        ...action.payload,
        id: action?.payload?._id,
        screen_size_to_show: `${action?.payload.screen_size}''`,
        uploadedImages: action?.payload?.device_images?.length,
        removedImage: [],
        newImages: [],
      });
    },
    updateDeviceCatalogueDetails: (state, action) => {
      state.deviceCatalogue = state.deviceCatalogue.map((deviceCatalogueDetails) => {
        let output = deviceCatalogueDetails;
        if (deviceCatalogueDetails.id === action?.payload?._id) {
          output = {
            ...action.payload,
            id: action?.payload?._id,
            screen_size_to_show: `${action?.payload.screen_size}''`,
            uploadedImages: action?.payload?.device_images?.length,
            removedImage: [],
            newImages: [],
          };
        }
        return output;
      });
    },
    deleteDeviceCatalogueDetails: (state, action) => {
      state.deviceCatalogue = state.deviceCatalogue.filter(
        (deviceCatalogueDetails) => deviceCatalogueDetails.id !== action?.payload,
      );
    },
    resetDeviceCatalog: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDeviceCatalogue.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDeviceCatalogue.fulfilled, (state, action) => {
        state.status = 'success';
        if (action?.payload?.code === 200) {
          const data = action?.payload?.results ?? [];
          state.pageCount = action?.payload?.page;
          state.totalPageCount = action?.payload?.totalPageCount === 0 ? 1 : action?.payload?.totalPageCount;
          state.totalDataCount = action?.payload?.totalPageCount;
          if (action?.payload?.page === 1) {
            state.deviceCatalogue = data;
          } else {
            state.deviceCatalogue = state.deviceCatalogue.push(...data);
          }
        }
      });
    builder.addCase(fetchDeviceCatalogueListAll.fulfilled, (state, action) => {
      // Add user to the state array
      state.deviceCatalogueAllList = action.payload;
    });
  },
});

export const {
  setTableFilterData,
  setFilter,
  resetDeviceCatalog,
  addDeviceCatalogueDetails,
  updateDeviceCatalogueDetails,
  deleteDeviceCatalogueDetails,
} = deviceCatalogSlice.actions;

export default deviceCatalogSlice.reducer;
