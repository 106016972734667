/* eslint-disable no-param-reassign */
/* eslint no-underscore-dangle: 0 */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { setErrorNotification } from './NotificationSlice';
import licensesApi from '../../utils/api/licenses';
// import { currencySymbol } from '../../utils/helpers';

export const fetchLicensesPending = createAsyncThunk(
  'licensesPending/fetchLicensesPending',
  async (data, { dispatch }) => {
    const response = await licensesApi.getLicenses(data)
      .then((res) => ({
        ...res.data,
        results: res.data.results.map((val) => ({
          ...val,
          company_name: val.company.name,
          plan_name: val.license_plan.name,
          pending_payment: val.pending_payment.toFixed(2),
          id: val._id,
        })),
        code: 200,
      }))
      .catch((error) => dispatch(setErrorNotification(error?.response?.data)));
    return response;
  },
);

const initialState = {
  licensesPending: [],
  pageCount: 0,
  totalPageCount: 1,
  totalDataCount: 0,
  filter: {},
  selectedOrders: {},
  tableFilterData: {},
  status: '',
};

export const licensesPendingSlice = createSlice({
  name: 'licensesPending',
  initialState,
  reducers: {
    setTableFilterData: (state, action) => {
      state.tableFilterData = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    setSelectedOrders: (state, action) => {
      state.selectedOrders = action.payload;
    },
    removeLicensesPendingById: (state, action) => {
      const pendingList = state.licensesPending;

      // Find the index to delete by ID
      const index = pendingList.findIndex((pending) => pending.id === action.payload);
      if (index !== -1) {
        pendingList.splice(index, 1);
      }
    },
    resetLicensesPendingState: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchLicensesPending.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchLicensesPending.fulfilled, (state, action) => {
        if (action?.payload?.code === 200) {
          state.pageCount = action.payload.page + 1;
          state.totalPageCount = action.payload.totalPages > 0 ? action.payload.totalPages : 1;
          const fetchedData = action.payload.results;
          if (action.payload.page === 1) {
            state.licensesPending = fetchedData;
          } else {
            state.licensesPending.push(...fetchedData);
          }
        }
        state.status = 'succeed';
      });
  },
});

export const {
  setFilter,
  setTableFilterData,
  setSelectedOrders,
  resetLicensesPendingState,
  removeLicensesPendingById,
} = licensesPendingSlice.actions;

export default licensesPendingSlice.reducer;
