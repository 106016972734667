import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import more from '../../assets/icons/more.png';
import TopContent from '../../Shared/Components/TopContent';
import DataTable from '../Table';
import ModalBox from '../../Shared/Components/ModalBox';
import PlayerVersionAdd from './component/PlayerVersionAdd';
import {
  fetchPlayerAppVersionList,
  setFilter,
  setTableFilterData,
} from '../../redux/slices/PlayerAppSlice';
import DropDown from '../../Shared/Components/DropDown';
import PlayerVersionUpdate from './component/PlayerVersionUpdate';
import PlayerVersionView from './component/PlayerVersionView';
import { checkPermissionAllow } from '../../utils/helpers';
import height from '../../utils/size-variables';

function PlayerUpload() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    playerVersions,
    pageCount,
    status,
    totalDataCount,
    totalPageCount,
    filter,
    tableFilterData,
  } = useSelector((state) => state.playerAppVersion);

  const [popup, setPopup] = useState('close');
  const [modalView, setModalView] = useState();

  // Mui table filter and sorting variables
  const [rowSelection, setRowSelection] = useState({});
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [columnVisibility, setColumnVisibility] = useState({});

  const getPlayerAppVersions = (count, search) => {
    dispatch(fetchPlayerAppVersionList({ page: count, ...search }));
  };

  const viewFunction = (playerAppData) => {
    setPopup('open');
    setModalView({
      title: t('updatePlayerVersion'),
      content: <PlayerVersionView
        closeModal={setPopup}
        playerAppData={playerAppData}
      />,
    });
  };

  const updateFunction = (playerAppData) => {
    setPopup('open');
    setModalView({
      title: t('updatePlayerVersion'),
      content: <PlayerVersionUpdate
        closeModal={setPopup}
        playerAppData={playerAppData}
      />,
    });
  };

  const actions = [
    {
      name: t('view'),
      url: '',
      function: viewFunction,
      key: 'view',
      sendFullDetails: true,
      enable: checkPermissionAllow('getPlayAppManager'),
    },
    {
      name: t('update'),
      url: '',
      function: updateFunction,
      key: 'view',
      sendFullDetails: true,
      enable: checkPermissionAllow('editPlayAppManager'),
    },
  ];

  const columns = [
    {
      header: t('sNo'),
      size: 60,
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      Cell: ({ row }) => row.index + 1,
    },
    {
      header: t('deviceOs'),
      accessorKey: 'os',
      Cell: ({ row }) => {
        // Get the device OS and capitalize the first letter
        const deviceOs = row.original.os;
        const capitalizedDeviceOs = deviceOs
          ? deviceOs.charAt(0).toUpperCase() + deviceOs.slice(1)
          : '';
        return <span>{capitalizedDeviceOs}</span>;
      },
    },
    {
      header: t('playerVersion'),
      accessorKey: 'version',

    },
    {
      header: t('releaseNote'),
      accessorKey: 'releaseNotes',
      Cell: ({ row }) => {
        let releaseNotes;
        if (row.original && row.original.releaseNotesToShow) {
          if (row.original.releaseNotesToShow.length > 30) {
            releaseNotes = <span>{row.original.releaseNotesToShow.substring(0, 30)}...</span>;
          } else {
            releaseNotes = <span>{row.original.releaseNotesToShow}</span>;
          }
        } else {
          releaseNotes = <span />;
        }
        return releaseNotes;
      },
    },
    {
      header: t('updatedAt'),
      accessorKey: 'updatedAt',
      enableColumnFilter: false,
    },
    {
      header: '',
      accessorKey: 'id',
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      enableHiding: true,
      filterVariant: 'none',
      size: 50,
      Cell: ({ row }) => (
        <DropDown
          action={actions}
          icon={more}
          value={row?.original}
          dropdown="tableForm"
          height="15px"
          tooltip=""
        />
      ),
    },
  ];

  const clickFunction = () => {
    setPopup('open');
    setModalView({
      title: t('newPlayerVersion'),
      content: <PlayerVersionAdd closeModal={setPopup} />,
    });
  };

  const handleScroll = () => {
    getPlayerAppVersions(pageCount + 1, filter);
  };

  const onFilterOrSortingChange = (filteredValues, reduxColumnFiltersSorting) => {
    dispatch(setTableFilterData(reduxColumnFiltersSorting));
    dispatch(setFilter(filteredValues));
    getPlayerAppVersions(1, filteredValues);
  };
  useEffect(() => {
    setIsFirstRender(false);
    if (pageCount === 0 && Object.keys(tableFilterData).length === 0) {
      getPlayerAppVersions(1, {});
    }
  }, []);

  return (
    <div className="main-container">
      <ModalBox
        status={popup === 'open'}
        closeModal={() => setPopup('close')}
        modalView={modalView}
      />
      <TopContent
        label={t('playerAppManagement')}
        button={checkPermissionAllow('addPlayAppManager')}
        buttonClass="success-button"
        buttonLabel={t('newPlayerVersion')}
        click={clickFunction}
      />
      <DataTable
        header={columns}
        value={playerVersions}
        status={status}
        totalPageCount={totalPageCount}
        pageCount={pageCount}
        totalDataCount={totalDataCount}
        reduxColumnFiltersSorting={tableFilterData}
        onFilterOrSortingChange={onFilterOrSortingChange}
        isFirstRender={isFirstRender}
        scrollData={handleScroll}
        columnVisibility={columnVisibility}
        setColumnVisibility={setColumnVisibility}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
        enable={false}
        height={height.onlyTableHeight}
      />
    </div>
  );
}

PlayerUpload.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      releaseNotes: PropTypes.string.isRequired,
      os: PropTypes.string.isRequired,
      charAt: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default PlayerUpload;
