/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import SelectBox from '../../../Shared/Components/Form/SelectBox';
import { Input } from '../../../Shared/Components/Form/Input';
import Button from '../../../Shared/Components/Button';
import ModalBox from '../../../Shared/Components/ModalBox';
import Success from '../../../Shared/Components/Success';
import licensesApi from '../../../utils/api/licenses';

import { integerValidation } from '../../../Shared/Components/Form/Validation';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import { addLicensesDetails, fetchAccountListAll } from '../../../redux/slices/LicensesSlice';

function AddonsAdd({ back }) {
  const { t } = useTranslation();
  const planNames = [
    { label: t('business'), id: 'business' },
    { label: t('pro'), id: 'pro' },
  ];
  const [value, setValue] = useState({});
  const dispatch = useDispatch();
  const companyList = useSelector((state) => state.licenses.accountAll);
  const [disabled, setDisabled] = useState(true);
  const [buttonStatus, setButtonStatus] = useState('');
  const [standardDevices, setStandardDevices] = useState([]);
  const [standardTenure, setStandardTenure] = useState([]);
  const [submitButtonClicked, setSubmitButtonClicked] = useState(false);

  const planTypes = [
    {
      label: t('standard'),
      id: 'standard',
    },
    {
      label: t('advanced'),
      id: 'advanced',
    },
    {
      label: t('custom'),
      id: 'custom',
    },
  ];

  const advancedDevices = [
    {
      label: 100,
      id: 100,
    },
    {
      label: 200,
      id: 200,
    },
    {
      label: 500,
      id: 500,
    },
    {
      label: 1000,
      id: 1000,
    },
  ];
  const calculation = () => {
    if (value.license_type
      && value.account
      && value.plan_name
      && (value.tenure || value.tenure === 0)
      && value.display
      && value.licenseKey !== 'custom'
    ) {
      const request = {
        currency: value.currency,
        license_type: value.licenseKey,
        license_plan: value.planKey,
        // duration_type: 'year',
        duration_type: value.tenure === 0 ? 'unlimited' : 'year',
        device_count: value.display,
        duration_value: value.tenure,
      };
      licensesApi.getLicensesPriceCalculating(request).then((response) => {
        setValue((prevState) => ({
          ...prevState,
          price: value.licenseKey === 'standard' ? response.data.price * value.display : response?.data?.price,
        }));
      }).catch((error) => {
        dispatch(setErrorNotification(error?.response?.data));
      });
    }
  };

  const handleChange = (name, data) => {
    if (data) {
      if (name === 'account') {
        setValue((prevState) => ({
          ...prevState,
          [name]: data.label,
          accountKey: data.id,
          currency: data.currency,
        }));
      } else if (name === 'license_type') {
        setValue((prevState) => ({
          ...prevState,
          [name]: data.label,
          licenseKey: data.id,
          display: '',
          price: '',
          tenure: '',
          tenureLabel: '',
        }));
        if (data.id === 'standard') {
          const newArray = [];
          for (let index = 1; index <= 50; index += 1) {
            newArray.push({
              label: index,
              id: index,
            });
          }
          setStandardDevices(newArray);
          setStandardTenure([
            { label: 1, id: 1 },
            { label: 3, id: 3 },
          ]);
        } else {
          setStandardDevices(advancedDevices);
          setStandardTenure([
            { label: 1, id: 1 },
            { label: 5, id: 5 },
            { label: t('unlimited'), id: 0 },
          ]);
        }
      } else if (name === 'plan_name') {
        setValue((prevState) => ({
          ...prevState,
          [name]: data.label,
          planKey: data.id,
        }));
      } else if (name === 'tenure') {
        setValue((prevState) => ({
          ...prevState,
          tenureLabel: data.label,
          [name]: data.id,
        }));
      } else if (name === 'display') {
        setValue((prevState) => ({
          ...prevState,
          [name]: data.id,
        }));
      }
    }
  };
  const inputHandel = (name, changValue) => {
    // Update the state with the input field value
    setValue((prevState) => ({
      ...prevState,
      [name]: changValue,
    }));
  };

  const submit = () => {
    setSubmitButtonClicked(true);
    const request = {
      company: value.accountKey,
      license_type: value.licenseKey,
      license_plan: value.planKey,
      device_count: value.display,
      duration_type: value.tenure === 0 ? 'unlimited' : 'year',
      tenure: value.tenure,
      license_price: value.price,
      saas_customer: false,
    };
    licensesApi.newLicenses(request).then((response) => {
      dispatch(addLicensesDetails(response?.data));
      back();
    }).catch((error) => {
      dispatch(setErrorNotification(error?.response?.data));
    });
  };

  useEffect(() => {
    dispatch(fetchAccountListAll());
  }, []);

  useEffect(() => {
    if (value.account
      && value.license_type
      && value.plan_name
      && (value.tenure || value.tenure === 0)
      && value.display
      && value.price) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [value]);

  useEffect(() => {
    calculation();
  }, [value.account, value.license_type, value.plan_name, value.tenure, value.display]);

  return (
    <div className="main-content">
      <ModalBox
        status={buttonStatus === 'success'}
        setOpen={setButtonStatus}
        closeModal={() => { }}
        modalView={{ content: <Success message={t('addonCreatedSuccess')} /> }}
        notification
      />
      <div className="content-area" style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <div className="single-row">
          <SelectBox
            label={t('account')}
            name="account"
            required
            value={value.account}
            onchange={handleChange}
            lists={companyList}
            id="select-account"
            placeholder={t('selectProduct')}
          />
        </div>
        <div className="single-row">
          <SelectBox
            label={t('licenseType')}
            name="license_type"
            required
            value={value.license_type}
            onchange={handleChange}
            lists={planTypes}
            id="select-license-type"
            placeholder={t('selectLicenseType')}
          />
        </div>
        <div className="single-row">
          <SelectBox
            label={t('planName')}
            name="plan_name"
            required
            value={value.plan_name}
            onchange={handleChange}
            lists={planNames}
            id="select-plan-name"
            placeholder={t('selectPlanName')}
          />
        </div>
        <div className="single-row">
          {
            value.licenseKey !== 'custom' ? (
              <SelectBox
                label={t('tenure')}
                name="tenure"
                required
                value={value.tenureLabel}
                onchange={handleChange}
                lists={standardTenure}
                id="select-plan-name"
                placeholder={t('selectTenure')}
              />
            ) : (
              <Input
                icon={' '}
                label={t('tenure')}
                type="number"
                name="tenure"
                required
                value={value.tenure}
                change={inputHandel}
                placeholder={t('Enter Tenure')}
                error={!integerValidation(1, null, value?.display) && submitButtonClicked}
                errorMessage={t('tenureValidation')}
              />
            )
          }
        </div>
        <div className="single-row" style={{ display: 'flex', gap: '20px' }}>
          {
            value.licenseKey !== 'custom' ? (
              <SelectBox
                label={t('noOfDisplay')}
                name="display"
                required
                value={value.display}
                onchange={handleChange}
                lists={standardDevices}
                id="select-plan-name"
                placeholder={t('selectDisplay')}
              />
            ) : (
              <Input
                icon={' '}
                label={t('noOfDisplay')}
                type="number"
                name="display"
                required
                value={value.display}
                change={inputHandel}
                placeholder={t('enterNoOfDisplay')}
                error={!integerValidation(1, null, value?.display) && submitButtonClicked}
                errorMessage={t('displayValidation')}
              />
            )
          }
          <Input
            icon={' '}
            label={t('price')}
            type="number"
            name="price"
            required
            value={value.price}
            readonly={value.licenseKey !== 'custom'}
            change={inputHandel}
            placeholder={t('enterPrice')}
            error={!integerValidation(1, null, value?.price) && submitButtonClicked}
            errorMessage={t('priceValidation')}
          />
        </div>
        <div className="form-button-group">
          <div className="form-button">
            <Button label={t('back')} click={back} classes="default-button" />
          </div>
          <div className="form-button">
            <Button
              label={t('createLicense')}
              click={submit}
              classes="success-button"
              disabled={disabled}
              loading={buttonStatus === 'loading'}
            />
          </div>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
}
AddonsAdd.propTypes = {
  back: PropTypes.func.isRequired,
};

export default AddonsAdd;
