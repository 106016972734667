import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import ModalBox from '../../../Shared/Components/ModalBox';
import SubscriptionView from './SubscriptionView';
import {
  fetchActiveSubscription,
  setTableFilterData,
  setFilter,
} from '../../../redux/slices/ActiveSubscriptionSlice';
import DeleteModal from '../../../Shared/Components/DeleteModal';
import subscription from '../../../utils/api/subscription';
import { changeDateFormat } from '../../Global/timeFormat';
import ExtendSubscription from './ExtendSubscription';
import ConfirmModal from '../../../Shared/Components/ConfirmModal';
import DropDown from '../../../Shared/Components/DropDown';
import Success from '../../../Shared/Components/Success';
import more from '../../../assets/icons/more.png';
import DataTable from '../../Table';
import exportCSV from '../../Global/exportCSV';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import PlanSwitch from './PlanSwitch';
import AddOnCountEdit from './AddOnCountEdit';
import DeviceCountEdit from './DeviceCountEdit';
import { checkPermissionAllow } from '../../../utils/helpers';
import height from '../../../utils/size-variables';

function ActiveTable() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Status of the popup model
  const [open, setOpen] = useState(false);
  const [confirm, setConfirm] = useState(false);

  // Content of popup model
  const [modalView, setModalView] = useState({});

  // Delete Popup model
  const [deleteModal, setDeleteModal] = useState(false);

  // To show close icon popup model
  const [notificationPopup, setNotificationPopup] = useState(false);

  // ID of the selected subscription
  const [subscriptionId, setSubscriptionId] = useState('');
  const [isFirstRender, setIsFirstRender] = useState(true);

  const {
    pageCountActive,
    totalPageCountActive,
    activeSubscription,
    status,
    filterActive,
    tableFilterDataActive,
    totalDataCountActive,
  } = useSelector((state) => state.activeSubscription);
  const [rowSelection, setRowSelection] = useState({});
  const [columnVisibility, setColumnVisibility] = useState({});

  // Subscription view popup
  const viewFunction = (singleSubscription) => {
    setOpen(true);
    setNotificationPopup(false);
    setModalView({
      title: t('subscriptionView'),
      content: <SubscriptionView
        data={singleSubscription}
      />,
    });
  };

  const getSubscriptions = (counts, search) => {
    dispatch(fetchActiveSubscription({ page: counts, ...search }));
  };

  // Function to open plan switch pop-up
  const planSwitchPopup = (singleSubscription) => {
    setNotificationPopup(false);
    setModalView({
      title: t('planUpdate'),
      content: <PlanSwitch
        singleSubscription={singleSubscription}
        setOpen={setOpen}
      />,
    });
    setOpen(true);
  };

  // Function to open add-on pop-up
  const addOnPopup = (singleSubscription) => {
    setNotificationPopup(false);
    setModalView({
      title: t('addonStorage'),
      content: <AddOnCountEdit
        singleSubscription={singleSubscription}
        setOpen={setOpen}
      />,
    });
    setOpen(true);
  };

  // Function to open add-on pop-up
  const deviceCountEditPopup = (singleSubscription) => {
    setNotificationPopup(false);
    setModalView({
      title: t('editDevice'),
      content: <DeviceCountEdit
        singleSubscription={singleSubscription}
        setOpen={setOpen}
      />,
    });
    setOpen(true);
  };

  // Function to run when subscription extended successfully
  const extendSubmit = () => {
    setNotificationPopup(true);
    setModalView({ content: <Success message={t('datesExtendedSuccess')} /> });
    setTimeout(() => {
      setOpen(false);
      getSubscriptions(1, {});
    }, 3000);
  };

  // Open subscription extend popup
  const extendSubscriptionFunction = () => {
    setConfirm(false);
    setNotificationPopup(false);
    setModalView({
      title: t('extentSubscription'),
      content: <ExtendSubscription
        data={subscriptionId}
        setOpen={setOpen}
        extendSubmit={extendSubmit}
      />,
    });
    setOpen(true);
    setSubscriptionId('');
  };

  const extendFunction = (id) => {
    setSubscriptionId(id);
    setConfirm(true);
  };

  const deleteFunction = (id) => {
    setSubscriptionId(id);
    setDeleteModal(true);
  };

  // Cancel the existing subscription
  const cancelFunction = () => {
    setDeleteModal(false);
    subscription.deleteSubscription(subscriptionId)
      .then(() => {
        setNotificationPopup(true);
        setModalView({ content: <Success message={t('subscriptionCancelledSuccess')} /> });
        setOpen(true);
        getSubscriptions(1, {});
        setTimeout(() => {
          setOpen(false);
          getSubscriptions(1, {});
        }, 3000);
      })
      .catch((err) => dispatch(setErrorNotification(err?.response?.data)));
  };

  const handleScroll = () => {
    getSubscriptions(pageCountActive + 1, filterActive);
  };

  const closeModal = () => {
    setOpen(false);
    setNotificationPopup(false);
  };

  const checkName = (name, statusCheck) => {
    if (name === 'enterprise' && statusCheck === 'update') {
      return true;
    }
    if (name === 'Trial' && (statusCheck === 'cancel' || statusCheck === 'extend' || statusCheck === 'update')) {
      return true;
    }
    return false;
  };

  const actions = [
    {
      name: t('view'),
      url: '',
      function: viewFunction,
      key: 'view',
      sendFullDetails: true,
      enable: checkPermissionAllow('getSubscription'),
    },
    {
      name: t('device'),
      url: '',
      function: deviceCountEditPopup,
      key: 'update',
      sendFullDetails: true,
      enable: checkPermissionAllow('deviceIncreaseSubscription'),
    },
    {
      name: t('addOn'),
      url: '',
      function: addOnPopup,
      key: 'update',
      sendFullDetails: true,
      enable: checkPermissionAllow('addonSubscription'),
    },
    {
      name: t('plans'),
      url: '',
      function: planSwitchPopup,
      key: 'update',
      sendFullDetails: true,
      enable: checkPermissionAllow('planChangeSubscription'),
    },
    {
      name: t('extendSubscription'),
      url: '',
      function: extendFunction,
      key: 'extend',
      enable: checkPermissionAllow('expendSubscription'),
    },
    {
      name: t('cancel'),
      url: '',
      function: deleteFunction,
      key: 'cancel',
      enable: checkPermissionAllow('cancelSubscription'),
    },
  ];

  const columns = [
    {
      header: t('product'),
      accessorKey: 'product',
    },
    {
      header: t('planType'),
      accessorKey: 'plan_type',
    },
    {
      header: t('planName'),
      accessorKey: 'name',
      filterFn: 'commonFIlterFn',
      Cell: ({ row }) => row.original.plan_name,
    },
    {
      header: t('account'),
      accessorKey: 'company_name',
    },
    {
      header: t('subscriptionID'),
      accessorKey: 'order_id',
    },
    {
      header: t('price'),
      accessorKey: 'total_price',
      Cell: ({ row }) => row.original.total_price_toShow,
      enableColumnFilter: false,
      enableSorting: false,
    },
    {
      header: t('startDate'),
      accessorKey: 'start_date',
      enableColumnFilter: false,
      enableSorting: false,
    },
    {
      header: t('endDate'),
      accessorKey: 'end_date',
      enableColumnFilter: false,
      enableSorting: false,
    },
    {
      header: '',
      accessorKey: 'action',
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      enableHiding: true,
      filterVariant: 'none',
      size: 50,
      Cell: ({ row }) => (
        <DropDown
          action={actions}
          icon={more}
          value={row?.original}
          dropdown="tableForm"
          height="15px"
          tooltip=""
          statusCheck={checkName}
        />
      ),
    },
  ];

  const exportData = () => {
    const columnHeaders = [
      'plan_type',
      'plan_name',
      'order_id',
      'product',
      'company',
      'total_price',
      'start_date',
      'end_date',
    ];

    // Filter the hidden column
    const filteredHeaders = columnHeaders.filter((header) => {
      if (header === 'plan_name') {
        return columnVisibility.name !== false;
      }
      return columnVisibility[header] !== false;
    });

    // API call for export CSV
    subscription.exportSubscriptionData({ fields: filteredHeaders, ...filterActive, status: 'active' })
      .then((res) => {
        const rows = res?.data?.map((row) => ({
          ...row,
          start_date: row?.start_date ? changeDateFormat(row?.start_date) : undefined,
          end_date: row?.end_date ? changeDateFormat(row?.end_date) : undefined,
        }));

        // Function to export as CSV file
        exportCSV(Object.keys(rows?.[0]), rows);
      })
      .catch((err) => {
        dispatch(setErrorNotification(err?.response?.data));
      });
  };

  const onFilterOrSortingChange = (filteredValues, reduxColumnFiltersSorting) => {
    dispatch(setTableFilterData(reduxColumnFiltersSorting));
    dispatch(setFilter(filteredValues));
    getSubscriptions(1, filteredValues);
  };
  useEffect(() => {
    setIsFirstRender(false);
    // if (pageCountActive === 0 && Object.keys(tableFilterDataActive).length === 0) {
    getSubscriptions(1, filterActive);
    // }
  }, []);

  return (
    <div className="content-area">
      <ModalBox
        status={open}
        closeModal={closeModal}
        modalView={modalView}
        notification={notificationPopup}
      />
      <DeleteModal
        title={t('cancelSubscriptionConfirmation')}
        subTitle={t('cancelSubscriptionMessage')}
        buttonLabel={t('yes')}
        displayLabelNo
        status={deleteModal}
        setOpen={setDeleteModal}
        okFunction={cancelFunction}
      />
      <ConfirmModal
        title={t('extendSubscriptionConfirmation')}
        subTitle={t('extendSubscriptionInfo')}
        subTitle1={t('cannotUndoAction')}
        buttonLabel={t('yes')}
        status={confirm}
        setOpen={setConfirm}
        okFunction={extendSubscriptionFunction}
      />
      <div className="my-content">
        <div className="my-content-area">
          <DataTable
            header={columns}
            value={activeSubscription}
            status={status}
            totalPageCount={totalPageCountActive}
            pageCount={pageCountActive}
            scrollData={handleScroll}
            reduxColumnFiltersSorting={tableFilterDataActive}
            onFilterOrSortingChange={onFilterOrSortingChange}
            isFirstRender={isFirstRender}
            totalDataCount={totalDataCountActive}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            columnVisibility={columnVisibility}
            setColumnVisibility={setColumnVisibility}
            enable={Object.keys(filterActive).filter((val) => val !== 'sortField' && val !== 'sortBy').length > 0}
            height={height.tableHeight}
            exportButton
            exportData={exportData}
          />
        </div>
      </div>
    </div>
  );
}
ActiveTable.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
  }),
};

ActiveTable.defaultProps = {
  row: null,
};
export default ActiveTable;
