import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import TopContent from '../../Shared/Components/TopContent';
import DropDown from '../../Shared/Components/DropDown';

import DeleteModal from '../../Shared/Components/DeleteModal';
import PopupModel from '../../Shared/Components/PopupModel';

import more from '../../assets/icons/more.png';
import {
  fetchRolesList,
  setTableFilterData,
  setFilter,
  deleteRoleDetails,
} from '../../redux/slices/RolesSlice';
import roles from '../../utils/api/roles';

import DataTable from '../Table';
import { setErrorNotification } from '../../redux/slices/NotificationSlice';
import { checkPermissionAllow } from '../../utils/helpers';
import height from '../../utils/size-variables';

function Roles() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const rolesData = useSelector((state) => state.roles.roles);
  const {
    pageCount,
    totalPageCount,
    status,
    filter,
    tableFilterData,
    totalDataCount,
  } = useSelector((state) => state.roles);
  const dispatch = useDispatch();

  const [deleteId, setDeleteId] = useState();
  const [deleteModel, setDeleteModel] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);

  const [rowSelection, setRowSelection] = useState({});
  const [columnVisibility, setColumnVisibility] = useState({});

  const getData = (count, filterData) => {
    if (totalPageCount >= count && status === 'success') {
      dispatch(fetchRolesList({ page: count, ...filterData }));
    }
  };

  const handleScroll = () => {
    getData(pageCount + 1, filter);
  };

  useEffect(() => {
    if (rolesData.length === 0) {
      getData();
    }
  }, [navigate]);

  const deleteFunction = (id) => {
    setDeleteId(id);
    setDeleteModel(true);
  };

  const deleteData = () => {
    roles.deleteRole(deleteId).then(() => {
      dispatch(deleteRoleDetails(deleteId));
      setDeleteModel(false);
      setDeleteId('');
    }).catch((err) => {
      dispatch(setErrorNotification(err?.response?.data));
    });
  };

  const viewFunction = (id) => {
    navigate(`roles-view/${id}`);
  };

  const actions = [
    {
      name: t('view'),
      url: '',
      function: viewFunction,
      key: 'view',
      enable: checkPermissionAllow('getRole'),
    },
    {
      name: t('delete'),
      url: '',
      function: deleteFunction,
      key: 'delete',
      enable: checkPermissionAllow('deleteRole'),
    },
  ];

  const addRole = () => {
    navigate('roles-add');
  };

  const columns = [
    {
      header: t('role'),
      accessorKey: 'name',
      Cell: ({ row }) => (
        <div className="content-thumbnail">
          <span className="content_name">
            {row.original.name}
          </span>
        </div>
      ),
    },
    {
      header: t('permissions'),
      accessorKey: 'permissions',
      Cell: ({ row }) => (
        <PopupModel list={row.original.permissions} name="Permission List" className="text-dropdown" />
      ),
    },
    {
      header: '',
      accessorKey: 'id',
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      enableHiding: true,
      filterVariant: 'none',
      size: 50,
      Cell: ({ row }) => (
        <DropDown
          action={actions}
          icon={more}
          value={row?.original}
          dropdown="tableForm"
          height="15px"
          tooltip=""
        />
      ),
    },
  ];

  const onFilterOrSortingChange = (filteredValues, reduxColumnFiltersSorting) => {
    dispatch(setTableFilterData(reduxColumnFiltersSorting));
    dispatch(setFilter(filteredValues));
    getData(1, filteredValues);
  };
  useEffect(() => {
    setIsFirstRender(false);
    if (pageCount === 0 && Object.keys(tableFilterData).length === 0) {
      getData(1, {});
    }
  }, []);

  return (
    <div className="main-container">
      <TopContent
        label={t('roles')}
        buttonClass="success-button"
        button={checkPermissionAllow('addRole')}
        buttonLabel={t('newRole')}
        click={addRole}
      />
      <DeleteModal
        status={deleteModel}
        setOpen={setDeleteModel}
        buttonLabel={t('delete')}
        title={t('confirmDeleteRole')}
        okFunction={deleteData}
      />
      <div className="content-area">
        <div className="my-content">
          <div className="my-content-area">
            <DataTable
              header={columns}
              value={rolesData}
              status={status}
              totalPageCount={totalPageCount}
              pageCount={pageCount}
              reduxColumnFiltersSorting={tableFilterData}
              onFilterOrSortingChange={onFilterOrSortingChange}
              isFirstRender={isFirstRender}
              scrollData={handleScroll}
              totalDataCount={totalDataCount}
              rowSelection={rowSelection}
              setRowSelection={setRowSelection}
              columnVisibility={columnVisibility}
              setColumnVisibility={setColumnVisibility}
              enable={Object.keys(filter).filter((val) => val !== 'sortField' && val !== 'sortBy').length > 0}
              height={height.onlyTableHeight}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
Roles.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      name: PropTypes.string.isRequired,
      permissions: PropTypes.string.isRequired,
    }).isRequired,
  }),
};

Roles.defaultProps = {
  row: null,
};
export default Roles;
