import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import TopContent from '../../../Shared/Components/TopContent';
import { Input } from '../../../Shared/Components/Form/Input';
import { singleRoles, fetchPermissionList } from '../../../redux/slices/RolesSlice';
import { checkPermissionAllow } from '../../../utils/helpers';

function RolesView() {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [value, setValue] = useState({});
  const [select, selectData] = useState([]);
  const { permissions, roles, singleRoles: rolesData } = useSelector((state) => state.roles);

  const getPermissions = () => {
    if (permissions.length === 0) {
      dispatch(fetchPermissionList());
    }
  };

  useEffect(() => {
    if (roles.length === 0) {
      navigate('../');
    }
    getPermissions();
    dispatch(singleRoles(params?.id));
  }, [params]);

  useEffect(() => {
    setValue(rolesData);
    selectData(rolesData?.permissions);
  }, [rolesData]);

  const editRole = () => {
    navigate(`../roles-edit/${params?.id}`);
  };

  return (
    <div className="main-container">
      <TopContent
        label={[t('roles'), value?.name]}
        buttonClass="default-button button-outline-success-1"
        button={checkPermissionAllow('editRole')}
        buttonLabel={t('editRole')}
        click={editRole}
      />
      <div className="content-area">
        <div className="my-content">
          <div className="my-content-area-form">
            <div className="my-content-form">
              <Input
                icon={' '}
                label={t('role')}
                type="text"
                name="name"
                readonly
                value={value?.name}
              />
            </div>
            <div className="my-content-permissions-list">
              <h3 className="title">{t('permissions')}</h3>
              <div className="permission-list">
                <div className="permission-lis-session">
                  {
                    permissions?.map((values) => (
                      <FormControl component="fieldset">
                        <FormLabel component="legend">{values.name}</FormLabel>
                        <FormGroup
                          aria-label="position"
                          row
                          sx={{
                            gap: 7,
                            rowGap: 2,
                          }}
                        >
                          {
                            values?.children.map((e) => (
                              <FormControlLabel
                                value={e.value}
                                control={(
                                  <Checkbox
                                    size="small"
                                    sx={{
                                      color: '##1C75BC',
                                      '&.Mui-checked': {
                                        color: '##1C75BC',
                                      },
                                    }}
                                    checked={select?.includes(e.value)}
                                  // onChange={handleChange}
                                  />
                                )}
                                label={e.name}
                              />
                            ))
                          }
                        </FormGroup>
                      </FormControl>
                    ))
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RolesView;
