/* eslint no-underscore-dangle: 0 */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Table from '../../Table';
import ModalBox from '../../../Shared/Components/ModalBox';
import Success from '../../../Shared/Components/Success';
import { fetchTransactionSummary, setFilter, setTableFilterData } from '../../../redux/slices/TransactionSummarySlice';
import height from '../../../utils/size-variables';

function TransactionSummary() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    transactionSummary,
    status,
    tableFilterData,
  } = useSelector((state) => state.transactionSummary);

  const [successNotification, setSuccessNotification] = useState('');

  // Table state variables
  const [rowSelection, setRowSelection] = useState({});
  const [columnVisibility, setColumnVisibility] = useState({});
  const [isFirstRender, setIsFirstRender] = useState(true);

  const columns = [
    {
      header: t('product'),
      accessorKey: 'product_name',
      enableColumnFilter: false,
      enableSorting: false,
    },
    {
      header: t('planName'),
      accessorKey: 'plan_name',
      enableColumnFilter: false,
      enableSorting: false,
    },
    {
      header: t('account'),
      accessorKey: 'company_name',
      enableColumnFilter: false,
      enableSorting: false,
    },
    {
      header: t('orderType'),
      accessorKey: 'event',
      enableColumnFilter: false,
      enableSorting: false,
    },
    {
      header: t('rentalOrderID'),
      accessorKey: 'order_id',
      enableColumnFilter: false,
      enableSorting: false,
    },
    {
      header: t('transactionType'),
      accessorKey: 'transaction_type',
      enableColumnFilter: false,
      enableSorting: false,
    },
    {
      header: t('transactionDate'),
      accessorKey: 'transaction_data',
      enableColumnFilter: false,
      enableSorting: false,
    },
    {
      header: t('price'),
      accessorKey: 'price_to_display',
      enableColumnFilter: false,
      enableSorting: false,
    },
  ];

  const getTransactionSummary = (page, filters) => {
    if (status === 'succeed') {
      dispatch(fetchTransactionSummary({ page, ...filters }));
    }
  };

  const onFilterOrSortingChange = (filteredValues, reduxColumnFiltersSorting) => {
    dispatch(setTableFilterData(reduxColumnFiltersSorting));
    dispatch(setFilter(filteredValues));
    getTransactionSummary(1, filteredValues);
  };
  useEffect(() => {
    setIsFirstRender(false);
    // if (Object.keys(tableFilterData).length === 0) {
    getTransactionSummary(1, {});
    // }
  }, []);

  return (
    <div>
      <ModalBox
        status={successNotification === 'open'}
        closeModal={() => setSuccessNotification('close')}
        modalView={{ content: <Success message={t('paymentVerifiedSuccess')} /> }}
        notification
      />
      <div className="table-container">
        <Table
          header={columns}
          value={transactionSummary}
          status={status}
          totalPageCount={1}
          pageCount={1}
          scrollData={() => { }}
          reduxColumnFiltersSorting={tableFilterData}
          onFilterOrSortingChange={onFilterOrSortingChange}
          isFirstRender={isFirstRender}
          totalDataCount={transactionSummary.length}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          columnVisibility={columnVisibility}
          setColumnVisibility={setColumnVisibility}
          height={height.tableHeight}
          disableFilterButton
        />
      </div>
    </div>
  );
}

TransactionSummary.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      name: PropTypes.string.isRequired,
      permissions: PropTypes.string.isRequired,
    }).isRequired,
  }),
};

TransactionSummary.defaultProps = {
  row: null,
};

export default TransactionSummary;
