const height = {
  tableHeight: `${window.innerHeight - 251}px`,
  onlyTableHeight: `${window.innerHeight - 231}px`,
};
if (window.innerHeight > 791) {
  height.tableHeight = '67vh';
  height.onlyTableHeight = '73vh';
}
if (window.innerHeight <= 791 && window.innerHeight > 731) {
  height.tableHeight = '65vh';
  height.onlyTableHeight = '71vh';
}
if (window.innerHeight <= 731 && window.innerHeight > 695) {
  height.tableHeight = '63vh';
  height.onlyTableHeight = '69vh';
}
if (window.innerHeight <= 695 && window.innerHeight > 599) {
  height.tableHeight = '61vh';
  height.onlyTableHeight = '67vh';
}
if (window.innerHeight <= 599 && window.innerHeight > 100) {
  height.tableHeight = '59vh';
  height.onlyTableHeight = '65vh';
}
export default height;
