import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Input, TextArea } from '../../../Shared/Components/Form/Input';
import APKIcon from '../../../assets/icons/document-file-icon.png';
import { capitalize } from '../../../utils/helpers';

function PlayerVersionView(props) {
  const { t } = useTranslation();
  const {
    playerAppData,
  } = props;
  const playerAppName = `Wejha_player_${playerAppData?.version}`;

  return (
    <div className="model-main-container">
      <div className="main-content">
        <Input
          label={t('platform')}
          type="text"
          name="os"
          value={capitalize(playerAppData?.os)}
          readonly
        />
        <Input
          label={t('playerVersion')}
          type="text"
          name="version"
          value={playerAppData?.version}
          readonly
        />
        <TextArea
          label={t('releaseNote')}
          type="text"
          name="releaseNotes"
          value={playerAppData?.releaseNotes?.[0]}
          readonly
        />
        <div className="view-file">
          <img src={APKIcon} alt="view" />
          <p>{playerAppName}</p>
        </div>
        <div className="replace-version-button">
          <p>{t('replaceExistingVersion')}</p>
          <div className="toggle-button">
            <label className="switch" htmlFor="toggle-checkbox">
              <input
                type="checkbox"
                id="toggle-checkbox"
                checked={playerAppData?.latestUpdate}
              />
              <span className="slider round" />
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}
PlayerVersionView.propTypes = {
  playerAppData: PropTypes.objectOf().isRequired,
};
export default PlayerVersionView;
