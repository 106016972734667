import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

const filter = createFilterOptions();

function AutocompleteBox({
  label,
  required,
  name,
  value,
  lists,
  onchange,
  multiple,
  border,
  disableClearable,
  id,
  placeholder,
  allowNewOption,
}) {
  const handleChange = (event, newValue) => {
    if (typeof newValue === 'string') {
      onchange(name, { label: newValue });
    } else if (newValue && newValue.inputValue) {
      onchange(name, { label: newValue.inputValue });
    } else {
      onchange(name, newValue);
    }
  };

  const filterOptions = (options, params) => {
    const filtered = filter(options, params);

    if (allowNewOption) {
      const { inputValue } = params;
      const isExisting = options.some((option) => inputValue === option.label);
      if (inputValue !== '' && !isExisting) {
        filtered.push({
          inputValue,
          label: `Add "${inputValue}"`,
        });
      }
    }

    return filtered;
  };

  return (
    <div className="input-black">
      <label className="label" htmlFor={name}>
        {label} {required && <span className="text-danger">*</span>}
      </label>
      <Autocomplete
        multiple={multiple}
        value={value}
        name={name}
        onChange={handleChange}
        filterOptions={filterOptions}
        options={lists}
        id={id}
        disableClearable={disableClearable}
        isOptionEqualToValue={(option, values) => option.label === values.label}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            InputLabelProps={{ shrink: true }}
            sx={{
              '.MuiAutocomplete-input': { fontSize: '14px', fontFamily: 'Poppins' },
              '.MuiOutlinedInput-root': { padding: '4.5px 4.5px' },
              '.MuiOutlinedInput-notchedOutline': {
                border: border === false ? 'none !important' : '0.5px solid #CACACA !important',
                borderRadius: '6px !important',
              },
            }}
          />
        )}
        renderOption={(props, option) => (
          <li {...props}>
            <span id={option.label}>{option.label}</span>
          </li>
        )}
      />
    </div>
  );
}

AutocompleteBox.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  lists: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ])),
  onchange: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  border: PropTypes.bool,
  disableClearable: PropTypes.bool,
  id: PropTypes.string,
  allowNewOption: PropTypes.bool,
  placeholder: PropTypes.string,
};

AutocompleteBox.defaultProps = {
  multiple: false,
  label: '',
  value: null,
  lists: [],
  border: true,
  disableClearable: false,
  id: '',
  required: false,
  allowNewOption: false,
  placeholder: '',
};

export default AutocompleteBox;
