import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Input, TextArea } from '../../../Shared/Components/Form/Input';
import UploadFile from '../../../assets/icons/uploadFile.png';
import Button from '../../../Shared/Components/Button';
import APKIcon from '../../../assets/icons/document-file-icon.png';
import PlayerApp from '../../../utils/api/player-app';
import ModalBox from '../../../Shared/Components/ModalBox';
import Success from '../../../Shared/Components/Success';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import { fetchPlayerAppVersionList } from '../../../redux/slices/PlayerAppSlice';
import SelectBox from '../../../Shared/Components/Form/SelectBox';
import { versionValidation } from '../../../Shared/Components/Form/Validation';

function PlayerVersionAdd(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const availableOs = [
    {
      label: 'Android',
      key: 'android',
    },
    {
      label: 'Windows',
      key: 'windows',
    },
    {
      label: 'Tizen',
      key: 'tizen',
    },
  ];

  const [playerVersionData, setPlayerVersionData] = useState({
    latestUpdateChecked: false,
  });
  const [buttonStatus, setButtonStatus] = useState('');
  const [buttonDisable, setButtonDisable] = useState(true);
  const [submitButtonClicked, setSubmitButtonClicked] = useState(false);

  const handleChange = (name, changeValue) => {
    if (name === 'platform' && changeValue) {
      setPlayerVersionData((prevValue) => ({
        ...prevValue,
        platformLabel: changeValue?.label,
        [name]: changeValue?.key,
      }));
    } else if (name !== 'platform') {
      setPlayerVersionData((prevValue) => ({
        ...prevValue,
        [name]: changeValue,
      }));
    }
  };

  const onChangeApk = (e) => {
    const maxFileSizeInMB = 100;
    const maxFileSizeInBytes = maxFileSizeInMB * 1024 * 1024; // Convert MB to bytes
    const selectedFile = e.target.files[0];

    if (selectedFile?.size <= maxFileSizeInBytes) {
      setPlayerVersionData((preValue) => ({
        ...preValue,
        file: selectedFile,
      }));
    } else if (selectedFile?.size > maxFileSizeInBytes) {
      dispatch(setErrorNotification({ message: `File size should be less than ${maxFileSizeInMB} MB` }));
    } else {
      dispatch(setErrorNotification({ message: 'Something went wrong!' }));
    }
  };

  const handleLatestUpdateToggle = () => {
    setPlayerVersionData({
      ...playerVersionData,
      latestUpdateChecked: !playerVersionData.latestUpdateChecked,
    });
  };

  const submit = () => {
    setSubmitButtonClicked(true);
    if (versionValidation(playerVersionData?.playerVersion)) {
      setButtonDisable(true);
      setButtonStatus('loading');
      const formData = new FormData();
      formData.append('os', playerVersionData?.platform);
      formData.append('version', playerVersionData?.playerVersion);
      formData.append('releaseNotes[0]', playerVersionData?.releaseNote);
      formData.append('apk', playerVersionData?.file);
      formData.append('latestUpdate', playerVersionData?.latestUpdateChecked);
      PlayerApp.addPlayerVersion(formData)
        .then(() => {
          setButtonDisable(false);
          setButtonStatus('success');
          setTimeout(() => {
            setButtonStatus('');
            setButtonDisable(true);
            dispatch(fetchPlayerAppVersionList({ page: 1 }));
            props?.closeModal(false);
          }, 3000);
        })
        .catch((err) => {
          setButtonDisable(false);
          setButtonStatus('error');
          dispatch(setErrorNotification(err?.response?.data));
        });
    }
  };

  useEffect(() => {
    if (
      playerVersionData?.platform
      && playerVersionData?.playerVersion
      && playerVersionData?.releaseNote
      && playerVersionData?.file
    ) {
      setButtonDisable(false);
    } else {
      setButtonDisable(true);
    }
  }, [playerVersionData]);

  return (
    <div className="model-main-container">
      <ModalBox
        status={buttonStatus === 'success'}
        closeModal={() => setButtonStatus('close')}
        modalView={{ content: <Success message={t('playerAppSuccess')} /> }}
        notification
      />
      <div className="main-content">
        <SelectBox
          label={t('platform')}
          name="platform"
          required
          value={playerVersionData?.platformLabel}
          onchange={handleChange}
          lists={availableOs}
          id="select-os"
          placeholder={t('selectPlatformName')}
        />
        <Input
          label={t('playerVersion')}
          type="text"
          name="playerVersion"
          value={playerVersionData?.playerVersion}
          change={handleChange}
          placeholder={t('enterPlayerVersion')}
          required
          error={!versionValidation(playerVersionData?.playerVersion) && submitButtonClicked}
          errorMessage={t('versionValidation')}
        />
        <TextArea
          label={t('releaseNote')}
          type="text"
          name="releaseNote"
          value={playerVersionData?.releaseNote}
          change={handleChange}
          placeholder={t('enterReleaseNote')}
          required
        />
        <div className="file-upload">
          <label htmlFor="file" className="upload-click">
            <div className="file-upload-image">
              <img src={UploadFile} alt="icon" className="img" />
            </div>
            <p>{t('uploadFiles')}</p>
          </label>
          <input
            type="file"
            id="file"
            accept=".apk"
            multiple
            onChange={onChangeApk}
            style={{ display: 'none' }}
          />
        </div>
        {playerVersionData?.file
          && (
            <div className="view-file">
              <img src={APKIcon} alt="view" />
              <p>{playerVersionData.file?.name}</p>
            </div>
          )}
        <div className="replace-version-button">
          <p>{t('replaceExistingVersion')}</p>
          <div className="toggle-button">
            <label className="switch" htmlFor="toggle-checkbox">
              <input
                type="checkbox"
                id="toggle-checkbox"
                checked={playerVersionData.latestUpdateChecked}
                onChange={handleLatestUpdateToggle}
              />
              <span className="slider round" />
            </label>
          </div>
        </div>
        <div className="Form-button">
          <Button
            label={t('back')}
            classes="default-button"
            click={() => props?.closeModal(false)}
          />
          <div className="btn">
            <Button
              label={buttonStatus === 'loading' ? t('uploading') : t('upload')}
              click={submit}
              classes={buttonStatus === 'loading' ? 'success-button loading-btn' : 'success-button'}
              disabled={buttonDisable}
              loading={buttonStatus === 'loading'}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
PlayerVersionAdd.propTypes = {
  closeModal: PropTypes.func.isRequired,
};
export default PlayerVersionAdd;
