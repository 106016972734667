import cookies from 'js-cookie';
import { get, post } from './base/index';

export default {
  login: (data = {}) => {
    const params = { ...data };
    return post(`${process.env.REACT_APP_API_URL}admin/v1/auth/login`, { ...params });
  },
  logout: (data = {}) => {
    const token = JSON.parse(cookies.get('secure-auth-refresh'));
    const params = { ...data, refreshToken: token };
    return post(`${process.env.REACT_APP_API_URL}admin/v1/auth/logout`, { ...params });
  },
  forgotPassword: (data = {}) => post(`${process.env.REACT_APP_API_URL}admin/v1/auth/forgot-password`, data),
  getData: (data = {}) => {
    const params = { ...data };
    return get(`${process.env.REACT_APP_API_URL}admin/v1/auth/login`, { params });
  },
  resetPassword: (data = {}, token = '') => {
    const params = { ...data };
    return post(`${process.env.REACT_APP_API_URL}admin/v1/auth/reset-password?token=${token}`, { ...params });
  },
  verifyEmail: (data = {}) => {
    const params = { ...data };
    return post(`${process.env.REACT_APP_API_URL}admin/v1/auth/verify-otp`, { ...params });
  },
  sendLogInOtp: (data = {}) => {
    const params = { ...data };
    return post(`${process.env.REACT_APP_API_URL}admin/v1/auth/send-login-otp`, { ...params });
  },
};
