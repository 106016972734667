/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Tabs, Tab, Box } from '@mui/material';
import TopContent from '../../../Shared/Components/TopContent';
import SelectBox from '../../../Shared/Components/Form/SelectBox';
import { InputInnerSymbol } from '../../../Shared/Components/Form/Input';
import Button from '../../../Shared/Components/Button';

import Success from '../../../Shared/Components/Success';
import ModalBox from '../../../Shared/Components/ModalBox';
import { currencySymbol } from '../../../utils/helpers';
import licensesPlan from '../../../utils/api/licenses-plan';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import { addLicensesPlanDetails } from '../../../redux/slices/LicensesPlanSlice';

function LicensePlansAdd() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const planNames = [
    { label: t('business'), id: 'business' },
    { label: t('pro'), id: 'pro' },
  ];

  const [buttonStatus, setButtonStatus] = useState('');

  const [value, setValue] = useState({});
  const isAdvancedPlan = value.planKey === 'advanced';
  const [activeTab, setActiveTab] = useState('1');
  const [pricePerDevice, setPricePerDevice] = useState([
    {
      currency: 'SAR',
      price_in_duration: [
        {
          duration_type: 'year',
          duration_value: 1,
          price: 0,
        },
        {
          duration_type: 'year',
          duration_value: 3,
          price: 0,
        },
      ],
    },
    {
      currency: 'USD',
      price_in_duration: [
        {
          duration_type: 'year',
          duration_value: 1,
          price: 0,
        },
        {
          duration_type: 'year',
          duration_value: 3,
          price: 0,
        },
      ],
    },
    {
      currency: 'EUR',
      price_in_duration: [
        {
          duration_type: 'year',
          duration_value: 1,
          price: 0,
        },
        {
          duration_type: 'year',
          duration_value: 3,
          price: 0,
        },
      ],
    },
    {
      currency: 'INR',
      price_in_duration: [
        {
          duration_type: 'year',
          duration_value: 1,
          price: 0,
        },
        {
          duration_type: 'year',
          duration_value: 3,
          price: 0,
        },
      ],
    },
  ]);

  const [pricePerDevices, setPricePerDevices] = useState([
    {
      currency: 'SAR',
      device_counts: [
        {
          device: '100',
          price_in_duration: [
            {
              duration_type: 'year',
              duration_value: 1,
              price: 0,
            },
            {
              duration_type: 'year',
              duration_value: 5,
              price: 0,
            },
            {
              duration_type: 'unlimited',
              duration_value: 0,
              price: 0,
            },
          ],
        },
        {
          device: '200',
          price_in_duration: [
            {
              duration_type: 'year',
              duration_value: 1,
              price: 0,
            },
            {
              duration_type: 'year',
              duration_value: 5,
              price: 0,
            },
            {
              duration_type: 'unlimited',
              duration_value: 0,
              price: 0,
            },
          ],
        },
        {
          device: '500',
          price_in_duration: [
            {
              duration_type: 'year',
              duration_value: 1,
              price: 0,
            },
            {
              duration_type: 'year',
              duration_value: 5,
              price: 0,
            },
            {
              duration_type: 'unlimited',
              duration_value: 0,
              price: 0,
            },
          ],
        },
        {
          device: '1000',
          price_in_duration: [
            {
              duration_type: 'year',
              duration_value: 1,
              price: 0,
            },
            {
              duration_type: 'year',
              duration_value: 5,
              price: 0,
            },
            {
              duration_type: 'unlimited',
              duration_value: 0,
              price: 0,
            },
          ],
        },
      ],
    },
    {
      currency: 'USD',
      device_counts: [
        {
          device: '100',
          price_in_duration: [
            {
              duration_type: 'year',
              duration_value: 1,
              price: 0,
            },
            {
              duration_type: 'year',
              duration_value: 5,
              price: 0,
            },
            {
              duration_type: 'unlimited',
              duration_value: 0,
              price: 0,
            },
          ],
        },
        {
          device: '200',
          price_in_duration: [
            {
              duration_type: 'year',
              duration_value: 1,
              price: 0,
            },
            {
              duration_type: 'year',
              duration_value: 5,
              price: 0,
            },
            {
              duration_type: 'unlimited',
              duration_value: 0,
              price: 0,
            },
          ],
        },
        {
          device: '500',
          price_in_duration: [
            {
              duration_type: 'year',
              duration_value: 1,
              price: 0,
            },
            {
              duration_type: 'year',
              duration_value: 5,
              price: 0,
            },
            {
              duration_type: 'unlimited',
              duration_value: 0,
              price: 0,
            },
          ],
        },
        {
          device: '1000',
          price_in_duration: [
            {
              duration_type: 'year',
              duration_value: 1,
              price: 0,
            },
            {
              duration_type: 'year',
              duration_value: 5,
              price: 0,
            },
            {
              duration_type: 'unlimited',
              duration_value: 0,
              price: 0,
            },
          ],
        },
      ],
    },
    {
      currency: 'EUR',
      device_counts: [
        {
          device: '100',
          price_in_duration: [
            {
              duration_type: 'year',
              duration_value: 1,
              price: 0,
            },
            {
              duration_type: 'year',
              duration_value: 5,
              price: 0,
            },
            {
              duration_type: 'unlimited',
              duration_value: 0,
              price: 0,
            },
          ],
        },
        {
          device: '200',
          price_in_duration: [
            {
              duration_type: 'year',
              duration_value: 1,
              price: 0,
            },
            {
              duration_type: 'year',
              duration_value: 5,
              price: 0,
            },
            {
              duration_type: 'unlimited',
              duration_value: 0,
              price: 0,
            },
          ],
        },
        {
          device: '500',
          price_in_duration: [
            {
              duration_type: 'year',
              duration_value: 1,
              price: 0,
            },
            {
              duration_type: 'year',
              duration_value: 5,
              price: 0,
            },
            {
              duration_type: 'unlimited',
              duration_value: 0,
              price: 0,
            },
          ],
        },
        {
          device: '1000',
          price_in_duration: [
            {
              duration_type: 'year',
              duration_value: 1,
              price: 0,
            },
            {
              duration_type: 'year',
              duration_value: 5,
              price: 0,
            },
            {
              duration_type: 'unlimited',
              duration_value: 0,
              price: 0,
            },
          ],
        },
      ],
    },
    {
      currency: 'INR',
      device_counts: [
        {
          device: '100',
          price_in_duration: [
            {
              duration_type: 'year',
              duration_value: 1,
              price: 0,
            },
            {
              duration_type: 'year',
              duration_value: 5,
              price: 0,
            },
            {
              duration_type: 'unlimited',
              duration_value: 0,
              price: 0,
            },
          ],
        },
        {
          device: '200',
          price_in_duration: [
            {
              duration_type: 'year',
              duration_value: 1,
              price: 0,
            },
            {
              duration_type: 'year',
              duration_value: 5,
              price: 0,
            },
            {
              duration_type: 'unlimited',
              duration_value: 0,
              price: 0,
            },
          ],
        },
        {
          device: '500',
          price_in_duration: [
            {
              duration_type: 'year',
              duration_value: 1,
              price: 0,
            },
            {
              duration_type: 'year',
              duration_value: 5,
              price: 0,
            },
            {
              duration_type: 'unlimited',
              duration_value: 0,
              price: 0,
            },
          ],
        },
        {
          device: '1000',
          price_in_duration: [
            {
              duration_type: 'year',
              duration_value: 1,
              price: 0,
            },
            {
              duration_type: 'year',
              duration_value: 5,
              price: 0,
            },
            {
              duration_type: 'unlimited',
              duration_value: 0,
              price: 0,
            },
          ],
        },
      ],
    },
  ]);
  const [disable, setDisable] = useState(true);

  const products = [
    {
      label: t('cmsServer'),
      key: 'cmsServer',
    },
  ];

  const planTypes = [
    {
      label: t('standard'),
      key: 'standard',
    },
    {
      label: t('advanced'),
      key: 'advanced',
    },
  ];

  const clickFunction = () => {
    navigate('../');
  };
  const [showFeaturesPricing, setShowFeaturesPricing] = useState(false); // New state
  const handleContinueClick = () => {
    setShowFeaturesPricing(true);
  };
  const handleChange = (name, data) => {
    if (data) {
      if (name === 'product') {
        setValue({ ...value, [name]: data.label, productKey: data.key });
      } else if (name === 'plan_type') {
        setValue({ ...value, [name]: data.label, planKey: data.key });
      } else if (name === 'plan_name') {
        setValue({ ...value, [name]: data.label, planNameKey: data.id });
      }
    }
  };

  const handleChangeDevice = (currency, changeValue, index) => {
    const updatedPricePerDevice = pricePerDevice.map((device) => {
      if (device.currency === currency) {
        const updatedPriceInMonths = device.price_in_duration.map((month) => {
          if (month.duration_value === index) {
            return { ...month, price: changeValue ? Number(changeValue) : null };
          }
          return month;
        });
        return { ...device, price_in_duration: updatedPriceInMonths };
      }
      return device;
    });
    setPricePerDevice(updatedPricePerDevice);
  };

  const handleChangeDevices = (currency, changeValue, index) => {
    const updatedPricePerDevice = pricePerDevices.map((device) => {
      let deviceCounts = device.device_counts;
      if (device.currency === currency) {
        deviceCounts = device.device_counts.map((duration) => {
          let correctDuration = duration.price_in_duration;
          if (duration.device === index) {
            correctDuration = duration.price_in_duration.map((price) => ({
              ...price,
              price: price.duration_value === parseInt(activeTab, 10) ? changeValue : price.price,
            }));
          }
          return {
            ...duration,
            price_in_duration: correctDuration,
          };
        });
      }
      return { ...device, device_counts: deviceCounts };
    });
    setPricePerDevices(updatedPricePerDevice);
  };

  const submit = () => {
    const request = {
      name: value.planNameKey,
      product: value.productKey,
      license_type: value.planKey,
    };
    if (value.plan_type === 'Standard') {
      request.price_per_device = pricePerDevice;
    } else {
      request.advanced_plan_pricing = pricePerDevices;
    }
    licensesPlan.newLicensesPlan(request)
      .then((response) => {
        setButtonStatus('success');
        setTimeout(() => {
          dispatch(addLicensesPlanDetails(response?.data));
          setButtonStatus('');
          navigate('../');
        }, 3000);
      })
      .catch((err) => {
        dispatch(setErrorNotification(err?.response?.data));
      });
  };

  useEffect(() => {
    let error = 0;
    if (showFeaturesPricing && value.planKey === 'standard') {
      pricePerDevice.forEach((currency) => {
        currency.price_in_duration.forEach((duration) => {
          if (duration.price === 0 || !duration.price) {
            error = 1;
          }
        });
      });
    }
    if (showFeaturesPricing && value.planKey === 'advanced') {
      pricePerDevices.forEach((currency) => {
        currency.device_counts.forEach((devices) => {
          devices.price_in_duration.forEach((duration) => {
            if (duration.price === 0 || !duration.price) {
              error = 1;
            }
          });
        });
      });
    }
    if (value?.product
      && value?.plan_type
      && value?.plan_name
      && error === 0
    ) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [value, pricePerDevice, showFeaturesPricing, pricePerDevices]);

  return (
    <div className="main-container">
      <ModalBox
        status={buttonStatus === 'success'}
        setOpen={setButtonStatus}
        closeModal={() => { }}
        modalView={{ content: <Success message={t('planCreatedSuccess')} /> }}
        notification
      />
      <TopContent
        label={[t('plansAndPricing'), t('newPlan')]}
        buttonClass="success-button"
        click={clickFunction}
      />
      <div className="plans-form-wrap">
        <div className="plans-details">
          <div className="single-row">
            <SelectBox
              label={t('product')}
              name="product"
              required
              value={value.product}
              onchange={handleChange}
              lists={products}
              id="select-plans"
              placeholder={t('selectProduct')}
            />
            <SelectBox
              label={t('licenseType')}
              name="plan_type"
              required
              value={value.plan_type}
              onchange={handleChange}
              lists={planTypes}
              id="select-plans"
              placeholder={t('enterPlanType')}
            />
          </div>
          <div className="single-row">
            <SelectBox
              label={t('planName')}
              name="plan_name"
              required
              value={value.plan_name}
              onchange={handleChange}
              lists={planNames}
              id="select-plans"
              placeholder={t('enterPlanName')}
            />
          </div>
          <div className="form-button-group">
            <div className="form-button">
              {!showFeaturesPricing && (
                <Button
                  label={t('back')}
                  click={clickFunction}
                  classes="default-button"
                />
              )}
            </div>
            <div className="form-button">
              {!showFeaturesPricing && (
                <Button
                  label={t('continue')}
                  click={handleContinueClick}
                  classes="success-button"
                  disabled={disable}
                  loading={buttonStatus === 'loading'}
                />
              )}
            </div>
          </div>
          {isAdvancedPlan && showFeaturesPricing && (
            <Box sx={{
              borderBottom: 1,
              borderColor: 'divider',
              mb: 2,
              display: 'flex',
              justifyContent: 'end',
            }}
            >
              <Tabs value={activeTab} onChange={(e, newValue) => setActiveTab(newValue)} aria-label="pricing tabs">
                <Tab label={t('yearly')} value="1" />
                <Tab label={t('5Years')} value="5" />
                <Tab label={t('unlimited')} value="0" />
              </Tabs>
            </Box>
          )}
          {showFeaturesPricing && (
            <>
              <div className="features-pricing-license">
                <div className="pricing">
                  {
                    value.plan_type === 'Standard' ? (
                      <table className="device">
                        <tr>
                          <th>{t('deviceDuration')}</th>
                          {
                            pricePerDevice?.[0]?.price_in_duration?.map((val) => (
                              <td
                                className={val?.month === 12 && 'last-data'}
                              >
                                {val.duration_value === 1 ? t('yearlyPerDevice') : t('3yearlyPerDevice')}
                              </td>
                            ))
                          }
                        </tr>
                        {
                          pricePerDevice.map((item, index) => (
                            <tr>
                              <th
                                className={index === pricePerDevice.length - 1 && 'bottom-data'}
                              >
                                <span>{currencySymbol(item.currency)}&nbsp;</span>
                                {item.currency}
                              </th>
                              {item?.price_in_duration?.map((month) => (
                                <td
                                  label="table-data"
                                  className={
                                    month?.month === 12
                                      ? 'last-data'
                                      : index === pricePerDevice.length - 1
                                        ? 'bottom-data'
                                        : index === pricePerDevice.length - 1 && month?.month === 12
                                          ? 'last-data bottom-data'
                                          : ''
                                  }
                                >
                                  <div className="input-container">
                                    <InputInnerSymbol
                                      type="number"
                                      name={item?.currency}
                                      index={month.duration_value}
                                      value={month.price}
                                      change={handleChangeDevice}
                                      placeholder={0}
                                      symbol={currencySymbol(item.currency)}
                                    />
                                  </div>
                                </td>
                              ))}
                            </tr>
                          ))
                        }
                      </table>
                    ) : (
                      <table className="device">
                        <tr
                          style={{
                            gridTemplateColumns: isAdvancedPlan
                              ? '1.0fr repeat(4, 1fr)' : '1.0fr repeat(2, 1fr)',
                          }}
                        >
                          <th>{t('deviceDuration')}</th>
                          {pricePerDevices[0].device_counts.map((val, index) => (
                            <td key={val.device_count} className={index === 2 && !isAdvancedPlan ? 'last-data' : ''}>
                              {val.device} {t('devices')}
                            </td>
                          ))}
                        </tr>
                        {pricePerDevices.map((item, rowIndex) => (
                          <tr
                            key={item.currency}
                            style={{
                              gridTemplateColumns: isAdvancedPlan
                                ? '1.0fr repeat(4, 1fr)' : '1.0fr repeat(2, 1fr)',
                            }}
                          >
                            <th className={rowIndex === pricePerDevices.length - 1 ? 'bottom-data' : ''}>
                              <span>{currencySymbol(item.currency)}&nbsp;</span>
                              {item.currency}
                            </th>
                            {
                              item.device_counts.map((devices, columnIndex) => {
                                const price = devices.price_in_duration
                                  .find((val) => val.duration_value === parseInt(activeTab, 10));
                                return (columnIndex < 3 || (isAdvancedPlan && columnIndex === 3)) && (
                                  <td
                                    label="text"
                                    key={devices.month}
                                    className={`
                                                  ${columnIndex === 2 && !isAdvancedPlan ? 'last-data' : ''}
                                                  ${columnIndex === 3 && isAdvancedPlan ? 'last-data' : ''}
                                                  ${rowIndex === pricePerDevices.length - 1 ? 'bottom-data' : ''}
                                                `}
                                  >
                                    <div className="input-container">
                                      <InputInnerSymbol
                                        type="number"
                                        name={item.currency}
                                        index={devices?.device}
                                        value={price?.price}
                                        change={handleChangeDevices}
                                        placeholder={0}
                                        symbol={currencySymbol(item.currency)}
                                      />
                                    </div>
                                  </td>
                                );
                              })
                            }
                          </tr>
                        ))}
                      </table>
                    )
                  }
                </div>
              </div>
              <div className="form-button-group">
                <div className="form-button">
                  <Button label={t('back')} click={clickFunction} classes="default-button" />
                </div>
                <div className="form-button">
                  <Button
                    label={buttonStatus === 'loading' ? t('creating') : t('createPlan')}
                    classes="success-button"
                    disabled={disable}
                    click={submit}
                    loading={buttonStatus === 'loading'}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default LicensePlansAdd;
