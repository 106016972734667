import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { NotFoundPageIcon } from '../../Shared/Components/svgIcon';
import Button from '../../Shared/Components/Button';
import { resetAllSlices } from '../../redux/slices/ReduxStateResetSlice';

function NotFoundPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const goToLogin = () => {
    dispatch(resetAllSlices());
    navigate('/login');
  };
  return (
    <div className="not-found">
      <div className="not-found-image">
        <NotFoundPageIcon />
      </div>
      <div className="not-found-text">
        <h1>{t('pageNotFound')}</h1>
        <h3>{t('pageNotFoundMassage')}</h3>
        <div className="not-found-button">
          <Button
            label={t('backToLogin')}
            click={goToLogin}
            classes="success-button"
          />
        </div>
      </div>
    </div>
  );
}

export default NotFoundPage;
