/* eslint no-underscore-dangle: 0 */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import more from '../../../assets/icons/more.png';

import Table from '../../Table';
import ModalBox from '../../../Shared/Components/ModalBox';
import { checkPermissionAllow } from '../../../utils/helpers';
import {
  fetchTransactionSummary,
  setFilter,
  setTableFilterData,
} from '../../../redux/slices/LicenseTransactionSummarySlice';
import height from '../../../utils/size-variables';
import VerifyPayment from './VerifyPayment';
import DropDownMenu from '../../../Shared/Components/Form/DropDownMenu';

function TransactionSummary() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    transactionSummary,
    status,
    pageCount,
    totalPageCount,
    filter,
    tableFilterData,
  } = useSelector((state) => state.licenseTransactionSummary);

  // Table state variables
  const [rowSelection, setRowSelection] = useState({});
  const [columnVisibility, setColumnVisibility] = useState({});
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [open, setOpen] = useState(false);
  const [modelView, setModelView] = useState();

  const closeModal = () => {
    setOpen(false);
  };

  const verifyPayment = (selectedTransaction) => {
    setOpen(true);
    setModelView({
      title: t('verifyPayment'),
      content: <VerifyPayment transaction={selectedTransaction} closeModal={closeModal} />,
    });
  };

  const statusCheck = (name, itemStatus) => name === t('verifyPayment')
    && ['verified'].includes(itemStatus);

  const actions = [
    {
      name: t('verifyPayment'),
      function: (row) => verifyPayment(row),
      key: 'update',
      approve: true,
      sendFullDetails: true,
      enable: checkPermissionAllow('verifyPaymentTransaction'),
    },
  ];

  const columns = [
    {
      header: t('account'),
      accessorKey: 'company_name',
      filterFn: 'commonFilterFn',
    },
    {
      header: t('licenseType'),
      accessorKey: 'license_type',
    },
    {
      header: t('planName'),
      accessorKey: 'plan_name',
    },
    {
      header: t('transactionDate'),
      accessorKey: 'transaction_date',
      enableColumnFilter: false,
      enableSorting: false,
    },
    {
      header: t('TransactionID'),
      accessorKey: 'transaction_id',
    },
    {
      header: t('TransactionMode'),
      accessorKey: 'transaction_type',
    },
    {
      header: t('paidAmount'),
      accessorKey: 'price_to_display',
      enableColumnFilter: false,
      enableSorting: false,
    },
    {
      header: t('status'),
      accessorKey: 'is_verified',
      enableColumnFilter: false,
      enableSorting: false,
      Cell: ({ row }) => {
        const originalStatus = row.original.is_verified;
        return originalStatus === 'unverified'
          ? <span className="payment-pending">{t('unverified')}</span>
          : <span className="payment-updated">{t('verified')}</span>;
      },
    },
    {
      header: '',
      accessorKey: 'action',
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      enableHiding: true,
      filterVariant: 'none',
      size: 50,
      Cell: ({ row }) => (
        <DropDownMenu
          action={actions}
          icon={more}
          value={row?.original}
          dropdown="tableForm"
          height="15px"
          statusCheck={statusCheck}
          tooltip=""
        />
      ),
    },
  ];

  const getTransactionSummary = (page, filters) => {
    if (status === 'succeed') {
      dispatch(fetchTransactionSummary({ page, ...filters }));
    }
  };

  // Function to fetch next page data, scroll inside table
  const handleScroll = () => {
    getTransactionSummary(pageCount + 1, filter);
  };

  const onFilterOrSortingChange = (filteredValues, reduxColumnFiltersSorting) => {
    dispatch(setTableFilterData(reduxColumnFiltersSorting));
    dispatch(setFilter(filteredValues));
    getTransactionSummary(1, filteredValues);
  };
  useEffect(() => {
    setIsFirstRender(false);
    // if (Object.keys(tableFilterData).length === 0) {
    getTransactionSummary(1, {});
    // }
  }, []);

  return (
    <div>
      <ModalBox
        status={open}
        closeModal={closeModal}
        modalView={modelView}
      />
      <div className="table-container">
        <Table
          header={columns}
          value={transactionSummary}
          status={status}
          totalPageCount={totalPageCount}
          pageCount={pageCount}
          scrollData={handleScroll}
          reduxColumnFiltersSorting={tableFilterData}
          onFilterOrSortingChange={onFilterOrSortingChange}
          isFirstRender={isFirstRender}
          totalDataCount={transactionSummary.length}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          columnVisibility={columnVisibility}
          setColumnVisibility={setColumnVisibility}
          height={height.tableHeight}
          disableFilterButton
        />
      </div>
    </div>
  );
}

TransactionSummary.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      name: PropTypes.string.isRequired,
      permissions: PropTypes.string.isRequired,
      is_verified: PropTypes.string.isRequired,
    }).isRequired,
  }),
};

TransactionSummary.defaultProps = {
  row: null,
};

export default TransactionSummary;
