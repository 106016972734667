/* eslint-disable no-param-reassign */
/* eslint no-underscore-dangle: 0 */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import company from '../../utils/api/company';
import { currencySymbol } from '../../utils/helpers';
import { setErrorNotification } from './NotificationSlice';
import { changeDateTimeFormat } from '../../pages/Global/timeFormat';

export const fetchCompanyList = createAsyncThunk(
  'company/fetchCompany',
  async (data, { dispatch }) => {
    const response = await company.getCompanies(data).then((response1) => {
      const tableValues = response1?.data?.results.map((val) => ({
        ...val,
        id: val?._id,
        city_state: `${val?.city}, ${val?.state}`,
        createdAt: changeDateTimeFormat(val?.createdAt),
      }));
      return { ...response1?.data, results: tableValues, code: 200 };
    }).catch((error) => dispatch(setErrorNotification(error?.response?.data)));
    return response;
  },
);

export const fetchCompanyListAll = createAsyncThunk(
  'company/fetchCompanyAll',
  async (data, { dispatch }) => {
    const response = await company.getCompaniesAll()
      .then((response1) => response1?.data.map((val) => ({
        ...val,
        id: val._id,
        label: val.name,
        currency_symbol: currencySymbol(val.currency || 'USD'),
      })))
      .catch((error) => dispatch(setErrorNotification(error?.response?.data)));
    return response;
  },
);

const initialState = {
  company: [],
  companyAll: [],
  pageCount: 0,
  totalPageCount: 1,
  totalDataCount: 0,
  status: 'success',
  singleCompany: {},
  filter: {},
  tableFilterData: {},
};

export const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    singleCompany: (state, action) => {
      state.singleCompany = state.company.find((val) => val.id === action.payload);
    },
    addCompanyDetails: (state, action) => {
      state.company.unshift({
        ...action.payload,
        id: action?.payload?._id,
        city_state: `${action?.payload?.city}, ${action?.payload?.state}`,
        createdAt: changeDateTimeFormat(action?.payload?.createdAt),
      });
    },
    updateCompanyDetails: (state, action) => {
      state.company = state.company.map((companyDetails) => {
        let output = companyDetails;
        if (companyDetails.id === action?.payload?._id) {
          output = {
            ...action.payload,
            id: action?.payload?._id,
            city_state: `${action?.payload?.city}, ${action?.payload?.state}`,
            createdAt: changeDateTimeFormat(action?.payload?.createdAt),
          };
        }
        return output;
      });
    },
    deleteCompanyDetails: (state, action) => {
      state.company = state.company.filter((companyDetails) => companyDetails.id !== action?.payload);
    },
    setIndirectFilter: (state, action) => {
      state.filterIndirectCompany = action.payload;
    },
    setIndirectTableFilterData: (state, action) => {
      state.tableFilterIndirectCompanyData = action.payload;
    },
    setTableFilterData: (state, action) => {
      state.tableFilterData = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    resetCompanyState: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchCompanyList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCompanyList.fulfilled, (state, action) => {
        // Add user to the state array
        if (action?.payload?.code === 200) {
          state.pageCount = action.payload.page;
          state.totalPageCount = action.payload.totalPages > 0 ? action.payload.totalPages : 1;
          const data = action.payload.results;
          if (action.payload.page === 1) {
            state.company = data;
          } else {
            const finalData = [...state.company];
            // Add fetchedData to finalData, but only if the id is not already present
            data.forEach((obj) => {
              if (!finalData.some((item) => item._id === obj._id)) {
                finalData.push(obj);
              }
            });
            state.company = finalData;
          }
        }
        state.status = 'success';
      });
    builder.addCase(fetchCompanyListAll.fulfilled, (state, action) => {
      // Add user to the state array
      state.companyAll = action.payload;
    });
  },
});

export const {
  singleCompany,
  setTableFilterData,
  setFilter,
  addCompanyDetails,
  resetCompanyState,
  updateCompanyDetails,
  deleteCompanyDetails,
  setIndirectFilter,
  setIndirectTableFilterData,
} = companySlice.actions;

export default companySlice.reducer;
