import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import TopContent from '../../Shared/Components/TopContent';

import DeleteModal from '../../Shared/Components/DeleteModal';
import {
  fetchDeviceCatalogue,
  setTableFilterData,
  setFilter,
  deleteDeviceCatalogueDetails,
} from '../../redux/slices/DeviceCatalogueSlice';
import DropDown from '../../Shared/Components/DropDown';
import more from '../../assets/icons/more.png';

import deviceCatalogueApi from '../../utils/api/device-catalogue';
import DataTable from '../Table';
import { checkPermissionAllow } from '../../utils/helpers';
import height from '../../utils/size-variables';
import { setErrorNotification } from '../../redux/slices/NotificationSlice';

function DeviceCatalogue() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    deviceCatalogue,
    pageCount,
    totalPageCount,
    status,
    filter,
    totalDataCount,
    tableFilterData,
  } = useSelector((state) => state.deviceCatalogue);

  const [rowSelection, setRowSelection] = useState({});
  const [columnVisibility, setColumnVisibility] = useState({});
  const [isFirstRender, setIsFirstRender] = useState(true);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(false);

  const getDeviceCatalogue = (counts, search) => {
    if (status === 'success') {
      dispatch(fetchDeviceCatalogue({ page: counts, limit: 10, ...search }));
    }
  };

  const handleScroll = () => {
    getDeviceCatalogue(pageCount, filter);
  };

  const getDeleteUserId = (id) => {
    setOpenDeleteModal(true);
    setDeleteId(id);
  };

  const deleteUser = () => {
    deviceCatalogueApi.deleteDeviceCatalogue(deleteId).then(() => {
      dispatch(deleteDeviceCatalogueDetails(deleteId));
      setDeleteId('');
      setOpenDeleteModal(false);
    }).catch((error) => dispatch(setErrorNotification(error?.response?.data)));
  };

  const viewDeviceCatalogue = (id) => {
    navigate(`device-view/${id}`);
  };

  const actions = [
    // {
    //   name: 'Edit',
    //   url: '',
    //   function: () => {},
    //   key: 'edit',
    // },
    {
      name: t('view'),
      url: '',
      function: viewDeviceCatalogue,
      key: 'view',
      enable: checkPermissionAllow('getDeviceCatalogue'),
    },
    {
      name: t('delete'),
      url: '',
      function: getDeleteUserId,
      key: 'delete',
      enable: checkPermissionAllow('deleteDeviceCatalogue'),
    },
  ];

  const columns = [
    {
      header: t('productName'),
      accessorKey: 'name',
    },
    {
      header: t('os'),
      accessorKey: 'os',
    },
    {
      header: t('size'),
      accessorKey: 'screen_size',
      filterFn: 'commonFIlterFn',
      Cell: ({ row }) => row.original.screen_size_to_show,
    },
    {
      header: t('orientation'),
      accessorKey: 'orientation',
    },
    {
      header: t('ram'),
      accessorKey: 'ram',
    },
    {
      header: t('rom'),
      accessorKey: 'rom',
    },
    {
      header: '',
      accessorKey: 'id',
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      enableHiding: true,
      filterVariant: 'none',
      size: 50,
      Cell: ({ row }) => (
        <DropDown
          action={actions}
          icon={more}
          value={row?.original}
          dropdown="tableForm"
          height="15px"
          tooltip=""
        />
      ),
    },
  ];

  const addPlaylist = () => {
    navigate('device-add');
  };

  const onFilterOrSortingChange = (filteredValues, reduxColumnFiltersSorting) => {
    dispatch(setTableFilterData(reduxColumnFiltersSorting));
    dispatch(setFilter(filteredValues));
    getDeviceCatalogue(1, filteredValues);
  };
  useEffect(() => {
    setIsFirstRender(false);
    if (pageCount === 0 && Object.keys(tableFilterData).length === 0) {
      getDeviceCatalogue(1, {});
    }
  }, []);

  return (
    <div className="main-container">
      <TopContent
        label={t('deviceCatalogue')}
        button={checkPermissionAllow('addDeviceCatalogue')}
        buttonLabel={t('newProduct')}
        buttonClass="success-button"
        click={addPlaylist}
      />
      <DeleteModal
        status={openDeleteModal}
        setOpen={setOpenDeleteModal}
        okFunction={deleteUser}
        buttonLabel={t('delete')}
        title={t('deviceCatalogueDelete')}
        subTitle={t('deviceCatalogueDeletePermanently')}
      />
      <div className="content-area">
        <div className="my-content">
          <div className="my-content-area">
            <DataTable
              header={columns}
              value={deviceCatalogue}
              status={status}
              totalPageCount={totalPageCount}
              pageCount={pageCount}
              reduxColumnFiltersSorting={tableFilterData}
              onFilterOrSortingChange={onFilterOrSortingChange}
              isFirstRender={isFirstRender}
              scrollData={handleScroll}
              totalDataCount={totalDataCount}
              rowSelection={rowSelection}
              setRowSelection={setRowSelection}
              columnVisibility={columnVisibility}
              setColumnVisibility={setColumnVisibility}
              enable={Object.keys(filter).filter((val) => val !== 'sortField' && val !== 'sortBy').length > 0}
              height={height.onlyTableHeight}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
DeviceCatalogue.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
  }),
};

DeviceCatalogue.defaultProps = {
  row: null,
};

export default DeviceCatalogue;
