function formatDate(date, format) {
  let value = '';
  if (format === 'time') {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours %= 12;
    hours = hours || 12; // the hour '0' should be '12'
    hours = hours < 10 ? `0${hours.toString()}` : hours;
    minutes = minutes < 10 ? `0${minutes.toString()}` : minutes;
    const strTime = `${hours} : ${minutes} ${ampm}`;
    value = strTime;
  } else {
    value = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  }
  return value;
}
const changeDateFormat = (date) => {
  const time = formatDate(new Date(date), 'date');
  return time;
};

const changeTimeFormat = (date) => {
  const time = formatDate(new Date(date), 'time');
  return time;
};

const changeDateTimeFormat = (date) => {
  const time = `${changeDateFormat(date)} ${changeTimeFormat(date)}`;
  return time;
};

export {
  changeTimeFormat,
  changeDateFormat,
  changeDateTimeFormat,
};
