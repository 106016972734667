import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchExpiredDeviceOrder,
  setTableFilterData,
  setFilter,
} from '../../../redux/slices/DeviceOrderExpiredSlice';
import subscription from '../../../utils/api/subscription';
import { changeDateFormat } from '../../Global/timeFormat';
import DataTable from '../../Table';
import exportCSV from '../../Global/exportCSV';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import PopupModel from '../../../Shared/Components/PopupModel';
import height from '../../../utils/size-variables';

function ExpiredDeviceTable() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    pageCount,
    totalPageCount,
    deviceOrder,
    status,
    filter,
    tableFilterData,
    totalDataCount,
  } = useSelector((state) => state.deviceOrderExpired);

  const [rowSelection, setRowSelection] = useState({});
  const [columnVisibility, setColumnVisibility] = useState({});
  const [isFirstRender, setIsFirstRender] = useState(true);

  const getDeviceRental = (counts, search) => {
    if (status === 'success') {
      dispatch(fetchExpiredDeviceOrder({ page: counts, ...search }));
    }
  };

  const handleScroll = () => {
    getDeviceRental(pageCount + 1, filter);
  };

  const columns = [
    {
      header: t('sNo'),
      size: 60,
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      Cell: ({ row }) => row.index + 1,
    },
    {
      header: t('account'),
      accessorKey: 'company_name',
      filterFn: 'commonFIlterFn',
      Cell: ({ row }) => row?.original?.company?.name,
    },
    {
      header: t('orderId'),
      accessorKey: 'order_id',
    },
    {
      header: t('deviceName'),
      accessorKey: 'device_name',
      filterFn: 'commonFIlterFn',
      Cell: ({ row }) => row?.original?.device_catalog?.name,
    },
    {
      header: t('rentalTenure'),
      enableColumnFilter: false,
      accessorKey: 'tenure',
      muiFilterTextFieldProps: { type: 'number', onWheel: (e) => e.target.blur() },
      Cell: ({ row }) => `${row?.original?.tenure} Months`,
    },
    {
      header: t('quantity'),
      accessorKey: 'quantity',
      filterFn: 'commonFIlterFn',
      Cell: ({ row }) => (
        <PopupModel
          list={row?.original?.deviceSerialNumber}
          name={row?.original?.deviceSerialNumber?.length}
          id={row?.original.id}
        />
      ),
    },
    {
      header: t('rentalDuration'),
      accessorKey: 'duration',
      enableColumnFilter: false,
      Cell: ({ row }) => (
        <div className="text-danger">{row?.original?.startDate} - {row?.original?.endDate}</div>
      ),
    },
  ];

  const exportData = () => {
    const columnHeaders = [
      'account',
      'order_id',
      'name',
      'type',
      'quantity',
      'from_date',
      'to_date',
    ];

    // Filter the hidden column
    const filteredHeaders = columnHeaders.filter((header) => {
      if (header === 'plan_name') {
        return columnVisibility.name !== false;
      }
      return columnVisibility[header] !== false;
    });

    // API call for export CSV
    subscription.exportSubscriptionData({ fields: filteredHeaders, ...filter, status: 'expired' })
      .then((res) => {
        const rows = res?.data?.map((row) => ({
          ...row,
          start_date: row?.start_date ? changeDateFormat(row?.start_date) : undefined,
          end_date: row?.end_date ? changeDateFormat(row?.end_date) : undefined,
        }));

        // Function to export as CSV file
        exportCSV(Object.keys(rows?.[0]), rows);
      })
      .catch((err) => {
        dispatch(setErrorNotification(err?.response?.data));
      });
  };

  const onFilterOrSortingChange = (filteredValues, reduxColumnFiltersSorting) => {
    dispatch(setTableFilterData(reduxColumnFiltersSorting));
    dispatch(setFilter(filteredValues));
    getDeviceRental(1, filteredValues);
  };
  useEffect(() => {
    setIsFirstRender(false);
    if (pageCount === 0 && Object.keys(tableFilterData).length === 0) {
      getDeviceRental(1, {});
    }
  }, []);

  return (
    <div className="content-area">
      <div className="my-content">
        <div className="my-content-area">
          <DataTable
            header={columns}
            value={deviceOrder}
            status={status}
            totalPageCount={totalPageCount}
            pageCount={pageCount}
            scrollData={handleScroll}
            reduxColumnFiltersSorting={tableFilterData}
            onFilterOrSortingChange={onFilterOrSortingChange}
            isFirstRender={isFirstRender}
            totalDataCount={totalDataCount}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            columnVisibility={columnVisibility}
            setColumnVisibility={setColumnVisibility}
            enable={Object.keys(filter).filter((val) => val !== 'sortField' && val !== 'sortBy').length > 0}
            height={height.tableHeight}
            exportButton={false}
            exportData={exportData}
          />
        </div>
      </div>
    </div>
  );
}
ExpiredDeviceTable.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      deviceSerialNumber: PropTypes.arrayOf.isRequired,
      startDate: PropTypes.string.isRequired,
      endDate: PropTypes.string.isRequired,
    }).isRequired,
  }),
};

ExpiredDeviceTable.defaultProps = {
  row: null,
};
export default ExpiredDeviceTable;
