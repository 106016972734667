import React from 'react';
import PropTypes from 'prop-types';

function Button(props) {
  const {
    classes,
    click,
    disabled,
    loading,
    fontColor,
    buttonColor,
    buttonHoverColor,
  } = props;
  const empty = false;
  return (
    <div className="btn">
      <button
        type="button"
        className={classes}
        onClick={click}
        disabled={disabled}
        style={{
          cursor: loading ? 'not-allowed' : 'pointer',
          '--font-color': fontColor,
          '--button-color': buttonColor,
          '--button-hover-color': buttonHoverColor,
        }}
      >
        <span>{props?.label}</span>
        {props?.loading && (
          <div className="loading">
            <div className="dot">{empty && <p>dot</p>}</div>
            <div className="dot">{empty && <p>dot</p>}</div>
            <div className="dot">{empty && <p>dot</p>}</div>
          </div>
        )}
      </button>
    </div>
  );
}

Button.propTypes = {
  classes: PropTypes.string.isRequired,
  click: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  fontColor: PropTypes.string.isRequired,
  buttonColor: PropTypes.string.isRequired,
  buttonHoverColor: PropTypes.string.isRequired,
};
Button.defaultProps = {
  disabled: false,
  loading: false,
};

export default Button;
