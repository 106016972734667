import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { createTheme, ThemeProvider } from '@mui/material';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import cookies from 'js-cookie';
import Router from './Router';

import '../../scss/app.scss';

function App() {
  const currentLanguageCode = cookies.get('i18next') || 'en';

  const theme = createTheme({
    direction: currentLanguageCode === 'ar' ? 'rtl' : 'ltr',
  });

  // Create rtl cache
  const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
  });

  const cacheltr = createCache({
    key: 'muiltr',
  });

  useEffect(() => {
    document.body.dir = currentLanguageCode === 'ar' ? 'rtl' : 'ltr';
  }, [currentLanguageCode]);

  return (
    <div className="App">
      <CacheProvider value={currentLanguageCode === 'ar' ? cacheRtl : cacheltr}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </ThemeProvider>
      </CacheProvider>
    </div>
  );
}

export default App;
