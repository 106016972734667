/* eslint-disable no-param-reassign */
/* eslint no-underscore-dangle: 0 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import feature from '../../utils/api/feature';
import { setErrorNotification } from './NotificationSlice';

export const fetchFeatureSubFeature = createAsyncThunk(
  'feature/fetchFeatureSubFeature',
  async (data, { dispatch }) => {
    const response = await feature.getFeatureSubFeatureList()
      .then((res) => ({ data: res?.data, code: 200 }))
      .catch((error) => dispatch(setErrorNotification(error?.response?.data)));
    return response;
  },
);

export const fetchFeatureController = createAsyncThunk(
  'feature/fetchFeatureController',
  async (data, { dispatch }) => {
    const response = await feature.getFeatureControllerList()
      .then((res) => ({ data: res?.data, code: 200 }))
      .catch((error) => dispatch(setErrorNotification(error?.response?.data)));
    return response;
  },
);

const initialState = {
  featureList: [],
  featureController: [],
  status: '',
};

export const featureControllerSlice = createSlice({
  name: 'feature',
  initialState,
  reducers: {
    resetFeatureState: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFeatureSubFeature.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchFeatureSubFeature.fulfilled, (state, action) => {
        state.status = 'success';
        if (action?.payload?.code === 200) {
          state.featureList = action?.payload?.data;
        }
      });
    builder
      .addCase(fetchFeatureController.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchFeatureController.fulfilled, (state, action) => {
        state.status = 'success';
        if (action?.payload?.code === 200) {
          state.featureController = action?.payload?.data;
        }
      });
  },
});

export const {
  resetFeatureState,
} = featureControllerSlice.actions;

export default featureControllerSlice.reducer;
