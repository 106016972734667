/* eslint no-underscore-dangle: 0 */
/* eslint-disable no-nested-ternary */

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import TopContent from '../../../Shared/Components/TopContent';
import { Input, InputInnerSymbol } from '../../../Shared/Components/Form/Input';

import plansApi from '../../../utils/api/plans';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import { checkPermissionAllow } from '../../../utils/helpers';

function PlansView() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currencies = [
    {
      currency: 'SAR',
      symbol: '﷼',
    },
    {
      currency: 'USD',
      symbol: '$',
    },
    {
      currency: 'EUR',
      symbol: '€',
    },
    {
      currency: 'INR',
      symbol: '₹',
    },
  ];

  const navigate = useNavigate();
  const params = useParams();
  const { plans } = useSelector((state) => state.plans);

  const [value, setValue] = useState(plans?.find((plan) => plan.id === params?.id));

  const clickFunction = () => {
    navigate(`../plans-edit/${params?.id}`);
  };

  const fetchPlanDetails = async () => {
    await plansApi.getSinglePlan(params?.id)
      .then((response) => {
        const item = response?.data;
        console.log(item);
        setValue({
          ...item,
          id: item?._id,
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch(setErrorNotification(err?.response?.data));
      });
  };

  useEffect(() => {
    if (!value) {
      fetchPlanDetails();
    }
  }, [params]);

  return (
    <div className="main-container">
      <TopContent
        label={[t('plansAndPricing'), value?.name]}
        click={clickFunction}
        button={checkPermissionAllow('editPlan')}
        buttonLabel={t('editPlan')}
        buttonClass="default-button"
      />
      <div className="plans-form-wrap">
        <div className="plans-details">
          <div className="single-row">
            <Input
              label={t('product')}
              name="product"
              readonly
              value={value?.product}
              id="select-plans"
              placeholder={t('selectProduct')}
            />
            <Input
              label={t('planType')}
              name="plan_type"
              readonly
              value={value?.plan_type}
              id="select-plans"
              placeholder={t('enterPlanType')}
            />
          </div>
          <div className="single-row">
            {value?.description
              && (
                <Input
                  label={t('description')}
                  type="text"
                  name="description"
                  value={value?.description}
                  placeholder={t('enterDescription')}
                  readonly
                />
              )}
            <Input
              label={t('planName')}
              type="text"
              name="plan_name"
              readonly
              value={value?.name}
              placeholder={t('enterPlanName')}
            />
          </div>
        </div>
        <div className="features-pricing">
          <div className="features">
            <h1>{t('features')}</h1>
            {value?.features?.map((feature, index) => (
              <div className="each-feature">
                <Input
                  label=""
                  type="text"
                  name={index}
                  value={feature}
                  placeholder={t('enterPlanFeature')}
                  readonly
                />
              </div>
            ))}
          </div>
          <div className="pricing">
            <table className="device">
              <tr>
                <th>{t('deviceDuration')}</th>
                {
                  value?.price_per_device?.[0]?.price_in_months?.map((val) => (
                    <td
                      className={val?.month === 12 && 'last-data'}
                    >
                      {val.month} {val.month > 1 ? t('months') : t('month')}
                    </td>
                  ))
                }
              </tr>
              {
                value?.price_per_device.map((item, index) => (
                  <tr>
                    <th
                      className={index === value.price_per_device.length - 1 && 'bottom-data'}
                    >
                      {
                        currencies?.map((currency) => (
                          currency.currency === item.currency
                          && (
                            <span>
                              {currency.symbol}&nbsp;
                            </span>
                          )
                        ))
                      }
                      {item.currency}
                    </th>
                    {item?.price_in_months?.map((month) => (
                      <td
                        label="table-data"
                        className={
                          month?.month === 12
                            ? 'last-data'
                            : index === value.price_per_device.length - 1
                              ? 'bottom-data'
                              : index === value.price_per_device.length - 1 && month?.month === 12
                                ? 'last-data bottom-data'
                                : ''
                        }
                      >
                        <div className="input-container">
                          <InputInnerSymbol
                            type="number"
                            name={item?.currency}
                            index={month.month}
                            value={month.price}
                            readonly
                            symbol={currencies.find((each) => each.currency === item.currency)?.symbol}
                          />
                        </div>
                      </td>
                    ))}
                  </tr>
                ))
              }
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlansView;
