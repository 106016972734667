import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Button from './Button';

import arrowDown from '../../assets/icons/arrow-down.png';

function TopContent(props) {
  const clickFunction = () => {
    props?.click();
  };

  return (
    <div className="top-content">
      <div className="top-main">
        <h1 className="top-label">
          {Array.isArray(props?.label)
            ? (
              <TopTitle
                title={props?.label}
                labelClickFunc={props?.labelClickFunc}
                customLabelClickFunc={props?.customLabelClickFunc}
              />
            )
            : props?.label}
        </h1>
        {
          props?.button && (
            <div className="top-button">
              <Button
                label={props?.buttonLabel}
                click={clickFunction}
                classes={props?.buttonClass}
              />
            </div>
          )
        }
      </div>
    </div>
  );
}

function TopTitle(props) {
  const navigate = useNavigate();
  const navigateBack = () => {
    if (props?.customLabelClickFunc) {
      props?.labelClickFunc();
    } else {
      navigate('../');
    }
  };
  return (
    <div className="title-text">
      {props?.title[0] !== undefined
        && <span role="presentation" onClick={navigateBack}>{props?.title[0]}</span>}
      {props?.title[1] !== undefined && <img src={arrowDown} alt="sort" />}
      {props?.title[1] !== undefined && props?.title[1]}
    </div>
  );
}

TopContent.propTypes = {
  click: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  button: PropTypes.bool.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  buttonClass: PropTypes.string.isRequired,
  labelClickFunc: PropTypes.func,
  customLabelClickFunc: PropTypes.bool,
};

TopContent.defaultProps = {
  labelClickFunc: () => { },
  customLabelClickFunc: false,
};

TopTitle.propTypes = {
  title: PropTypes.arrayOf().isRequired,
  labelClickFunc: PropTypes.func,
  customLabelClickFunc: PropTypes.bool,
};

TopTitle.defaultProps = {
  labelClickFunc: () => { },
  customLabelClickFunc: false,
};

export default TopContent;
