import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';

import { fetchPlansWithoutTrail } from '../../../redux/slices/PlanSlice';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import { fetchActiveSubscription } from '../../../redux/slices/ActiveSubscriptionSlice';

import Button from '../../../Shared/Components/Button';
import ModalBox from '../../../Shared/Components/ModalBox';
import Success from '../../../Shared/Components/Success';

import subscription from '../../../utils/api/subscription';

function PlanSwitch(props) {
  const { t } = useTranslation();
  const {
    singleSubscription,
    setOpen,
  } = props;
  const dispatch = useDispatch();
  const { plansWithoutTrail } = useSelector((state) => state.plans);
  const headerContent = [
    {
      label: t('orderID:'),
      value: singleSubscription.order_id,
    },
    {
      label: t('account:'),
      value: singleSubscription.company_name,
    },
  ];

  const [selectPlan, setSelectedPlan] = useState({});
  const [paymentType, setPaymentType] = useState(false);
  const [buttonState, setButtonState] = useState('disable');

  const handlePlanSwitch = (event) => {
    const selectedId = event.target.value;
    setSelectedPlan({
      id: selectedId,
      name: plansWithoutTrail.find((plan) => plan.id === selectedId).name,
    });
  };

  const submitPlanSwitch = () => {
    setButtonState('loading');
    const planSwitchData = {
      id: singleSubscription.id,
      plan: selectPlan.id,
      create_payment_link: paymentType,
    };
    subscription.editSubscriptionPlan(planSwitchData)
      .then(() => {
        setButtonState('success');
        setTimeout(() => {
          setOpen(false);
          setButtonState('disable');
          dispatch(fetchActiveSubscription({ page: 1 }));
        }, 3000);
      })
      .catch((err) => {
        setButtonState('enable');
        dispatch(setErrorNotification(err?.response?.data));
      });
  };

  const paymentOptionChange = (event) => {
    setPaymentType(event.target.value === 'online');
  };

  useEffect(() => {
    if (selectPlan.id) {
      setButtonState('enable');
    } else {
      setButtonState('disable');
    }
  }, [selectPlan]);

  useEffect(() => {
    if (plansWithoutTrail.length === 0) {
      dispatch(fetchPlansWithoutTrail());
    }
  }, []);

  return (
    <div className="popup-wrap">
      <ModalBox
        status={buttonState === 'success'}
        setOpen={setButtonState}
        modalView={{ content: <Success message={t('planEdited')} /> }}
        closeModal={() => {}}
        notification
      />
      <div className="popup-header">
        {headerContent.map((content) => (
          <div className="header-details">
            <h2>{content.label}</h2>
            <p>{content.value}</p>
          </div>
        ))}
      </div>
      <div className="popup-container">
        <div className="plan-wrap">
          <div className="current-plan">
            <span>{singleSubscription.plan.name}</span>
            <span>{t('current')}</span>
          </div>
          <div className="available-plans">
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              value={selectPlan?.name}
              onChange={handlePlanSwitch}
              name="radio-buttons-group"
            >
              {plansWithoutTrail.map((plan) => {
                let radioButton;
                if (plan.id !== singleSubscription.subscription.plan) {
                  radioButton = <FormControlLabel value={plan.id} control={<Radio />} label={plan.name} />;
                }
                return radioButton;
              })}
            </RadioGroup>
          </div>
          <h1 className="payment-label">{t('paymentMode')}</h1>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            onChange={paymentOptionChange}
            defaultValue="offline"
          >
            <FormControlLabel
              value="online"
              control={<Radio />}
              label={t('online')}
              disabled
              sx={{
                '& .MuiTypography-root': {
                  fontFamily: 'Poppins',
                },
              }}
            />
            <FormControlLabel
              value="offline"
              control={<Radio />}
              label={t('offline')}
              sx={{
                '& .MuiTypography-root': {
                  fontFamily: 'Poppins',
                },
              }}
            />
          </RadioGroup>
          {
            paymentType && (
              <p className="payment-message">{t('onlinePaymentMessage')}</p>
            )
          }
        </div>
        <div className="plan-btn-wrap">
          <Button
            label={t('back')}
            classes="default-button"
            click={() => setOpen(false)}
          />
          <Button
            label={t('updatePlan')}
            loading={buttonState === 'loading'}
            classes={
              buttonState === 'loading'
                ? 'success-button loading-btn'
                : 'success-button'
            }
            click={submitPlanSwitch}
            disabled={buttonState === 'disable'}
          />
        </div>
      </div>
    </div>
  );
}

PlanSwitch.propTypes = {
  singleSubscription: PropTypes.arrayOf.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default PlanSwitch;
