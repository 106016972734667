import {
  post,
  get,
  deletes,
  patch,
} from './base/index';

export default {
  getCompanies: (data = {}) => {
    const params = { ...data };
    return get(`${process.env.REACT_APP_API_URL}admin/v1/companies`, { params });
  },
  getIndirectCompanies: (data = {}) => {
    const params = { ...data };
    return get(`${process.env.REACT_APP_API_URL}admin/v1/companies/indirectCompanyList`, { params });
  },
  getDistributorCompanies: (data = {}) => {
    const params = { ...data };
    return get(`${process.env.REACT_APP_API_URL}admin/v1/companies/distributorList`, { params });
  },
  getServerCompanies: (data = {}) => {
    const params = { ...data };
    console.log(data, 'DATA');
    return get(`${process.env.REACT_APP_API_URL}admin/v1/companies/cms-server-company-list`, { params });
  },
  getCompaniesAll: (data = {}) => {
    const params = { ...data };
    return get(`${process.env.REACT_APP_API_URL}admin/v1/companies/companyList`, { params });
  },
  getSingleCompany: (data) => get(`${process.env.REACT_APP_API_URL}admin/v1/companies/${data}`),
  addCompanies: (data = {}) => {
    const body = { ...data };
    return post(`${process.env.REACT_APP_API_URL}admin/v1/companies`, body, true);
  },
  companyUpdate: (id, data = {}) => patch(`${process.env.REACT_APP_API_URL}admin/v1/companies/${id}`, data, true),
  deleteCompany: (data) => deletes(`${process.env.REACT_APP_API_URL}admin/v1/companies/${data}`, {}),
  exportCompanyData: (data) => post(`${process.env.REACT_APP_API_URL}admin/v1/companies/export`, { ...data }),
  exportIndirectCompany: (data) => post(
    `${process.env.REACT_APP_API_URL}admin/v1/companies/exportIndirectCompany`,
    { ...data },
  ),
  exportDistributors: (data) => post(
    `${process.env.REACT_APP_API_URL}admin/v1/companies/exportDistributorList`,
    { ...data },
  ),
};
