/* eslint no-underscore-dangle: 0 */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchDistributorList,
  setTableFilterData,
  setFilter,
  updateDistributorDetails,
} from '../../../redux/slices/DistributorSlice';
import DataTable from '../../Table';
import DeleteModal from '../../../Shared/Components/DeleteModal';
import TopContent from '../../../Shared/Components/TopContent';
// import ModalBox from '../../../Shared/Components/ModalBox';
// import NewContent from './NewCompany';
import companyApi from '../../../utils/api/company';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import DropDown from '../../../Shared/Components/DropDown';
import exportCSV from '../../Global/exportCSV';

import moreMenusIcon from '../../../assets/icons/more.png';
import { changeDateTimeFormat } from '../../Global/timeFormat';
import { checkPermissionAllow } from '../../../utils/helpers';
import height from '../../../utils/size-variables';
import { Switch } from '../../../Shared/Components/Form/Input';

function DistributorTable() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    distributors,
    pageCount,
    status,
    filter,
    tableFilterData,
    totalDataCount,
    totalPageCount,
  } = useSelector((state) => state.distributor);

  const [rowSelection, setRowSelection] = useState({});
  const [columnVisibility, setColumnVisibility] = useState({});

  const [companyId, setCompanyId] = useState(false);
  const [deleteModel, setDeleteModel] = useState('');
  const [isFirstRender, setIsFirstRender] = useState(true);

  const getDistributorList = (counts, search) => {
    if (status === 'success') {
      dispatch(fetchDistributorList({ page: counts, ...search }));
    }
  };

  const handleScroll = () => {
    getDistributorList(pageCount + 1, filter);
  };

  const deleteFunction = (id) => {
    setCompanyId(id);
    setDeleteModel(true);
  };

  const deleteData = () => {
    companyApi.deleteCompany(companyId).then(() => {
      setCompanyId(' ');
      getDistributorList(1, {});
      setDeleteModel(false);
    }).catch((err) => {
      dispatch(setErrorNotification(err?.response?.data));
    });
  };

  const viewFunction = (id) => {
    navigate(`../company-view/${id}`);
  };

  const handleSwitch = async (data) => {
    const updateBody = { two_factor: data.two_factor ? !data.two_factor : true };
    await companyApi.companyUpdate(data._id, updateBody).then((res) => {
      dispatch(updateDistributorDetails(res?.data));
    }).catch((err) => {
      setErrorNotification(err?.response?.data);
    });
  };
  const actions = [
    {
      name: t('view'),
      url: '',
      function: viewFunction,
      key: 'view',
      enable: checkPermissionAllow('editCompany'),
    },
    {
      name: t('delete'),
      url: '',
      function: deleteFunction,
      key: 'delete',
      enable: checkPermissionAllow('deleteCompany'),
    },
  ];

  const columns = [
    {
      header: t('companyName'),
      accessorKey: 'name',
      Cell: ({ row }) => (
        <div className="content-thumbnail">
          <span className="content_name">
            {row.original.name}
          </span>
        </div>
      ),
    },
    {
      header: t('address'),
      accessorKey: 'address',
    },
    {
      header: t('cityAndState'),
      accessorKey: 'city',
      filterFn: 'commonFIlterFn',
      Cell: ({ row }) => row.original.city_state,
    },
    {
      header: t('country'),
      accessorKey: 'country',
    },
    {
      header: t('account_type'),
      accessorKey: 'account_type',
      Cell: ({ row }) => (
        <div className={`${row.original.account_type}-tag`}>
          {row.original.account_type}
        </div>
      ),
      enableColumnFilter: false,
      enableSorting: false,
    },
    {
      header: t('2FA'),
      accessorKey: 'two_factor',
      Cell: ({ row }) => (
        <Switch
          type="checkbox"
          name="enable-device-sync"
          change={() => handleSwitch(row.original)}
          checked={row.original.two_factor}
          id={`toggle-checkbox-${row?.index}`}
        />
      ),
      enableColumnFilter: false,
      enableSorting: false,
    },
    {
      header: t('createdDate'),
      accessorKey: 'createdAt',
      enableColumnFilter: false,
    },
    {
      header: '',
      accessorKey: 'id',
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      enableHiding: true,
      filterVariant: 'none',
      size: 50,
      Cell: ({ row }) => (
        <DropDown
          action={actions}
          icon={moreMenusIcon}
          value={row?.original}
          dropdown="tableForm"
          height="15px"
          tooltip=""
        />
      ),
    },
  ];

  const exportData = () => {
    const columnHeaders = ['name', 'createdAt', 'address', 'city', 'state', 'country'];

    // Filter the hidden column
    const filteredHeaders = columnHeaders.filter((header) => {
      if (header === 'city' || header === 'state') {
        return columnVisibility.city !== false;
      }
      return columnVisibility[header] !== false;
    });

    // API call for export CSV
    companyApi.exportDistributors({ fields: filteredHeaders, ...filter })
      .then((res) => {
        const rows = res?.data?.map((row) => ({
          ...row,
          createdAt: row?.createdAt ? changeDateTimeFormat(row?.createdAt) : undefined,
        }));

        // Loop through each object in the array and remove undefined keys
        const updatedResults = rows.map((obj) => {
          const newObj = {};
          Object.keys(obj).forEach((key) => {
            if (obj[key] !== undefined) {
              newObj[key] = obj[key];
            }
          });
          return newObj;
        });

        // Function to export as CSV file
        exportCSV(Object.keys(updatedResults?.[0]), updatedResults);
      })
      .catch((err) => {
        dispatch(setErrorNotification(err?.response?.data));
      });
  };

  const onFilterOrSortingChange = (filteredValues, reduxColumnFiltersSorting) => {
    dispatch(setTableFilterData(reduxColumnFiltersSorting));
    dispatch(setFilter(filteredValues));
    getDistributorList(1, filteredValues);
  };
  useEffect(() => {
    setIsFirstRender(false);
    if (pageCount === 0 && Object.keys(tableFilterData).length === 0) {
      getDistributorList(1, {});
    }
  }, []);

  return (
    <div className="main-container">
      <TopContent
        label={t('distributor')}
        buttonClass="success-button"
        button={checkPermissionAllow('addCmsCompany') || checkPermissionAllow('addDistributorCompany')}
        buttonLabel={t('newAccount')}
        click={() => navigate('../company-add/distributor')}
      />
      <div className="content-area">
        <div className="my-content">
          <div className="my-content-area">
            <div className="content-area">
              <DeleteModal
                title={t('confirmDeleteDistributor')}
                buttonLabel={t('delete')}
                status={deleteModel}
                setOpen={setDeleteModel}
                okFunction={deleteData}
              />
              <div className="my-content">
                <div className="my-content-area">
                  <DataTable
                    header={columns}
                    value={distributors}
                    status={status}
                    totalPageCount={totalPageCount}
                    pageCount={pageCount}
                    scrollData={handleScroll}
                    reduxColumnFiltersSorting={tableFilterData}
                    onFilterOrSortingChange={onFilterOrSortingChange}
                    isFirstRender={isFirstRender}
                    totalDataCount={totalDataCount}
                    rowSelection={rowSelection}
                    setRowSelection={setRowSelection}
                    columnVisibility={columnVisibility}
                    setColumnVisibility={setColumnVisibility}
                    enable={Object.keys(filter).filter((val) => val !== 'sortField' && val !== 'sortBy').length > 0}
                    height={height.tableHeight}
                    exportButton
                    exportData={exportData}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
DistributorTable.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      name: PropTypes.string.isRequired,
      account_type: PropTypes.string.isRequired,
      _id: PropTypes.string,
      two_factor: PropTypes.bool,
    }).isRequired,
    index: PropTypes.number,
  }).isRequired,
};

export default DistributorTable;
