import React, { useState, useEffect } from 'react';
import {
  Route,
  Routes,
  Outlet,
  Navigate,
  useLocation,
} from 'react-router-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
// Login
import Login from '../Login';
import ForgotPassword from '../ForgotPassword';
import ResetPassword from '../ResetPassword';
// Shared
import SideBar from '../../Shared/Components/SideBar';
import Header from '../../Shared/Components/Header';
import Notification from '../../Shared/Components/Notification';
// Dashboard
import Dashboard from '../Dashboard';
// Company
import Company from '../Company';
import CompanyView from '../Company/Components/CompanyView';
import CompanyAdd from '../Company/Components/CompanyAdd';
import CompanyEdit from '../Company/Components/CompanyEdit';
// Roles
import Roles from '../Roles';
import RolesAdd from '../Roles/Components/RolesAdd';
import RolesView from '../Roles/Components/RoleView';
import RolesEdit from '../Roles/Components/RolesEdit';
// Users
import Users from '../Users';
import UserView from '../Users/Components/UserView';
import UserEdit from '../Users/Components/UserEdit';
import UserAdd from '../Users/Components/UserAdd';
// Profile
import Profile from '../Profile';
import ProfileEdit from '../Profile/Components/ProfileEdit';
// Device Catalogue edit
import DeviceCatalogue from '../DeviceCatalogue';
import DeviceCatalogueAdd from '../DeviceCatalogue/Components/DeviceCatalogueAdd';
import DeviceCatalogueView from '../DeviceCatalogue/Components/DeviceCatalogueView';
import DeviceCatalogueEdit from '../DeviceCatalogue/Components/DeviceCatalogueEdit';
// Plans and Pricing
import Plans from '../Plans';
import PlansAdd from '../Plans/Components/PlansAdd';
import PlansView from '../Plans/Components/PlansView';
import PlansEdit from '../Plans/Components/PlansEdit';
// License Plans and Pricing
import LicensePlans from '../LicensePlan';
import LicensePlansAdd from '../LicensePlan/Components/PlansAdd';
import LicensePlansEdit from '../LicensePlan/Components/PlansEdit';
// License
import License from '../License';
import LicenseAdd from '../License/Components/LicenseAdd';
// License Transaction
import LicenseTransaction from '../LicenseTransaction';
import LicenseTransactionSummary from '../LicenseTransaction/Components/LicensePaid';
import LicensePending from '../LicenseTransaction/Components/LicensePending';
import LicenseHistory from '../LicenseTransaction/Components/LicenseTransactionSummary';
import LicensePaymentContent from '../LicenseTransaction/Components/LicensePaymentContent';
import LicensePaymentInfo from '../LicenseTransaction/Components/LicensePaymentInfo';
// License Feature Controller
import LicenseFeatureController from '../LicenseFeatureController';
// Subscription
import Subscription from '../Subscription';
import SubscriptionAdd from '../Subscription/Components/SubscriptionAdd';
import Summary from '../Subscription/Components/Summary';
import UpdatePayment from '../Subscription/Components/UpdatePayment';
import SubscriptionEdit from '../Subscription/Components/SubscriptionEdit';
// Addons
import Addons from '../Addon';
import AddonsAdd from '../Addon/Components/AddonsAdd';
import AddonsView from '../Addon/Components/AddonsView';
// Feature Management
import FeatureMaster from '../FeatureMaster';
import FeatureController from '../FeatureController';
// Device Order
import DeviceOrder from '../DeviceOrder';
import DeviceOrderAdd from '../DeviceOrder/Components/DeviceOrderAdd';
import DeviceOrderSummary from '../DeviceOrder/Components/Summary';
import DeviceOrderEditSerialNo from '../DeviceOrder/Components/DeviceOrderEditSerialNo';
import DeviceRental from '../DeviceRental';
import DeviceOrderView from '../DeviceOrder/Components/DeviceOrderView';
// Player Upload
import PlayerUpload from '../PlayerAppManagement';
// Transactions
import Transaction from '../Transactions';
import TransactionSummary from '../Transactions/Components/TransactionSummary';
import SaaSPending from '../Transactions/Components/SaasPending';
import RentalPending from '../Transactions/Components/RentalPending';
import PaymentSummary from '../Transactions/Components/PaymentSummary';
import PaymentInfo from '../Transactions/Components/PaymentInfo';
import SaasRentalPending from '../Transactions/Components/SaasRentalPending';
import Contacts from '../Contacts';
import NotFoundPage from './NotFoundPage';
import AuthGuard from './AuthGuard';
import { checkPermissionAllow } from '../../utils/helpers';
import DistributorTable from '../Company/Components/DistributorTable';
import ServerTable from '../CmsServer';

function NavigatePermission({ permission }) {
  let output = '';
  permission.forEach((element) => {
    if (checkPermissionAllow(element.permission) && output === '') {
      output = <Navigate to={element.route} />;
    }
  });
  return output;
}

const routeData = [
  {
    path: 'dashboard',
    children: [
      {
        path: '',
        permission: ['viewDashboard'],
        component: <Dashboard />,
      },
    ],
  },
  {
    path: 'profile',
    children: [
      {
        path: '',
        permission: [],
        component: <Profile />,
      },
      {
        path: 'edit',
        permission: [],
        component: <ProfileEdit />,
      },
    ],
  },
  {
    path: 'company',
    children: [
      {
        path: '',
        permission: ['getCompany', 'getDirectCompany', 'getIndirectCompany', 'getDistributors'],
        component: (
          <NavigatePermission
            permission={[
              {
                permission: 'getDirectCompany',
                route: 'direct',
              },
              {
                permission: 'getIndirectCompany',
                route: 'indirect',
              },
              {
                permission: 'getDistributors',
                route: 'distributor',
              },
              {
                permission: 'getDistributors',
                route: 'cmsServer',
              },
            ]}
          />
        ),
      },
      {
        path: 'distributor',
        permission: ['getDistributors'],
        component: <DistributorTable />,
      },
      {
        path: ':page',
        permission: ['getDirectCompany', 'getIndirectCompany'],
        component: <Company />,
      },
      {
        path: 'cmsServer/:page',
        permission: ['getServerCompany'],
        component: <ServerTable />,
      },
      {
        path: 'company-add/:account',
        permission: ['addCmsCompany', 'addDistributorCompany'],
        component: <CompanyAdd />,
      },
      {
        path: 'company-view/:id',
        permission: ['getCompany'],
        component: <CompanyView />,
      },
      {
        path: 'company-edit/:id',
        permission: ['editCompany'],
        component: <CompanyEdit />,
      },
    ],
  },
  {
    path: 'roles',
    children: [
      {
        path: '',
        permission: ['getRole'],
        component: <Roles />,
      },
      {
        path: 'roles-add',
        permission: ['addRole'],
        component: <RolesAdd />,
      },
      {
        path: 'roles-view/:id',
        permission: ['getRole'],
        component: <RolesView />,
      },
      {
        path: 'roles-edit/:id',
        permission: ['editRole'],
        component: <RolesEdit />,
      },
    ],
  },
  {
    path: 'users',
    children: [
      {
        path: '',
        permission: ['getUsers'],
        component: <Users />,
      },
      {
        path: 'user-add',
        permission: ['addUser'],
        component: <UserAdd />,
      },
      {
        path: 'user-view/:id',
        permission: ['getUsers'],
        component: <UserView />,
      },
      {
        path: 'user-edit/:id',
        permission: ['editUser'],
        component: <UserEdit />,
      },
    ],
  },
  {
    path: 'device-catalogue',
    children: [
      {
        path: '',
        permission: ['getDeviceCatalogue'],
        component: <DeviceCatalogue />,
      },
      {
        path: 'device-add',
        permission: ['addDeviceCatalogue'],
        component: <DeviceCatalogueAdd />,
      },
      {
        path: 'device-view/:id',
        permission: ['getDeviceCatalogue'],
        component: <DeviceCatalogueView />,
      },
      {
        path: 'device-edit/:id',
        permission: ['editDeviceCatalogue'],
        component: <DeviceCatalogueEdit />,
      },
    ],
  },
  {
    path: 'plans',
    children: [
      {
        path: '',
        permission: ['getPlan'],
        component: <Plans />,
      },
      {
        path: 'plans-add',
        permission: ['addPlan'],
        component: <PlansAdd />,
      },
      {
        path: 'plans-view/:id',
        permission: ['getPlan'],
        component: <PlansView />,
      },
      {
        path: 'plans-edit/:id',
        permission: ['editPlan'],
        component: <PlansEdit />,
      },
    ],
  },
  {
    path: 'subscription',
    children: [
      {
        path: '',
        permission: ['getSubscription'],
        component: (
          <NavigatePermission
            permission={[
              {
                permission: 'getActiveSubscription',
                route: 'active',
              },
              {
                permission: 'getPendingSubscription',
                route: 'pending',
              },
              {
                permission: 'getUpcomingSubscription',
                route: 'upcoming',
              },
              {
                permission: 'getExpiredSubscription',
                route: 'expired',
              },
              {
                permission: 'getSuspendedSubscription',
                route: 'suspended',
              },
              {
                permission: 'getCancelledSubscription',
                route: 'cancelled',
              },
            ]}
          />
        ),
      },
      {
        path: ':page',
        permission: ['getSubscription'],
        component: <Subscription />,
      },
      {
        path: 'add',
        permission: ['addSubscription'],
        component: <SubscriptionAdd />,
      },
      {
        path: 'summary',
        permission: ['addSubscription'],
        component: <Summary />,
      },
      {
        path: 'payment/:id',
        permission: ['getSubscription'],
        component: <UpdatePayment />,
      },
      {
        path: 'edit/:id',
        permission: [],
        component: <SubscriptionEdit />,
      },
    ],
  },
  {
    path: 'payment',
    children: [
      {
        path: 'summary',
        permission: ['getSubscription'],
        component: <PaymentSummary />,
      },
      {
        path: 'info',
        permission: ['getSubscription'],
        component: <PaymentInfo />,
      },
    ],
  },
  {
    path: 'device-order',
    children: [
      {
        path: '',
        permission: ['getDeviceOrder'],
        component: (
          <NavigatePermission
            permission={[
              {
                permission: 'processedGetDeviceOrder',
                route: 'processed',
              },
              {
                permission: 'pendingGetDeviceOrder',
                route: 'pending',
              },
              {
                permission: 'fulfillmentGetDeviceOrder',
                route: 'fulfillment',
              },
            ]}
          />
        ),
      },
      {
        path: ':page',
        permission: ['getDeviceOrder'],
        component: <DeviceOrder />,
      },
      {
        path: 'add',
        permission: ['addDeviceOrder'],
        component: <DeviceOrderAdd />,
      },
      {
        path: 'summary',
        permission: ['addDeviceOrder'],
        component: <DeviceOrderSummary />,
      },
      {
        path: 'view/:id',
        permission: ['getDeviceOrder'],
        component: <DeviceOrderView />,
      },
      {
        path: 'edit-serial-no/:id',
        permission: ['setSerialNoDeviceOrder'],
        component: <DeviceOrderEditSerialNo />,
      },
    ],
  },
  {
    path: 'device-rental',
    children: [
      {
        path: '',
        permission: ['getDeviceRental'],
        component: (
          <NavigatePermission
            permission={[
              {
                permission: 'getActiveDeviceRental',
                route: 'active',
              },
              {
                permission: 'getExpiredDeviceRental',
                route: 'expired',
              },
            ]}
          />
        ),
      },
      {
        path: ':page',
        permission: ['getDeviceRental'],
        component: <DeviceRental />,
      },
    ],
  },
  {
    path: 'addons',
    children: [
      {
        path: '',
        permission: ['getAddons'],
        component: <Addons />,
      },
      {
        path: 'add',
        permission: ['addAddons'],
        component: <AddonsAdd />,
      },
      {
        path: 'view/:id',
        permission: ['getAddons'],
        component: <AddonsView />,
      },
    ],
  },
  {
    path: 'feature-master',
    children: [
      {
        path: '',
        permission: ['getFeatureController'],
        component: <FeatureMaster />,
      },
    ],
  },
  {
    path: 'feature-controller',
    children: [
      {
        path: '',
        permission: ['getFeatureController'],
        component: <FeatureController />,
      },
    ],
  },
  {
    path: 'player-app-upload',
    children: [
      {
        path: '',
        permission: ['getPlayAppManager'],
        component: <PlayerUpload />,
      },
    ],
  },
  {
    path: 'contacts',
    children: [
      {
        path: '',
        permission: ['getContacts'],
        component: <Contacts />,
      },
    ],
  },
  {
    path: 'licenseplan',
    children: [
      {
        path: '',
        permission: ['getPlan'],
        component: <LicensePlans />,
      },
      {
        path: 'licenseplan-add',
        permission: ['addPlan'],
        component: <LicensePlansAdd />,
      },
      // {
      //   path: 'license-view/:id',
      //   permission: ['getPlan'],
      //   component: <LicensePlansView />,
      // },
      {
        path: 'licenseplan-edit/:id',
        permission: ['editPlan'],
        component: <LicensePlansEdit />,
      },
    ],
  },
  {
    path: 'licenses',
    children: [
      {
        path: '',
        permission: ['getPlan'],
        component: <License />,
      },
      {
        path: 'license-add',
        permission: ['addPlan'],
        component: <LicenseAdd />,
      },
    ],
  },
  {
    path: 'license-payment',
    children: [
      {
        path: 'summary',
        permission: ['getSubscription'],
        component: <LicensePaymentContent />,
      },
      {
        path: 'info',
        permission: ['getSubscription'],
        component: <LicensePaymentInfo />,
      },
    ],
  },
  {
    path: 'license-featureController',
    children: [
      {
        path: '',
        permission: ['getFeatureController'],
        component: <LicenseFeatureController />,
      },
    ],
  },
];

function AdminRoutes() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  return (
    <div>
      <div className="layout">
        <div className="side-bar-session">
          <SideBar
            setDrawerOpen={setDrawerOpen}
            drawerOpen={drawerOpen}
          />
        </div>
        <div className="content-session">
          <Header
            setDrawerOpen={setDrawerOpen}

          />
          <Outlet />
        </div>
        <Notification />
      </div>
    </div>
  );
}

function Router() {
  const locate = useLocation();
  useEffect(() => {
    const style = document.createElement('style');
    style.id = 'recaptcha-style';
    document.head.appendChild(style);
    if (locate.pathname.startsWith('/super-admin')) {
      style.innerHTML = '.grecaptcha-badge { visibility: hidden; }';
    }
    return () => {
      document.head.removeChild(style);
    };
  }, [locate.pathname]);
  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}>
      <Routes>
        <Route path="/" exact element={<Navigate to="/login" />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/forgot-password" element={<ForgotPassword />} />
        <Route exact path="reset-password" element={<ResetPassword />} />
        <Route exact path="/not-found" element={<NotFoundPage />} />
        <Route path="/super-admin" element={<AdminRoutes />}>
          {
            routeData.map((route) => (
              <Route path={route.path}>
                {
                  route?.children?.map((child) => (
                    <Route
                      path={child.path}
                      element={<AuthGuard permission={child.permission} Component={child.component} />}
                    />
                  ))
                }
              </Route>
            ))
          }
          <Route path="transaction" element={<AuthGuard permission={['getTransaction']} Component={<Transaction />} />}>
            {
              checkPermissionAllow('getSummaryTransaction')
              && <Route path="" element={<Navigate to="summary" />} />
            }
            {
              checkPermissionAllow('getSaasPendingTransaction')
              && <Route path="" element={<Navigate to="saas-pending" />} />
            }
            {
              checkPermissionAllow('getRentalPendingTransaction')
              && <Route path="" element={<Navigate to="rental-pending" />} />
            }
            {
              checkPermissionAllow('getSaasRentalPendingTransaction')
              && <Route path="" element={<Navigate to="saas+rental-pending" />} />
            }
            <Route
              path="summary"
              element={(
                <AuthGuard permission={['getSummaryTransaction']} Component={<TransactionSummary />} />
              )}
            />
            <Route
              path="saas-pending"
              element={(
                <AuthGuard permission={['getSaasPendingTransaction']} Component={<SaaSPending />} />
              )}
            />
            <Route
              path="rental-pending"
              element={(
                <AuthGuard permission={['getRentalPendingTransaction']} Component={<RentalPending />} />
              )}
            />
            <Route
              path="saas+rental-pending"
              element={(
                <AuthGuard
                  permission={['getSaasRentalPendingTransaction']}
                  Component={<SaasRentalPending />}
                />
              )}
            />
          </Route>
          <Route
            path="license-transaction"
            element={<AuthGuard permission={['getTransaction']} Component={<LicenseTransaction />} />}
          >
            {
              checkPermissionAllow('getSummaryTransaction')
              && <Route path="" element={<Navigate to="summary" />} />
            }
            {
              checkPermissionAllow('getSaasPendingTransaction')
              && <Route path="" element={<Navigate to="pending" />} />
            }
            {
              checkPermissionAllow('getSaasPendingTransaction')
              && <Route path="" element={<Navigate to="transactionSummary" />} />
            }
            <Route
              path="summary"
              element={(
                <AuthGuard permission={['getSummaryTransaction']} Component={<LicenseTransactionSummary />} />
              )}
            />
            <Route
              path="pending"
              element={(
                <AuthGuard permission={['getSaasPendingTransaction']} Component={<LicensePending />} />
              )}
            />
            <Route
              path="transactionSummary"
              element={(
                <AuthGuard permission={['getSaasPendingTransaction']} Component={<LicenseHistory />} />
              )}
            />
          </Route>
        </Route>
        <Route path="/*" element={<NotFoundPage />} />
      </Routes>
    </GoogleReCaptchaProvider>
  );
}

export default Router;
