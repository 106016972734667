import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { OpenIcon } from './svgIcon';
import ModalBox from './ModalBox';

function DeviceModal({ deviceId }) {
  const [selectDevice, setSelectedDevice] = useState({});
  const deviceData = [
    {
      id: '001',
      name: 'Device1',
      device_os: 'Android',
      ram: '8GB',
      rom: '256GB',
      orientation: 'Landscape',
      size: '32inch',
      months_12_price: 12,
      months_24_price: 24,
      months_36_price: 36,
    },
    {
      id: '002',
      name: 'Device2',
      device_os: 'iOS',
      ram: '12GB',
      rom: '128GB',
      orientation: 'portrait',
      size: '64inch',
      months_12_price: 34,
      months_24_price: 44,
      months_36_price: 54,
    },
    {
      id: '003',
      name: 'Device3',
      device_os: 'Android',
      ram: '4GB',
      rom: '516GB',
      orientation: 'Landscape',
      size: '16inch',
      months_12_price: 50,
      months_24_price: 100,
      months_36_price: 200,
    },
  ];

  useEffect(() => {
    const tempDevice = deviceData.filter((device) => device.id === deviceId);
    setSelectedDevice(tempDevice[0]);
  }, [deviceId]);

  const detailHeader = [
    {
      name: 'Device name',
      key: 'name',
    },
    {
      name: 'Device OS',
      key: 'device_os',
    },
    {
      name: 'Orientation',
      key: 'orientation',
    },
    {
      name: 'Size',
      key: 'size',
    },
    {
      name: 'RAM',
      key: 'ram',
    },
    {
      name: 'ROM',
      key: 'rom',
    },
  ];

  return (
    <div className="device-details">
      {detailHeader?.map((header) => (
        <div className="each-detail">
          <h3>{header?.name} :</h3>
          <p>{selectDevice[header?.key]}</p>
        </div>
      ))}
    </div>
  );
}

function DeviceDetails(props) {
  const [open, setOpen] = useState(false);
  const closeModal = () => {
    setOpen(false);
  };

  const modalView = {
    title: props?.device?.device_name || 'Device',
    content: <DeviceModal deviceId={props?.device?.deviceId} />,
  };

  return (
    <div className="device-name-wrap">
      <ModalBox
        status={open}
        closeModal={closeModal}
        modalView={modalView}
      />
      {props?.device?.device_name}
      <div
        role="presentation"
        onClick={() => setOpen(true)}
        className="device-open-icon"
      >
        <OpenIcon color="#1A5EEC" />
      </div>
    </div>
  );
}
DeviceModal.propTypes = {
  deviceId: PropTypes.string.isRequired,
};
DeviceDetails.propTypes = {
  device: PropTypes.shape({
    device_name: PropTypes.string.isRequired,
    deviceId: PropTypes.string.isRequired,
  }),
};
DeviceDetails.defaultProps = {
  device: {},
};

export default DeviceDetails;
