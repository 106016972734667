import React, { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import PropTypes from 'prop-types';
import sort from '../../assets/icons/active-sort.png';
import DropDown from './DropDown';

import calender from '../../assets/icons/calendar-tick.png';
import more from '../../assets/icons/more.png';
import PopupModel from './PopupModel';
import profile from '../../assets/images/download.jpg';
import DeviceDetails from './DeviceDetails';

function TableForm(props) {
  const [order, setOrder] = useState(true);
  const shorted = (key) => {
    props?.shorted(props?.value.sort((a, b) => {
      const nameA = a[key].toUpperCase(); // ignore upper and lowercase
      const nameB = b[key].toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return order ? -1 : 1;
      }
      if (nameA > nameB) {
        return order ? 1 : -1;
      }
      return 0;
    }));
    setOrder(!order);
  };
  const handleChange1 = (event) => {
    if (props?.checkedValues.includes(event.target.value)) {
      const select = props?.checkedValues;
      const index = select.indexOf(event.target.value);
      if (index > -1) {
        select.splice(index, 1);
      }
      props?.checkBoxValue('delete', [...select]);
    } else {
      props?.checkBoxValue('add', event.target.value);
    }
  };

  const selectAll = (event) => {
    const permittedValues = props?.value.map((value) => value.id);
    if (event.target.checked) {
      props?.allChecked(permittedValues);
    } else {
      props?.allChecked([]);
    }
  };
  return (
    <table className="table">
      <thead>
        <tr className={props?.class}>
          {
            props?.checkBox && (
              <td label="Checkbox">
                <Checkbox
                  size="small"
                  sx={{
                    color: '#8E8E8E',
                    '&.Mui-checked': {
                      color: '#30A84B',
                    },
                  }}
                  onChange={selectAll}
                />
              </td>
            )
          }
          {
            props?.header.map((value) => (
              <td key={props?.key}>
                <span>{value.name}
                  {
                    value.sort
                    && (
                      <div className="sort-button" role="presentation" onClick={() => shorted(value.key)}>
                        <img src={sort} alt="sort" />
                      </div>
                    )
                  }
                </span>
              </td>
            ))
          }
        </tr>
      </thead>
      <tbody>
        {
          props?.value.map((value) => (
            <tr className={props?.class}>
              {
                props?.checkBox && (
                  <td label="Checkbox">
                    <Checkbox
                      size="small"
                      checked={props?.checkedValues && props?.checkedValues.includes(value.id)}
                      sx={{
                        color: '#8E8E8E',
                        '&.Mui-checked': {
                          color: '#30A84B',
                        },
                      }}
                      onChange={handleChange1}
                      value={value.id}
                    />
                  </td>
                )
              }
              {
                props?.header.map((key) => {
                  let val;
                  val = (
                    <td>
                      {key.tag && value.tag
                        ? (
                          <div className="coll-tag">
                            {value[key.key].substring(0, 50)}{value[key.key].length > 49 && '...'}
                            <div className="tag">{value.tag}</div>
                          </div>
                        )
                        : value[key.key] && value[key.key].toString().replace(/,/g, ', ').substring(0, 50)}{value[key.key] && value[key.key].length > 49 && '...'}
                    </td>
                  );
                  if (key.avatar) {
                    val = (
                      <td>
                        <div className="coll-tag-avatar">
                          <img src={value.signedUrl || profile} alt="profileImage" />
                          <div className="profile_name">
                            {value[key.key].substring(0, 50)}{value[key.key].length > 49 && '...'}
                            <p>{value.email}</p>
                          </div>
                        </div>
                      </td>
                    );
                  }
                  // if (key.avatar) {
                  //   val = (
                  //     <td>
                  //       <div className="coll-tag-avatar">
                  //         <img alt="profile_image" src={value.image} />
                  //         <div className="profile_name">
                  //           {value[key.key].substring(0, 50)}{value[key.key].length > 49 && '...'}
                  //           <p>{value.email}</p>
                  //         </div>
                  //       </div>
                  //     </td>
                  //   );
                  // }
                  if (key.popup) {
                    val = (
                      <td label="Checkbox">
                        <PopupModel list={value[key.key]} name={key.key} />
                      </td>
                    );
                  }
                  if (key.array) {
                    val = (
                      <td className="array-col">
                        {
                          value[key.key].map((values) => (<p>{values.name} : <b>{values.value}</b></p>))
                        }
                      </td>
                    );
                  }
                  if (key.status_tag) {
                    val = (
                      <td className="array-col">
                        {
                          value[key.key] === 'Online'
                            ? (<div className="success-tag">{value[key.key]}</div>)
                            : (<div className="danger-tag">{value[key.key]}</div>)
                        }
                      </td>
                    );
                  }
                  if (key.key === 'createdAt') {
                    val = (
                      <td className="array-col">
                        {new Date(value[key.key]).toLocaleDateString()} {new Date(value[key.key]).toLocaleTimeString()}
                      </td>
                    );
                  }
                  if (key.key === 'scheduleDate' || key.key === 'scheduledTime' || key.key === 'available_time') {
                    val = (
                      <td>
                        {value[key.key]}
                      </td>
                    );
                  }
                  if (key.key === 'devices_list' || key.key === 'contents_list') {
                    val = (
                      <td label="Checkbox">
                        <PopupModel list={value[key.key]} name={key.key.replace('_', ' ')} />
                      </td>
                    );
                  }
                  if (key.key === 'device_name') {
                    val = (
                      <td label="Modal">
                        <DeviceDetails device={value} />
                      </td>
                    );
                  }
                  if (key.key === 'available_days_count') {
                    val = (
                      <td className="array-col">
                        <div style={{ display: 'flex', gap: '10px' }}>
                          <div className="success-tag">{value[key.key]}</div>
                          {value[key.key] > 0
                            && <DropDown action={value.available_days} value={value} icon={calender} />}
                        </div>
                      </td>
                    );
                  }
                  if (key.key === 'unavailable_days_count') {
                    val = (
                      <td className="array-col">
                        <div style={{ display: 'flex', gap: '10px' }}>
                          <div className="danger-tag">{value[key.key]}</div>
                          {value[key.key] > 0
                            && <DropDown action={value.unavailable_days} value={value} icon={calender} />}
                        </div>
                      </td>
                    );
                  }
                  if (key.key === 'action') {
                    val = (
                      <td className="array-col" label="Checkbox">
                        <DropDown action={props?.action} value={value} icon={more} />
                      </td>
                    );
                  }
                  return val;
                })
              }
            </tr>
          ))
        }

        {
          props?.value.length === 0
          && (<tr><td colSpan={props?.header.length} className="no-data-found">No data found</td></tr>)
        }
      </tbody>
    </table>
  );
}

TableForm.propTypes = {
  header: PropTypes.node.isRequired,
  key: PropTypes.string.isRequired,
  value: PropTypes.node.isRequired,
  action: PropTypes.node.isRequired,
  checkBox: PropTypes.node.isRequired,
  checkBoxValue: PropTypes.func.isRequired,
  checkedValues: PropTypes.node.isRequired,
  class: PropTypes.node.isRequired,
  allChecked: PropTypes.func.isRequired,
  shorted: PropTypes.func.isRequired,
};

export default TableForm;
