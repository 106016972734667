/* eslint-disable no-param-reassign */
/* eslint no-underscore-dangle: 0 */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import company from '../../utils/api/licenses';
import { setErrorNotification } from './NotificationSlice';
import { changeDateTimeFormat } from '../../pages/Global/timeFormat';

export const fetchServerIndirectList = createAsyncThunk(
  'indirect/fetchServerIndirectList',
  async (data, { dispatch }) => {
    const response = await company.getIndirectSeverCompanies(data)
      .then((response1) => {
        const tableValues = response1?.data?.results.map((val) => ({
          ...val,
          id: val?._id,
          city_state: `${val?.city}, ${val?.state}`,
          createdAt: changeDateTimeFormat(val?.createdAt),
        }));
        return { ...response1?.data, results: tableValues, code: 200 };
      })
      .catch((error) => dispatch(setErrorNotification(error?.response?.data)));
    return response;
  },
);

const initialState = {
  inDirectServers: [],
  pageCount: 0,
  totalPageCount: 1,
  totalDataCount: 0,
  status: 'success',
  filter: {},
  tableFilterData: {},
};

export const serverSlice = createSlice({
  name: 'cmsServer',
  initialState,
  reducers: {
    addServerDetails: (state, action) => {
      state.inDirectServers.unshift({
        ...action.payload,
        id: action?.payload?._id,
        city_state: `${action?.payload?.city}, ${action?.payload?.state}`,
        createdAt: changeDateTimeFormat(action?.payload?.createdAt),
      });
    },
    updateDistributorDetails: (state, action) => {
      state.inDirectServers = state.inDirectServers.map((companyDetails) => {
        let output = companyDetails;
        if (companyDetails.id === action?.payload?._id) {
          output = {
            ...action.payload,
            id: action?.payload?._id,
            city_state: `${action?.payload?.city}, ${action?.payload?.state}`,
            createdAt: changeDateTimeFormat(action?.payload?.createdAt),
          };
        }
        return output;
      });
    },
    deleteDistributorDetails: (state, action) => {
      state.inDirectServers = state.inDirectServers.filter((companyDetails) => companyDetails.id !== action?.payload);
    },
    setTableFilterData: (state, action) => {
      state.tableFilterData = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    resetDistributorState: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchServerIndirectList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchServerIndirectList.fulfilled, (state, action) => {
        // Add user to the state array
        if (action?.payload?.code === 200) {
          state.pageCount = action.payload.page;
          state.totalPageCount = action.payload.totalPages > 0 ? action.payload.totalPages : 1;
          const data = action.payload.results;
          if (action.payload.page === 1) {
            state.inDirectServers = data;
          } else {
            const finalData = [...state.inDirectServers];
            // Add fetchedData to finalData, but only if the id is not already present
            data.forEach((obj) => {
              if (!finalData.some((item) => item._id === obj._id)) {
                finalData.push(obj);
              }
            });
            state.inDirectServers = finalData;
          }
        }
        state.status = 'success';
      });
  },
});

export const {
  setTableFilterData,
  setFilter,
  addServerDetails,
  resetDistributorState,
  updateDistributorDetails,
  deleteDistributorDetails,
} = serverSlice.actions;

export default serverSlice.reducer;
