/* eslint-disable no-param-reassign */
/* eslint no-underscore-dangle: 0 */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { setErrorNotification } from './NotificationSlice';
import transaction from '../../utils/api/licenses';
import { changeDateFormat, currencySymbol } from '../../utils/helpers';

const formatTransactionSummaryData = (data) => {
  const formattedData = data.results?.map((item) => ({
    ...item,
    id: item?._id,
    plan_name: item?.plan_name ? item.plan_name : '-',
    transaction_date: item?.transaction_date ? changeDateFormat(item?.transaction_date) : '-',
    price_to_display: `${currencySymbol(item?.curreny || 'USD')} ${item?.transaction_amount}`,
    is_verified: item?.is_verified ? 'verified' : 'unverified',
    status: item?.is_verified ? 'verified' : 'unverified',
  }));
  return formattedData;
};

export const fetchTransactionSummary = createAsyncThunk(
  'transactionPending/fetchTransactionSummary',
  async (data, { dispatch }) => {
    const response = await transaction.getLicenseTransactionSummary(data)
      .then((res) => ({
        ...res.data,
        results: formatTransactionSummaryData(res.data),
        code: 200,
      }))
      .catch((error) => dispatch(setErrorNotification(error?.response?.data)));
    return response;
  },
);

const initialState = {
  transactionSummary: [],
  pageCount: 0,
  totalPageCount: 1,
  totalDataCount: 0,
  filter: {},
  tableFilterData: {},
  status: 'succeed',
};

export const transactionSummarySlice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    setTableFilterData: (state, action) => {
      state.tableFilterData = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    resetTransactionState: (state) => {
      Object.assign(state, initialState);
    },
    removePendingById: (state, action) => {
      const pendingList = state.transactionSummary.map((val) => {
        const output = val;
        if (output.id === action.payload) {
          output.is_verified = 'verified';
        }
        return output;
      });
      state.transaction = pendingList;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchTransactionSummary.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTransactionSummary.fulfilled, (state, action) => {
        if (action?.payload?.code === 200) {
          const data = action?.payload?.results;
          state.pageCount = action.payload.page;
          state.totalPageCount = action?.payload?.totalPages === 0 ? 1 : action?.payload?.totalPages;
          if (action?.payload?.page === 1) {
            state.transactionSummary = data;
          } else {
            const finalData = [...state.transactionSummary];
            // Add fetchedData to finalData, but only if the id is not already present
            data.forEach((obj) => {
              if (!finalData.some((item) => item._id === obj._id)) {
                finalData.push(obj);
              }
            });
            state.transactionSummary = finalData;
          }
        }
        state.status = 'succeed';
      });
  },
});

export const {
  setTableFilterData,
  setFilter,
  resetTransactionState,
  removePendingById,
} = transactionSummarySlice.actions;

export default transactionSummarySlice.reducer;
