import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cookies from 'js-cookie';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

export default function DropDownMenu(props) {
  const currentLanguageCode = cookies.get('i18next') || 'en';

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const statusCheck = (name, status) => (props?.statusCheck ? props?.statusCheck(name, status) : false);

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          textAlign: 'center',
          paddingTop: '2px',
        }}
      >
        <Tooltip title={props?.tooltip}>
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2, marginLeft: 0 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <img src={props?.icon} alt="" className="menu-icon" style={{ height: props?.height }} />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            marginLeft: '0',
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 4px rgba(0,0,0,0.25))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              marginLeft: '0',
              position: 'absolute',
              top: 0,
              right: currentLanguageCode === 'ar' ? 'none' : '14px',
              left: currentLanguageCode === 'ar' ? '14px' : 'none',
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {props?.action?.map((item) => {
          let value;
          if (props?.dropdown === 'tableForm') {
            value = (
              <div>
                <MenuItem
                  onClick={() => item?.function(item?.sendFullDetails ? props?.value : props?.value?.id)}
                  disabled={item?.disabled || statusCheck(item?.name, props?.value?.status)}
                >
                  <p className="menu-text" id={item?.id}>{item?.name}{statusCheck(item?.name, props?.value?.status)}</p>
                </MenuItem>
                {props?.divider && <Divider />}
              </div>
            );
          } else if (props?.dropdown === 'dropdown') {
            value = (
              <div>
                <MenuItem
                  onClick={() => item?.function(item?.code)}
                  disabled={item?.disabled || statusCheck(item?.name, props?.value?.status)}
                >
                  <p className="menu-text" style={{ margin: 0 }}>{item?.name}</p>
                </MenuItem>
                {props?.divider && <Divider />}
              </div>
            );
          }
          return value;
        })}
      </Menu>
    </div>
  );
}
DropDownMenu.propTypes = {
  tooltip: PropTypes.string,
  icon: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  action: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string.isRequired,
      code: PropTypes.string,
      function: PropTypes.func.isRequired,
      sendFullDetails: PropTypes.bool,
      disabled: PropTypes.bool,
    }),
  ).isRequired,
  dropdown: PropTypes.string.isRequired,
  value: PropTypes.shape({
    id: PropTypes.string.isRequired,
    status: PropTypes.string,
  }),
  statusCheck: PropTypes.func,
  divider: PropTypes.bool,
};
DropDownMenu.defaultProps = {
  value: {
    id: '',
    status: '',
  },
  tooltip: '',
  divider: false,
  statusCheck: () => false,
};
