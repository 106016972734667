/* eslint-disable no-param-reassign */
/* eslint no-underscore-dangle: 0 */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { setErrorNotification } from './NotificationSlice';
import transaction from '../../utils/api/transaction';
import { changeDateFormat, currencySymbol } from '../../utils/helpers';

const formatSaasRentalDataToDisplay = (rentalData) => {
  const formattedData = rentalData.map((item) => ({
    ...item,
    id: item?._id,
    product: item?.plan?.product,
    order_id: item?.subscription.order_id,
    account: item?.company?.name,
    actual_price: item?.total_price,
    tenure: `${item?.subscription?.tenure} months`,
    subscription_total_price: `${currencySymbol(item?.company?.currency || 'USD')} ${item?.subscription?.total_price}`,
    price_to_display: `${currencySymbol(item?.company?.currency || 'USD')} ${item?.total_price}`,
    valid_from: changeDateFormat(item?.subscription?.start_date),
  }));
  return formattedData; // Return the formatted data
};

export const fetchSaasDeviceRentalPending = createAsyncThunk(
  'transactionPending/fetchSaasDeviceRentalPending',
  async (data, { dispatch }) => {
    const response = await transaction.getSaasDeviceRentalPending(data)
      .then((res) => ({
        ...res.data,
        results: formatSaasRentalDataToDisplay(res.data.results),
        code: 200,
      }))
      .catch((error) => dispatch(setErrorNotification(error?.response?.data)));
    return response;
  },
);

const initialState = {
  updatePaymentType: '',
  selectedOrders: [],
};

export const transactionPendingSlice = createSlice({
  name: 'transactionPending',
  initialState,
  reducers: {
    setTableFilterData: (state, action) => {
      state.tableFilterData = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    setSelectedOrders: (state, action) => {
      state.updatePaymentType = action.payload.updatePaymentType;
      state.selectedOrders = action.payload.selectedOrders;
    },
    resetTransactionState: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSaasDeviceRentalPending.pending, (state) => {
        state.saasDeviceRentalStatus = 'loading';
      })
      .addCase(fetchSaasDeviceRentalPending.fulfilled, (state, action) => {
        if (action?.payload?.code === 200) {
          state.saasDeviceRentalPageCount = action.payload.page + 1;
          state.saasDeviceRentalTotalPageCount = action.payload.totalPages > 0
            ? action.payload.totalPages
            : 1;
          const fetchedData = action.payload.results;
          if (action.payload.page === 1) {
            state.saasDeviceRentalPending = fetchedData;
          } else {
            state.saasDeviceRentalPending.push(...fetchedData);
          }
        }
        state.saasDeviceRentalStatus = 'succeed';
      });
  },
});

export const {
  setFilter,
  setTableFilterData,
  setSelectedOrders,
  resetTransactionState,
} = transactionPendingSlice.actions;

export default transactionPendingSlice.reducer;
